import React, { Dispatch, useEffect, useState } from "react";
import { Formik, FormikProps, useFormikContext } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button as MButton,
  Stepper,
  MobileStepper,
  Step,
  StepLabel,
  StepButton,
  Fab,
  Paper,
  Grid,
  Box,
  Snackbar,
} from "@material-ui/core";
import { useCounter } from "ahooks";
import { Close as CloseIcon, Info } from "@material-ui/icons";
import useStyles from "./styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CompanyInfo, FileUpload, Share, ElevatorPitch, ProblemStatement, Solution, Market, Milestone, Funds, GoToMarket, Tokenomics, Competition, Business } from "./components";
import { Button } from "../../components/Wrappers/Wrappers";
import FadeIn from "react-fade-in";
import { ProjectDispatch, FormInfo, LayoutAction, AddTeams } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { createProject } from "../../actions/project";
import { RootState } from "../../reducers";
import ReactDOMServer from 'react-dom/server'
import html2pdf from 'html2pdf.js';
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import { countries, sectors } from "./components/data";
import { showUpgradeModal } from "../../actions/layout";
// import { Moment } from "moment";
import AutomateCompanyScore from "./components/AutomateScore/AutomateCompanyScore";
import BusinessLandscape from "./components/BusinessLandscape/BusinessLandscape";
import ExpertScore from "./components/ExpertScore/ExpertScore";
import invitation from '../../repositories/invitation';
import { validationSchema, scoringAssist } from "./components/infos";
// import DemoComponent from "./components/companyDetailPDF";
import CompanyDetailPDF from "./components/companyDetailPDF";
import { callableFunctions, firestore, storage } from "../../repositories/firebase";
import PdfCardComponents from "./components/PdfCardComponents";
import TeamDetailPDF from "./components/teamDetailPDF";
import axios from 'axios';
import PDFComponent from "./components/PDFComponent";
import CreatePitchDeckDialog from "../../components/Modals/CreatePitchDeckDialog";
import ElivaterPitchPdfComponents from "./components/ElivaterPitchPdfComponents";
import SubscriptionCard from "./components/plan";
import { getParentCollection } from "./draftData";
import { Alert } from "@mui/material";
// import * as model from "../../model";


const projectCollection = firestore.collection("project");


function Create() {

  const intitialValues: FormInfo = {
    ...scoringAssist,
    legal: true,
    sections: ["team", "idea", "market", "competition", "finance", "esg"],
    emails: [],
    selectedEmails: [],
    companyName: "",
    companyDescription: "",
    rapidDescription: "",
    companyImage: "",
    companySector: "",
    companyLocation: "",
    companyStage: "early",
    companyWebsite: "",
    privacy: 0,
    dealSource: "",
    contactEmail: "",
    sectionWeights: { "competition": 10, "esg": 5, "finance": 15, "idea": 25, "market": 20, "team": 25 },
    emailSections: {},
    sendProjectToMe: false,
    dateRange: [],
    scoringTypes: ["Technical", "i3D Expert", "i3D Rapid", "all"],
    pdfLinks: {},
    imageLinks: {},
    singlePdf: false,
    singlePdfLink: "",
    crypto: true,
    CompetitiveLandscape: 'No Competitive Landscape selected',
    MarketingPartners: "No Marketing Partners selected",
    SocialNetwork: "No Social & Networking Presence selected",
    industryCagr: "No   Industry CAGR selected",
    marketRisk: "Market Risk Perception",
    SalesChannels: "No Sales Channels selected",
    marketSize: "No Size of Market selected",
    MAU: "",
    TRL_Levels: '',
    needExpertScore: 'No',
    fundFromWhere: "Funds come from where?",
    burnRate: "Burn Rate",
    RevenueStage: '',
    CapitalSize: "Size of intended capital raise",
    ExternalFundYear: "ExternalFundYear"
  
  };
  const classes = useStyles();
  let { id } = useParams();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const history = useHistory();
  const [step, { inc, dec }] = useCounter(0);
  const [yesNoSwitch, setyesNoSwitch] = useState("No")
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const { creating, createError } = useSelector(
    (state: RootState) => state.project
  );
  const userData = useSelector((state: RootState) => state.firebase.auth);
  const dispatch = useDispatch<ProjectDispatch>();
  const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
  const [user, setUser] = React.useState<AddTeams[]>([]);
  const [technicalScoringFund, setTechnicalScoringFund] = React.useState<Record<string, any>[]>([]);
  const [technicalScoringMarket, setTechnicalScoringMarket] = React.useState<Record<string, any>[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeName, setActiveName] = React.useState("");
  const [companyData, setCompanyData] = React.useState("");
  const [pitchDeckUrl, setPitchDeckUrl] = React.useState("")
  const [pdfLoader, setPdfLoader] = React.useState(false)
  const [openPitchDeckPopup, setOpenPitchDeckPopup] = useState(false)
  const [companyStepFilled, setCompanyFilled] = React.useState(false);
  const [formikData, setFormikData] = React.useState();
  const [problemData, setProblemData] = React.useState();
  const [solutionData, setSolutionData] = React.useState();
  const [marketData, setMarketData] = React.useState();
  const [businessData, setBusinessData] = React.useState();
  const [gotoMarketData, setGotoMarketData] = React.useState();
  const [milestoneData, setMilestoneData] = React.useState();
  const [fundsData, setFundsData] = React.useState();
  const [competative, setCompetative] = React.useState();
  const [tokenamicsData, setTokenamicsData] = React.useState();
  const [openPlan, setOpenPlan] = useState(false)
  const [companyPdfData, setCompanyPdfData] = React.useState({});
  const [elivaterPitch, setElivaterPitch] = useState()
  const [updatedInitialValue, setUpdatedInitialValue] = useState(null)
  const [state, setState] = React.useState(false);
  const [hasNavigatedBack, setHasNavigatedBack] = useState(false);
  let clickTypeSubmit = false
  //   const { setFieldValue } = useFormikContext<
  //   FormInfo
  // >();


  // const onOpenSnackbar = () => {
  //   setState(true);
  // };

  const handleCloseSnackbar = () => {
    setState(false);
  };
  const formik = useFormikContext<FormInfo>();

  function areAllKeysFilled(obj) {
    for (let key in obj) {
      if (!obj.hasOwnProperty(key) || !obj[key]) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (areAllKeysFilled(companyData) == true) {
      setCompanyFilled(true)
    }
  }, [companyData])

  useEffect(() => {
    // for populate data in create form if the project is in autosave
    if(id != '' && id != undefined){ 
    getParentCollection(intitialValues, projectCollection, id, technicalScoringMarket, technicalScoringFund)
    setyesNoSwitch(formikData ? formikData.needExpertScore : "No")
    getTeamDataCallableFunction(id)
    }else{
      console.debug("wjkbjkwk:else mein gaya", intitialValues)
    }
  }, [projectCollection, id != "", technicalScoringMarket.length > 0])

  const openPriceDialog = () => {
    setOpenPlan(true)
  }

  const getTeamDataCallableFunction = async (id) => {
    const getTeamMemberDetail = callableFunctions.httpsCallable('getTeamMemberDetail')
    const result = await getTeamMemberDetail({ projectId: id })
    setUser(result.data.teamData)
  }

  // useEffect(() => {
  //   const handlePopstate = () => {
  //     // Call your function when the back button is pressed
  //     onCloseProject(formikData);
  //   };

  //   // Add the event listener for the popstate event
  //   window.addEventListener('popstate', handlePopstate);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, [formikData]);


  var stage = 9
  const location = useLocation()

  useEffect(() => {
    // Validation for plan purchase and project upload
    if (profile.isEmpty == false && profile.isLoaded == true) {
      if (profile.currentPlan) {
        let plan = profile.currentPlan
        if (plan.uploadProject != plan.uploadedProject && plan.uploadProject >= plan.uploadedProject) {
          // return history.push("/create");
        } else {
          if (location.pathname == "/create") {
            return history.push("/app/dashboard");
          }
          return dispatchLayout(showUpgradeModal());
        }
      } else {
        openPriceDialog()
      }
    }
  }, [profile.isEmpty == false && profile.isLoaded == true]);

  useEffect(() => {
    invitation.getTechnicalQuestions().then((res) => {
      setTechnicalScoringFund(res.filter((val) => val.section == 'finance'));
      setTechnicalScoringMarket(res.filter((val) => val.section == 'market'));
    });
    // invitation.getTechnicalMarket().then((res)=>{
    //   setTechnicalScoringMarket(res);
    // });
  }, []);

  function addWidthAndHeightToImage(htmlString, width, height) { // function for fixing the size of image in pitch deck project document
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const imgTag = doc.querySelector('img');

    if (imgTag) {
      imgTag.setAttribute('width', width);
      imgTag.setAttribute('height', height);
    }

    return doc.documentElement.innerHTML;
  }

  function convertDataImg(data) {  //function for fixing the size of image in pitch deck project document

    const containsImage = data.includes('<img');

    // Set the width and height if it contains an image
    if (containsImage) {
      const modifiedHtml = addWidthAndHeightToImage(data, 250, 250); // Set your desired width and height
      return modifiedHtml;
    } else {
      return data
    }

  }


  useEffect(() => {
    // useEffect is used for formatting data to Populate data in Pitch deck Pdf
    let company = {
      "companyImage": formikData ? formikData.companyImage : "",
      "companyDescription": formikData ? formikData.companyDescription : "",
      "companyName": formikData ? formikData.companyName : "",
      "companyStage": formikData ? formikData.companyStage : "",
      "companySector": formikData ? formikData.companySector : "",
      "companyWebsite": formikData ? formikData.companyWebsite : "",
    }
    if (formikData != undefined && formikData.companyImage != "") {
      convertImageUrlToBlobTestGiveHtml(formikData ? formikData.companyImage : "").then(r => {
        company.image = r;
      });
    }
    setCompanyPdfData(company)

    /** Problem Defineition Data */
    let problem = {
      "heading": "Problem Definition",
      "cardHeading1": "Problem Statement",
      "cardData1": convertDataImg(formikData ? formikData.statement : ""),
      "cardHeading2": "Define The Problem",
      "cardData2": convertDataImg(formikData ? formikData.DTP : ""),
      "cardHeading3": "Market Dynamics",
      "cardData3": convertDataImg(formikData ? formikData.marketDynamics : ""),
      "cardHeading4": "Pain Points",
      "cardData4": convertDataImg(formikData ? formikData.painPoints : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.problem1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.problem2 : "" : "",
    }
    let imgArray = [];
    if (problem.imageLink1) {
      imgArray.push(problem.imageLink1);
    }
    if (problem.imageLink2) {
      imgArray.push(problem.imageLink2);
    }
    Promise.all(imgArray.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        problem.images = convertedImages;
      });
    setProblemData(problem)

    /** Solution  Data */
    let solution = {
      "heading": "Solution Proposed",
      "cardHeading1": "Solution Statement",
      "cardData1": convertDataImg(formikData ? formikData.solutionStatement : ""),
      "cardHeading2": "UPS's &  Benefits",
      "cardData2": convertDataImg(formikData ? formikData.USPB : ""),
      "cardHeading3": "Product Key Features",
      "cardData3": convertDataImg(formikData ? formikData.PKF : ""),
      "cardHeading4": "Use Cases",
      "cardData4": convertDataImg(formikData ? formikData.useCases : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.solution1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.solution2 : "" : "",
    }
    let imgArraySolution = [];
    if (solution.imageLink1) {
      imgArraySolution.push(solution.imageLink1);
    }
    if (problem.imageLink2) {
      imgArraySolution.push(solution.imageLink2);
    }
    Promise.all(imgArraySolution.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        solution.images = convertedImages;
      });
    setSolutionData(solution)

    /** Marget  Data */

    let market = {
      "heading": "Market Potential",
      "cardHeading1": "Market Size: TAM, SAM, Target Market",
      "cardData1": convertDataImg(formikData ? formikData.marketSizeTam : ""),
      "cardHeading2": "Market Growth Potential",
      "cardData2": convertDataImg(formikData ? formikData.MGP : ""),
      "cardHeading3": "Market Opportunity",
      "cardData3": convertDataImg(formikData ? formikData.marketOpportunity : ""),
      "cardHeading4": "Market Trends",
      "cardData4": convertDataImg(formikData ? formikData.marketTrends : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.market1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.market2 : "" : "",
    }
    let imgArrayMarket = [];
    if (market.imageLink1) {
      imgArrayMarket.push(market.imageLink1);
    }
    if (market.imageLink2) {
      imgArrayMarket.push(market.imageLink2);
    }
    Promise.all(imgArrayMarket.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        market.images = convertedImages;
      });
    setMarketData(market)


    /** Bussiness  Data */

    let bussines = {
      "heading": "Business Model",
      "cardHeading1": "Revenue Streams",
      "cardData1": convertDataImg(formikData ? formikData.revenueStreams : ""),
      "cardHeading2": "Cost Structure and Runway",
      "cardData2": convertDataImg(formikData ? formikData.costStructure : ""),
      "cardHeading3": "Sales Channels-Customer pipeline",
      "cardData3": convertDataImg(formikData ? formikData.salesChannelsCustomer : ""),
      "cardHeading4": "Customer Acquisition Strategy",
      "cardData4": convertDataImg(formikData ? formikData.CAS : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.business1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.business2 : "" : "",
    }
    let imgArrayBussines = [];
    if (bussines.imageLink1) {
      imgArrayBussines.push(bussines.imageLink1);
    }
    if (bussines.imageLink2) {
      imgArrayBussines.push(bussines.imageLink2);
    }
    Promise.all(imgArrayBussines.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        bussines.images = convertedImages;
      });
    setBusinessData(bussines)

    /** Go To Markeet  Data */

    let gotoMarket = {
      "heading": "Go To Market",
      "cardHeading1": "Target Market Audience",
      "cardData1": convertDataImg(formikData ? formikData.targetAudience : ""),
      "cardHeading2": "User Profiles",
      "cardData2": convertDataImg(formikData ? formikData.userProfiles : ""),
      "cardHeading3": "Market Strategy",
      "cardData3": convertDataImg(formikData ? formikData.marketingStrategy : ""),
      "cardHeading4": "Sales Strategy",
      "cardData4": convertDataImg(formikData ? formikData.salesStrategy : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.goto1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.goto2 : "" : "",
    }
    let imgArraygotoMarket = [];
    if (gotoMarket.imageLink1) {
      imgArraygotoMarket.push(gotoMarket.imageLink1);
    }
    if (gotoMarket.imageLink2) {
      imgArraygotoMarket.push(gotoMarket.imageLink2);
    }
    Promise.all(imgArraygotoMarket.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        gotoMarket.images = convertedImages;
      });
    setGotoMarketData(gotoMarket)

    /**Milestone Data */

    let milestone = {
      "heading": "Traction And Milestones",
      "cardHeading1": "Milestones &  Traction",
      "cardData1": convertDataImg(formikData ? formikData.MT : ""),
      "cardHeading2": "Market Validation",
      "cardData2": convertDataImg(formikData ? formikData.marketValidation : ""),
      "cardHeading3": "Stategic Partnerships",
      "cardData3": convertDataImg(formikData ? formikData.strategicPartnerships : ""),
      "cardHeading4": "Future Plans",
      "cardData4": convertDataImg(formikData ? formikData.futurePlans : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.milestone1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.milestone2 : "" : "",
    }
    let imgArrayMilestone = [];
    if (milestone.imageLink1) {
      imgArrayMilestone.push(milestone.imageLink1);
    }
    if (milestone.imageLink2) {
      imgArrayMilestone.push(milestone.imageLink2);
    }
    Promise.all(imgArrayMilestone.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        milestone.images = convertedImages;
      });
    setMilestoneData(milestone)

    /**Funds Data */

    let funds = {
      "heading": "Funding and Ask",
      "cardHeading1": "Equity and/or Token Structure",
      "cardData1": convertDataImg(formikData ? formikData.fundingAmount : ""),
      "cardHeading2": "Use of Funds & Milestones",
      "cardData2": convertDataImg(formikData ? formikData.useofFunds : ""),
      "cardHeading3": "Valuation",
      "cardData3": convertDataImg(formikData ? formikData.valuation : ""),
      "cardHeading4": "Financial Projections Summary",
      "cardData4": convertDataImg(formikData ? formikData.financialProjections : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.fund1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.fund2 : "" : "",
    }
    let imgArrayFunds = [];
    if (funds.imageLink1) {
      imgArrayFunds.push(funds.imageLink1);
    }
    if (funds.imageLink2) {
      imgArrayFunds.push(funds.imageLink2);
    }
    Promise.all(imgArrayFunds.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        funds.images = convertedImages;
      });
    setFundsData(funds)

    /**Competative Data */

    let competative = {
      "heading": "Competitive Landscape",
      "cardHeading1": "Overview about Competitive Landscape",
      "cardData1": convertDataImg(formikData ? formikData.competitiveLandscape : ""),
      "cardHeading2": "Identify Competitors",
      "cardData2": convertDataImg(formikData ? formikData.identifyCompetitors : ""),
      "cardHeading3": "Competitive Advantage",
      "cardData3": convertDataImg(formikData ? formikData.competitiveAdvantage : ""),
      "cardHeading4": "MOAT and IP",
      "cardData4": convertDataImg(formikData ? formikData.MOAT : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.competition1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.competition2 : "" : "",
    }
    let imgArrayCompetative = [];
    if (competative.imageLink1) {
      imgArrayCompetative.push(competative.imageLink1);
    }
    if (competative.imageLink2) {
      imgArrayCompetative.push(competative.imageLink2);
    }
    Promise.all(imgArrayCompetative.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        competative.images = convertedImages;
      });
    setCompetative(competative)

    /**Tokenomics Data */

    let tokenomics = {
      "heading": "Tokenomics",
      "cardHeading1": "Token Utility",
      "cardData1": convertDataImg(formikData ? formikData.tokenUtility : ""),
      "cardHeading2": "Token Distribution & Vesting periods",
      "cardData2": convertDataImg(formikData ? formikData.tokenDistribution : ""),
      "cardHeading3": "Token Sale Details",
      "cardData3": convertDataImg(formikData ? formikData.tokenSale : ""),
      "cardHeading4": "Incentive &  Regulatory Compliance",
      "cardData4": convertDataImg(formikData ? formikData.incentives : ""),
      "imageLink1": formikData ? formikData.imageLinks ? formikData.imageLinks.token1 : "" : "",
      "imageLink2": formikData ? formikData.imageLinks ? formikData.imageLinks.token2 : "" : "",
    }
    let imgArrayTokenomics = [];
    if (tokenomics.imageLink1) {
      imgArrayTokenomics.push(tokenomics.imageLink1);
    }
    if (tokenomics.imageLink2) {
      imgArrayTokenomics.push(tokenomics.imageLink2);
    }
    Promise.all(imgArrayTokenomics.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
      .then(convertedImages => {
        tokenomics.images = convertedImages;
      });
    setTokenamicsData(tokenomics)

    /**Elivater Pitch */
    let elivaterPitch = {
      "heading": "Elevator Pitch",
      "cardData": convertDataImg(formikData ? formikData.rapidDescription : ""),

    }
    setElivaterPitch(elivaterPitch)
  }, [formikData]);

  const handleNext = (step, stage) => {
    setActiveStep(step + 1);
    setActiveName(stage)
  };
  const handleBack = (step, stage) => {
    setActiveStep(step - 1);
    setActiveName(stage)

  };

  const PdfJSX = ({ formikData }) => { // Component of Pitch Deck HTML which shows in the pdf 

    return (
      <div id="element-to-print" >
        <CompanyDetailPDF data={companyPdfData} />
        {formikData.rapidDescription && <>
          < div className="html2pdf__page-break" > </div>
          <ElivaterPitchPdfComponents data={elivaterPitch} /></>}
        {formikData.statement && <>
          < div className="html2pdf__page-break" > </div>
          <PdfCardComponents data={problemData} /></>}
        {formikData.solutionStatement && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={solutionData} />
        </>}
        {formikData.marketSizeTam && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={marketData} />
        </>}
        {formikData.revenueStreams && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={businessData} />
        </>}
        {formikData.targetAudience && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={gotoMarketData} />
        </>}
        {formikData.MT && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={milestoneData} />
        </>}
        {formikData.fundingAmount && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={fundsData} />
        </>}
        {formikData.competitiveLandscape && <>

          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={competative} />
        </>}
        {formikData.tokenUtility && <>

          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={tokenamicsData} />
        </>}
        {user.length && <>
          < div className="html2pdf__page-break" > </div>
          < TeamDetailPDF data={user} />
        </>}
      </div>
    );
  };


  async function goToCreatePdf(values) {  //function to create pitch pdf function at the time of project creation 
 
    setPdfLoader(true)
    const opt = {
      margin: [0, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    const printElement = ReactDOMServer.renderToString(<PdfJSX formikData={formikData} />);
    const modifiedPrintElement = `<div style="background-color: #002060";>
                             ${printElement}
                          </div>`;
    const pdfBlob = await html2pdf().from(modifiedPrintElement).set(opt).outputPdf("blob");

    // Upload the PDF blob to Firestore
    const storageRef = storage.ref('pitchDeck');
    const pdfRef = storageRef.child(`${companyPdfData.companyName}.pdf`);
    try {
      await pdfRef.put(pdfBlob);
      const pdfDownloadUrl = await pdfRef.getDownloadURL();

      setPitchDeckUrl(pdfDownloadUrl)
      clickTypeSubmit = true
    dispatch(createProject(values, history, technicalScoringFund, technicalScoringMarket, userData.uid, pdfDownloadUrl, user, clickTypeSubmit, id))
    // setPdfLoader(false)
      // console.debug('PDF stored in Firestore:', pdfDownloadUrl);
    } catch (error) {
      console.error('Error storing PDF in Firestore:', error);
    }
  }

  async function convertImageUrlToBlobTestGiveHtml(imageUrl) {
    try {
      const response = await axios.get(imageUrl, { responseType: 'blob' });

      if (response.status === 200) {
        const blobUrl = URL.createObjectURL(response.data);

        return blobUrl;
      } else {
        throw new Error('Image fetch failed');
      }
    } catch (error) {
      return null;
    }
  }

  // console.debug("userData:profile", profile)
  function getButtons(step, activeName, formik) {
    switch (activeName) {
      case "Company Info":
        return <Button type="button" onClick={() => handleNext(activeStep, "Elevator Pitch")}>
          Next
        </Button>;
      case "Elevator Pitch":
        return (
          <>
            {profile.currentPlan && profile.currentPlan.name === "Start-up" ? 
            <Button type="button" onClick={() => handleNext(10, "Teams")}>
              Next
            </Button> :
              <Button type="button" onClick={() => handleNext(activeStep, "Problem Statement")}>
                Next
              </Button>}

            <Button type="button" onClick={() => handleBack(activeStep, "Company Info")}>
              Back
            </Button></>
        );;
      case "Problem Statement":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Solution")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Elevator Pitch")}>
              Back
            </Button></>
        );;
      case "Solution":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Business Model")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Problem Statement")}>
              Back
            </Button></>
        );
      case "Business Model":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Market")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Solution")}>
              Back
            </Button></>
        );
      case "Market":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Competition")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Business Model")}>
              Back
            </Button></>
        );
      case "Competition":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Milestone")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Market")}>
              Back
            </Button></>
        );
      case "Milestone":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Funds")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Competition")}>
              Back
            </Button></>
        );
      case "Funds":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "GoToMarket")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Milestone")}>
              Back
            </Button></>
        );
      case "GoToMarket":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, formik.values.crypto == true ? "Tokenomics" : "Teams")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Funds")}>
              Back
            </Button></>
        );
      case "Tokenomics":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Teams")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "GoToMarket")}>
              Back
            </Button></>
        );
      case "Teams":
        return (
          <>
            <Button type="button" onClick={() => { handleNext(activeStep, "Business Lanscape"); }}>
              Next
            </Button>
            <Button type="button" onClick={() => handleBack(activeStep,  "Tokenomics")}>
              Back
            </Button>
          </>
        );
      case "Business Lanscape":
        // if ((formik.values.scoringTypes).includes("i3D Expert") || (formik.values.scoringTypes).includes("all")) {
          return (
            <>
              {profile.currentPlan.name === "Start-up" ? <Button
                type="button"
                onClick={() => {
                  formik.values.team = user;
                  formik.values.legal = true;
                  goToCreatePdf(formik.values)
                }}
              >
                Submit
              </Button> :
                <Button type="button" onClick={() => { handleNext(12, "Sections"); }}>
                  Next
                </Button>
                }

              <Button type="button" onClick={() => handleBack(activeStep, "Teams")}>
                Back
              </Button>
              </>
          );
        // }
        // return (
        //   <>   {profile.currentPlan.name === "Start-up" ? <Button
        //     type="button"
        //     onClick={() => {
        //       formik.values.team = user;
        //       formik.values.legal = true;
        //       goToCreatePdf(formik.values)
        //     }}
        //   >
        //     Submit
        //   </Button> :
        //     <Button type="button" onClick={() => { handleNext(12, "Sections") }}>
        //       Next
        //     </Button>}
        //     <Button type="button" onClick={() => handleBack(activeStep, "Teams")}>
        //       Back
        //     </Button></>
        // );

      //   let validExpert =
      //     !formik.errors.companyImage &&
      //     !formik.errors.companyDescription &&
      //     !formik.errors.companyName &&
      //     !formik.errors.companySector &&
      //     !formik.errors.companyStage &&
      //     !formik.errors.companyWebsite &&
      //     !formik.errors.dealSource;

      //   if (yesNoSwitch == "No") {
      //     return (
      //       <>
      //         <Button
      //           // disabled={!validExpert}
      //           type="button"
      //           onClick={() => {
      //             formik.values.team = user;
      //             formik.values.legal = true;
      //             goToCreatePdf(formik.values)
      //           }}
      //         >
      //           Submit
      //         </Button>
      //         <Button type="button" onClick={() => handleBack(activeStep, "Business Lanscape")}>
      //           Back
      //         </Button></>
      //     );
      //   }
      //   return (
      //     <><Button
      //       //  disabled={!validExpert} 
      //       type="button" onClick={() => handleNext(activeStep, "Sections")}>
      //       Next
      //     </Button>
      //       <Button type="button" onClick={() => handleBack(activeStep, "Business Lanscape")}>
      //         Back
      //       </Button>
      //     </>
      //   );
      // // // else
      case "Sections":
        let validSections =
          !!formik.values.sections.length &&
          formik.values.dateRange.length === 2 &&
          ((formik.values.singlePdf && !!formik.values.singlePdfLink) ||
            Object.keys(formik.values.pdfLinks).length >= 1) &&
          Object.values(formik.values.sectionWeights).reduce(
            (p, n) => p + n,
            0
          ) === 100;
        if (formik.values.crypto) {
          return (
            <>
              <Button disabled={!validSections} type="button" onClick={() => handleNext(activeStep, "Share")}>
                Next
              </Button>
              <Button type="button" onClick={() => handleBack(activeStep, "Business Lanscape")}>
                Back
              </Button>

            </>
          );
        }
        return (
          <>
            <Button disabled={!validSections} type="button" onClick={() => handleNext(activeStep, "Share")}>
              Next
            </Button>
            
            {(formik.values.scoringTypes).includes("i3D Expert") || (formik.values.scoringTypes).includes("all") ? <Button type="button" onClick={() => handleBack(activeStep, "Business Lanscape")}>
              Back
            </Button> : <Button type="button" onClick={() => handleBack(activeStep, "Business Lanscape")}>
              Back
            </Button>}

          </>
        );
      case "Share":
        return (
          <>
            <Button
              disabled={!formik.isValid}
              type="button"
              onClick={() => formik.submitForm()}
            >
              Submit
            </Button>

            <Button
              type="button"
              onClick={() => {
                handleBack(activeStep, "Sections")
              }}
            >
              Back
            </Button>
          </>
        );
      default:
        return (
          <>
            <Button type="button" onClick={() => handleNext(activeStep, "Elevator Pitch")}>
              Next
            </Button>
          </>
        );
    }

  }
  const handleStep = (stage, name, formik) => () => {
    getButtons(stage, name, formik)
    // getContent(stage);
    setActiveStep(stage);
    setActiveName(name);
    // formik.handleChange(event);
    // setIsFormFilled(formik.dirty && formik.isValid);


  };


  const openPitchPopup = () => {
    setOpenPitchDeckPopup(true)
    // setOpen(false);
  };

  const isEqualWithCoercion = (a, b) => {
    const keysA = Object.keys(a).filter(key => key !== 'dateRange');
    const keysB = Object.keys(b).filter(key => key !== 'dateRange');
    if (keysA.length !== keysB.length) {
      return false;
    }
    for (const key of keysA) {
      const valueA = a[key];
      const valueB = b[key];
      if (typeof valueA === 'object' && typeof valueB === 'object') {
        // Recursively compare objects
        if (!isEqualWithCoercion(valueA, valueB)) {
          return false;
        }
      } else if (Array.isArray(valueA) && Array.isArray(valueB)) {
        // Compare arrays element by element
        if (valueA.length !== valueB.length) {
          return false;
        }
        for (let i = 0; i < valueA.length; i++) {
          if (typeof valueA[i] === 'object' && typeof valueB[i] === 'object') {
            // Recursively compare objects within arrays
            if (!isEqualWithCoercion(valueA[i], valueB[i])) {
              return false;
            }
          } else if (valueA[i] != valueB[i]) {
            return false;
          }
        }
      } else if (valueA != valueB) {
        return false;
      }
    }
    return true;
  };

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
        return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

  function onCloseProject(formik) {
      clickTypeSubmit = false
      if(!deepEqual(formik.values, intitialValues)){ 
       dispatch(createProject(formik.values, history, technicalScoringFund, technicalScoringMarket, userData.uid, pitchDeckUrl,user, clickTypeSubmit, id));
      }else{
        history.push("/app/dashboard")
      }

  }
  function getContent(stage, formik) {
    setFormikData(formik.values)

    switch (stage) {
      case 0:
        return <CompanyInfo setCompanyData={setCompanyData} />;
      case 1:
        return <ElevatorPitch />;
      case 2:
        return <ProblemStatement />;
      case 3:
        return <Solution />;
      case 4:
        return <Business />;
      case 5:
        return <Market />;
      case 6:
        return <Competition />;
      case 7:
        return <Milestone />;
      case 8:
        return <Funds />;
      case 9:
        return <GoToMarket />;
      case 10:
        return <> {formik.values.crypto == true ? <Tokenomics /> : <AutomateCompanyScore user={user} setUser={setUser} />} </>;
      case 11:
        return <> {formik.values.crypto == true ? <AutomateCompanyScore user={user} setUser={setUser} /> : <BusinessLandscape technicalScoringFund={technicalScoringFund} technicalScoringMarket={technicalScoringMarket} />}</>;
      case 12:
        return <>{formik.values.crypto == true ? <BusinessLandscape technicalScoringFund={technicalScoringFund} technicalScoringMarket={technicalScoringMarket} /> : <ExpertScore setyesNoSwitch={setyesNoSwitch} />}</>;

      // case 13:
      //   return <>{formik.values.crypto == true ? <ExpertScore yesNoSwitch={yesNoSwitch} setyesNoSwitch={setyesNoSwitch} /> : <FileUpload />}</>;
      // else
      case 13:
        return <>{formik.values.crypto == true ? <FileUpload /> : <Share />}</>;
      case 14:
        return <Share />;
      default:
        return <CompanyInfo />;
    }
  }
  if (creating && clickTypeSubmit) return <Loader text="Creating project..." />;
  if (state == true) return <Loader text="Save to draft..." />;

  if (pdfLoader) return <Loader text="Creating project..." />
  if (createError) return <ErrorDisplay error={createError} />;
  const handleDownloadSamplePdf = () => {
    const pdfUrl = './Guide to Creating a Project.pdf'; // Replace with the actual URL or file path of your PDF

    const a = document.createElement('a');
    a.href = pdfUrl;
    a.download = 'Guide to Creating a Project.pdf'; // You can specify the name for the downloaded file
    a.style.display = 'none';
    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
  };

  return (
    <div className={classes.root}>
      {/* <button onClick={onClickHandler}> Click Here</button> */}
      <Formik<FormInfo>
        initialValues={intitialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        // enableReinitialze={true}
        onSubmit={(values) => {
          values.team = user;
          goToCreatePdf(values)
          //  dispatch(createProject(values, history, technicalScoringFund, technicalScoringMarket, userData.uid, pitchDeckUrl));
        }}
      >
        {(formik) => (
          <Form className={classes.root}>
            <FadeIn>
              <AppBar elevation={0} position="static" color="transparent">
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() =>  history.goBack()}
                  >
                    <CloseIcon />
                  </IconButton>
                  {/* <Typography className={classes.title}>
                    {formik.values.companyName || "Untitled Project"}
                  </Typography> */}
                  <MButton color="inherit" onClick={() => onCloseProject(formik)}>
                    Save to drafts
                  </MButton>
                </Toolbar>
              </AppBar>

              <Grid container className={classes.pitchCreateBtn} >
                <Grid item xs={7} className={classes.selection}>
                  <Button
                    className={classes.buttonScore}
                    variant="contained"
                    color="primary"
                    onClick={openPitchPopup} endIcon={<Info fontSize="small" color='white' />}>
                    Creating your Pitch Deck
                  </Button>
                  <Button
                    className={classes.buttonScore}
                    style={{ marginLeft: "2rem" }}
                    variant="contained"
                    onClick={handleDownloadSamplePdf}
                    color="primary" endIcon={<Info fontSize="small" color='white' />}> Sample Pitch & Advice
                  </Button>
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className={classes.stepperContainer}>
                  <Stepper
                    nonLinear
                    activeStep={activeStep}
                    className={classes.stepperCom}
                  // style={{ webkit }}
                  // activeStep={step}
                  >
                    {/* <Step>
                      <StepLabel>Select Platform</StepLabel>
                    </Step> */}
                    <Step>
                      <StepButton className={companyStepFilled ? classes.stepper : '"'} onClick={handleStep(0, "Company Info", formik)}>Company Info</StepButton>
                    </Step>
                    <Step>
                      <StepButton onClick={handleStep(1, "Elevator Pitch", formik)}>Elevator Pitch</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} className={activeStep == 2 ? classes.activeColor : ""} onClick={handleStep(2, "Problem Statement", formik)}>Problem Statement</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(3, "Solution", formik)}>Solution</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(4, "Business Model", formik)}>Business Model</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(5, "Market", formik)}>Market</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(6, "Competition", formik)}>Competition</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(7, "Milestone", formik)}>Milestone</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(8, "Funds", formik)}>Funds</StepButton>
                    </Step>
                    <Step >
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(9, "GoToMarket", formik)}>GoToMarket</StepButton>
                    </Step>
                    {
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(10, "Tokenomics", formik)}>Tokenomics</StepButton>
                        </Step> :
                        <Step>
                          <StepButton onClick={handleStep(10, "Teams", formik)}>Teams</StepButton>
                        </Step>
                    }
                    {
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton onClick={handleStep(11, "Teams", formik)}>Teams</StepButton>
                        </Step> :
                        <Step>0
                          <StepButton onClick={handleStep(11, "Business Lanscape", formik)}>Business Lanscape </StepButton>
                        </Step>
                    }
                    {
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton onClick={handleStep(12, "Business Lanscape", formik)}>Business Lanscape</StepButton>
                        </Step> :
                        (!(formik.values.scoringTypes).includes("i3D Expert") || (formik.values.scoringTypes).includes("all"))
                          ? <Step>
                            <StepButton onClick={handleStep(12, "Expert Score", formik)}>Expert Score</StepButton>
                          </Step> : <></>
                    }

                    {/* {
                      formik.values.crypto == true ? (!(formik.values.scoringTypes).includes("i3D Expert") || (formik.values.scoringTypes).includes("all"))
                        ? <Step>
                          <StepButton onClick={handleStep(13, "Expert Score", formik)}>Expert Score</StepButton>
                        </Step> : <></>
                        :
                        yesNoSwitch === "Yes" && (<Step>
                          <StepButton onClick={handleStep(13, "Sections", formik)}>Sections</StepButton>
                        </Step>)
                    }  */}


                    {/* When the current plan is pro or Global then Expert Score is not shown and only sections and share are shown */}
                    {(profile.currentPlan && profile.currentPlan.name === "Pro" || profile.currentPlan && profile.currentPlan.name === "Global") && (
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton onClick={handleStep(13, "Sections", formik)}>Sections</StepButton>
                        </Step> : <Step>
                          <StepButton onClick={handleStep(13, "Share", formik)}>Share</StepButton>
                        </Step>)}
                    {(profile.currentPlan && profile.currentPlan.name === "Pro" || profile.currentPlan && profile.currentPlan.name === "Global") && (
                      formik.values.crypto === true ? (
                        <Step>
                          <StepButton onClick={handleStep(14, "Share", formik)}>Share</StepButton>
                        </Step>
                      ) : null
                    )}
                    {/* {yesNoSwitch === "Yes"   && profile.currentPlan?.name!=="Pro" && profile.currentPlan?.name!=="Global" &&(
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton onClick={handleStep(14, "Sections", formik)}>Sections</StepButton>
                        </Step> : <Step>
                          <StepButton onClick={handleStep(14, "Share", formik)}>Share</StepButton>
                        </Step>)} */}
                    {/* {yesNoSwitch === "Yes" && (
                      formik.values.crypto === true ? (
                        <Step>
                          <StepButton onClick={handleStep(15, "Share", formik)}>Share</StepButton>
                        </Step>
                      ) : null
                    )} */}
                  </Stepper>
                  {getContent(activeStep, formik)}
                  <div style={{ marginBottom: 40 }} />
                  {getButtons(activeStep, activeName, formik)}
                </div>
              </div>
            </FadeIn>
          </Form>
        )}
      </Formik>
      <Box sx={{ width: 500 }}>
        <Snackbar
          autoHideDuration={3000}
          open={state}
          onClose={handleCloseSnackbar}
          message="Your unsaved project will be saved as draft"
        >
          <Alert severity="success" className="snackbarBox">Your unsaved project will be saved as draft!</Alert>
        </Snackbar>
      </Box>
            <SubscriptionCard open={openPlan} setOpenPlan={setOpenPlan} />
      <CreatePitchDeckDialog open={openPitchDeckPopup} setOpenPitchDeckPopup={setOpenPitchDeckPopup} />
    </div>
  );
}

export default Create;

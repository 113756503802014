import { SubscriptionThunk, SubscriptionActions } from "../model";
import { Firestore } from "../repositories/firestore";

export const fetchSubscription = (): SubscriptionThunk => {
    return async (dispatch) => {
      dispatch({ type: SubscriptionActions.FETCH });
      const res = await Firestore.fetchSubscription();
    //   const res = await ProjectRepo.fetchAll();
      if (res instanceof Error)
        dispatch({ type: SubscriptionActions.FETCH_ERROR, payload: res });
      else dispatch({ type: SubscriptionActions.FETCHED_ALL, payload: res });
    };
  };

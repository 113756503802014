import React, { Dispatch } from "react";
import { Menu, MenuItem, IconButton, Typography } from "@material-ui/core";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { LayoutAction, ModalType, Project } from "../../../model";
import { shareUpgradeModal, showModal } from "../../../actions/layout";
import { RootState } from "../../../reducers";
import { useHistory } from "react-router-dom";

interface Props {
  project: Project;
}

export function ReportMenu(props: Props) {
  const { project } = props;
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
  const classes = useStyles();
  let history = useHistory()
  var [moreButtonRef, setMoreButtonRef] = React.useState();
  var [isMoreMenuOpen, setMoreMenuOpen] = React.useState<boolean>(false);
   const plan = profile.currentPlan;
  const GotoShareAnalysis = (e) =>{
    e.preventDefault();
    if(plan){ 
    if(plan.shareProject != plan.sharedProject && plan.shareProject >= plan.sharedProject || plan.shareProject == "no-limit" ){ 
      return dispatchLayout(showModal(ModalType.SHARE, project))
    }else{
      return dispatchLayout(shareUpgradeModal());
    } 
  }else{
    return history.push("/plan")
  }
  }

  

  return (
    <>
      <IconButton
        color="primary"
        classes={{ root: classes.moreButton }}
        aria-owns="widget-menu"
        aria-haspopup="true"
        onClick={() => setMoreMenuOpen(true)}
        buttonRef={setMoreButtonRef}
      >
        <MoreIcon />
      </IconButton>

      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        {/* {("admin" === profile.role && myTimestamp.seconds < project.endDate?.seconds) && ( */}
          <MenuItem
            style={{ minWidth: 250 }}
            onClick={(e) => {
              setMoreMenuOpen(false)
              GotoShareAnalysis(e)
              // ;
              // {plan? ((plan.shareProject != plan.sharedProject && plan.shareProject >= plan.sharedProject) || plan.shareProject == "no-limit" )?
              //    dispatchLayout(showModal(ModalType.SHARE, project)): "": ""
              // }
            }}
          >
            <Typography>Share Project For Analysis</Typography>
          </MenuItem>
        {/* )} */}
        {(!project.isDemo && "admin" === profile.role)&&(
          <MenuItem
            style={{ minWidth: 250 }}
            onClick={(e) => {
              setMoreMenuOpen(false);
              dispatchLayout(showModal(ModalType.DELETE, project));
            }}
          >
            <Typography color="secondary">Delete Project</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",

    },
    title: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: "2rem",
        height: "auto",
        margin: "16px",
        position: "relative",
        boxShadow: "0px 0px 8px 1px #ccc",
        textAlign: "center",
        borderRadius: "8px",
        padding: "2rem",
        // paddingLeft: "0px",
        // paddingTop: "1rem",
        backgroundColor:theme.palette.background.default,

    },
    expertText:{
        marginRight: "2rem",
        fontWeight: "600"
    }
}));
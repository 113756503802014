import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import useStyles from "../styles";

export default function TeamTable(props) {
    const classes = useStyles();

    return (
        <Grid item xs={6} className={classes.parentGrid}>
            <Paper elevation={2} variant="outlined" className={classes.card} >
                <Grid xs={12} container direction="row" className={classes.fields} >
                    <Grid xs={6}>
                        <Typography className={classes.text} >Name</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography className={classes.textField}>{props.data.name}</Typography>
                    </Grid>
                </Grid>
                <Grid xs={12} container direction="row" className={classes.fields}>
                    <Grid xs={6}>
                        <Typography className={classes.text}>Role</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography className={classes.textField}>{props.data.Role}</Typography>
                    </Grid>
                </Grid>
                <Grid xs={12} container direction="row" className={classes.fields} >
                    <Grid xs={6}>
                        <Typography className={classes.text}>Experience</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography className={classes.textField}>{props.data.Experience}</Typography>
                    </Grid>
                </Grid>
                {props.data.Education && <Grid xs={12} container direction="row" className={classes.fields}>
                    <Grid xs={6}>
                        <Typography className={classes.text}>Education</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography className={classes.textField}>{props.data.Education}</Typography>
                    </Grid>
                </Grid> }
                {props.data.time && <Grid xs={12} container direction="row" className={classes.fields}>
                    <Grid xs={6}>
                        <Typography className={classes.text}>FT or PT</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography className={classes.textField}>{props.data.time}</Typography>
                    </Grid>
                </Grid>}
                {props.data.linkedin && <Grid xs={12} container direction="row" className={classes.fields}>
                    <Grid xs={6}>
                        <Typography className={classes.text}>Linkedin</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography className={classes.textField}>{props.data.linkedin}</Typography>
                    </Grid>
                </Grid>}
             
            </Paper>
        </Grid>
    );
}

import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { app, messaging } from '../repositories/firebase';

function Test(props) {
  const [fcmToken, setFcmToken] = useState('');
  const user = useSelector((state) => state.firebase.auth);
  const [instanceUrl, setInstanceUrl] = useState(app.options.apiUrl);
  const [vapkeyId, setVapkeyId] = useState(app.options.vapkeyId);

  const storeToken = async (values) => {
    try {
      const data = {
        fcmToken: values,
        userEmail: user.email,
        userId: user.uid,
        createdAt: new Date(),
      };

      const response = await axios.post(
        instanceUrl + '.cloudfunctions.net/storeNotificationToken',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.debug('Success response:', response.data);
    } catch (error) {
      console.error('Error storing token:', error);
    }
  };

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          messaging.getToken({ vapidKey: vapkeyId }).then((currentToken) => {
            if (currentToken) {
              console.debug('FCM token:', currentToken);
              storeToken(currentToken);

              // Handle incoming messages
              messaging.onMessage((payload) => {
                console.debug('Received foreground message:', payload);
                // Handle the received message and display it in your app's UI
              });
            } else {
              console.log('No FCM token available.');
            }
          }).catch((error) => {
            console.error('Error getting FCM token:', error);
          });
        } else {
          console.debug('Notification permission not granted.');
        }
      });
    } else {
      console.debug('Browser does not support desktop notification');
    }
  }, []);

  return (
    <div>
      Hello Test
    </div>
  );
}

export default Test;
import React from "react";
import { ProjectCardDraft } from "../../components/ProjectCardDraft";
import FadeIn from "react-fade-in";
import useStyles from './styles';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { Filter } from "../../utils/enums";
import {  Paper } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

export default function ProjectGrid(props){
	const classes = useStyles();
	const search = useSelector((state: RootState) => state.search);
	const user = useSelector((state: RootState) => state.firebase.auth);
    console.debug("ProjectGrid:ProjectGrid", props)
    if(props.walletOpen)
    {
        return(
            <div className={classes.gridContainer}>
                
                {props.sortedProjects.filter((v) =>
                        v.company.name
                            .toLowerCase()
                            .includes(
                                search.searchString.length >= 3
                                    ? search.searchString.toLowerCase()
                                    : ""
                            )
                    )
                    .slice(0,3)
                    .map((v, index) => {
                        if (props.filter == Filter.uploads) {
                            if (v.requester == user.uid) {
                                return (
                                    <ProjectCardDraft project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else if (props.filter == Filter.likes) {
                            if (v.likes) {
                                return (
                                    <ProjectCardDraft project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else if (props.filter == Filter.shares) {
                            if (v.shared ? v.shared : false) {
                                return (
                                    <ProjectCardDraft project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else {
                            return (
                                <ProjectCardDraft project={v} key={index} filter={props.scoreSort} />
                            )
                        }
                    })}
                    {props.sortedProjects.length > 0 ?
                    props.sortedProjects.filter((v) =>
                        v.company.name
                            .toLowerCase()
                            .includes(
                                search.searchString.length >= 3
                                    ? search.searchString.toLowerCase()
                                    : ""
                            )
                    )
                    .slice(3,props.sortedProjects.length)
                    .map((v, index) => {
                        return(
                            <>
                                <ProjectCardDraft project={v} key={index} filter={props.scoreSort} top={true}/>

                            </>
                            // <Paper variant="outlined" className={classes.paper}>
                            //     {/* <Link to={`/app/reports/${v.id}`}> */}
                            //     <Link to={'/connect'}>
                            //     <LockIcon fontSize="large"/>
                            //     </Link>
                            // </Paper>
                        )   
                        
                    }):"No Data Found"}
            </div>)
    }
    else{
        return(
        <FadeIn className={classes.gridContainer}>
        {props.sortedProjects.length > 0 ?
            props.sortedProjects.filter((v) =>
                v.company.name
                    .toLowerCase()
                    .includes(
                        search.searchString.length >= 3
                            ? search.searchString.toLowerCase()
                            : ""
                    )
            )
            .map((v, index) => {
                if (props.filter == Filter.uploads) {
                    if (v.requester == user.uid) {
                        return (
                            <ProjectCardDraft project={v} key={index} filter={props.scoreSort} />
                        )
                    }
                }
                else if (props.filter == Filter.likes) {
                    if (v.likes) {
                        return (
                            <ProjectCardDraft project={v} key={index} filter={props.scoreSort} />
                        )
                    }
                }
                else if (props.filter == Filter.shares) {
                    if (v.shared ? v.shared : false) {
                        return (
                            <ProjectCardDraft project={v} key={index} filter={props.scoreSort} />
                        )
                    }
                }
                else {
                    return (
                        <ProjectCardDraft project={v} key={index} filter={props.scoreSort} />
                    )
                }
            }):  
                 <div className={classes.wrapper}>
                <span>No Data found</span></div>}
        </FadeIn>
        );
    }

}
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  pages: {
    padding: 30,
  },
  companyInfo: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    color: theme.palette.blackColor.main,
    marginLeft: "-15px",

  },
  paperContainer: {
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '& div': {
      height: "35px",
      marginTop: "40px",
      width: "75%",
      marginLeft: "2%",
      borderRadius: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#2b2b2b",
    },
    '& div p': {
      textAlign: "center",
      color: theme.palette.background.default
    }
  },
  alignCenter: {
    textAlign: "center",
    size: "A4",
    padding: 9 + '%',
    backgroundColor: theme.palette.background.default,
    clear: "both",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  alignLeft: {
    textAlign: "left",
    padding: 9 + '%',
    size: "A4",
  },
  MuiTableCellSizeSmall: {
    padding: "0px",
  },
  bolderText: {
    fontWeight: "bolder",
  },
  pageHeading: {
    fontWeight: "bolder",
    fontSize: 34,
  },
  sideHeading: {
    fontWeight: "bold",
    color: "#666666"
  },
  textNormal: {
    fontSize: 18,
    marginTop: "1rem"
  },
  grey: {
    color: "#666666",
    fontWeight: "600"
  },
  red: {
    color: "#ff0000",
  },
  blue: {
    color: "#4798F1",
  },
  table: {
    minWidth: 100 + "%",
    border: "none",
    backgroundColor:theme.palette.background.default,

  },
  paper: {

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  companyLogo: {
    width: "100%",
    height: "100%",
  },

  atGlancePaper: {
    padding: 10, height: 60, margin: "auto",
    marginTop: 40,
    width: 85 + '%',
    borderRadius: 20,
    display:"flex",
    justifyContent:"center",
    alignItems: "center",
    backgroundColor: "#2b2b2b",
    color:theme.palette.background.default,
  },
  downloadReport:{
    backgroundColor: "rgb(164, 198, 57) !important",
    color: "#fff",
    boxShadow: theme.customShadows.widget,
    textTransform: "capitalize",
    width: "165px",
 
  }
}));


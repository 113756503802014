import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
  select: {
    height: 100,
  },
  cardContainer:{
    height: "auto",
    margin: "16px",
    position: "relative",
    boxShadow: "0px 0px 8px 1px #ccc",
    textAlign: "center",
    borderRadius: "8px",
    padding: "2rem",
    // paddingLeft: "0px",
    paddingTop: "1rem",
    backgroundColor:theme.palette.background.default,
},
}));

import { firebase, auth, callableFunctions } from "../repositories/firebase";
import { handleError } from "../utils/decorators";
import { firestore } from "../repositories/firebase";

const userCollection = firestore.collection("users");
const metamaskCollection = firestore.collection("metamasks");

export class Auth {
  @handleError("Failed to sign in...")
  static async signInWithEmail(
    email: string,
    password: string
  ): Promise<firebase.User | Error> {
    const authResult = await auth.signInWithEmailAndPassword(email, password);
    if (!authResult.user) return new Error("No user exists for this account.");
    return authResult.user;
  }

  @handleError("Failed to reset password...")
  static async resetPassword(
    email: string,
    password: string,
    newPassword: string,
  ): Promise<string | Error> {
    const authResult = await auth.signInWithEmailAndPassword(email, password);
    if (!authResult.user) return new Error("wrong Password");
    var user = firebase.auth().currentUser;

    user.updatePassword(newPassword).then(function () {
      // Update successful.
    }).catch(function (error) {
      // An error happened.
    });
    return "password reset success";
  }

  @handleError("Failed to register...")
  static async registerWithEmail(
    name: string,
    lastName: string,
    email: string,
    password: string,
    sector: string,
  ): Promise<firebase.User | Error> {
    try {
      const checkBlockedDomainCallable = callableFunctions.httpsCallable('checkBlockedDomain');
      const response = await checkBlockedDomainCallable({ email: email });
      if (response.data) {
        console.debug("blocked email", response)
        throw new Error(' Your email domain is blocked.');
      }
      else {
        const authResult = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        if (!authResult.user) return new Error("No user exists for this account.");
        await authResult.user.updateProfile({ displayName: name });
        await userCollection.doc(authResult.user.uid).set({
          sector: sector,
          lastName: lastName,
        });
        return authResult.user;
      }

    } catch (error) {
      // Handle errors
      return new Error(`Registration failed: ${error.message}`);
    }
  }

  @handleError("Failed to store wallet address...")
  static async storeWalletAddress(
    metamaskAddress: string,
  ): Promise<firebase.User | Error> {
    var user = firebase.auth().currentUser;
    await metamaskCollection.add({
      metamaskAddress: metamaskAddress,
      userId: user.uid,
    });
    return user;
  }

  @handleError("Failed to sign in with Google...")
  static async signInWithGoogle(): Promise<firebase.User | Error> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    auth.useDeviceLanguage();
    const user = await auth.signInWithPopup(provider);
    if (!user.user) return new Error("No user found for this account.");
    return user.user;
  }

  @handleError("Failed to send password email...")
  static async resetPasswordEmail(
    email: string,
  ): Promise<string | Error> {

    const authEmail = await firebase.auth().fetchSignInMethodsForEmail(email)
    if (authEmail.length === 0) {
      return "This email had not been registered, Please enter registered email";
    }
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        console.log(error)
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // ..
      });
    return "email sent successfully";
  }

  static async resetForgetPassword(
    newPassword: string,
    code: string,
  ): Promise<string | Error> {
    firebase.auth().confirmPasswordReset(code, newPassword)
      .then(function () {
        // Success
      })
      .catch(function () {
        return "invalid code or code is expired";
        // Invalid code
      })
    return "you can now login with your new Password"
  }


  @handleError("Failed to sign out...")
  static async signOut(): Promise<void | Error> {
    await auth.signOut();
  }
}

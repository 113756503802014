import {
  ProjectActions,
  ProjectThunk,
  FormInfo,
  Invitation,
  ProjectAction,
  Project,
  ShareInfo,
  ShareReport,
  AddTeams,
  newScoringAssist
} from "../model";
import { History, LocationState } from "history";
import { ProjectRepo } from "../repositories/project";
import { array2Object, array2ObjectFunc } from "../utils";
import { firestore } from "firebase";
// import { time } from "../pages/create/components/data";
// import {technicalScoringMarket} from "../pages/create/components/data";

// function _createPdfLinks(
//   sections: string[],
//   link?: string
// ): Record<string, string> {
//   if (!link) return {};
//   let temp: Record<string, string> = {};
//   for (const section of sections) temp[section] = link;
//   return temp;
// }

function _createPdfLinks(
  sections: string[],
  link?: string,
  pitchurl?: string,
): Record<string, string> {
  if (!link) return {};
  let temp: Record<string, string> = {};
  for (const section of sections) {
    if (section !== "pitchDeck") {
      temp[section] = link;
    } else {
      temp["pitchDeck"] = pitchurl
    }
  }
  return temp;
}


// const [showPopup, setShowPopup] = useState(false);

export const createProject = (
  form: FormInfo,
  history: History<LocationState>,
  technicalScoringFund: Array<Record<string, any>>,
  technicalScoringMarket: Array<Record<string, any>>,
  userdId: string,
  pitchDeckUrl: string,
  teamsData:Array<Record<string, any>>,
  clickTypeSubmit:boolean,
  id:number
): ProjectThunk => {

  return async (dispatch, getState) => {
    // console.debug("qubibuwbkjbk",form)
    if (!form.legal) return;
    dispatch({ type: ProjectActions.CREATE });
    const user = getState().firebase.auth;
    Object.keys(form.sectionWeights).map(
      (key) => (form.sectionWeights[key] /= 100)
    );
    let singleSection = form.sections.map(v => v)
    const sSection = ["pitchDeck", ...singleSection]
    let multipleUpload = {
      ...form.pdfLinks, "pitchDeck": pitchDeckUrl
    }

    // const pdfLinks = form.singlePdf ? _createPdfLinks(sSection, form.singlePdfLink)
    //   : form.pdfLinks;
    const pdfLinks = form.singlePdf ? _createPdfLinks(sSection, form.singlePdfLink, pitchDeckUrl)
      : multipleUpload;
    const selectedPeople = form.selectedEmails.map((value) => ({
      email: value,
      projectID: "",
      company_name: form.companyName,
    }));
    // console.debug("PdfLinks", pdfLinks)
    selectedPeople.push({
      email: "team@invluencer.com",
      projectID: "",
      company_name: form.companyName,
    })
    var businessComments = [];
    if (!form.crypto) {
      // console.debug("technicalScoringMarket", technicalScoringMarket)
      if (technicalScoringMarket.length) {
        technicalScoringMarket.forEach((value) => {
          businessComments.push({ "comment": form[value.value].split("|")[1], "type": "Automated" })

        })
      }
    }
    else {
      var index = form.scoringTypes.indexOf("Technical")
      if (index > -1)
        form.scoringTypes.splice(index, 1);
      if (!(form.scoringTypes.includes("i3D Expert")))
        form.scoringTypes.push("i3D Expert");
    }
    const invitations: Invitation[] = form.emails.map<Invitation>((value) => ({
      email: value,
      projectID: "",
      requestor: user.displayName!,
      requestorId: user.uid,
      company_name: form.companyName,
      company_website: form.companyWebsite,
      sections: form.sections,
      sentAt: new Date(),
      dueDate: form.dateRange[1].toDate(),
      disclaimerAccepted: false,
      sectionsStatus: array2Object(form.sections, "sent"),
      // sectionsStatus: array2Object(form.sections.map(obj => obj.value), "sent"),

      pdfLink: pdfLinks,
      formLink: {},
    }));
    // console.debug("form:team", form)
    const team: AddTeams[] = teamsData ? teamsData.map<AddTeams>((value) => ({
      name: value.name,
      Education: value.Education,
      Experience: value.Experience,
      Role: value.Role,
      time: value.time,
      age: value.age ? value.age : 0,
      projectId: "",
      memberDescription: value.memberDescription,
      linkedin: value.linkedin,
    })) : [];

    const scoringAssist: newScoringAssist = {
      statement: form.statement,
      DTP: form.DTP,
      painPoints: form.painPoints,
      marketDynamics: form.marketDynamics,
      solutionStatement: form.solutionStatement,
      USPB: form.USPB,
      PKF: form.PKF,
      useCases: form.useCases,
      competitiveLandscape: form.competitiveLandscape,
      identifyCompetitors: form.identifyCompetitors,
      competitiveAdvantage: form.competitiveAdvantage,
      MOAT: form.MOAT,
      marketSizeTam: form.marketSizeTam,
      MGP: form.MGP,
      marketOpportunity: form.marketOpportunity,
      marketTrends: form.marketTrends,
      revenueStreams: form.revenueStreams,
      costStructure: form.costStructure,
      salesChannelsCustomer: form.salesChannelsCustomer,
      CAS: form.CAS,
      fundingAmount: form.fundingAmount,
      useofFunds: form.useofFunds,
      valuation: form.valuation,
      financialProjections: form.financialProjections,
      targetAudience: form.targetAudience,
      userProfiles: form.userProfiles,
      marketingStrategy: form.marketingStrategy,
      salesStrategy: form.salesStrategy,
      MT: form.MT,
      marketValidation: form.marketValidation,
      strategicPartnerships: form.strategicPartnerships,
      futurePlans: form.futurePlans,
      tokenUtility: form.tokenUtility,
      tokenDistribution: form.tokenDistribution,
      tokenSale: form.tokenSale,
      incentives: form.incentives,
    }
    const projectData: Project = {
      id: "",
      isDemo: false,
      company: {
        name: form.companyName,
        logo: form.companyImage,
        description: form.companyDescription,
        rapidDescription: form.rapidDescription,
        sector: form.companySector!,
        location: form.companyLocation!,
        stage: form.companyStage,
        website: form.companyWebsite,
        investmentLink: form.dealSource,
        contactEmail: form.contactEmail,
      },
      crypto: form.crypto,
      startDate: firestore.Timestamp.fromDate(form.dateRange[0] ? form.dateRange[0].toDate() : new Date()),
      endDate: firestore.Timestamp.fromDate(form.dateRange[1] ? form.dateRange[1].toDate() : new Date()),
      privacy: form.privacy,
      requester: user.uid,
      sections: form.sections,
      needExpertScore:form.needExpertScore,
      selectedEmails:form.selectedEmails,
      emails:form.emails,
      sendProjectToMe:form.sendProjectToMe,
      pdfLinks: pdfLinks,
      singlePdf: form.singlePdf,
      imageLinks: form.imageLinks,
      sectionWeights: form.sectionWeights,
      emailSections:form.emailSections,
      scoringTypes: form.scoringTypes,
      scoringAssist: scoringAssist,
      sectionStats: {
        ...array2ObjectFunc(form.sections, (value) => ({
        // ...array2ObjectFunc(form.sections.map(obj => obj.value), (value) => ({

          avgRating: 0,
          numComments: 0,
          views: 0,
          completed: 0,
          invited: invitations.reduce(
            (pre, next) => pre + (next.sections.includes(value) ? 1 : 0),
            0
          ),
        })),
        overall: {
          avgRating: 0,
          numComments: 0,
          views: 0,
          invited: form.emails.length,
          completed: 0,
        },
      },

      TechnicalAnalysis: technicalAnalysis(form, technicalScoringFund, technicalScoringMarket)

    };
    console.debug("projectData", projectData)
    console.debug("invitations", invitations)
    console.debug("selectedPeople", selectedPeople)
    console.debug("team", team)
    console.debug("businessComments", businessComments)
    console.debug("technicalScoringFund", technicalScoringFund)
    console.debug("technicalScoringMarket", technicalScoringMarket)
    console.debug("goToCreatePdf:goToCreatePdf mein gya page mein gaya" , projectData)
localStorage.setItem("email-suggestions", JSON.stringify(form.emails));
localStorage.setItem("pref-weights", JSON.stringify(form.sectionWeights));
const res = await ProjectRepo.create(projectData, invitations, selectedPeople, team, businessComments, technicalScoringFund, technicalScoringMarket, userdId, pitchDeckUrl, clickTypeSubmit, id);
// console.debug("clickTypeSubmit:project bana", res)
if (res instanceof Error){ 
  // console.debug("clickTypeSubmit:errror", res)
  dispatch({ type: ProjectActions.CREATE_ERROR, payload: res });
}else {
  // console.debug("clickTypeSubmit:success", res)

  // setShowPopup(true);
  // Navigate to the project detail page and pass the projectId as a query parameter
  // console.debug("clickTypeSubmit:project bana", res)
  if(clickTypeSubmit == true && res ){
    dispatch({ type: ProjectActions.CREATE_SUCCESS, payload: res });
    history.push(`/app/reports/${res}?showPopup=true`);
  }
  else{
    history.push(`/app/drafts`);
  }
  // history.push(`/app/reports/${res}`);
}
  };
};

function technicalAnalysis(form, technicalScoringFund, technicalScoringMarket) {
  var technical = {};
  technicalScoringMarket.forEach(element => {
    technical[element.value] = form[element.value] ? parseInt(form[element.value].split("|")[0]) : 0
  });
  technicalScoringFund.forEach(element => {
    technical[element.value] = form[element.value] ? form[element.value] : 0
  });
  return technical;
}


export const fetchAllProjects = (): ProjectThunk => {
  return async (dispatch, getState) => {
    const { uid, email } = getState().firebase.auth;
    dispatch({ type: ProjectActions.FETCH });
    const res = await ProjectRepo.fetchAll(uid, email);
    // console.debug(res,"prject action response")
    if (res instanceof Error)
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: res });
    else dispatch({ type: ProjectActions.FETCHED_ALL, payload: res });
  };
};
export const fetchAllDraftProjects = (): ProjectThunk => {
  return async (dispatch, getState) => {
    const { uid, email } = getState().firebase.auth;
    dispatch({ type: ProjectActions.FETCHED_DRAFT });
    const res = await ProjectRepo.fetchAllDrafts(uid, email);
    // console.debug("prject action response", res)
    if (res instanceof Error)
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: res });
    else dispatch({ type: ProjectActions.FETCHED_DRAFT, payload: res });
  };
};

//export const addFavourite = (projectId: string,): ProjectThunk => {
export const addFavourite = (projectId: string,) => {
  return async (dispatch, getState) => {
    const { uid } = getState().firebase.auth;
    // console.debug(uid,"uuid")
    // console.debug(projectId,"projectId")
    const data = {
      UID: uid,
      projectid: projectId,
    }
    const res = await ProjectRepo.addToFavrourite(data);
    // console.debug(res,"project addFavourite action response")
    if (res instanceof Error)
      dispatch({ type: ProjectActions.ADD_FAVOURITE_ERROR, payload: res });
    else
      dispatch({ type: ProjectActions.ADD_FAVOURITE, payload: projectId });
  };
};

//export const addFavourite = (projectId: string,): ProjectThunk => {
export const removeFavourite = (projectId: string,) => {
  // console.debug("remove fav action")
  return async (dispatch, getState) => {
    const { uid } = getState().firebase.auth;
    const data = {
      UID: uid,
      projectid: projectId,
    }
    const res = await ProjectRepo.removeToFavrourite(data);
    // console.debug(res,"project removeFavourite action response")
    if (res instanceof Error)
      dispatch({ type: ProjectActions.REMOVE_FAVOURITE_ERROR, payload: res });
    else
      dispatch({ type: ProjectActions.REMOVE_FAVOURITE, payload: projectId });
  };
};

export const fetchReport = (
  projectId: string,
  project?: Project
): ProjectThunk => {
  return async (dispatch) => {
    dispatch({ type: ProjectActions.FETCH });
    const res = await ProjectRepo.fetchReport(projectId, project);
    if (res instanceof Error)
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: res });
    else dispatch({ type: ProjectActions.FETCHED_REPORT, payload: res });
  };
};

export const deleteProject = (projectId: string): ProjectThunk => {
  return async (dispatch) => {
    dispatch({ type: ProjectActions.DELETE });
    const err = await ProjectRepo.deleteProject(projectId);
    if (err) dispatch({ type: ProjectActions.DELETE_ERROR, payload: err });
    else {
      dispatch({ type: ProjectActions.DELETED_PROJECT, payload: projectId });
      if (window.location.pathname !== "/app/dashboard")
        window.location.assign("/");
    }
  };
};

export const incrementProjectViews = (
  projectId: string,
  section: string
): ProjectThunk => {
  return async () => {
    const res = await ProjectRepo.incrementViews(projectId, section);
    if (res) console.error(res);
  };
};

export const shareProject = (
  project: Project,
  info: ShareInfo
): ProjectThunk => {
  return async (dispatch, getState) => {
    const user = getState().firebase.auth;
    dispatch({ type: ProjectActions.SHARE });
    const err = await ProjectRepo.shareProject(
      project,
      info,
      user.displayName!,
      user.uid
    );
    if (err) dispatch({ type: ProjectActions.SHARE_ERROR, payload: err });
    else dispatch({ type: ProjectActions.SHARED_PROJECT });
  };
};

export const shareProjectReport = (
  project: Project,
  info: ShareReport
): ProjectThunk => {
  return async (dispatch, getState) => {
    const user = getState().firebase.auth;
    dispatch({ type: ProjectActions.SHARE });
    const err = await ProjectRepo.shareProjectReport(
      project,
      info,
      user.displayName!,
      user.uid
    );
    if (err) dispatch({ type: ProjectActions.SHARE_ERROR, payload: err });
    else dispatch({ type: ProjectActions.SHARED_REPORT });
  };
};

export const resetProjectModal = (): ProjectAction => ({
  type: ProjectActions.RESET_MODAL,
});

import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useLocation,
} from "react-router-dom";


// Initialize Firebase (replace with your actual Firebase config)

// components
import Layout from "./Layout/Layout";
import axios from 'axios';
import * as pages from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Loader from "./Loader/Loader";
import { UserDispatch } from "../model";
import { redirectToPlan } from "../actions/user";
import { setWalletOpen } from "../actions/metamask";
import PaymentSuccessFull from "../pages/create/components/PaymentSuccessFull/PaymentSuccessFull";
import { app, auth, messaging } from "../repositories/firebase";
declare var window: any;

const networkChanged = (chainId) => {
  console.log({ chainId });
};

export default function App() {
  const authtenication = useSelector((state: RootState) => state.firebase.auth);
  console.debug("yugygi:authtenication", authtenication)
  const user = useSelector((state: RootState) => state.user);
  const isAuthenticated = isLoaded(authtenication) && !isEmpty(authtenication);
  const dispatch = useDispatch();

  useEffect(() => {
    // Handle Ethereum events (unchanged)
    if (window.ethereum) {
      window.ethereum.on('disconnect', (error: ProviderRpcError) => {
        window.location.reload(true);
        dispatch(setWalletOpen(true));
      });
      window.ethereum.on('chainChanged', () => {
        window.location.reload(true);
        return () => {
          dispatch(setWalletOpen(true));
          window.ethereum.removeListener("chainChanged", networkChanged);
        };
      });
      window.ethereum.on('accountsChanged', () => {
        window.location.reload(true);
        dispatch(setWalletOpen(true));
      });
    }

    // Request FCM permission and store token
    if (isAuthenticated) {
      requestPermissionAndStoreToken(authtenication);
    }
  }, [authtenication, isAuthenticated]);

  const requestPermissionAndStoreToken = async (authtenication) => {
    try {
      await messaging.requestPermission();
      const fcmToken = await messaging.getToken();
      if (fcmToken) {
        
        storeToken(fcmToken, authtenication);
      } else {
        console.warn('No FCM token retrieved');
      }
    } catch (error) {
      console.error('Error getting permission or token', error);
    }
  };

  
  const storeToken = async (fcmToken, authtenication) => {
    try {
      // Get the Firebase ID token of the current user
      console.debug("qsxkbhqbkjqs:fcmToken", fcmToken)

      console.debug("qsxkbhqbkjqs:authtenication", authtenication)
      const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);
      console.debug("qsxkbhqbkjqs:idToken", idToken)


      const data = {
        fcmToken,
        userEmail: authtenication.email,
        userId: authtenication.uid,
        createdAt: new Date(),
      };
      console.debug("xklnlklks", app.options)
      const response = await axios.post(
       "https://us-central1-invluencer-test.cloudfunctions.net/storeNotificationToken",
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`, // Include the Firebase ID token
          },
        }
      );

      console.debug('Success response:', response.data);
    } catch (error) {
      console.error('Error storing token:', error);
    }
  };

  if (!isLoaded(auth)) return <Loader />;
  if (user.paymentLoading)
    return <Loader text="Redirecting to payment portal..." />;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
        <Route exact path="/subscription" component={Payment} />
        <Route path="/form" component={pages.Form} />
        <PrivateRoute path="/typeform/expertScore" component={pages.ExpertScore} />
        <PrivateRoute path="/typeform/technicalScore" component={pages.TechnicalScore} />
        <PrivateRoute path="/create" component={pages.Create} />
        <PrivateRoute path="/app/create/:id" component={pages.Create} />
        <PrivateRoute path="/test" component={pages.Test} />
        <PrivateRoute path="/payment-success/:planId" component={PaymentSuccessFull} />
        <PrivateRoute path="/payment-success" component={PaymentSuccessFull} />
        <PrivateRoute path="/createTechnicalScore" component={pages.CreateTechnicalScore} />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/forgotPassword" component={pages.ForgotPassword} />
        <PublicRoute path="/reset/newpassword" component={pages.ResetForgetPassword} />
        <PublicRoute path="/login" component={pages.Login} />
        <PrivateRoute path="/connect" component={pages.WalletConnect} />
        <PrivateRoute path="/overview/:id" component={pages.OverView} />
        <PrivateRoute path="/newscore/:id/:section" component={pages.NewScore} />
        <Route component={pages.Error} />
      </Switch>
    </BrowserRouter>
  );

  function PrivateRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, {...props})
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

function Payment() {
  const location = useLocation();
  const dispatchPayment = useDispatch<UserDispatch>();
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get("plan");

  useEffect(() => {
    if (plan && !profile.plan) dispatchPayment(redirectToPlan(plan));
  });

  return (
    <Redirect
      to={{
        pathname: `/login?plan=${plan}`,
        state: {
          from: location,
        },
      }}
    />
  );
}

import React, { useEffect } from "react";
import { PDFObject } from "react-pdfobject";
import useStyles from "./styles";
import {  createStyles, FormControl, Grid, 
  InputBase, InputLabel, NativeSelect, TextareaAutosize, Theme,
   Typography, withStyles } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as constants from "../../utils/constants";
import { Home as CloseIcon } from "@material-ui/icons";
import { Invitation, ProjectDispatch } from "../../model";

import * as typeformEmbed from "@typeform/embed";
import { useDispatch } from "react-redux";
import { incrementProjectViews } from "../../actions/project";
import { grey } from "@material-ui/core/colors";


const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
    
      'label + &': {
        marginTop: theme.spacing(3),
       
      },
      
    },
    input: {
      borderRadius: 5,
      position: 'relative',
      backgroundColor:"#F4F6F9 !important",
      border: 'none',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),

     
      // Use the system font instead of the default Robot
      
      '&:focus': {
        borderRadius: 5,
        borderColor: '#F4F6F9',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
      
    },
    formControl: {
      margin: 0,
      display: "flex",
      wrap: "wrap"
    }
  }),
)(InputBase);


  
 
function Form() {
    const classes = useStyles();
    const dark = grey[900];
    return (
        <div className={classes.root}>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <ol  style={{width:100+"%"}}>
                <li className={classes.questions} style={{marginTop: "10%"}} >
                    
                   <Typography className={classes.questions}>Is there a presence of advisors? 
                  
                   </Typography>
                   
                   <FormControl className={classes.commentBox}>
                    <InputLabel htmlFor="demo-customized-select-native"></InputLabel>
                    <NativeSelect
                     id="demo-customized-select-native"
                     
                   
                     input={<BootstrapInput />}
                   >
                      <option aria-label="None" value="Advisors or mentors with previous exits or experience within the industry (++)" >
                      Advisors or mentors with previous exits or experience within the industry (++)</option>
                     
                    </NativeSelect>
                  </FormControl>
                </li>
                
                <li className={classes.questions} >
                    
                    <Typography className={classes.questions}> How many co-founders are there?

                      
                    </Typography>
                    
                    
                    <FormControl className={classes.commentBox}>
                    <InputLabel htmlFor="demo-customized-select-native"></InputLabel>
                    <NativeSelect
                     id="demo-customized-select-native"
                     
                   
                     input={<BootstrapInput />}
                   >
                      <option aria-label="None" value="Two co-founders (++)" >Two co-founders (++)</option>
                     
                    </NativeSelect>
                  </FormControl>
                 </li>
                 <li className={classes.questions} >
                    
                    <Typography className={classes.questions}> What is the experience level of the CEO?
                   
                    
                    </Typography>
                    
                     
                    <FormControl className={classes.commentBox}>
                    <InputLabel htmlFor="demo-customized-select-native"></InputLabel>
                    <NativeSelect
                     id="demo-customized-select-native"
                     
                   
                     input={<BootstrapInput />}
                   >
                      <option aria-label="None" value="CEO has experience as a CEO (+++)" >CEO has experience as a CEO (+++)</option>
                     
                    </NativeSelect>
                  </FormControl>
                 </li>
                 <li className={classes.questions} >
                    
                    <Typography className={classes.questions}>How complete is the management team? 
                   
                      
                    </Typography>
                    
                     
                    <FormControl className={classes.commentBox}>
                    <InputLabel htmlFor="demo-customized-select-native"></InputLabel>
                    <NativeSelect
                     id="demo-customized-select-native"
                     
                   
                     input={<BootstrapInput />}
                   >
                      <option aria-label="None" value="Complete (+++)" >Complete (+++)</option>
                     
                    </NativeSelect>
                  </FormControl>
                 </li>
                 <li className={classes.questions} >
                    
                    <Typography className={classes.questions}>Do the founder(s) work full-time?
 
                    </Typography>
                    
                    <FormControl className={classes.commentBox}>
                    <InputLabel htmlFor="demo-customized-select-native"></InputLabel>
                    <NativeSelect
                     id="demo-customized-select-native"
                     
                   
                     input={<BootstrapInput />}
                   >
                      <option aria-label="None" value="Founder(s) work full time (+++)" >Founder(s) work full time (+++)</option>
                     
                    </NativeSelect>
                  </FormControl>
                 </li>
                 <li className={classes.questions} >
                    
                    <Typography className={classes.questions}>What is the perceived risk of the market? 
                   
                     
                    </Typography>
                    
                    
                    <FormControl className={classes.commentBox}>
                    <InputLabel htmlFor="demo-customized-select-native"></InputLabel>
                    <NativeSelect
                     id="demo-customized-select-native"
                     
                   
                     input={<BootstrapInput />}
                   >
                      <option aria-label="None" value="Low perceived risk of market (++)" >Low perceived risk of market (++)</option>
                     
                    </NativeSelect>
                  </FormControl>
                 </li>
            </ol>
           
        
        
        
        </Grid>
      
        
        <Grid item xs={12} sm={12} md={6} lg={6}>
        
       
        <img src="/image53.png" className={classes.companyLogo}/>
        
        
        </Grid>
        </Grid>
        </div>
    )
}

export default Form;

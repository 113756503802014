import { callableFunctions, firestore } from "../repositories/firebase";
import { Project,Section, Report, IResponse, ShareInfo,ShareReport, AddTeams } from "../model";
import { Invitation } from "../model/invitation";
import { email2ObjectKey, array2Object } from "../utils";
import firebase from "firebase";
import { handleError } from "../utils/decorators";
import { ImportantDevicesOutlined } from "@material-ui/icons";
import {Roles} from "../pages/create/components/data";
import { Result } from "antd";
// import {technicalScoringFund,technicalScoringMarket,Roles} from "../pages/create/components/data";

const projectCollection = firestore.collection("project");
const userCollection = firestore.collection("users");
const favouritesCollection = firestore.collection("favourites");
const invitationsCollection = firestore.collection("invitations");
const teamCollection = firestore.collection("teams");
const shareReportsCollection = firestore.collection("shareReports");
const selectedShareCollection = firestore.collection("selectedShare");
const userProjectTracker = firestore.collection("userProjectUploadTracker");
const automatedCommentsCollection = firestore.collection("automatedComments");
const pitchDeckCollection = firestore.collection("PitchDeckUrlCollection")
const demoProjectId = "eu6mLyPqUO3FU5mDhdTc";

const handleUpdateProjectCount = async () => {
  try {
    // Call the Firebase Cloud Function to decrease project count
    const updateProjectCount = callableFunctions.httpsCallable('updateProjectCount');
    await updateProjectCount();

    console.log('Project count decreased successfully.');

    // Additional logic to create the project
    // ...
  } catch (error) {
    console.error('Error decreasing project count:', error);
  }
  };


  const handleShareCount = async () => {
    try {
      // Call the Firebase Cloud Function to decrease project count
      // console.debug('Share count decreased successfully.');
      const updateShareCount = callableFunctions.httpsCallable('updateShareCount');
      await updateShareCount();
  
      console.log('Share count decreased successfully.');
  
      // Additional logic to create the project
      // ...
    } catch (error) {
      console.error('Error decreasing project count:', error);
    }
    };

export class ProjectRepo {
  @handleError("Failed to create project...")
  static async create(
    project: Project,
    invitations: Invitation[],
    selectedPeople: Record<string,string>[],
    team: AddTeams[],
    businessComments: Record<string,string>[],
    technicalScoringFund,
    technicalScoringMarket,
    userId: number,
    pitchDeckUrl,
    clickTypeSubmit:boolean,
    projectId:number
  ): Promise<string | Error> {

    console.debug("Create : project",project)
    console.debug("Create : invitations",invitations)
    console.debug("Create : selectedPeople",selectedPeople)
    console.debug("Create : selectedPeople",selectedPeople)
    console.debug("Create : team",team)
    console.debug("Create : businessComments",businessComments)
    console.debug("Create : technicalScoringFund",technicalScoringFund)
    console.debug("Create : technicalScoringMarket",technicalScoringMarket)
    console.debug("Create : userId",userId)
    console.debug("Create : pitchDeckUrl",pitchDeckUrl)
    console.debug("Create : clickTypeSubmit",clickTypeSubmit)
    console.debug("Create : projectId",projectId)

    // if(!project.crypto)
    // {
      const technicalScore = this.calculateTechnicalScore(project,team,technicalScoringFund,technicalScoringMarket);
      project["technical"]=technicalScore;
    // }
    // const technicalScore = this.calculateTechnicalScore(project,team,technicalScoringFund,technicalScoringMarket);
    
    const comments = await this.automatedComments(team);
    project.sectionStats.overall.numComments = (comments.concat(businessComments)).length;
    let result
    //conditon for displaying project on dashboard or draft 
    if(clickTypeSubmit == true){ 
    project.activeStatus = true
    }else{
      project.activeStatus = false
    }  
    if(projectId){ 
       // if projectId is there or user coming from draft section
      console.debug("Create Project , If projectId", projectId)
      console.debug("Create Project , If project", project)


     await projectCollection.doc(projectId).update(project);
     result = projectId
      console.debug("Create Project , If ProjectiD", result.id)
      console.debug("Create : pitchDeckUrl:111",pitchDeckUrl)
      if(team.length > 0){ 
        addcollectionToTeam(team, projectId, projectId)
        }
      if(clickTypeSubmit == true){ 
        handleUpdateProjectCount()
        const urlId = pitchDeckCollection.add({projectId: projectId, pitchDeckUrl, createdAt:new Date()})
        if(team.length > 0){ 
          addcollectionToTeam(team, projectId, projectId)
          }
          if(invitations.length > 0){ 
            addcollectionToInvitation(invitations, result.id, result)
            }
            if(selectedPeople.length > 0){ 
              addcollectionToSelectedShare(invitations, result.id, result)
              }
      }
    }else{
       // if projectId is not there or user coming from create section
       project.userId = userId
       if(clickTypeSubmit == true){ 
      console.debug("Create Project , else  Project", project)
      result = await projectCollection.add(project);
      console.debug("Create Project , else  ProjectiD", result.id)
      console.debug("Create : pitchDeckUrl:137",pitchDeckUrl)
      const urlId = pitchDeckCollection.add({projectId: result.id, pitchDeckUrl, createdAt:new Date()})
      handleUpdateProjectCount()
       userProjectTracker.add({projectId: result.id, activeStatud: project.activeStatus , userId:userId, createdAt:new Date()})
      if(team.length > 0){ 
        addcollectionToTeam(team, result.id, result)
        }
        if(invitations.length > 0){ 
        addcollectionToInvitation(invitations, result.id, result)
        }
        if(selectedPeople.length > 0){ 
        addcollectionToSelectedShare(invitations, result.id, result)
        }
       }else{
        result = await projectCollection.add(project);
        console.debug("team mein data hai",  team)
        if(team.length > 0){ 
        addcollectionToTeam(team, result.id, result)
        }
       }
    }

    async function addcollectionToSelectedShare(selectedPeople, projectId, result) {
    await Promise.all(
      selectedPeople.map((v) =>
      selectedShareCollection.add({ ...v, projectID: projectId ? projectId :result.id })
      )
    );
    }

    async function addcollectionToInvitation(invitations, projectId, result) {
    await Promise.all(
      invitations.map((v) =>
        invitationsCollection.add({ ...v, projectID: projectId ? projectId :result.id })
      )
    );
    }

    async function addcollectionToTeam(team, projectId, result) {
      await Promise.all(
        team.map((v) =>
        teamCollection.add({ ...v, projectID: projectId ? projectId :result.id, projectId: projectId ? projectId :result.id })
        )
      );
    }
    
   
    const parentCollection = projectCollection.doc(projectId ? projectId :result.id).collection("responses");
    await Promise.all(
      (comments.concat(businessComments)).map((v) =>
      parentCollection.add({ ...v })
      )
    );
    return projectId ? projectId :result.id;
  }

  static async getComments(responses)
  {
    var comments= [];
    comments = await Promise.all(
      responses.map((element) => {
        console.debug("automatedCommentsCollection:automatedCommentsCollection",automatedCommentsCollection)
      return automatedCommentsCollection.doc(element.comment).get();
        // comments.push(comment);
      })
    );
    var comm = await Promise.all(
      comments.map((element)=>
        element.data()
      )
    );
    return comm;
  }
  static async automatedComments(team)
  {
    var founcount=0;
    var advcount=0;
    var roleCount=0;
    var role =[];
    var exp =[];
    var edu = [];
    var adv = [];
    var comments= [];
    team.forEach(element => {
      if(element.Role.includes("Founder"))
      {
        founcount++;
      }
      if(element.Role.includes("Advisor"))
      {
        adv.push(element.Experience);
      }
      exp.push(element.Experience)
      edu.push(element.Education)
      element.Role.forEach(val => {
        if(!(role.includes(val)))
        {
          role.push(val);
        }
      });
    });
    Roles.forEach(element => {
      if(role.indexOf(element.value)> -1)
      {
        roleCount++;
      }
    });
    if(adv.includes("Experience in a C-Level position"))
    {
      comments.push({"comment":"advisorComment2", "type":"Automated"});
    }
    if(adv.includes("Business experience in Sector"))
    {
      comments.push({"comment":"advisorComment1", "type":"Automated"});
    }
    if(advcount == 0)
    {
      comments.push({"comment":"advisorComment3", "type":"Automated"});
    }
    if(edu.includes("No tertiary Education"))
    {
      comments.push({"comment":"educationComment1", "type":"Automated"});
    }
    if(edu.includes("Tertiary Education to Bachelors level"))
    {
      comments.push({"comment":"educationComment2", "type":"Automated"});
    }
    if(edu.includes("Masters level and above"))
    {
      comments.push({"comment":"educationComment3", "type":"Automated"});
    }
    if(exp.includes("Experience as a CEO"))
    {
      comments.push({"comment":"expComment1", "type":"Automated"});
    }
    if(exp.includes("Experience in a C-Level position") && !(exp.includes("Experience as a CEO")))
    {
      comments.push({"comment":"expComment2", "type":"Automated"});
    }
    if(exp.includes("Business experience in Sector") && !(exp.includes("Experience in a C-Level position")) && !(exp.includes("Experience as a CEO")))
    {
      comments.push({"comment":"expComment3", "type":"Automated"});
    }
    if(exp.includes("Product Manager/Owner experience") && !(exp.includes("Business experience in Sector")) && !(exp.includes("Experience in a C-Level position")) && !(exp.includes("Experience as a CEO")))
    {
      comments.push({"comment":"expComment4", "type":"Automated"});
    }
    if(exp.includes("No business Experience") && !(exp.includes("Product Manager/Owner experience")) && !(exp.includes("Business experience in Sector")) && !(exp.includes("Experience in a C-Level position")) && !(exp.includes("Experience as a CEO")))
    {
      comments.push({"comment":"expComment5", "type":"Automated"});
    }
    if(founcount==1)
    {
      comments.push({"comment":"founderComment1", "type":"Automated"});
    }
    else if(founcount==2)
    {
      comments.push({"comment":"founderComment2", "type":"Automated"});
    }
    else
    {
      comments.push({"comment":"founderComment3", "type":"Automated"});
    }
    if(roleCount==Roles.length)
    {
      comments.push({"comment":"roleComment1", "type":"Automated"});
    }
    if(roleCount>=Roles.length/2 && roleCount<Roles.length)
    {
      comments.push({"comment":"roleComment3", "type":"Automated"});
    }
    if(roleCount==1&& role.includes("Entrepreneurial"))
    {
      comments.push({"comment":"roleComment2", "type":"Automated"});
    }
    return comments;

  }
  static calculateTechnicalScore(project,team,technicalScoringFund,technicalScoringMarket)
  {
    var data = team.map((ele,index)=>{
      return {
        ...ele,
        ExperienceScore: ele.Experience=="Experience as a CEO"?15:ele.Experience=="Business experience in Sector"?10:ele.Experience=="Experience in a C-Level position"?10:ele.Experience=="Product Manager/Owner experience"?5:ele.Experience=="No business Experience"?-10:0,
        educationScore:ele.education=="No tertiary Education"?-5:ele.education=="Tertiary Education to Bachelors level"?5:10,
        timeScore: ele.time=="full-time"?15:0,
      }
    })
    var founcount=0;
    var ceocount=0;
    var expsum=0;
    var edusum=0;
    var timesum=0;
    var agesum=0;
    var role =[];
    var adsum = 0;
    var adCount = 0;
    data.forEach(element => {
      if(element.Role.includes("Founder"))
      {
        founcount++;
        timesum=timesum+element.timeScore;
      }
      if(element.Role.includes("Advisor"))
      {
        adCount++;
        adsum = element.Experience=="Business Experience in Sector"?10: element.Experience=="Experience in a C-Level position"?5:0;
      }
      if(element.Role.includes("CEO"))
      {
        ceocount++;
        agesum=agesum+(element.age?element.age:0);
      }
      element.Role.forEach(val => {
        if(!(role.includes(val))){
          role.push(val);
        }
      });
      
      expsum=expsum+element.ExperienceScore;
      edusum=edusum+element.educationScore;
    });
    if(founcount)
    timesum=timesum/founcount;
    expsum=data.length?expsum/data.length:0;
    edusum=data.length?edusum/data.length:0;
    if(ceocount)
    agesum=agesum/ceocount;
    if(adCount == 0)
    {
      adsum = -10;
    }
    var roleCount = 0;
    Roles.forEach(element => {
      if(role.indexOf(element.value)> -1)
      {
        roleCount++;
      }
    });
    var roleSum=0;
    if(roleCount==Roles.length)
    {
      roleSum=20;
    }
    if(roleCount>=Roles.length/2 && roleCount<Roles.length)
    {
      roleSum=10;
    }
    if(roleCount==1&& role.includes("Entrepreneurial"))
    {
      roleSum=5;
    }
    var foundSum = founcount==1?5:founcount==2?10:-10;
    var teamScore = ((roleSum*0.4)+(foundSum*0.1)+agesum+(edusum*0.1)+(adsum*0.1)+(expsum*0.25)+(timesum*0.05))/30.5;
    if(!data.length)
    {
      teamScore = 0;
    }
    var marketScore=0;
    var marketTotal=0;
    technicalScoringMarket.forEach((mar)=>{
      marketScore=marketScore+((project.TechnicalAnalysis[mar.value])*(mar.weight));
      marketTotal = marketTotal + ((Math.max(...mar.options.map((v)=>v.score)))*(mar.weight));
    })
    marketScore=marketScore/marketTotal;
    var fundScore=0;
    var fundTotal = 0;
    technicalScoringFund.forEach((mar)=>{
      fundScore=fundScore+((project.TechnicalAnalysis[mar.value])*(mar.weight));
      fundTotal = fundTotal + ((Math.max(...mar.options.map((v)=>v.score)))*(mar.weight));
    })
    fundScore=fundScore/fundTotal;
    var overall= teamScore*0.5+marketScore*0.3+fundScore*0.2;
    return {
      teamScore:teamScore,
      marketScore:marketScore,
      fundScore:fundScore,
      overall: overall,
    }

  }

  @handleError("Failed to add to favourite project...")
  // static async addToFavrourite(data): Promise<string | Error> {
  static async addToFavrourite(data): Promise<string | Error> {
    const result = await favouritesCollection.add(data);
    console.debug(result,"result in  add to fav repository")
    return result.id;
  }

  // var jobskill_query = db.collection('job_skills').where('job_id','==',post.job_id);
  // jobskill_query.get().then(function(querySnapshot) {
  //   querySnapshot.forEach(function(doc) {
  //     doc.ref.delete();
  //   });
  // });

  @handleError("Failed to remove from favourite project...")
  // static async addToFavrourite(data): Promise<string | Error> {
  static async removeToFavrourite(data): Promise<string | Error> {
    const query = await favouritesCollection.where('UID' ,"==", data.UID).where("projectid","==",data.projectid);

    const result = await query.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        doc.ref.delete();
      });
    });
    console.debug(result,"result in  remove to fav repository")
    if(result==undefined)
      return data.projectid;
    else
      return "result.id";
  }

  @handleError("Failed to fetch all projects...")
  static async fetchAll(uid: string,email: string): Promise<Project[] | Error> {
    //created by me
    // const snapshot = await projectCollection
    //   .where("requester", "==", uid)
    //   .get();
    //privacy- for everyone
    const snapshot2 = await projectCollection.where('activeStatus','==',true).get();
    // const demoDoc = await projectCollection.doc(demoProjectId).get();
    const favColl = await favouritesCollection.where("projectid", "==",demoProjectId ).where('UID','==',uid)
    .get();
    const likes = await favouritesCollection.where("projectid", "==",demoProjectId ).get();
    const shared = await selectedShareCollection.where("email","==",email).get();
    const sharedProjects = await Promise.all(
      shared.docs
      .filter(doc => doc.exists)
      .map(doc=> projectCollection.doc(
        doc.data().projectID).get())
    );
    //shared with me
    const SharedWithme = await Promise.all (sharedProjects
    .filter(doc => doc.exists)
    .map( async (doc) => {
       return ({...doc.data(),id: doc.id,
        // shared:true,
        likes:await this.getUserLikeStatus(doc.id,uid),likesCount: (await this.getProjectLikes(doc.id)).length} as Project) 
      } 
    ));
    // let test=()=>{
    //   let a=favColl.docs.map((d)=>{
    //     return {...d.data()};
    //   })
    //   return (
    //     a
    //   )
    // }
    // let likesArray=()=>{
    //   let a=likes.docs.map((d)=>{
    //     return {...d.data()};
    //   })
    //   return (
    //     a
    //   )
    // }
    const sharedStatus = (id)=>{
     if(SharedWithme.find((obj)=>obj.id===id)){
       return true;
     }
     return false;
    }
     
    // const demo = {
    //   ...demoDoc.data(),
    //   id: demoProjectId,
    //   isDemo: true,
    //   likes:this.getLikeStatus(test(),demoProjectId),
    //   likesCount:likesArray().length,
    // } as Project;
    // const projectsFetched = await Promise.all(snapshot.docs.map(
    //   async (d) => {
    //     return ({ ...d.data(), id: d.id,likes:await this.getUserLikeStatus(d.id,uid),likesCount: (await this.getProjectLikes(d.id)).length} as Project) 
    //   }
    // ));

    const projectsFetched1 = await Promise.all(snapshot2.docs.map(
      async (d) => {
        return ({ ...d.data(), id: d.id,shared:sharedStatus(d.id),likes:await this.getUserLikeStatus(d.id,uid),likesCount: (await this.getProjectLikes(d.id)).length} as Project) 
      }
    ));
  //  const allProjects= _.uniqWith(SharedWithme.concat(projectsFetched1), _.isEqual)
   
    return [...projectsFetched1];
    // return [...projectsFetched, demo,...SharedWithme];
  }


  static async fetchAllDrafts(uid: string,email: string): Promise<Project[] | Error> {
    //created by me
    // const snapshot = await projectCollection
    //   .where("requester", "==", uid)
    //   .get();
    //privacy- for everyone
    const snapshotDraft = await projectCollection.where('activeStatus','==',false).where('userId', "==",uid).get();
    // const demoDoc = await projectCollection.doc(demoProjectId).get();
    const favColl = await favouritesCollection.where("projectid", "==",demoProjectId ).where('UID','==',uid)
    .get();
    const likes = await favouritesCollection.where("projectid", "==",demoProjectId ).get();
    const shared = await selectedShareCollection.where("email","==",email).get();
    const sharedProjects = await Promise.all(
      shared.docs
      .filter(doc => doc.exists)
      .map(doc=> projectCollection.doc(
        doc.data().projectID).get())
    );
    //shared with me
    const SharedWithme = await Promise.all (sharedProjects
    .filter(doc => doc.exists)
    .map( async (doc) => {
       return ({...doc.data(),id: doc.id,
        // shared:true,
        likes:await this.getUserLikeStatus(doc.id,uid),likesCount: (await this.getProjectLikes(doc.id)).length} as Project) 
      } 
    ));
    const sharedStatus = (id)=>{
     if(SharedWithme.find((obj)=>obj.id===id)){
       return true;
     }
     return false;
    }
    const projectsFetched1 = await Promise.all(snapshotDraft.docs.map(
      async (d) => {
        return ({ ...d.data(), id: d.id,shared:sharedStatus(d.id),likes:await this.getUserLikeStatus(d.id,uid),likesCount: (await this.getProjectLikes(d.id)).length} as Project) 
      }
    ));
    return [...projectsFetched1];
  }

  static async getProjectLikes(projectId)
  {
    const favColl =  await favouritesCollection.where("projectid", "==",projectId ).get();
    let likesArray=()=>{
      let a=favColl.docs.map((d)=>{
        return {...d.data()};
      })
      return (
        a
      )
    }
    return likesArray();
  }

  static async getUserLikeStatus(projectId,uid)
  {
    const favColl =  await favouritesCollection.where("projectid", "==",projectId ).where('UID','==',uid).get();
    let test=()=>{
      let a=favColl.docs.map((d)=>{
        return {...d.data()};
      })
      return (
        a
      )
    }
    return this.getLikeStatus(test(),projectId);
  } 

static getLikeStatus(liksProjectArray,prjoectId){
  let likeProjectArray=liksProjectArray.filter((project)=>{
    if(project.projectid==prjoectId)
    return prjoectId;
  })
  if(likeProjectArray.length)
  return true;
  else
  return false;
}

static getRank(score,allProjects,id,startDate){
  var rank= allProjects.length;
  allProjects.forEach(element => {
    if((element.sectionStats?(element.sectionStats.overall?element.sectionStats.overall.avgRating:0):0)<score)
    {
      rank--;
    }
    if(id != element.id)
    {
      if((element.sectionStats?(element.sectionStats.overall?element.sectionStats.overall.avgRating:0):0)==score && element.startDate>startDate)
      {
        rank--;
      }
     
    }
  });
  return rank;
  
}

static getSectionRank(score,id,section,allProjects,startDate){
  var rank= allProjects.length;

      if(section=="team")
      {
        // var data = allProjects.sort(function(a,b){return((b.sectionStats.team?b.sectionStats.team.avgRating:0)-(a.sectionStats.team?a.sectionStats.team.avgRating:0))})
        // data.forEach((element,index) => {
        //   if(element.id==id)
        //   {
        //     rank = index;
        //   }
        // });
        allProjects.forEach(element => {
            if((element.sectionStats?(element.sectionStats.team?element.sectionStats.team.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.team?element.sectionStats.team.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
        });
      }
      if(section=="market")
      {
        allProjects.forEach(element => {
            if((element.sectionStats?(element.sectionStats.market?element.sectionStats.market.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.market?element.sectionStats.market.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
        });
      }
      if(section=="idea")
      {
        allProjects.forEach(element => {
            if((element.sectionStats?(element.sectionStats.idea?element.sectionStats.idea.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.idea?element.sectionStats.idea.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
        });
      }
      if(section=="finance")
      {
        allProjects.forEach(element => {
            if((element.sectionStats?(element.sectionStats.finance?element.sectionStats.finance.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.finance?element.sectionStats.finance.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
        });
      }
      if(section=="esg")
      {
        allProjects.forEach(element => {
          
            if((element.sectionStats?(element.sectionStats.esg?element.sectionStats.esg.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.esg?element.sectionStats.esg.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
          
        });
      }
      if(section=="competition")
      {
        allProjects.forEach(element => {
          
            if((element.sectionStats?(element.sectionStats.competition?element.sectionStats.competition.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.competition?element.sectionStats.competition.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
          
        });
      }
      if(section=="overall")
      {
        allProjects.forEach(element => {
          
            if((element.sectionStats?(element.sectionStats.overall?element.sectionStats.overall.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.overall?element.sectionStats.overall.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
          
        });
      }
      
  return rank;
  
}

@handleError("Failed to fetch your project...")
static async fetchCompanyReport(
  projectId: string,
): Promise<Project> {
  const company = await projectCollection.doc(projectId).get();
  const data=  company.data() as Project;
  return data;
}


  @handleError("Failed to fetch your report...")
  static async fetchReport(
    projectId: string,
    project?: Project
  ): Promise<Report | Error> {
    const projectDoc = projectCollection.doc(projectId);
    let fetchedProject: firebase.firestore.DocumentSnapshot | undefined;
    const projectAll = await projectCollection.get();
    const allProjects=projectAll.docs.map(
      (d) => ({ ...d.data(), id: d.id } as Project)
    )
    const likes = await favouritesCollection.where("projectid", "==",projectId ).get();
    let likesArray=()=>{
      let a=likes.docs.map((d)=>{
        return {...d.data()};
      })
      return (
        a
      )
    }
    if (!project) fetchedProject = await projectDoc.get();
    // console.debug(project.sectionStats.overall.avgRating,"project");
    const [responses, invitations, teams] = await Promise.all([
      projectDoc.collection("responses").get(),
      invitationsCollection.where("projectID", "==", projectId).get(),
      teamCollection.where("projectID", "==", projectId).get(),
    ]);

    if (project) {
    const score = project.sectionStats.overall.avgRating;
    var rank = this.getRank(score,allProjects,project.id,project.startDate);
    return {
        project: {
          ...project,
          likesCount:likesArray().length,
        },
        responses: responses.docs.map((d) => d.data() as IResponse),
        invitations: invitations.docs.map(
          (d) => ({ ...d.data(), id: d.id } as Invitation)
        ),
        team: teams.docs.map((d)=>({
          ...d.data(),
          id: d.id,
        })
        ),
        rank: rank,
      };
    } else if (fetchedProject && fetchedProject.exists) {
    const score = fetchedProject.data().sectionStats.overall.avgRating;  
      var rank = this.getRank(score,allProjects,fetchedProject.id,fetchedProject.data().startDate);
      return {
        project: {
          ...fetchedProject.data(),
          id: fetchedProject.id,
          likesCount:likesArray().length,
        } as Project,
        responses: responses.docs.map((d) => d.data() as IResponse),
        invitations: invitations.docs.map(
          (d) =>
            ({
              ...d.data(),
              id: d.id,
            } as Invitation)
        ),
        team: teams.docs.map((d)=>({
          ...d.data(),
          id: d.id,
        })
        ),
        rank: rank,
      };
    } else {
      return new Error("This project does not exist");
    }
  }

  @handleError("Failed to delete project...")
  static async deleteProject(projectId: string): Promise<void | Error> {
    const projectDoc = projectCollection.doc(projectId);
    await projectDoc.delete();
  }

  @handleError("Failed to get all projects...")
  static async listProjects() {
    const projectAll = await projectCollection.get();
    const allProjects=projectAll.docs.map(
      (d) => ({ ...d.data(), id: d.id } as Project)
    )
    return allProjects;
  }

  @handleError("Failed to increment project views...")
  static async incrementViews(
    projectId: string,
    section: string
  ): Promise<void | Error> {
    await projectCollection.doc(projectId).update({
      [`sectionStats.${section}.views`]: firebase.firestore.FieldValue.increment(
        1
      ),
    });
  }

  @handleError("Failed to store rank...")
  static async projectRank(
    rank: number,
    projectId: string
  ): Promise<void | Error> {
    await projectCollection.doc(projectId).set({
      previousRank: rank,
  }, { merge: true });
  }

  @handleError("Failed to store rank...")
  static async projectSectionRank(
    rank: number,
    projectId: string,
    section: string,
  ): Promise<void | Error> {
    await projectCollection.doc(projectId).update({
      [`sectionStats.${section}.previousRank`]: rank
    });
  }

  @handleError("Failed to share your project...")
  static async shareProject(
    project: Project,
    info: ShareInfo,
    requestorName: string,
    requestorId: string
  ): Promise<void | Error> {
    const invitations: Invitation[] = info.emails.map((email) => {
      const sections = info.emailSections[email2ObjectKey(email)];
      return {
        projectID: project.id,
        email: email,
        requestor: requestorName,
        requestorId: requestorId,
        company_name: project.company.name,
        company_website: project.company.website?project.company.website:"",
        sections: sections,
        sentAt: new Date(),
        dueDate: ("endDate" in project)
          ? project.endDate?.toDate()
          : new Date(),
        disclaimerAccepted: false,
        sectionsStatus: array2Object(sections, "sent"),
        pdfLink: project.pdfLinks ?? {},
        formLink: {},
      };
    });
    var data: Record<string, Section> = project.sectionStats;
    project.sections.forEach((val)=>{
      data[val].invited = project.sectionStats[val].invited + invitations.reduce(
        (pre, next) => pre + (next.sections.includes(val) ? 1 : 0),
        0
      )
    })
    data.overall.invited = project.sectionStats.overall.invited+info.emails.length;
    

    await Promise.all(
      invitations.map((invite) => invitationsCollection.add(invite))
    );
    


    await projectCollection.doc(project.id).update({
      sectionStats: data,
    });

  }

  @handleError("Failed to share your project...")
  static async shareProjectReport(
    project: Project,
    info: ShareReport,
    requestorName: string,
    requestorId: string
  ): Promise<void | Error> {
    console.debug(info);
    const data={
      projectID: project.id,
      email: info.email[0],
      requestor: requestorName,
      requestorId: requestorId,
      receiver_name: info.firstName,
      project_link: info.project_link,
      company_name: project.company.name,
      sentAt: new Date(),
    }
    
    await shareReportsCollection.add(data)
    handleShareCount()
    // await Promise.all(
    //   invitations.map((invite) => invitationsCollection.add(invite))
    // );
  }
}

import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent:"center",
      alignItems:"center",
      borderRadius: "10px",
  
    },
    expertText:{
      marginTop: "2rem",
      marginBottom: "2rem",
      fontWeight: "600"
  },
  addButton:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
    "& > button":{
        height: "38px",
        padding: "0 16px",
        minWidth: "48px",
        minHeight: "auto",
        borderRadius: "5px",
        // color: "white",
        // backgroundColor: "#137DFB",
  // backgroundColor: theme.palette.primary.main,

    }
},
addMember:{
  color:'white',
  backgroundColor:"#137DFB !important",
}
  
  }));
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {

        '& .MuiBackdrop-root': {
            backgroundColor: "rgba(0, 0, 0, 0.5) !important"

        },

    },
    container: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        // backgroundColor:"#00005b",
        backgroundColor:'#F2F7FF'

    },
    logotypeContainer: {
        // backgroundColor: theme.palette.primary.main,
        width: "60%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    logotypeImage: {
        width: "100%",
        height: "100%",
        marginBottom: theme.spacing(0),
    },
    logotypeText: {
        fontFamily: "'Manrope', sans-serif",
        fontWeight: "bold",
        color: "white",
        fontSize: 84,
        [theme.breakpoints.down("md")]: {
            fontSize: 48,
        },
    },

    
        textField: {
            color:"black",
            "& > MuiFormLabel-root":{
             color:"#black !important"
            },
            "& > input:webkit-autofill":{
              color: "#black !important"
            }
          },

    submitField:{
        "& > label":{
        color:"#fff",
        width:"100%",
        textAlign:"center"
       },
       "& > div > input:-webkit-autofill":{
        transition: "background-color 5000s ease-in-out 0s",
        '-webkit-text-fill-color': "white",
     },
    },
    formContainer: {
        width: "40%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
    },
    form: {
        width: 320,
        display: "flex",
        justifyContent: "center",
        height: "35%",
        textAlign:"center",
        alignItems: "center",
    },
    copyright: {
        marginTop: theme.spacing(4),
        whiteSpace: "nowrap",
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            bottom: theme.spacing(2),
        },
    },
    submitButton: {
        background: "#137DFB !important",
        // backgroundColor: theme.palette.primary.main,
        border: "1px solid #137DFB",
        fontSize: "13px",
        // color: theme.palette.background.default,
        color:"white",
        width: 30 + "%",
        padding: "0.4rem !important",
        marginTop: "1rem !important"
    },
    greeting: {
        fontSize: "2rem",
        marginTop: "5rem",
        color:"black",
    },
    loginLink: {
        position: "absolute",
        bottom: "25px",
        right: "310px",
        fontSize: "15px",
        color:"black",  
        
    },
    textFieldUnderline: {
        '&:before': {
            borderBottom: 'none !important', // Remove the underline before input
          },
          '&:after': {
            borderBottom: 'none !important', // Remove the underline after input
          },
    
      },
    
    logotypeContainer1:{

    },
    formikPassword:{
        border:"1.5px solid #B2BEB5 !important",
        padding:"5px",
        borderRadius:"10px",
        "& > label":{
          color:"#B2BEB5",
          paddingLeft:"10px"
         },
          "& > div > input:-webkit-autofill":{
            transition: "background-color 5000s ease-in-out 0s",
            '-webkit-text-fill-color': "#B2BEB5 !important",
         }
      },
    forgotpara: {
        fontSize: "18px",
        fontWeight: "600",
        marginTop: "2rem",
        color:"black",

    },
    forgotparas: {
        fontSize: "16px",
        margin: "20px",
        width: 75 + "%",
        textAlign: "center",
        color:"black",
        marginBottom:'0px'


    },
    i3dLogo: {
        marginTop: theme.spacing(5),
        height: "100px",
        width: "100px",
        // marginBottom: theme.spacing(5),
      },


}));

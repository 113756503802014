export const sentimentGraphDescription =
  "Sentiment analysis is a machine learning tool that analyses texts for polarity, from positive to negative. By training machine learning tools with examples of emotions in text, machines automatically learn how to detect sentiment without human input. Our sentiment analysis uses natural language processing in order to represent the 'feelings' or 'gut feel' that our network of experts have when analysing a venture.\n\nIt is important to note that this is supplemental information that does not currently reflect on the overall score that a venture gets for any given section. It should be viewed as a double checking of sorts, as we would expect the sentiment to correlate with the score.";
export const expertScoreTooltip = 
"This will help to determine whether the product will meet a strong demand, and if the product will be successful at capturing early adopters";
  export const RED_FLAG_THRESHOLD = 0.6;
export const HIGH_SCORE_THRESHOLD = 0.9;


export const expertScorePopup = 
"The Expert Score is completed by experts based in the i3D Inner Swarm who complete a deeper dive analysis of the documentation supplied by the company being analysed. This is a sectional analysis that looks at the key factors for potential success. Each expert completes the analysis anonymously, creating an aggregated score free of bias"
export const technicalScorePopup = 
"The Technical Score is based on a series of factors determined from a regression analysis that examined thousands of companies to determine what contributed to their successes and failures. These factors are then examined against quantitative data supplied by the company analysed, and using a series of algorithms, are assigned a technical score. Insights provided are an automated response that can be assigned due to the matching of a sub-factor score to a predefined success metric. These insights should be read together with those provided by the network"
export const rapidScorePopup = 
"The i3D Rapid Score is based on a greater anonymous network of Outer Swarm members rapidly answer a short series of questions to feedback on wat is commonly known as an Elevator Pitch.";
export const weightingPopup = 
"The Weighting Factor is the weight given to the data points to assign each section of scoring (Technical, Expert Inner Swarm & Rapid Outer Swarm) a lighter, or heavier importance in the network scoring process. "

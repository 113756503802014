import { combineReducers } from "redux";
import * as model from "../model";
import { userReducer } from "./user";
import { layoutReducer } from "./layout";
import { searchReducer } from "./search";
import { projectReducer } from "./project";
import { notificationsReducer } from "./notifications";
import { invitationReducer } from "./invitation";
import { commentReducer } from "./responses";
import { feedbackReducer } from "./feedback";
import { reminderReducer } from "./reminder";
import {metamaskReducer} from "./metamask";
import {subscriptionReducer} from "./subscription"
import {
  firebaseReducer,
  firestoreReducer,
  FirebaseReducer,
  FirestoreReducer,
} from "react-redux-firebase";

export interface RootState {
  user: model.UserState;
  layout: model.LayoutState;
  search: model.SearchState;
  project: model.ProjectState;
  notifications: model.NotificationsState;
  subscription: model.SubscriptionState;
  comments: model.ResponseState;
  invitation: model.InvitationState;
  feedback: model.FeedbackState;
  reminder: model.ReminderState;
  firebase: FirebaseReducer.Reducer<model.UserProfile>;
  firestore: FirestoreReducer.Reducer;
  metamask: model.MetaMaskState;
}

export default () =>
  combineReducers({
    user: userReducer,
    layout: layoutReducer,
    search: searchReducer,
    project: projectReducer,
    notifications: notificationsReducer,
    comments: commentReducer,
    metamask: metamaskReducer,
    invitation: invitationReducer,
    feedback: feedbackReducer,
    subscription:subscriptionReducer,
    reminder: reminderReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  });

import { withStyles, Switch, Typography, Grid } from "@material-ui/core";
import { ViewList, ViewModule } from "@material-ui/icons";
import React from "react";

interface Props {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const PurpleSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.light,
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {
    backgroundColor: theme.palette.grey[400],
  },
}))(Switch);

export default function ListViewToggle(props: Props) {
  const { checked, onChange } = props;
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <ViewModule />
        </Grid>
        <Grid item>
          <PurpleSwitch checked={checked} onChange={onChange} name="checkedA" />
        </Grid>
        <Grid item>
          <ViewList />
        </Grid>
      </Grid>
    </Typography>
  );
}

import React from 'react';

const ElivaterPitchPdfComponents = ({ data }) => {

  const containerStyle = {
    backgroundColor: '#002060',
    padding: "30px",
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    maxHeight: '100vh',
    color: "white"

  }
  const card = {
    backgroundColor: 'white',
    height: "63rem",
    width: "42rem",
    padding: "20px",
    color: "black",
    margin: "auto",
    borderRadius:"20px"
  }
  const image = {
    height: "290px",
    width: "285px",
  }
  const imageStyle = {
    height: "291px",
    width: "286px",
    padding: "0px",
    margin:"0px"
  }
  // console.debug("wddvtfwyhwj: problem statemenbt", data )
  return (
    <div >
      <div className='row p-4'>
        <h4 className='text-white ms-5 mt-1'> {data ? data.heading : ""}</h4>
        <div className='col-md-12 d-flex  justify-content-center '>
        <div className='card mx-auto' style={card}>
            <span dangerouslySetInnerHTML={{
                    __html: data ? data.cardData.replace(/<br\s*\/?>/gi, ' ').replace(/&(nbsp|amp|quot|lt|gt);/g, " "):""
                }}></span>
                {/* {data ? data.cardData1 : ""} */}
            </div>
        </div>
       
      </div>
    </div>



  );
};

export default ElivaterPitchPdfComponents;

import { makeStyles } from "@material-ui/core/styles";
// import { content } from "html2canvas/dist/types/css/property-descriptors/content";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    backgroundColor: "rgb(242, 247, 255)",
    "& .MuiStepIcon-active": { color: "#137DFB" },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#AEAEAE",
  },
  title: {
    flexGrow: 1,
    fontSize: 24,
    fontWeight: 600,
    color: "#AEAEAE",
  },
  subtitle: {
    flexGrow: 1,
    color: "#414141",
    fontSize: "13px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "20px"
  },
  heading: {
    color: theme.palette.blackColor.main,
    fontWeight: 500,
    textAlign: "left",
  },
  comp_details: {
    width: "40vw",
    marginTop: "8%",
    height: "100%",
    marginLeft: 27 + "%",
  },
  toolbar: {
    minHeight: 80,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepperContainer: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    // width: "50vw",
    // width: "60vw",
    overflow: 'hidden',
    height: "100%",
    fontSize: 32,
    // fontFamily:"Montserrat",
    fontWeight: "bold",
    marginBottom: "1rem",
    "& .MuiStepConnector-root": {
      width: "80px"
    },
    "& >div:nth-child(2)":{
      width: "60vw",
      margin: "auto"
    },
    "& ::-webkit-scrollbar":{
      display:"none",
      width:"0px"
    },
  //  " &::-webkit-scrollbar'": { width: 0, }

  },
  stepperCom: {
    backgroundColor: "transparent",
    width:"95vw",
    overflowX:'scroll',
  },
  projectHead: {
    color: theme.palette.blackColor.main
  },
  submitDetail: {
    width: "150px",
    height: "40px",
    // background: "#137DFB",
    backgroundColor: theme.palette.primary.main,

    border: "1px solid #137DFB",
    boxSizing: "border-box",
    borderRadius: "10px",
    // fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#F8FAFD !important",
    marginTop: "2rem"
  },

  stepper: {
    color:"green",
    display:"block",

  },
  fab: {
    color:"blue",
    border:"1px"
  },
  pitchCreateBtn:{
display:"flex",
justifyContent:"center"
  },
  buttonScore:{
    fontSize: "0.8rem",
    // marginLeft:'17.5rem',
    backgroundColor:'#137DFB!important',
    
    // width: "100%",
    paddingTop: "0.7rem",
    paddingBottom: "0.7rem",

  }
  
  // steps: {
  //   backgroundColor: theme.palette.background.main,
  //   minWidth: "35vw",
  // },
}));

import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import useStyles from "./styles";
import FadeIn from "react-fade-in";
import { useFormikContext } from "formik";
import {
    Typography,
} from "@material-ui/core";
import { FormInfo } from "../../../../model";

export default function (props) {
    const classes = useStyles();
    const formik = useFormikContext<FormInfo>();
    const { values, errors, touched, setFieldValue } = useFormikContext<
    FormInfo
  >();
    console.debug("setyesNoSwitch:formik",formik)
    const handleYes = () => {
        props.setyesNoSwitch("Yes")
        setFieldValue('needExpertScore', "Yes")
        if (!formik.values.scoringTypes.includes("i3D Expert")) {
            formik.values.scoringTypes.push("i3D Expert");
        }
        // return "handle Yes";
    }
    const handleNo = () => {
        props.setyesNoSwitch("No")
        setFieldValue('needExpertScore', "No")
        if (formik.values.scoringTypes.includes("i3D Expert")) {
            var index = formik.values.scoringTypes.indexOf("i3D Expert");
            if (index > -1) {
                formik.values.scoringTypes.splice(index, 1);
            }
        }
        // return "handle No";

    }
    return (
        <FadeIn>

            <FormControl component="fieldset" className={classes.title}>
                <Typography className={classes.expertText}>
                    Would you like to have an Expert score the company?
                </Typography>
                <RadioGroup row aria-label="position" name="position" defaultValue={props.yesNoSwitch}>
                    <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                        name=''
                        onChange={handleYes}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                        onChange={handleNo}
                        labelPlacement="end"
                    />

                </RadioGroup>
            </FormControl>

        </FadeIn>

    );
}
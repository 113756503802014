import { firestore } from "../repositories/firebase";
import { handleError } from "../utils/decorators";
const weightCollection = firestore.collection("weights");

export default class {
    @handleError("Failed to fetch invitation data.")
    static async fetchWeights() {
      const invitation = await weightCollection.doc('DZCXUgVq4BYkaWyP4E6n').get();
      const data=  invitation.data();
      return data;
    }

}
import React from "react";

import {
  makeStyles,
  Theme,
  Grid,
  Paper,
} from "@material-ui/core";
import { Project } from "../../../model";
import { Link } from "react-router-dom";

interface Props {
  project: Project;
  data: Array<Record<string,any>>;
}

const useStyles = makeStyles((theme: Theme) => ({
    companyLogo:{
        width: "100%",
        height: "100%",
    }
}));

function MostSimilar(props: Props) {
  
  const { project, data } = props;
  const classes = useStyles();
  const projectRank= data.find(obj => {
    return obj.id === project.id;
  });
  const similarRank1= data.find(obj => {
    if(projectRank?.rank==1)
    {
    return obj.rank === projectRank.rank+1;
    }
    return obj.rank === projectRank?.rank-1;
  });
  const similarRank2= data.find(obj => {
    if(projectRank?.rank==1)
    {
    return obj.rank === projectRank.rank+2;
    }
    if(projectRank?.rank==data.length)
    {
    return obj.rank === projectRank.rank-2;
    }
    return obj.rank === projectRank?.rank+1;
  });
 
  
  return (
    <Grid container spacing={4}>
        <Grid item  xs={6}>
            <Link to={`/app/reports/${similarRank1?.id}`} style={{ display: "flex", flexDirection:"column" }}>
            <Paper elevation={3} style={{height:120,padding:10,borderRadius:10,}}>
                <img 
                src={similarRank1?.logo}
                alt="company logo"
                className={classes.companyLogo}
                />
            </Paper>
            </Link>
        </Grid>
        <Grid item  xs={6}>
            <Link to={`/app/reports/${similarRank2?.id}`} style={{ display: "flex", flexDirection:"column" }}>
            <Paper elevation={3} style={{height:120,padding:10,borderRadius:10,}}>
                <img 
                src={similarRank2?.logo}
                alt="company logo"
                className={classes.companyLogo}
                />
            </Paper>
            </Link>
        </Grid>
    </Grid>
      );
}

export default MostSimilar;

import React from "react";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Close as CloseIcon, Info } from "@material-ui/icons";
import { Select, Input, AutoComplete } from "formik-antd";
import { useFormikContext } from "formik";
import { AddTeams } from "../../model";
import { Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Modal, Paper, Tooltip } from "@material-ui/core";
import { time, Experience, education, Roles, age } from "../../pages/create/components/data";
import useStyles from "./style";
import TEditor from "../../pages/create/components/Problem/TEditor";
import { Editor } from "@tinymce/tinymce-react";
import { CallMade, Drafts, Filter1, Filter2, Inbox, NearMe, RocketLaunchIcon, Title } from "@material-ui/icons";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
function LoginLeftInfoDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [modalStyle] = React.useState(getModalStyle);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.setOpenLoginInfoPopup(false)
        // setOpen(false);
    };

    return (

        <Modal
            open={props.open}
            // onClose={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper className={classes.loginInfoPopup} style={modalStyle}>
                <Grid container
                    direction="column"
                    // justifyContent="center"
                    // alignItems="center"
                    >
                         <Typography variant="caption" className="fw-bold"gutterBottom>Unlock Your Start-Up Success with the i3D Protocol!​</Typography>
                    <ol className="ps-3" className={classes.infoPoints}>
                        <li className={classes.infoLoginPoint}>Entrepreneurship is an exhilarating adventure, filled with innovation, risks, and limitless potential. But in this dynamic landscape, how can you navigate the path to success?</li>

                    </ol>
                    <Typography variant="caption" className="fw-bold" gutterBottom>Enter the i3D Protocol – Your Blueprint for Start-Up Success!​</Typography>
                    <ol className="ps-3 mb-2" className={classes.infoPoints}>
                        <li className={classes.infoLoginPoint}>"Fail to Plan, Plan to Fail." You recognize the value of taking calculated risks and embracing experimentation, but you also understand the importance of a well-defined plan.</li>
                        <li className={classes.infoLoginPoint}>The i3D Protocol offers you the balance. It's not just about planning; it's about empowering you to adapt, learn, and pivot when necessary. We understand that every aspect of your start-up journey is interconnected, and we're here to guide you through the web of entrepreneurship with agility and confidence.​</li>

                    </ol> 
                    <Typography variant="caption" className="fw-bold" gutterBottom>Why Choose the i3D Protocol?​​</Typography>
                    <ol className="ps-3 mb-2" className={classes.infoPoints}>
                        <li className={classes.infoLoginPoint}>Structured Data Input-Output: We guide you through the creation of your Pitch to Investors and present it to them in a simple and clear format for fast analysis.</li>
                        <li className={classes.infoLoginPoint}>AI-Powered Insights: Our protocol utilizes Artificial Intelligence to provide you with data-driven insights into key success and failure factors for start-ups.​​</li>
                        <li className={classes.infoLoginPoint}>Crowd-Sourced Wisdom: We harness the collective intelligence of a crowd to anonymously analyze sentiment and trends, ensuring well-rounded guidance.​​</li>
                        <li className={classes.infoLoginPoint}>Extensive Research: Our insights are the product of in-depth research into both successful and failed companies, offering you a comprehensive roadmap for building resilient and scalable start-ups.​​​</li>
                        <li className={classes.infoLoginPoint}>Rigorous Validation: To validate our findings, we've subjected the i3D Protocol to a stringent validation process, including cross-referencing against powerful AI models.​</li>

                    </ol> 
                    <Typography variant="caption" className="fw-bold" gutterBottom>The Future of Entrepreneurship Awaits!</Typography>
                    <ol className="ps-3 mb-2" className={classes.infoPoints}>
                        <li className={classes.infoLoginPoint}>Artificial Intelligence and Crowd Intelligence is reshaping the entrepreneurial landscape. This technology isn't just about natural language understanding; it's a game-changer for industries like customer service, language translation, and creative content generation.​</li>
                        <li className={classes.infoLoginPoint}>By harnessing the power of Artificial Intelligence and Crowd Intelligence, you gain a competitive edge. The large i3D Network is on the lookout with the vision of identifying promising start-ups destined for success, whether you're a founder on the rise or an investor seeking the next big opportunity.​​</li>

                    </ol> 
                    <div className="justify-content-end d-flex">
                    <CloseIcon onClick={handleClose} />
                    </div>
                </Grid>
            </Paper>
        </Modal>
    )
}

export default LoginLeftInfoDialog;

import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    // width: "100%",
    marginTop: theme.spacing(4),
    width: "70vw !important",
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
  spacingTop: {
    marginTop: theme.spacing(2),
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  weightCard:{
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    width:"100%"
  },
  // weightCards:{
  //   display: "flex",
  //   justifyContent: "flex-end",
  //   marginTop: "1rem",
  //   width:"46%"
  // },
  uploadDoc:{
    width: "100%",
    textAlign: "left",
    display: "inline-block",
  },
  sectionFile:{
    display: "flex", alignItems: "center", minWidth: "270px" ,justifyContent: "flex-end",
    marginRight: "1rem",
  },
  cardContainer: {
    height: "auto",
    margin: "16px",
    position: "relative",
    boxShadow: "0px 0px 8px 1px #ccc",
    textAlign: "center",
    borderRadius: "8px",
    padding: "2rem",
    // paddingLeft: "0px",
    paddingTop: "1rem",
    backgroundColor: theme.palette.background.default,
  },
  singleFile:{
    display: "flex",
    alignItems: "center",
    //    justifyContent: "space-between",
    // marginTop: "1rem",
    // width:"100%"
  },
  weightText: {
    display: "flex",
    marginBottom: 16,
    // alignItems: "end",
    flexDirection: "column",
  
  },

  leftAlign:{
    textAlign: "left"
  },
  rightAlign:{
    textAlign: "right",
  },
  rightAutoAlign:{
    textAlign: "right",
    margin: "auto"
  },
  baseLine:{
    alignItems: "baseline"
  },
  info:{
    color:'#137DFB !important',
  }
 
}));

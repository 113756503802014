import React from "react";

import { Typography } from "../../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Select, Input, AutoComplete } from "formik-antd";
import { Field } from 'formik';
import FadeIn from "react-fade-in";
import { sectors, stages } from "../data";
import { ImageUploadField } from "../Fields/ImageUpload";
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import PlatformInformation from "./PlatformInformation";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
const { Option } = Select;

export default function () {
    const classes = useStyles();
    const formik = useFormikContext<FormInfo>();
    const [state, setState] = React.useState({
        Technical: true,
        i3DExpert: false,
        i3DRapid: false,
        All: false
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const { Technical, i3DExpert, i3DRapid, All } = state;
    const error = [Technical, i3DExpert, i3DRapid, All].filter((v) => v).length !== 2;
    return (
        <FadeIn>
            {/* _______________________COMPANY INFO_________________________ */}

            <div className={classes.companyInfoContainer}>
                <div className={classes.cardContainer}>
                    <div >
                        <div>
                            <div className={classes.inputFields} style={{ width: "100%", }}>
                                <Typography variant="h4" className={classes.sectionTitle}>
                                    Select Platform
                                </Typography>
                                <FormGroup className={classes.CompanyDetails} row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel
                                        value="Technical"
                                        control={<Checkbox color="primary" checked={Technical} onChange={handleChange} name="Technical" disabled />}
                                        label="Technical"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="i3D Expert"
                                        control={<Checkbox color="primary" checked={i3DExpert} onChange={handleChange} name="i3DExpert" />}
                                        label="i3D Expert"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="i3D Rapid"
                                        control={<Checkbox color="primary" checked={i3DRapid} onChange={handleChange} name="i3DRapid" />}
                                        label="i3D Rapid"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="All"
                                        control={<Checkbox color="primary" checked={All} onChange={handleChange} name="All" />}
                                        label="All"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </div>
                        </div>

                        <div className={classes.dropZone} >
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Add Logo
                            </Typography>
                            <ImageUploadField />

                        </div>


                        <div className={classes.inputFields} style={{ width: "100%", }}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Company Name
                            </Typography>
                            <AutoComplete
                                style={{ width: "100%", borderRadius: 10 }}
                                name="companyName"
                                placeholder="e.g. Invluencer Ltd."
                                className={classes.CompanyDetails}
                            />
                        </div>
                        <div>
                            {formik.errors.companyName && formik.touched.companyName && (
                                <Typography variant="caption" color="secondary">
                                    {formik.errors.companyName}
                                </Typography>
                            )}

                        </div>

                    </div>
                    <div>
                        <div className={classes.inputFields} style={{ width: "100%", }}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Company Description
                            </Typography>

                            <Field
                                className={classes.companyDescription + " " + classes.comapnyDes}
                                name="companyDescription"
                                style={{ width: "100%", borderRadius: 10 }}
                                placeholder="e.g. An awesome company that provides fintech solutions."
                                component="textarea">
                            </Field>

                        </div>
                        <div>
                            {formik.errors.companyDescription &&
                                formik.touched.companyDescription && (
                                    <Typography variant="caption" color="secondary">
                                        {formik.errors.companyDescription}
                                    </Typography>
                                )}
                        </div>
                        <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Company Stage
                            </Typography>
                            <Select
                                name="companyStage"
                                defaultValue="No stage selected"
                                style={{ width: "100%" }}
                                className={classes.CompanyDetails}
                            >
                                {stages.map((stage) => (
                                    <Option key={stage.value} value={stage.value} title={stage.label}>
                                        {stage.label}
                                    </Option>
                                ))}
                            </Select>

                        </div>
                        <div>
                            {formik.errors.companyStage && formik.touched.companyStage && (
                                <Typography variant="caption" color="secondary">
                                    {formik.errors.companyStage}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.inputFields} style={{ width: "100%", }}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Company Sector
                            </Typography>
                            <Select
                                showSearch
                                name="companySector"
                                placeholder="Select a sector"
                                optionFilterProp="children"
                                style={{ width: "100%", borderRadius: 10 }}
                                className={classes.CompanyDetails}
                                filterOption={(input, option) => {
                                    if (option)
                                        return (
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        );
                                    return true;
                                }}
                                onDropdownVisibleChange={(open) => {
                                    if (!open && document.activeElement instanceof HTMLElement)
                                        document.activeElement.blur();
                                }}
                            >
                                {sectors.map((sector) => (
                                    <Option value={sector.value} key={sector.value}>
                                        {sector.label}
                                    </Option>
                                ))}
                            </Select>

                        </div>
                        <div>
                            {formik.errors.companySector && formik.touched.companySector && (
                                <Typography variant="caption" color="secondary">
                                    {formik.errors.companySector}
                                </Typography>
                            )}
                        </div>
                    </div>
                    <div
                        style={{

                        }}
                    >
                        <div className={classes.inputFields} style={{ width: "100%", }}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Company Website
                            </Typography>
                            <AutoComplete
                                style={{ width: "100%", }}
                                name="companyWebsite"
                                placeholder="e.g. https://invluencer.com/"
                                className={classes.CompanyDetails}

                            />

                        </div>
                        <div>
                            {formik.errors.companyWebsite && formik.touched.companyWebsite && (
                                <Typography variant="caption" color="secondary">
                                    {formik.errors.companyWebsite}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.inputFields} style={{ width: "100%", }}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Deal Source
                            </Typography>
                            <Input
                                name="dealSource"
                                style={{ width: "100%", borderRadius: 10, }}
                                placeholder="e.g. https://invluencer.com/"
                                className={classes.companySourceEmail}
                            />

                        </div>
                        <div>
                            {formik.errors.dealSource && formik.touched.dealSource && (
                                <Typography variant="caption" color="secondary">
                                    {formik.errors.dealSource}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.inputFields} style={{ width: "100%", }}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Contact Email
                            </Typography>
                            <Input
                                name="contactEmail"
                                style={{ width: "100%", borderRadius: 10, }}
                                placeholder="e.g. contact email"
                                className={classes.companySourceEmail}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </FadeIn>
    );
}
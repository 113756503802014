import React from "react";
import { useLocation } from "react-router-dom";
import { Button,  Grid,  Typography } from "@material-ui/core";
import { ScoreForm } from "../../model/responses";
import { Field, useFormikContext } from "formik";

// styles
import useStyles from "./styles";
function ScoreThankyou(props) {
    const classes = useStyles();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const formik = useFormikContext<ScoreForm>();

    function buttons()
    {
        var data = formik.values;
        var submit =  true;
        props.questions.forEach((val)=>{
            if(!(val.id in data))
            {
                submit=false;
            }
        })
        if(submit)
        {
            return(
                <div className={classes.startButton}  >
                   <Button className={classes.buttonMargin} style={{color: "#fff", backgroundColor: "#a7a19e"}} onClick={() => props.setThankyou(false)}>go back</Button>
                   <Button style={{color: "#fff", backgroundColor: "#ed2128"}} onClick={() => formik.submitForm()}>Submit</Button>
               </div> 
           );

        }
        else
        {
            return(
                <>
                <div className={classes.startButton}>
                   <Button className={classes.buttonMargin} style={{color: "#fff", backgroundColor: "#a7a19e"}} onClick={() => props.setThankyou(false)}>go back</Button>
               </div> 
               <div style={{textAlign:"center"}}>
               <Typography color="error">make sure you answered every question</Typography>
               </div>
               </>
           );
        }
        
    }
    return (
        <Grid item xs={12} style={{padding:"2rem"}}>
            <Typography className={classes.comment}>
                Do you have a general Comment, Insight or Advice to give?
            </Typography>
            <Field
                className={classes.commentBoxExpert}
                component="textarea"
                name="generalComment"
                placeholder= "general comment..."
                style={{ width: "65%", borderRadius: 10 }}
            />
            <Typography className={classes.welcomPara} style={{marginTop: "5rem",}}>
                {/* Thank you for your work!  */}
                Are you ready to submit?
            </Typography>
            <Typography className={classes.welcomPara} style={{marginTop: "0rem", width: "100%"}}>
                Once you submit you cannot go Back
            </Typography>

            {buttons()}
            {/* <div className={classes.startButton}  >
                <Button className={classes.buttonMargin} style={{color: "#fff", backgroundColor: "#a7a19e"}} onClick={() => props.setThankyou(false)}>go back</Button>
                <Button style={{color: "#fff", backgroundColor: "#ed2128"}} onClick={() => formik.submitForm()}>Submit</Button>
            </div> */}

        </Grid>
    );
}

export default ScoreThankyou;

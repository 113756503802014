import React from "react";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Close as CloseIcon, Info } from "@material-ui/icons";
// import { Typography } from "@material-ui/core";
// import { Button } from "antd";
import { Select, Input, AutoComplete } from "formik-antd";
import { useFormikContext } from "formik";
import { AddTeams } from "../../model";
import { Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Modal, Paper, Tooltip } from "@material-ui/core";
import { time, Experience, education, Roles, age } from "../../pages/create/components/data";
// import RocketLaunchIcon from "mater"
import useStyles from "./style";
import TEditor from "../../pages/create/components/Problem/TEditor";
import { Editor } from "@tinymce/tinymce-react";
import { CallMade, Drafts, Filter1, Filter2, Inbox, NearMe, RocketLaunchIcon, Title } from "@material-ui/icons";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
function FutureProofingStartupsDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [modalStyle] = React.useState(getModalStyle);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.setOpenFutureProofingPopup(false)
        // setOpen(false);
    };

    return (

        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper className={classes.futureproof} style={modalStyle}>
                {/* <h1>Hello</h1> */}
                 <Grid container
                    direction="column"
                    style={{marginTop:'20px'}}
                    >
                   <Typography className={classes.fontSizeTypo} gutterBottom>The i3D Protocol was conceived and developed to address the problems Founders and Investors experience in the poor data environment that exists in the riskiest end of investing – very Early Stage or Alpha start-up investing. ​</Typography>
                   <Typography className={classes.fontSizeTypo} gutterBottom>The development premise of the Protocol is to use a combination of AI and algorithms backed by anonymous Crowd Sourced sentiment analysis to create an ecosystem using Intelligent Distributed Due Diligence – i3D. This ecosystem relies on well researched factors that affect start-up success or failure and seeks to score and rank potential start-ups against these factors.</Typography>
                   <Typography className={classes.fontSizeTypo} gutterBottom>  The factors and content generated by the i3D Protocol are the result of thousands of hours of deep level research into company failures and success. </Typography>
                   <Typography className={classes.fontSizeTypo} gutterBottom> Future-Proofing Start-ups: The Entrepreneur's and Investor's Building Blocks for Resilient and Scalable Start-ups is our attempt to reflect this research and the requirements Founders and Investors should look for when developing a start-up into a future success.</Typography>
                   <Typography className={classes.fontSizeTypo} gutterBottom>The methodology used to create this work included a validation of the deep level research conducted by the i3D Protocol against the AI power of ChatGPT by OpenAI. Every factor we had identified was questioned in ChatGPT with the responses recorded. Follow up ‘requests’ were made to elaborate or generate new information. Besides Development edits and Proofreading, every response was checked by a human to confirm the validity of it.</Typography>

                   <div className="justify-content-end d-flex">
                    <CloseIcon onClick={handleClose} />
                    </div>
                </Grid>
            </Paper>
        </Modal>
    )
}

export default FutureProofingStartupsDialog;

import React from "react";
import { Box, Grid, Paper, Tooltip } from "@material-ui/core";
import { Typography } from "../../../components/Wrappers/Wrappers";
import useStyles from "../styles";
import { withStyles } from "@material-ui/styles";

export default function DescriptionCard(props) {
    const classes = useStyles();

    // console.debug(project.focusedReport)
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    return (

        <Grid item xs={6} className={classes.parentGrid}>
            <Box>
            {props.tooltip ?
                <Typography variant="h4" noWrap className={classes.sectionTitle}>
                    {props.label}
                </Typography> :
                <Typography variant="h4" noWrap className={classes.sectionTitle}>
                    {props.label}
                </Typography>
            }
            <Paper elevation={2} variant="outlined" className={classes.card}>
                <span dangerouslySetInnerHTML={{
                    __html: props.description.replace(/<br\s*\/?>/gi, ' ').replace(/&(nbsp|amp|quot|lt|gt);/g, " ")
                }}>
                </span>
            </Paper>
            </Box>
        </Grid>
    );
}

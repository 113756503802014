import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { Project } from "../../model/project";
import { IResponse } from "../../model";
import {
  Button
} from "@material-ui/core";
import useStyles from "./styles";
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import {
  HIGH_SCORE_THRESHOLD,
  RED_FLAG_THRESHOLD,
} from "../../utils/constants";
import {storage} from '../../repositories/firebase';
import {
  getSectionDescription, getSectionLabel,overallScore
} from "../../pages/create/components/data";
import pdfHeader from './pdfheader.png'
import { ProjectRepo } from "../../repositories/project";
import { RootState } from "../../reducers";
import { ProjectDispatch, Report } from "../../model";
import { useSelector, useDispatch } from "react-redux";
import GroupIcon from '@material-ui/icons/Group';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import * as typefaceMontserrat from "https://cdn.skypack.dev/typeface-montserrat@1.1.13";
enum FilteringType {
  none = "none",
  redFlagsOnly = "redFlagsOnly",
  highScores = "highScores",
  general = "general",
}

interface Props {
  project: Project;
  data: Array<Record<string, any>>;
  rank: number;
  responses: IResponse[];
}


function createData(
  name: string,
  page: any
) {
  return { name, page };
}
function createScoreData(
  head: string,
  subHead: string,

) {
  return { head, subHead };
}

function createExpertScoreData(
  list: any,

) {
  return { list };
}
function createGeneralScoreData(
  list: any,

) {
  return { list };
}

function createExpertIconsData(
  Icons: any,

) {
  return { Icons };
}
function createExpertIdeaScoreData(
  list: any,

) {
  return { list };
}

function createGeneralIdeaScoreData(
  list: any,

) {
  return { list };
}
// _____________________ competetion list function ________________________
function createGeneralCompetetionScoreData(
  list: any,

) {
  return { list };
}

function createExpertCompetetionScoreData(
  list: any,

) {
  return { list };
}

var glanceIcon = [
  createExpertIconsData(`${<GroupIcon fontSize="large" style={{ backgroundColor: "#FFEADA", color: "#FCAE73" }} />}`)
]

const rows = [
  // createData("Introduction & Background",	2),
  createData("At a Glance", "3"),
  createData("Technical Scoring Results BETA", "4"),
  createData("Expert Scoring Results", "5"),
  createData("Idea and Product", "7"),
  createData("Market and Customer", "7"),
  createData("Competition", "7"),
  createData("Business Model and Financials", "7"),
  createData("ESG", "7"),
  createData("Capital at Risk and Disclaimer", "8"),
  createData("Annexures", "9"),
  createData("Financial Breakdown	", "9"),
  createData("Pitch Deck and Supplemental Files", "9"),

];

const scroingBeta = [
  createScoreData("Technical Scoring Results *BETA*", "Technical Score:")
]



interface State {
  loading: boolean;
  error?: Error | string;
  report?: Report;
  pageSlug: string;
}
function useLocalState(): State {
  const {
    fetching,
    fetchError,
    focusedReport: report,
    allProjects,
  } = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch<ProjectDispatch>();
  const { id } = useParams<{ id: string }>();

  

  return {
    loading: fetching,
    error: fetchError,
    report,
    pageSlug: id,
  };
}
function DownloadableReport(props: Props) {
  const state = useLocalState();
  const { id } = useParams<{ id: string }>();
  const projects = props.data;
  const project = props.project;
  const rank = props.rank;
  const responses = props.responses;
  const data = sectionData();
  const [technicalResponses, setTechnicalResponses] = React.useState([]);
  
  useEffect(() => {
    // console.debug(responses);
    const automatedResponses = responses.filter(
      (c) =>
        c.type === "Automated"
    );
    // console.debug(automatedResponses);
    ProjectRepo.getComments(automatedResponses).then((response) => {
      // console.debug(response);
      setTechnicalResponses(response);
    });
  }, []);
  function sectionData() {
    var data = {};
    state.report?.project.sections.forEach((sectionName) => {
      data[sectionName] = projects.map((element) => {
        if (sectionName == "team") {
          return ({
            name: element.company ? element.company.name : "",
            rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.team ? element.sectionStats.team.avgRating : 0) : 0, element.id, "team", projects, element.startDate),
            id: element.id,
            logo: element.company ? element.company.logo : "",
          });
        }
        if (sectionName == "idea") {
          return ({
            name: element.company ? element.company.name : "",
            rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.idea ? element.sectionStats.idea.avgRating : 0) : 0, element.id, "idea", projects, element.startDate),
            id: element.id,
            logo: element.company ? element.company.logo : "",
          });
        }
        if (sectionName == "market") {
          return ({
            name: element.company ? element.company.name : "",
            rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.market ? element.sectionStats.market.avgRating : 0) : 0, element.id, "market", projects, element.startDate),
            id: element.id,
            logo: element.company ? element.company.logo : "",
          });
        }
        if (sectionName == "finance") {
          return ({
            name: element.company ? element.company.name : "",
            rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.finance ? element.sectionStats.finance.avgRating : 0) : 0, element.id, "finance", projects, element.startDate),
            id: element.id,
            logo: element.company ? element.company.logo : "",
          });
        }
        if (sectionName == "competition") {
          return ({
            name: element.company ? element.company.name : "",
            rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.competition ? element.sectionStats.competition.avgRating : 0) : 0, element.id, "competition", projects, element.startDate),
            id: element.id,
            logo: element.company ? element.company.logo : "",
          });
        }
        if (sectionName == "esg") {
          return ({
            name: element.company ? element.company.name : "",
            rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.esg ? element.sectionStats.esg.avgRating : 0) : 0, element.id, "esg", projects, element.startDate),
            id: element.id,
            logo: element.company ? element.company.logo : "",
          });
        }

      });
    });
    return data;

  }

  function _checkFiltering(
    response: IResponse,
    filtering: FilteringType
  ): boolean {
    const score = response.score / 9;

    switch (filtering) {
      case FilteringType.redFlagsOnly:
        return score < RED_FLAG_THRESHOLD;
      case FilteringType.highScores:
        return score > HIGH_SCORE_THRESHOLD;
      case FilteringType.general:
        let bool = score > RED_FLAG_THRESHOLD && score < HIGH_SCORE_THRESHOLD;
        return bool;
      default:
        return true;
    }
  }

  function percentile(rank)
  {
    return (((props.data.length-rank)/props.data.length)*100).toFixed(0);
  }
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const sectionResponses = (sectionName) => {
    var sectionRep = responses.filter(
      (c) =>

        c.section === sectionName
        
    )
    return sectionRep;
  };

  const expertScroing = [
    createExpertScoreData(`${sectionResponses("team").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))}`),
    // createExpertScoreData(`${sectionResponses("team").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[4]}`),
  ]
  const expertGeneralScroing = [
    createGeneralScoreData(`${sectionResponses("team").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))}`)
  ]

  const expertIdeaScroing = [
    createExpertIdeaScoreData(`${sectionResponses("idea").map((v) => (_checkFiltering(v, FilteringType.highScores) && "-" + v.comment))}`),
  ]
  const expertGeneralIdeaScroing = [
    createGeneralIdeaScoreData(`${sectionResponses("idea").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))}`)
  ]

  const expertEsgScroing = [
    createExpertIdeaScoreData(`${sectionResponses("esg").map((v) => (_checkFiltering(v, FilteringType.highScores) && "-" + v.comment))}`),
  ]
  const expertGeneralEsgScroing = [
    createGeneralIdeaScoreData(`${sectionResponses("esg").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))}`)
  ]

  // __________________________COMPETETION SCORING _____________________________

  const expertCompetetionScroing = [
    // createExpertCompetetionScoreData(`${sectionResponses("competition").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[2]}`),
    // createExpertCompetetionScoreData(`${sectionResponses("competition").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[3]}`),
    createExpertCompetetionScoreData(`${sectionResponses("competition").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))}`),

  ]
  const expertGeneralCompetetionScroing = [
    // createGeneralCompetetionScoreData(`${sectionResponses("competition").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))[0]}`),
    // createGeneralCompetetionScoreData(`${sectionResponses("competition").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))[1]}`),
    createGeneralCompetetionScoreData(`${sectionResponses("competition").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))}`),


  ]

  // __________________________ FINANCE SCORING _____________________________

  const expertFinanceScroing = [
    // createExpertCompetetionScoreData(`${sectionResponses("finance").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[3]}`),
    // createExpertCompetetionScoreData(`${sectionResponses("finance").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[4]}`),
    createExpertCompetetionScoreData(`${sectionResponses("finance").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))}`),

  ]
  const expertGeneralFinanceScroing = [
    // createGeneralCompetetionScoreData(`${sectionResponses("finance").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))[1]}`),
    // createGeneralCompetetionScoreData(`${sectionResponses("finance").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))[2]}`),
    createGeneralCompetetionScoreData(`${sectionResponses("finance").map((v) => (_checkFiltering(v, FilteringType.general) && "-" + v.comment))}`),


  ]

  const expertMarketScroing = [
    // createExpertCompetetionScoreData(`${sectionResponses("market").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[0]}`),
    // createExpertCompetetionScoreData(`${sectionResponses("market").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[1]}`),
    createExpertCompetetionScoreData(`${sectionResponses("market").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))}`),

  ]
  const expertGeneralMarketScroing = [
    // createExpertCompetetionScoreData(`${sectionResponses("market").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[0]}`),
    // createExpertCompetetionScoreData(`${sectionResponses("market").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))[1]}`),
    createExpertCompetetionScoreData(`${sectionResponses("market").map((v) => (_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))}`),

  ]

  if (data["team"]) {
    var projectRankTeams =
      data["team"].find(obj => {
        return obj.id === project.id;
      })
    var similarRankTeams =
      data["team"].find(obj => {
        if (projectRankTeams.rank == 1) {
          return obj.rank === projectRankTeams.rank + 1;
        }
        return obj.rank === projectRankTeams.rank - 1;
      })
    // console.debug(similarRankTeams, "projectRankTeams")

    var similarRankTeams2 =
      data["team"].find(obj => {
        if (projectRankTeams.rank == 1) {
          return obj.rank === projectRankTeams.rank + 2;
        }
        if (projectRankTeams.rank == data["team"].length) {
          return obj.rank === projectRankTeams.rank - 2;
        }
        return obj.rank === projectRankTeams.rank + 1;
      });
  }


  if (data["idea"]) {
    var projectRankIdea =
      data["idea"].find(obj => {
        return obj.id === project.id;
      })
    // console.debug(projectRankIdea, "projectRankIdea")
    var similarRankIdea =
      data["idea"].find(obj => {
        if (projectRankIdea.rank == 1) {
          return obj.rank === projectRankIdea.rank + 1;
        }
        return obj.rank === projectRankIdea.rank - 1;
      })


    var similarRankIdea2 =
      data["idea"].find(obj => {
        if (projectRankIdea.rank == 1) {
          return obj.rank === projectRankIdea.rank + 2;
        }
        if (projectRankIdea.rank == data["idea"].length) {
          return obj.rank === projectRankIdea.rank - 2;
        }
        return obj.rank === projectRankIdea.rank + 1;
      });
  }

  if (data["competition"]) {
    var projectRankCompetition =
      data["competition"].find(obj => {
        return obj.id === project.id;
      })
    var similarRankComp =
      data["competition"].find(obj => {
        if (projectRankCompetition.rank == 1) {
          return obj.rank === projectRankCompetition.rank + 1;
        }
        return obj.rank === projectRankCompetition.rank - 1;
      })

    var similarRankComp2 =
      data["competition"].find(obj => {
        if (projectRankCompetition.rank == 1) {
          return obj.rank === projectRankCompetition.rank + 2;
        }
        if (projectRankCompetition.rank == data["competition"].length) {
          return obj.rank === projectRankCompetition.rank - 2;
        }
        return obj.rank === projectRankCompetition.rank + 1;
      });
  }


  if (data["finance"]) {
    var projectRankFinance =
      data["finance"].find(obj => {
        return obj.id === project.id;
      })
    var similarRankFinance =
      data["finance"].find(obj => {
        if (projectRankFinance.rank == 1) {
          return obj.rank === projectRankFinance.rank + 1;
        }
        return obj.rank === projectRankFinance.rank - 1;
      })


    var similarRankFinance2 =
      data["finance"].find(obj => {
        if (projectRankFinance.rank == 1) {
          return obj.rank === projectRankFinance.rank + 2;
        }
        if (projectRankFinance.rank == data["finance"].length) {
          return obj.rank === projectRankFinance.rank - 2;
        }
        return obj.rank === projectRankFinance.rank + 1;
      });
  }

  if (data["market"]) {
    var projectRankMarket =
      data["market"].find(obj => {
        return obj.id === project.id;
      })
    var similarRankMarket =
      data["market"].find(obj => {
        if (projectRankMarket.rank == 1) {
          return obj.rank === projectRankMarket.rank + 1;
        }
        return obj.rank === projectRankMarket.rank - 1;
      })


    var similarRankMarket2 =
      data["market"].find(obj => {
        if (projectRankMarket.rank == 1) {
          return obj.rank === projectRankMarket.rank + 2;
        }
        if (projectRankMarket.rank == data["market"].length) {
          return obj.rank === projectRankMarket.rank - 2;
        }
        return obj.rank === projectRankMarket.rank + 1;
      });
  }

  if (data["esg"]) {
    var projectRankEsg =
      data["esg"].find(obj => {
        return obj.id === project.id;
      })
    var similarRankEsg =
      data["esg"].find(obj => {
        if (projectRankEsg.rank == 1) {
          return obj.rank === projectRankEsg.rank + 1;
        }
        return obj.rank === projectRankEsg.rank - 1;
      })


    var similarRankEsg2 =
      data["esg"].find(obj => {
        if (projectRankEsg.rank == 1) {
          return obj.rank === projectRankEsg.rank + 2;
        }
        if (projectRankEsg.rank == data["market"].length) {
          return obj.rank === projectRankEsg.rank - 2;
        }
        return obj.rank === projectRankEsg.rank + 1;
      });
  }

  var pdfs = new jsPDF("portrait", "mm", "a4");

  function downloadDocument() {
    // var fontref = fontref
    setOpen(true);
    var img = new Image();
    img.src = pdfHeader;

    if (data["team"]) {
      var imgTeam = new Image();
      imgTeam.src = similarRankTeams?.logo
      var imgTeam2 = new Image();
      imgTeam2.src = similarRankTeams2?.logo
    }

    if (data["esg"]) {
      var imgEsg = new Image();
      imgEsg.src = similarRankEsg?.logo
      var imgEsg2 = new Image();
      imgEsg2.src = similarRankEsg2?.logo
    }

    if (data["idea"]) {
      var imgIdea = new Image();
      imgIdea.src = similarRankIdea?.logo
      var imgIdea2 = new Image();
      imgIdea2.src = similarRankIdea2?.logo
    }

    if (data["competition"]) {
      var imgCompetition = new Image();
      imgCompetition.src = similarRankComp?.logo
      var imgCompetition2 = new Image();
      imgCompetition2.src = similarRankComp2?.logo
    }

    if (data["finance"]) {
      var imgfinance = new Image();
      imgfinance.src = similarRankFinance?.logo
      var imgfinance2 = new Image();
      imgfinance2.src = similarRankFinance2?.logo
    }
    if (data["market"]) {
      var imgmarket = new Image();
      imgmarket.src = similarRankMarket?.logo
      var imgmarket2 = new Image();
      imgmarket2.src = similarRankMarket2?.logo
    }
    var width = 65;
    var height = 40;
    var footer1 = "3 © Invluencer Ltd 2021 Registered in England & Wales Company Reg No. 12299986";
    var footer2 = "Registered Office: Office 4, 219 Kensington High Street, London W8 6BD, UK All Rights Reserved";
    const input4 = document.getElementById('page4');

    const pdf = new jsPDF("portrait", "mm", "a4");

    const pageHeight= pdf.internal.pageSize.height;
    const imageProps = (dataurl) => {

      var imgProps = pdf.getImageProperties(dataurl);
      var pdfWidth = pdf.internal.pageSize.getWidth();
      var pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      return {
        "pdfWidth": pdfWidth, "pdfHeight": pdfHeight
      }

    }

    var splitDescriptionText = pdf.splitTextToSize(project.company.description, 90)
    pdf.addImage(img, 'JPEG', 35, -10, 160, 90);
    pdf.setFontSize(14, "bold");
    pdf.text("COMPANY INFORMATION", 20, 130)
    pdf.setFontSize(11, "normal");
    pdf.text("Company Name:", 20, 140);
    pdf.text(project.company.name, 55, 140);
    pdf.line(20, 143, 100, 143); // horizontal line  
    pdf.text("Stage:", 20, 148);
    pdf.text(project.company.stage, 55, 148);
    pdf.line(20, 151, 100, 151); // horizontal line  
    pdf.text("Sector", 20, 156);
    if(project.company.sector.length>24)
    {
      pdf.setFontSize(10, "normal");
      pdf.text(pdf.splitTextToSize(project.company.sector, 40), 55, 154);
      pdf.setFontSize(11, "normal");
    }
    else
    {
      pdf.text(project.company.sector, 55, 156);
    }
    pdf.line(20, 159, 100, 159); // horizontal line  
    pdf.text("Website:", 20, 165);
    pdf.text(project.company.website?(project.company.website).substring(0,100):"", 55, 165);
    pdf.line(20, 168, 100, 168); // horizontal line  
    pdf.text("Deal Originator:", 20, 174);
    pdf.text(project.company.investmentLink?(project.company.investmentLink).substring(0,100):"", 55, 174);
    pdf.line(20, 177, 100, 177); // horizontal line  
    pdf.setLineWidth(300);
    pdf.setFontSize(11, "normal");
    pdf.text("DESCRIPTION:", 110, 140);
    pdf.setFontSize(11, "normal");
    pdf.text(splitDescriptionText, 110, 149, { lineHeightFactor: 1 });
    pdf.setFillColor(29, 27, 27);
    pdf.roundedRect(30, 190, 160, 10, 5, 5, 'F');
    pdf.setTextColor("#fff")
    pdf.text("Warning - Please read Capital at Risk disclaimer below", 60, 196);
    pdf.setFontSize(9);
    pdf.setTextColor("#000")
    pdf.text(footer1, 20, 279);
    pdf.text(footer2, 20, 283);
    pdf.addPage();
    pdf.addImage(img, 'JPEG', 145, 5, width, height);
    pdf.setFontSize(14);
    pdf.text("CONTENTS", 20, 20)
    pdf.setFontSize(11);
    pdf.text("At a Glance", 20, 50)
    pdf.text("3", 175, 50)
    pdf.text("Technical Scoring Results BETA", 20, 65)
    pdf.text("4", 175, 65)
    pdf.text("Expert Scoring Results", 20, 80)
    pdf.text("5", 175, 80)
    pdf.text("Idea and Product", 20, 95)
    pdf.text("7", 175, 95)
    pdf.text("Market and Customer", 20, 110)
    pdf.text("7", 175, 110)
    pdf.text("Competition", 20, 125)
    pdf.text("7", 175, 125)
    pdf.text("Business Model and Financials", 20, 140)
    pdf.text("7", 175, 140)
    pdf.text("ESG", 20, 155)
    pdf.text("8", 175, 155)
    pdf.text("Capital at Risk and Disclaimer", 20, 170)
    pdf.text("8", 175, 170)
    pdf.text("Annexures", 20, 185)
    pdf.text("9", 175, 185)
    pdf.text("Financial Breakdown", 20, 200)
    pdf.text("9", 175, 200)
    pdf.text("Pitch Deck and Supplemental Files", 20, 215)
    pdf.text("9", 175, 215)
    pdf.setFontSize(9);
    pdf.text(footer1, 20, 279);
    pdf.text(footer2, 20, 283);
    pdf.addPage();
    htmlToImage.toPng(input4, { quality: 0.95 })
      .then(function (dataUrl) {
        const pdfWidth = imageProps(dataUrl).pdfWidth / 1.3;
        const pdfHeight = imageProps(dataUrl).pdfHeight / 2;
        var splitGlanceText = pdf.splitTextToSize("The report analyses the strengths and weaknesses of the company in 6 different categories where and when requested and the relelvant information has been supplied: Team, Idea and Product, Business Model and Financials, Market and Customer, Competition, and Environmental, Social, and Governance. Each section has been analyzed both by an expert analyst and an algorithm in order to provide robust qualititative and quantitative data. ", 140)
        var splitGlanceText2 = pdf.splitTextToSize("The company’s relative position compared to other i3D vetted companies was also examined, in order to provide benchmark and a holisitic view of the company.", 140)

        pdf.addImage(dataUrl, 'PNG', 27, 105, pdfWidth, pdfHeight);
        pdf.addImage(img, 'JPEG', 145, 5, width, height);
        pdf.setFontSize(14);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.text("AT A GLANCE", 20, 20)
        pdf.setFontSize(11);
        // pdf.setFont('Montserrat, ', "normal");
        pdf.text(splitGlanceText, 20, 45);
        pdf.text(splitGlanceText2, 20, 75);
        pdf.setFontSize(14);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.text(`Overall Score: ${project.company.name} - ${overallScore(project).toFixed(0)}%`, 20, 90)
        pdf.setFontSize(11);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.setFillColor(29, 27, 27);
        // pdf.rect(25, 90, 160, 15, 'F');
        pdf.roundedRect(30, 100, 155, 13, 4, 4, 'F');
        pdf.setTextColor("#fff")
        pdf.text(`${(project.sectionStats.overall.avgRating * 100).toFixed(0)}%`, 40, 105);
        pdf.text("Expert Score ", 35, 110);
        pdf.text(`${((project.sectionStats.i3DRapid?project.sectionStats.i3DRapid.avgRating:0) * 100).toFixed(0)}%`, 80, 105);
        pdf.text("Rapid Score ", 73, 110);
        pdf.text(`${((project.technical?project.technical.overall:0) * 100).toFixed(0)}%`, 120, 105);
        pdf.text("Technical Score ", 110, 110);
        pdf.text(`${project.likesCount}`, 160, 105);
        pdf.text("Likes ", 155, 110);
        // pdf.setFont('Montserrat, ', "normal");
        pdf.setTextColor("#000")
        pdf.setFontSize(10, "normal");
        pdf.setTextColor("#000")
        pdf.text(footer1, 20, 279);
        pdf.text(footer2, 20, 283);
        pdf.addPage();
        var splitText = pdf.splitTextToSize("The Technical Score is based on a series of factors determined from a regression analysis that examined thousands of companies to determine what  contributed to their successes and failures. These factors were then examined  against quantitative data supplied by the company analysed, and using a series   of algorithms, were assigned a technical score. Insights provided are an automated   response that can be assigned due to the matching of a subfactor score to a predefined success metric. These insights should be read together with those provided by the network.", 150, { align: "justify", });
        var splitTextBeta2 = pdf.splitTextToSize("These factors are reflected in the following broad areas of scope: ", 150, { align: "justify" })
        // pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.addImage(img, 'JPEG', 145, 5, width, height);
        pdf.setFontSize(14);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.text(scroingBeta.map(headData => headData.head), 20, 20)
        pdf.setFontSize(13);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.text(scroingBeta.map(subheadData => subheadData.subHead), 20, 45)
        pdf.setFontSize(11);
        // pdf.setFont('Montserrat, ', "normal");
        pdf.text(splitText, 20, 55)
        pdf.text(splitTextBeta2, 20, 90)
        pdf.text("-  Strength of the Entrepreneur and Management Team", 20, 95)
        pdf.text("-  Size and competitiveness of the market sector", 20, 100)
        pdf.text("-  Company’s go to market strategies", 20, 105)
        pdf.text("-  Funding and growth stage of the company", 20, 110)
          pdf.setFontSize(13);
          pdf.setTextColor("#50a14c")
          pdf.text("Automated:", 20, 120)
          pdf.setFontSize(11);
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments that are automatically generated)", 45, 120)
          var y = 125
          technicalResponses.forEach((val)=>{
            if (val.text)
            {
              var technicalComment =  pdf.splitTextToSize("- "+val.text, 150, { align: "justify", }) 
              // pdf.text(val.text.substring(0, 125)+"...",20,y);
              // y=y+6;
              if(val.text.length<83)
              {
                 pdf.text(technicalComment,20,y);
                 y=y+6;
              }
              if(val.text.length>82 && val.text.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(technicalComment,20,y);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.text.length>168 && val.text.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(technicalComment,20,y);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.text.length>251 && val.text.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(technicalComment,20,y);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            } 
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
        pdf.setFontSize(10);
        pdf.text(footer1, 20, 279);
        pdf.text(footer2, 20, 283);
        pdf.addPage();
        if (data["team"]) {
          var splitExpertText = pdf.splitTextToSize(`${getSectionDescription("team")}`, 155, { align: "justify" })
          var teamPos = sectionResponses("team").map((v)=>(_checkFiltering(v, FilteringType.highScores) && "-" + v.comment))
          var teamNeg = sectionResponses("team").map((v)=>(_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))
          var teamGen = sectionResponses("team").map((v)=>(_checkFiltering(v, FilteringType.general) && "-" + v.comment))
          const splitExpert = pdf.splitTextToSize(`${expertScroing.map(data => data.list)}`, 150)
          const splitGeneral = pdf.splitTextToSize(`${expertGeneralScroing.map(data => data.list)}`, 150)
          pdf.addImage(img, 'JPEG', 145, 5, width, height);
          pdf.setFontSize(14);
          pdf.text("EXPERT SCORING RESULTS", 20, 20)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text(`${getSectionLabel("team") + " Score"}`, 20, 45)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(splitExpertText, 20, 55)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Insights:", 20, 100)
          pdf.setTextColor("#50a14c")
          pdf.text("Positives:", 20, 110)
          pdf.setFontSize(11);
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are over 70%)", 40, 110)
          var y = 115
          teamPos.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
              // pdf.text(val.substring(0, 150)+"...",25,y);
              // y=y+5;
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          pdf.setFontSize(13);
          pdf.setTextColor("#ed2128")
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Negatives:", 20, y=y+10)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are <50%)", 42, y)
          // pdf.text(splitExpert2, 20, 130)
          teamNeg.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitExpert, 20, 130)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setTextColor("#63c8d6")
          pdf.text("General:", 20, y=y+10)
          pdf.setFontSize(11);
          pdf.setTextColor("#0e1111")
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text("(These are the comments where the scores are between 51% and 69%)", 40, y)
          // pdf.text(splitGeneral, 20, 160)
          teamGen.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Similar Companies:", 20, y=y+10)
          pdf.setFontSize(11);

          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "normal");
          pdf.splitTextToSize(`${"Base on the score " + `${project.company.name}` + " received for this section, companies " + `${similarRankTeams.name}` + " & " + `${similarRankTeams2.name}` + " are the most similar."}`, 20, y+10)
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
            if(imgTeam)
            pdf.addImage(imgTeam, 'JPEG', 30,  y=y+15, 30, 20)
            if(imgTeam2)
            pdf.addImage(imgTeam2, 'JPEG', 100,  y, 30, 20)
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setFontSize(13);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Rankings", 20, y=y+30)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Overall, " + `${project.company.name}` + " ranks "+ `${projectRankTeams.rank}`+" out of "+ `${data["team"].length}`+". This places it in the"+(percentile(projectRankTeams.rank)) +"(percentile) of teams.", 20, y=y+10)
          pdf.setFontSize(10);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(footer1, 20, 279);
          pdf.text(footer2, 20, 283);
          pdf.addPage();
        }

        if (data["idea"]) {

          var splitExpertIdeaText = pdf.splitTextToSize(`${getSectionDescription("idea")}`, 155, { align: "justify" })
          var ideaPos = sectionResponses("idea").map((v)=>(_checkFiltering(v, FilteringType.highScores) && "-" + v.comment))
          var ideaNeg = sectionResponses("idea").map((v)=>(_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))
          var ideaGen = sectionResponses("idea").map((v)=>(_checkFiltering(v, FilteringType.general) && "-" + v.comment))

          const splitExpertIdea = pdf.splitTextToSize(`${expertIdeaScroing.map(data => data.list)}`, 150)
          const splitGeneralIdea = pdf.splitTextToSize(`${expertGeneralIdeaScroing.map(data => data.list)}`, 150)
          pdf.addImage(img, 'JPEG', 145, 5, width, height);
          pdf.setFontSize(14);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("EXPERT SCORING RESULTS", 20, 20)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text(`${getSectionLabel("idea") + " Score"}`, 20, 45)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(splitExpertIdeaText, 20, 55)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Insights:", 20, 80)
          pdf.setTextColor("#50a14c")
          pdf.text("Positives:", 20, 90)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are over 70%)", 40, 90)
          var y = 95
          ideaPos.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitExpertIdea, 20, 100)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setTextColor("#ed2128")
          pdf.text("Negatives:", 20, y=y+10)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are <50%)", 42, y)
          ideaNeg.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setTextColor("#63c8d6")
          pdf.text("General:", 20, y=y+10)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are between 51% and 69%)", 40, y)
          ideaGen.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitGeneralIdea, 20, 130)
          pdf.setFontSize(13);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Similar Companies:", 20, y=y+15)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "normal");
          pdf.splitTextToSize(`${"Base on the score " + `${project.company.name}` + " received for this section, companies " + `${similarRankIdea.name}` + " & " + `${similarRankIdea2.name}` + " are the most similar."}`, 20, y=y+10)
          if (y >= pageHeight-30)
          {
            pdf.addPage();
            y = 20 // Restart height position
          }
          if(imgIdea)
          pdf.addImage(imgIdea, 'JPEG', 30, y=y+10, 30, 20)
          if(imgIdea2)
          pdf.addImage(imgIdea2, 'JPEG', 100, y, 30, 20)
          // pdf.setFont('Montserrat, ', "bold");
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.setFontSize(13);
          pdf.text("Rankings", 20, y=y+30)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text(`${"Overall, " + `${project.company.name}` + " ranks "+ `${projectRankIdea.rank}`+" out of "+ `${data["idea"].length}`+". This places it in the"+percentile(projectRankIdea.rank)+ "[percentile] of idea."}`, 20, 210)

          pdf.setFontSize(10);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(footer1, 20, 279);
          pdf.text(footer2, 20, 283);
          pdf.addPage();
        }

        // ______________________________COMPETETION PDF SECTION______________________________

        if (data["competition"]) {

          var splitExpertText = pdf.splitTextToSize(`${getSectionDescription("competition")}`, 155, { align: "justify" })
          var competitionPos = sectionResponses("competition").map((v)=>(_checkFiltering(v, FilteringType.highScores) && "-" + v.comment))
          var competitionNeg = sectionResponses("competition").map((v)=>(_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))
          var competitionGen = sectionResponses("competition").map((v)=>(_checkFiltering(v, FilteringType.general) && "-" + v.comment))

          const splitExpertCompetition = pdf.splitTextToSize(`${expertCompetetionScroing.map(data => data.list)}`, 150)
          const splitGeneralCompetition = pdf.splitTextToSize(`${expertGeneralCompetetionScroing.map(data => data.list)}`, 150)
          pdf.addImage(img, 'JPEG', 145, 5, width, height);
          pdf.setFontSize(14);
          pdf.text("EXPERT SCORING RESULTS", 20, 20)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text(`${getSectionLabel("competition") + " Score"}`, 20, 45)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(splitExpertText, 20, 55)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Insights:", 20, 75)
          pdf.setTextColor("#50a14c")
          pdf.text("Positives:", 20, 85)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are over 70%)", 40, 85)
          var y = 90
          competitionPos.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          pdf.setFontSize(13);
          pdf.setTextColor("#ed2128")
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Negatives:", 20, y=y+10)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are <50%)", 42, y)
          // pdf.text(splitExpert2, 20, 130)
          competitionNeg.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitExpertCompetition, 20, 105)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setTextColor("#63c8d6")
          pdf.text("General:", 20, y=y+10)
          pdf.setFontSize(11);
          pdf.setTextColor("#0e1111")
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text("(These are the comments where the scores are between 51% and 69%)", 40, y)
          competitionGen.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitGeneralCompetition, 20, 145)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Similar Companies:", 20, y=y+15)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "normal");
          pdf.splitTextToSize(`${"Base on the score " + `${project.company.name}` + " received for this section, companies " + `${similarRankComp.name}` + " & " + `${similarRankComp2.name}` + " are the most similar."}`, 20, y=y+10)
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          if(imgCompetition)
          pdf.addImage(imgCompetition, 'JPEG', 30, y=y+10, 30, 20)
          if(imgCompetition2)
          pdf.addImage(imgCompetition2, 'JPEG', 100, y, 30, 20)
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setFontSize(13);
          pdf.text("Rankings", 20, y=y+30)
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.setFontSize(11);
          pdf.text("Overall" + `${project.company.name}` + " ranks "+ `${projectRankCompetition.rank}`+" out of "+ `${data["competition"].length}`+". This places it in the "+percentile(projectRankCompetition.rank)+ " (percentile) of competition.", 20, y=y+20)
          pdf.setFontSize(10);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(footer1, 20, 279);
          pdf.text(footer2, 20, 283);
          pdf.addPage();

        }

        // ____________________________ FINANCE PDF SECTION ________________________________

        if (data["finance"]) {
          var financePos = sectionResponses("finance").map((v)=>(_checkFiltering(v, FilteringType.highScores) && "-" + v.comment))
          var financeNeg = sectionResponses("finance").map((v)=>(_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))
          var financeGen = sectionResponses("finance").map((v)=>(_checkFiltering(v, FilteringType.general) && "-" + v.comment))

          var splitExpertText = pdf.splitTextToSize(`${getSectionDescription("finance")}`, 155, { align: "justify" })
          const splitExpertFinance = pdf.splitTextToSize(`${expertFinanceScroing.map(data => data.list)}`, 150)
          const splitGeneralFinance = pdf.splitTextToSize(`${expertGeneralFinanceScroing.map(data => data.list)}`, 150)
          pdf.addImage(img, 'JPEG', 145, 5, width, height);
          pdf.setFontSize(14);
          pdf.text("EXPERT SCORING RESULTS", 20, 20)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text(`${getSectionLabel("finance") + " Score"}`, 20, 45)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(splitExpertText, 20, 55)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Insights:", 20, 80)
          pdf.setTextColor("#50a14c")
          pdf.text("Positives:", 20, 90)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are over 70%)", 40, 90)
          var y = 95
          financePos.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          pdf.setFontSize(13);
          pdf.setTextColor("#ed2128")
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Negatives:", 20, y=y+10)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are <50%)", 42, y)
          financeNeg.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitExpert2, 20, 130)
          // pdf.text(splitExpertFinance, 20, 110)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setTextColor("#63c8d6")
          pdf.text("General:", 20, y=y+10)
          pdf.setFontSize(11);
          pdf.setTextColor("#0e1111")
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text("(These are the comments where the scores are between 51% and 69%)", 40, y)
          financeGen.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitGeneralFinance, 20, 147)
          pdf.setFontSize(13);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Similar Companies:", 20, y=y+10)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "normal");
          pdf.splitTextToSize(`${"Base on the score " + `${project.company?.name}` + " received for this section, companies " + `${similarRankFinance?.name}` + " & " + `${similarRankFinance2?.name}` + " are the most similar."}`, 20, y=y+10)
          if (y >= pageHeight-30)
          {
            pdf.addPage();
            y = 20 // Restart height position
          }
          if(imgfinance)
          pdf.addImage(imgfinance, 'JPEG', 30, y=y+15, 30, 20)
          if(imgfinance2)
          pdf.addImage(imgfinance2, 'JPEG', y, 185, 30, 20)
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setFontSize(13);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Rankings", 20, y=y+30)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Overall, " + `${project.company?.name}` + " ranks "+ `${projectRankFinance?.rank}`+" out of "+ `${data["finance"].length}`+". This places it in the "+percentile(projectRankFinance.rank)+ " (percentile) of finance.", 20, y=y+10)
          pdf.setFontSize(10);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(footer1, 20, 279);
          pdf.text(footer2, 20, 283);
          pdf.addPage();

        }

        // ____________________________ FINANCE PDF SECTION ________________________________

        if (data["market"]) {
          var marketPos = sectionResponses("market").map((v)=>(_checkFiltering(v, FilteringType.highScores) && "-" + v.comment))
          var marketNeg = sectionResponses("market").map((v)=>(_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))
          var marketGen = sectionResponses("market").map((v)=>(_checkFiltering(v, FilteringType.general) && "-" + v.comment))

          var splitExpertText = pdf.splitTextToSize(`${getSectionDescription("market")}`, 155, { align: "justify" })
          const splitExpertMarket = pdf.splitTextToSize(`${expertMarketScroing.map(data => data.list)}`, 150)
          const splitGeneralMarket = pdf.splitTextToSize(`${expertGeneralMarketScroing.map(data => data.list)}`, 150)
          pdf.addImage(img, 'JPEG', 145, 5, width, height);
          pdf.setFontSize(14);
          pdf.text("EXPERT SCORING RESULTS", 20, 20)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text(`${getSectionLabel("market") + " Score"}`, 20, 45)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(splitExpertText, 20, 55)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Insights:", 20, 80)
          pdf.setTextColor("#50a14c")
          pdf.text("Positives:", 20, 90)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are over 70%)", 40, 90)
          var y = 95
          marketPos.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          pdf.setFontSize(13);
          pdf.setTextColor("#ed2128")
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Negatives:", 20, y=y+10)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are <50%)", 42, y)
          marketNeg.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setTextColor("#63c8d6")
          pdf.text("General:", 20, y=y+10)
          pdf.setFontSize(11);
          pdf.setTextColor("#0e1111")
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text("(These are the comments where the scores are between 51% and 69%)", 40, y)
          marketGen.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitGeneralMarket, 20, 147)
          pdf.setFontSize(13);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Similar Companies:", 20, y=y+15)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "normal");
          pdf.splitTextToSize(`${"Base on the score " + `${project.company?.name}` + " received for this section, companies " + `${similarRankMarket?.name}` + " & " + `${similarRankMarket2?.name}` + " are the most similar."}`, 20, y=y+10)
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
            if(imgmarket)
            pdf.addImage(imgmarket, 'JPEG', 30, y=y+10, 30, 20)
            if(imgmarket2)
            pdf.addImage(imgmarket2, 'JPEG', 100, y, 30, 20)
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setFontSize(13);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Rankings", 20, y=y+30)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Overall, " + `${project.company?.name}` + " ranks "+ `${projectRankMarket?.rank}`+" out of "+ `${data["market"].length}`+". This places it in the "+percentile(projectRankMarket.rank)+ " (percentile) of market.", 20, y=y+10)
          pdf.setFontSize(10);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(footer1, 20, 279);
          pdf.text(footer2, 20, 283);
          pdf.addPage();

        }

        if (data["esg"]) {
          var esgPos = sectionResponses("esg").map((v)=>(_checkFiltering(v, FilteringType.highScores) && "-" + v.comment))
          var esgNeg = sectionResponses("esg").map((v)=>(_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment))
          var esgGen = sectionResponses("esg").map((v)=>(_checkFiltering(v, FilteringType.general) && "-" + v.comment))

          var splitExpertText = pdf.splitTextToSize(`${getSectionDescription("esg")}`, 155, { align: "justify" })
          const splitExpertEsg = pdf.splitTextToSize(`${expertEsgScroing.map(data => data.list)}`, 150)
          const splitGeneralEsg = pdf.splitTextToSize(`${expertGeneralEsgScroing.map(data => data.list)}`, 150)
          pdf.addImage(img, 'JPEG', 145, 5, width, height);
          pdf.setFontSize(14);
          pdf.text("EXPERT SCORING RESULTS", 20, 20)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text(`${getSectionLabel("esg") + " Score"}`, 20, 45)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(splitExpertText, 20, 55)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Insights:", 20, 80)
          pdf.setTextColor("#50a14c")
          pdf.text("Positives:", 20, 90)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are over 70%)", 40, 90)
          var y = 95
          esgPos.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }   
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          pdf.setFontSize(13);
          pdf.setTextColor("#ed2128")
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Negatives:", 20, y=y+10)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.setTextColor("#0e1111")
          pdf.text("(These are the comments where the scores are <50%)", 42, y)
          // pdf.text(splitExpert2, 20, 130)
          esgNeg.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }  
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitExpertEsg, 20, 110)
          pdf.setFontSize(13);
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setTextColor("#63c8d6")
          pdf.text("General:", 20, y=y+10)
          pdf.setFontSize(11);
          pdf.setTextColor("#0e1111")
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text("(These are the comments where the scores are between 51% and 69%)", 40, y)
          esgGen.forEach((val)=>{
            var comment =  pdf.splitTextToSize(val+'', 150, { align: "justify", }) 

            if (val)
            {
              if(val.length<83)
              {
                 pdf.text(comment,20,y+5);
                 y=y+6;
              }
              if(val.length>82 && val.length<169)
              {
                if((y+12)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+12;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>168 && val.length<251)
              {
                if((y+18)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+18;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
              if(val.length>251 && val.length<334)
              {
                if((y+24)<(pageHeight-30))
                {
                  pdf.text(comment,20,y+5);
                  y=y+24;
                }
                else{
                  pdf.addPage();
                  y = 20
                }
              }
            }   
            if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          })
          // pdf.text(splitGeneralEsg, 20, 147)
          pdf.setFontSize(13);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          // pdf.setFont('Montserrat, ', "bold");
          pdf.text("Similar Companies:", 20, y=y+10)
          pdf.setFontSize(11);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.splitTextToSize(`${"Base on the score " + `${project.company?.name}` + " received for this section, companies " + `${similarRankEsg?.name}` + " & " + `${similarRankEsg2?.name}` + " are the most similar."}`, 20, y=y+10)
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          if(imgEsg)
            pdf.addImage(imgEsg, 'JPEG', 30, y=y+10, 30, 20)
          if(imgEsg2)
            pdf.addImage(imgEsg2, 'JPEG', 100, y, 30, 20)
          // pdf.setFont('Montserrat, ', "bold");
          pdf.setFontSize(13);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Rankings", 20, y=y+30)
          pdf.setFontSize(11);
          if (y >= pageHeight-30)
            {
              pdf.addPage();
              y = 20 // Restart height position
            }
          pdf.text("Overall, " + `${project.company?.name}` + " ranks "+ `${projectRankEsg?.rank}`+" out of "+ `${data["esg"].length}`+". This places it in the "+percentile(projectRankEsg.rank)+ " (percentile) of esg.", 20, y=y+10)
          pdf.setFontSize(10);
          // pdf.setFont('Montserrat, ', "normal");
          pdf.text(footer1, 20, 279);
          pdf.text(footer2, 20, 283);
          pdf.addPage();

        }

        var splitDisclaimerText = pdf.splitTextToSize("While we beleive that our i3D Platform for due diligence helps an investor make their decision, we do not give investment advice. You should consult your investment advisor orthe relevant professional prior to making a substantial investment in a company.", 150, { align: "justify" });
        var splitDisclaimerText2 = pdf.splitTextToSize("There are two principal risks to be aware of when investing in shares. particlarly when investing in growth-focused businesses.", 155, { align: "justify" })
        var splitDisclaimerText3 = pdf.splitTextToSize("1. The value of your shares depends on the success of the company. If the conpany is unsuccessful and decreases in value, the value of your shares will also fall. If the company fails completely and goes out of business, you will lose all your investment.", 150, { align: "justify" });
        var splitDisclaimerText4 = pdf.splitTextToSize("2. Your shares in a private company without its share being traded on a public market, are illiquid, which means they can be difficult to sell and it can be hard to value them. It may not be possible to sell them at all, even if the business is a success and your shares increased in value. You should therefore be prepared to hold your shares for the ling term", 150, { align: "justify" })
        var splitDisclaimerText5 = pdf.splitTextToSize("You can find out more information about making an equioty investment, including the different risks involved, in the approporate information section from the Deal Originator", 150, { align: "justify" })
        pdf.addImage(img, 'JPEG', 145, 5, width, height);
        pdf.setFontSize(14);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.text("CAPITAL AT RISK AND DISCLAIMER", 20, 20)
        pdf.setFontSize(11);
        // pdf.setFont('Montserrat, ', "normal");
        pdf.text(splitDisclaimerText, 20, 45);
        pdf.text(splitDisclaimerText2, 20, 65);
        pdf.text(splitDisclaimerText3, 20, 80);
        pdf.text(splitDisclaimerText4, 20, 100);
        pdf.text(splitDisclaimerText5, 20, 125);
        pdf.setFontSize(10);
        pdf.text(footer1, 20, 279);
        pdf.text(footer2, 20, 283);
        pdf.addPage();
        pdf.addImage(img, 'JPEG', 145, 5, width, height);
        pdf.setFontSize(14);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.text("Annexures", 20, 45);
        pdf.setFontSize(11);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.text("Financial Breakdown", 20, 50);
        pdf.setFontSize(11);
        // pdf.setFont('Montserrat, ', "normal");
        pdf.text("[Financials, if included]", 20, 60);
        pdf.setFontSize(11);
        // pdf.setFont('Montserrat, ', "bold");
        pdf.text("Pitch Deck and Supplemental Files", 20, 70);
        pdf.setFontSize(11);
        // pdf.setFont('Montserrat, ', "normal");
        pdf.text("[Pitch Deck] [Supplemental Files]", 20, 80);
        pdf.setFontSize(10);
        pdf.text(footer1, 20, 279);
        pdf.text(footer2, 20, 283);

        // var blob = pdf.output('blob');
        // // var formData = new FormData();
        // // formData.append('file',blob);
        // storage
        //     .ref("reports/"+project.company.name +"Report.pdf").put(blob).then((res)=>{
        //       console.debug(res,"hemanth");
        //     })
        pdf.save(project.company.name+"Report.pdf");
        // setLoading(false)
      });
    setOpen(false);


  }


  return (
    <>
      <Button onClick={downloadDocument} className={classes.downloadReport} >
        download report
      </Button>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
export { DownloadableReport };

import { makeStyles } from "@material-ui/core/styles";
// import { wordBreak } from "html2canvas/dist/types/css/property-descriptors/word-break";

export default makeStyles((theme) => ({

  container: {

    height: "100%",

    // bac: "aliceblue"
  },

  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center !important",
    borderRadius: "10px !important",
    alignContent: "center",
    // marginTop: "5rem !important",
    border: "1px solid grey ",
    boxShadow: "2px 2px",
    // minWidth: "35rem",
    width:"550px",
    height:"550px",
    padding:"0px",
    // width:"100%"
  },
  freeCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center !important",
    borderRadius: "10px !important",
    alignContent: "center",
    // marginTop: "5rem !important",
    border: "1px solid grey ",
    boxShadow: "2px 2px",
    // minWidth: "35rem",
    width:"550px",
    height:"500px",
    padding:"0px",
    // width:"100%"
  },
  cardContent:{
    width:"100%",
  

  },
  back:{
    top: "20px",
    left: "30px",
    fontSize: "40px",
    position: "absolute",
  },
  image: {
    height: "40px",
    display: "flex",
    margin: "auto", marginBottom: "1.2rem",
    marginTop:"1rem"
  },
  icon: {

    fill: "green", width: "6rem", height: "6rem",

    paddingBottom: "2px",

    display: "flex",

    margin: "auto"
  },

  box: {
    paddingTop: "2rem",
    paddingBottom: "1rem"
  },

  typography: {
    paddingBottom: "2px",
    fontSize:'12px',
    wordBreak:"break-word"

  },

  error:{
    color:"#ff0e0e",
    marginLeft:"40px !important"
  }


}));
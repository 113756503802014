import React, { useState, useEffect } from "react";
import { withRouter, useLocation, useParams, useHistory } from "react-router-dom";
import { Button, ButtonGroup, Grid, IconButton, TextareaAutosize, Theme, Tooltip, Typography, withStyles, Tabs, Tab } from "@material-ui/core";
import useStyles from "./styles";
import NewScoreMessage from "./newScoreMessage";
import ScoreThankyou from "./scoreThankyou";
import NewScoreForm from "./newScoreForm";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import { ScoreForm } from "../../model/responses";
import { Invitation } from "../../model";
import invitation from "../../repositories/invitation"
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import PublicIcon from '@material-ui/icons/Public';
import { getSectionLabel } from "../create/components/data";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScoringAssist from "./components/ScoringAssist";

function NewScore() {
    const { id, section } = useParams<{ id: string, section: string }>();
    const user = useSelector((state: RootState) => state.firebase.auth);

    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const myRef = React.createRef();
    const [invitationData, setInvitationData] = useState<Invitation>();
    const [page, setPage] = useState(true);
    const [start, setStart] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [thankyou, setThankyou] = useState(false);
    const [percentDone, setPercentDone] = useState(0);
    const [openDropdown, setOpenDropdown] = useState(false);
    //_____________________intial values__________________
    const initialValues = {

        event_id: "",
        event_type: "",
        form_response: {
            form_id: "",
            token: "",
            landed_at: new Date(),
            submitted_at: "",
            hidden: {
                company: invitationData ? invitationData.company_name : "",
                projectid: invitationData ? invitationData.projectID : "",
                section: section,
                invitationid: id,
            },
            definition: {
                id: "",
                title: "",
                fields: [],
            },
            answers: [],
            generalComment: '',
        }
    }

    useEffect(() => {
        /////////// fetching Invitation data///////////////////////
        invitation.fetchInvitation(id).then((response) => {
            if(response)
            {
                var total = response.sections.length;
                console.debug("fetchSectionQuestions:projectId", response.projectID)
                console.debug("fetchSectionQuestions:projectId", section)


                invitation.fetchSectionQuestions(section,response.projectID).then((res) => {
                    console.debug("fetchSectionQuestions:res", res)
                    setQuestions(res);
                });
                var done = 0;
                (response.sections).forEach(sec => {
                    if (response.sectionsStatus[sec] == "done")
                        done++;
                });
                var percent = (done / total) * 100;
                setPercentDone(percent);
            }
            else{
                setPage(false);
            }
           console.debug("fetchInvitation:response", response)
            setInvitationData(response);
        })
        //_______________fetching questions of the selected section_
        
    }, []);

    // _______________________Start Function__________________

    const handleStart = () => {
        setStart(true);
    };

    // __________________________SCORE SUBMIT FUNCTION______________________

    const handleSubmitScore = () => {
        setThankyou(true)
    };

    const handleSubmit = (values) => {
        setBackdrop(true);
        var answers = questions.filter((v)=> { return v.id != 10 } ).map((val) => {
            return {
                type: val.type == "number" ? "number" : "text",
                number: values[val.id],
                comment: values[val.id + "_comment"],
                field: val,
            }
        });
        values.form_response.answers = answers;
        values.form_response.generalComment = values.generalComment;
        values.submitted_at = new Date();
        values["user"] = user.email;
        questions.forEach((q) => {
            delete values[q.id];
            delete values[q.id + "_comment"];
        })
        const response = axios.post(
            //for main server
            "https://us-central1-invluencer-dev.cloudfunctions.net/submitForm",
            //for test instance
            // "https://us-central1-vk-invluencer-dev.cloudfunctions.net/submitForm",
            values,

        ).then(function (response) {
            // console.debug(response);
            invitation.updateSectionStatus(id, section).then((res) => {
                setBackdrop(false);
                history.push("/overview/" + id);
            })
            return response;
        }).catch(function (error) {
            console.debug(error);
            alert(error);
            setBackdrop(false);
            return error.response;
        });
        
    };

    const handleClick = () => {
        setOpenDropdown(!openDropdown)
    }
    const handleOutsideClick = () => {
        if (!myRef.current) {
            setOpenDropdown(false)
        }
    }

    if(!page) return <ErrorDisplay error={"can not find with the given id"}/>;
    if (!invitationData) return <Loader text="Fetching your form..." />;

    return (
        <>
        <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.container}>
            <Grid item xs={6} className={classes.assistContainer}>
                {/* <div className={classes.pdfContainer}> */}
                    {/* <iframe className={classes.pdfSection} src="http://docs.google.com/gview?url=http://infolab.stanford.edu/pub/papers/google.pdf&embedded=true"></iframe> */}
                    {/* <embed className={classes.pdfSection} src="http://scalified.com/valid-document-url.pdf" ></embed> */}
                    <div >
                    <ScoringAssist id={invitationData.projectID} start={start} companyData={invitationData}/>
                    </div>
                    {/* <object
                        className={classes.pdfSection}
                        data={invitationData.pdfLink[section]}
                        type="application/pdf"
                    /> */}
                {/* </div> */}
            </Grid>
        <Grid item xs={6}>
                <div className={classes.tabSection}>
                    <ul>
                        <li>
                            <Button onClick={() => handleClick()} style={{color: "#fff", backgroundColor: "#00248c"}} >Documents</Button>
                            {openDropdown && <div ref={myRef} className={classes.documentDropDown}>
                                {invitationData.sections.map((value) => {
                                    return (
                                        <a href={invitationData.pdfLink[value]} target="_blank" >{getSectionLabel(value)}</a>
                                    )
                                })}
                            </div>}
                        </li>
                        <li>
                            <Button>
                                <PublicIcon style={{ color: "#858C94" }} />
                                <a href={invitationData.company_website} target="_blanl">Website</a>
                            </Button>
                        </li>
                        <li>
                            <Button style={{backgroundColor: "#50a14c", color: "#fff"}}>{percentDone.toFixed(0)}% complete</Button>
                        </li>
                    </ul>
                </div>
                <div className={classes.formContainer} >



                    {/* {thankyou ? <ScoreThankyou /> : ""} */}

                    <Formik<ScoreForm>
                        initialValues={initialValues}
                        validateOnMount={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        // validationSchema={validationSchema}
                        onSubmit={(values) => handleSubmit(values)}
                    >
                        {(formik) => (
                            <Form>
                                {/* _______________________________SCORE SCTION FORM HTML __________________________________ */}

                                {start && !thankyou && <NewScoreForm questions={questions}
                                    handleSubmitScore={handleSubmitScore} setStart={setStart}
                                />}
                                {/* ____________________________________THANKYOU COMPONENT HERE___________________ */}


                                {thankyou && <ScoreThankyou setThankyou={setThankyou} questions={questions}/>}
                            </Form>
                        )}
                    </Formik>
                    {/* ______________________________________ NEWSCOREMESSAGE COMPONENT IMPORTED HERE____________________ */}
                    {!start && <NewScoreMessage handleStart={handleStart} data={invitationData} section={section} />}
                </div>
            </Grid>
        </Grid>
        </>
    );
}

export default NewScore;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({

    container: {
        height: "100%",
        // width: "100vw",
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundColor: "rgb(242, 247, 255)",
        position: "absolute",
        top: 0,
        left: 0,
    },
    sectionTitle: {
        // paddingRight:theme.spacing(2),
        marginLeft: 4,
        marginBottom: 20,
        fontSize: "16px !important",
        fontWeight: "700 !important",
        textAlign: "left",
        overflowWrap: "break-word",
        textOverflow: "ellipsis", overflow: "hidden",
        color: "white",
        paddingTop: 3

    },
    parentGridElivatorPitch: {
        marginTop: "25px",
        display: "flex",
        justifyContent: "center"
    },
    parentElivatorPitch: {
        borderBottom: "1px solid white",
        paddingBottom:"40px"
    },
    elivatorPitchCard: {
        width: "92%",
        height: "85vh",
        padding: "20px",
        borderRadius: "15px"
    },
    parentGrid: {
        display: "flex",
        justifyContent: "center"
        // padding:"25px !important"
    },
    assistContainer: {
        spacing: theme.spacing(1),
        height: '100vh',
        padding: 30,
        overflowY: "auto",
        overflowX: "auto",
        backgroundColor: "#002060",
    },
    card: {
        height: 325,
        width: 350,
        padding: 20
    },
    img: {
        height: 400,
        // width: 280,
        width: "396px",

        // padding: "6px"
        // paddingRight: "4px",
        paddingLeft: "35px",
        marginTop: "30px",
        marginBottom: "30px",
        borderRadius: "8px"


        // paddingRight: "7px",
    },
    pageTitle: {
        textAlign: "left",
        // marginBottom: 10,
        marginTop: 30,
        marginLeft: 35,

        // fontSize: "16px !important",
        fontWeight: "700 !important",
        color: "white",
    },
    pdfContainer: {
        width: "100%",
        // overflowY: "auto",
        // overflowX: "hidden",
        height: "100vh",
    },
    infoBtn: {
        fontWeight: 600,
        // color: "",
    },
    infoBtnTxt: {
        fontSize: "14px",
        backgroundColor: "white",
        color: "black",

    },
    gridContainer: {
        width: "100%",
        borderBottom: "2px solid white",
        paddingBottom: 15,
        display: "grid",
        gap: " 9px",
        gridTemplateColumns: 'repeat(2, 1fr)'
        // grid- template - columns: auto auto auto;

        // border-bottom pb-2 d-flex w-100
    },
    pdfSection: {
        width: "100%",
        height: "100%"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    //   ___________________ RIGHT SIDE CONTAINER CSS_____________________________-
    formContainer: {
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        height: "93vh",
        backgroundColor: "rgb(242, 247, 255)",
    },
    questions: {
        justifyContent: "center",
        marginTop: 4 + "%",
        // marginLeft: 5 + "%",
        fontWeight: "bolder",
        fontSize: 14

    },
    tabSection: {
        display: "flex",
        width: "100%",
        height: "7vh",
        border: "1px solid",
        alignItems: "center",
        '& ul': {
            listStyle: "none",
            display: "flex",
            width: "100%",
            padding: "0px",
            margin: "0px",
            justifyContent: "center",
            alignItems: "center",
            '& li:nth-child(1), li:nth-child(2)': {
                marginLeft: "1rem",
                marginRight: "4rem",
            },
            '& li': {
                marginLeft: "1rem",
                marginRight: "2rem",
                '& button': {
                    textTransform: "capitalize",
                    border: "1px solid",
                    padding: "2px 15px 2px 15px",
                    minWidth: "auto",
                    width: "auto"
                }
            }
        }
    },
    rating: {
        padding: 20,
        paddingLeft: 0,
        marginLeft: 1 + "%",
        '& button': {
            border: "1px solid #00248c !important",
            fontSize: "14px",
            color: "#00248c",
            fontWeight: "600",
            marginRight: "0.5rem",
            borderRadius: "4px !important",
            borderRight: "1px solid #00248c !important"
        },
        '& button:nth-child(2)': {
            padding: "12px 13px",
        },
        '& button:hover, .Mui-selected': {
            backgroundColor: "#00248c",
            color: theme.palette.background.default,
        },

    },
    commentBoxExpert: {
        borderRadius: 5,
        padding: 4,
        // marginLeft: 4 + "%",
        width: 78 + "%",
        height: "6rem",
        backgroundColor: "#F9F9F9",
        border: "1px solid #BEBEBE",
        marginTop: "0.5rem",
        '& .MuiInputBase-root': {
            marginTop: 4 + "%"
        },
        '& .MuiNativeSelect-select.MuiNativeSelect-select': {
            fontSize: "14px"
        }
    },
    comment: {
        padding: 4,
        // marginLeft: 4 + "%",
        fontSize: 13,
        color: "#949494",
    },

    buttonMargin: {
        marginRight: 20,
    },

    // ___________________________________NEWSCORE MESSAGE CSS START________________________________
    welcomPara: {
        width: "60%",
        margin: "auto",
        marginTop: "10rem",
        textAlign: "center",
        // color: "#137DFB",
        color: theme.palette.primary.main,

        fontSize: "18px",
    },
    sectionpara: {
        width: "75%",
        margin: "auto",
        marginTop: "1rem",
        textAlign: "center",
        // color: "#137DFB",
        color: theme.palette.primary.main,

        fontSize: "12px",
    },
    startButton: {
        display: "flex",
        alignItems: "center",
        marginTop: "2rem",
        marginBottom: "2rem",
        justifyContent: "center",
        '& button': {
            color: theme.palette.blackColor.main,
            // backgroundColor: "#137DFB",
            backgroundColor: theme.palette.primary.main,

            fontWeight: "600"
        },
        '& a': {
            fontSize: "1rem",
            marginLeft: "1rem"
        }
    },
    //    ____________________________DROPDOWN CSS START_________________________
    buttonHoverDropdown: {
        "&:hover + $documentDropDown": {
            display: "block"
        }
    },
    text: {
        fontSize: "14px",
        fontWeight: 600,
    },
    textField: {
        fontSize: "14px",
        wordWrap: "break-word",
        wordBreak: "break"
    },
    fields: {
        marginBottom: "0.5rem"
    },
    documentDropDown: {
        // display: "none",
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        minWidth: "125px",
        boxShadow: theme.customShadows.widget,
        zIndex: "1",
        borderRadius: "4px",
        top: "45px",
        '& a': {
            color: theme.palette.blackColor.main,
            display: "block",
            padding: "10px",
            fontFamily: "'Manrope', sans-serif",
            fontSize: "12px"
        },



    }

}));
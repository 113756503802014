import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
import { Project } from "../../../../model";
import {
  getSectionLabel,overallScore,overallWeightedScore
} from "../../../create/components/data";

interface Props {
  project: Project;
  weight: Record<string,number>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  summary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
    marginLeft: theme.spacing(5),
    textAlign: "left",
  },
  breakdown: {
    marginRight: theme.spacing(3),
  },
  container: {
    marginBottom: theme.spacing(4),
  },
  footer: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function PieOverall(props: Props) {
  const { project, weight } = props;
  const classes = useStyles();
  const containerSize = 400;
  // const [weight,setWeight] = useState();
 
  const data = Object.entries(project.sectionStats)
    .filter(([key]) => key !== "overall")
    .sort(([, valueA], [, valueB]) => valueB.avgRating - valueA.avgRating)
    .map(([key, value]) => ({
      name: getSectionLabel(key),
      score: value.avgRating * 100.0,
      weight: project.sectionWeights[key],
    }));
  const hasMiddleGraph = data.length >= 3;
  return (
    //   <div>
    //       <CircularProgress variant="determinate" value={75} />
    //   </div>
       <Box position="relative" display="inline-flex">
       <CircularProgress size={211} thickness={3.6} variant="determinate" value={overallWeightedScore(project,props.weight)} />
       <Box
         top={0}
         left={0}
         bottom={0}
         right={0}
         position="absolute"
         display="flex"
         alignItems="center"
         justifyContent="center"
       >
         <Typography variant="caption" component="div" style={{
            //  fontFamily: "Montserrat",
             fontStyle: "normal",
             fontWeight: "bold",
             fontSize: "60px",
             lineHeight: "52px",
             textAlign: "center",
             letterSpacing: "-1.37308px",
             color: "#137DFB",
         }}>{overallWeightedScore(project,weight).toFixed(0)}%</Typography>
       </Box>
     </Box>
      );
}

export default PieOverall;

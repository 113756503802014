import React, { Dispatch, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

import useStyles from "./styles";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Project, LayoutAction, ModalType } from "../../model";
import {
  getSectorLabel,
  getStageLabel,
  overallScore,
} from "../../pages/create/components/data";
//import { ReportMenu } from "../Modals/Menu/Menu";
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useSelector, useDispatch } from "react-redux";
// import { fetchAllProjects } from "../../actions/project";
import { addFavourite, removeFavourite } from "../../actions/project";
import { shareUpgradeModal, showModal, showUpgradeModal } from "../../actions/layout";
import { RootState } from "../../reducers";
import { ScoreType } from "../../utils/enums";
import LockIcon from '@material-ui/icons/Lock';
import { PinDropSharp } from "@material-ui/icons";
import SubscriptionCard from "../../pages/create/components/plan";
interface IProps {
  project: Project;
  filter: string;
  top?: boolean;
}
// import {initializeUser} from "../../actions/user";


function ProjectCardDraft({ project, filter, top }: IProps) {
  const classes = useStyles();
  const dispatchAddFavourite = useDispatch();
  const dispatchRemoveFavourite = useDispatch();
  let history = useHistory()
  const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
  const user = useSelector((state: RootState) => state.firebase.auth);
  const walletOpen = useSelector((state: RootState) => state.metamask.walletOpen);
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const [openPlan, setOpenPlan] = useState(false)
  const plan = profile.currentPlan;
  console.debug("plan", plan)

  const upgradePlanToShare = (e) => {
    e.preventDefault();
    return dispatchLayout(shareUpgradeModal());
  }

    const validateAndRedirect = (e: React.MouseEvent) => {
    // Determine the target URL based on some condition (example shown below)
    if (plan) {
      if (plan.uploadProject != plan.uploadedProject && plan.uploadProject >= plan.uploadedProject) {
        history.push(`/app/create/${project.id}`);
      } else {
        upgradePlanToShare(e)
      }
    } else {
      goToPlan(e)
    }
  };

  const goToPlan = (e) => {
    console.debug("ismein gaya")
    e.preventDefault();
    setOpenPlan(true)
    // history.push("/plan")
  }
  const addToFav = (e, projectId) => {
    e.preventDefault();
    dispatchAddFavourite(addFavourite(projectId));
  }

  // console.debug("profileSectionInCard:Share", profile)
  // const removeToFav = (e,projectId) =>{
  const removeToFav = (e) => {
    e.preventDefault();
    console.debug("remove fav click hadnler")
    dispatchRemoveFavourite(removeFavourite(project.id));
  }

  const shareReport = (e) => {
    e.preventDefault();
    return dispatchLayout(showModal(ModalType.SHAREREPORT, project));
  }

  function getScoreText(project) {
    switch (filter) {
      case ScoreType.expert:
        return (
          <Typography variant="body1" color="text" brightness="main">
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              {((project.sectionStats.overall ? project.sectionStats.overall.avgRating : 0) * 100).toFixed(0)}
            </span>
            <span style={{ fontSize: "12px" }}>
              % Expert Score
            </span>
          </Typography>
        );
      case ScoreType.technical:
        return (
          <Typography variant="body1" color="text" brightness="main">
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              {((project.technical ? project.technical.overall : 0) * 100).toFixed(0)}
            </span>
            <span style={{ fontSize: "12px" }}>
              % Technical Score
            </span>
          </Typography>
        );
      case ScoreType.rapid:
        return (
          <Typography variant="body1" color="text" brightness="main">
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              {((project.sectionStats.i3DRapid ? project.sectionStats.i3DRapid.avgRating : 0) * 100).toFixed(0)}
            </span>
            <span style={{ fontSize: "12px" }}>
              % Rapid Score
            </span>
          </Typography>
        );
      // case SortingType.rankingReversed:
      // 	break;
      default:
        return (
          <Typography variant="body1" color="text" brightness="main">
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              {overallScore(project).toFixed(0)}
            </span>
            <span style={{ fontSize: "12px" }}>
              % Overall Score
            </span>
          </Typography>
        );
        break;
    }


  }


  if (!plan) {
    return (
      <>
        <Paper variant="outlined" className={classes.paper}>
          <>
          {console.debug("plan.uploadProject != plan.uploadedProject", plan.uploadProject != plan.uploadedProject)}
            {plan ? (plan.uploadProject != plan.uploadedProject && plan.uploadProject >= plan.uploadedProject) ?
              <Link
                // to={`/connect`}
                to={`/app/create/${project.id}`}
                // to={profile.currentPlan ?`/create/${project.id}`: `/connect`}
                style={{ display: "flex", flexDirection: "column" }}>
                <div className={classes.wrapper}>
                  <div className={classes.infoWrapper}>
                    <img
                      src={project.company.logo}
                      alt="company logo"
                      className={classes.companyLogo}
                    />
                    <div className={classes.companyInfo}>

                      <Typography
                        align="left"
                        // color="text"
                        brightness="dark"
                        // weight="bold"
                        className={classes.companyName}
                      // variant="h4"
                      >
                        {project.company.name}

                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="text"
                          brightness="main"
                          style={{}}
                          className={classes.captionText}
                        >
                          {getSectorLabel(project.company.sector)}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text"
                          brightness="main"
                          className={classes.captionText}
                        >
                          {getStageLabel(project.company.stage)}
                        </Typography>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="d-flex">
                  <Typography
                    variant="body1"
                    className={classes.description}
                    color="text"
                    brightness="main"
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Description
                    </span>
                  </Typography>
                  {profile.currentPlan ? "" : <LockIcon fontSize="large" className="text-black " />}

                  {/* <LockIcon fontSize="large" className="text-black position-absolute " style={{left:"145px", top:"120px"}}/> */}
                </div>
                <Typography
                  variant="body1"
                  className={classes.description1}
                  color="text"
                  brightness="main"
                >

                  <span style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{
                    __html: project.company.description.length > 150 ? project.company.description.substring(0, 150) + "... " : project.company.description,
                  }}></span>
                  {/* <span style={{ fontSize: "12px" }}>
            {project.company.description}
          </span> */}
                </Typography>
                <Grid container spacing={3} className={classes.grid}>
                  <Grid item>
                    {getScoreText(project)}

                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="text" brightness="main">
                      <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                        {/* {project.sectionStats.overall.numComments} */}
                        {project.likesCount}
                      </span>{" "}
                      <span style={{ fontSize: "12px" }}>
                        Likes
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="text" brightness="main">
                      <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                        {project.sectionStats.overall ? project.sectionStats.overall.numComments : 0}
                      </span>{" "}
                      <span style={{ fontSize: "12px" }}>
                        Insights
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Link> : upgradePlanToShare : goToPlan}
          </>
          {/* <div style={{ position: "absolute", top: 8, right: 8 }}>
        <ReportMenu project={project} />
      </div> */}
        </Paper>
      </>
    );
  }

  return (
    <Paper variant="outlined" className={classes.paper}>
      <div onClick={(e =>validateAndRedirect(e))}
        // to={`/app/create/${project.id}`}
        style={{ display: "flex", flexDirection: "column" }}>
        <div className={classes.wrapper}>
          <div className={classes.infoWrapper}>
            <img
              src={project.company.logo}
              alt="company logo"
              className={classes.companyLogo}
            />
            <div className={classes.companyInfo}>

              <Typography
                align="left"
                // color="text"
                brightness="dark"
                // weight="bold"
                className={classes.companyName}
              // variant="h4"
              >
                {project.company.name}

              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="caption"
                  color="text"
                  brightness="main"
                  style={{}}
                  className={classes.captionText}
                >
                  {getSectorLabel(project.company.sector)}
                </Typography>
                <Typography
                  variant="caption"
                  color="text"
                  brightness="main"
                  className={classes.captionText}
                >
                  {getStageLabel(project.company.stage)}
                </Typography>
              </div>
            </div>

          </div>
        </div>
        <Typography
          variant="body1"
          className={classes.description}
          color="text"
          brightness="main"
        >
          <span style={{ fontWeight: "bold" }}>
            Description
          </span>
        </Typography>
        <Typography
          variant="body1"
          className={classes.description1}
          color="text"
          brightness="main"
        >
          <span style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{
            __html: project.company.description.length > 250 ? project.company.description.substring(0, 250) + "... " : project.company.description,
          }}></span>
          {/* <span style={{ fontSize: "12px" }}>
            {project.company.description}
          </span> */}
        </Typography>

      </div>
      <SubscriptionCard open={openPlan} setOpenPlan={setOpenPlan} />
      {/* <div style={{ position: "absolute", top: 8, right: 8 }}>
        <ReportMenu project={project} />
      </div> */}
    </Paper>

  );
}

export { ProjectCardDraft };

import { ViewType } from "./enums";

export function fetchPreferredWeights(): Record<string, number> {
  const rawWeights = localStorage.getItem("pref-weights");
  if (rawWeights && rawWeights !== "") {
    return JSON.parse(rawWeights);
  } else {
    return {};
  }
}

interface Suggestion {
  text: string;
  value: string;
}

export const fetchSuggestions = (): Suggestion[] => {
  const rawSuggestions = localStorage.getItem("email-suggestions");
  if (rawSuggestions && rawSuggestions !== "") {
    const parsed = JSON.parse(rawSuggestions);
    const data = parsed.map((email: string) => ({
      text: email,
      value: email,
    }));
    return data;
  } else {
    return [];
  }
};

const dashboardViewTypeKey = "i3d-dashaboard-preferred-view-type";
export function setDefaultViewType(viewType: ViewType): void {
  switch (viewType) {
    case ViewType.grid:
      localStorage.setItem(dashboardViewTypeKey, "grid");
      break;
    case ViewType.list:
      localStorage.setItem(dashboardViewTypeKey, "list");
      break;
  }
}
export function getDefaultViewType(): ViewType {
  const viewType = localStorage.getItem(dashboardViewTypeKey);
  switch (viewType) {
    case "grid":
      return ViewType.grid;
    case "list":
      return ViewType.list;
    default:
      return ViewType.grid;
  }
}

import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",

    },
    inputFields: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        marginTop: theme.spacing(4),
    },
    sectionTitle: {
        paddingRight: theme.spacing(2),
        fontSize: "15px !important",
        fontWeight: "500 !important",
        textAlign: "right",
        width: 300,
        color: theme.palette.blackColor.main,

    },
    CompanyDetails: {
        '& .ant-select-selector': {
            display: "flex",
            alignItems: "center",
            textAlign: "left",
            height: "40px !important",
            border: "1px solid #BEBEBE !important",
            background: "#fff !important",
            borderRadius: "10px !important",
            // fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "13px",
            lineHeight: "17px",
            color: theme.palette.blackColor.main,
            padding: "0 20px !important"
        },
        '& >div>span': {
            display: "flex",
            alignItems: "center",
            // justifyContent: "center"
        }
    },
    sectionHead:{
        textAlign: "center",
        width: "100%",
        fontSize: "1.2rem",
    },
    cardContainer:{
        height: "auto",
        margin: "16px",
        position: "relative",
        boxShadow: "0px 0px 8px 1px #ccc",
        textAlign: "center",
        borderRadius: "8px",
        padding: "2rem",
        paddingLeft: "0px",
        paddingTop: "1rem",
        backgroundColor:theme.palette.background.default,
    },


}));
import React from 'react';
export default function PdfCardComponents(props) {
  const card = {
    backgroundColor: 'white',
    height: "300px",
    width: "300px",
    padding: "20px",
    color: "black",
    margin: "auto"
  }
  const cardWrapper={
    paddingBottom:"2.3rem"
  }

  return (
    <div >
      <div className='row p-4'>
        <h4 className='text-white ms-5 my-3' > {"Team"}</h4>
        <div className='col-md-12  flex-wrap d-flex justify-content-start '>
         {props.data && props.data.map((item)=>
         <div className='col-md-6 ' style={cardWrapper}>
            <div className='card mx-auto' style={card}>
              <div className="row mb-2">
                <div className="col-md-6 ">
                 Name:
                </div>
                <div className="col-md-6 ">{item.name}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6 ">
                  Role:
                </div>
                <div className="col-md-6 ">{item.Role}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6 ">
                Experience:
                </div>
                <div className="col-md-6 ">{item.Experience}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6 ">
                 Education:
                </div>
                <div className="col-md-6 ">{item.Education}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6  ">
                 PT or FT:
                </div>
                <div className="col-md-6 ">{item.time}</div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6  ">
                Linkedin:
                </div>
                <div className="col-md-6 ">{item.linkedin}</div>
              </div>
            </div>
          </div>)} 
        </div>
      </div>
    </div>
  );
};


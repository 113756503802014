import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  moreButton: {
    margin: theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    "&:hover": {
      backgroundColor: theme.palette.background.shadow,
      color: "rgba(255, 255, 255, 0.35)",
    },
  },
}));

export interface MetaMaskState {
    walletOpen: boolean;
  }
export enum metamaskActions {
    WALLET_OPEN = "WALLET_OPEN",
  }

interface metamaskActionsType<T, P = boolean> {
  type: T;
  payload: P;
}

export type metamaskAction = 
| metamaskActionsType<metamaskActions.WALLET_OPEN>;
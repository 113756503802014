import React, { useEffect } from "react";
import {
  makeStyles,
  Theme,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { Project } from "../../../../model";
import {
  getSectionLabel,
} from "../../../create/components/data";
import LinearProgress from '@material-ui/core/LinearProgress';
import GroupIcon from '@material-ui/icons/Group';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PollIcon from '@material-ui/icons/Poll';
import HighlightIcon from '@material-ui/icons/Highlight';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import ProgressBar from './progressBar';
import { normalizeUnits } from "moment";
interface Props {
  project: Project;
}

const useStyles = makeStyles((theme: Theme) => ({
  score: {
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#000000",
  },
  
}));

function ExpertScore(props: Props) {


  let sectionName = ['team', 'market', 'idea', 'finance', 'competition', 'esg']

  let d = sectionName.map(section =>{
    return {
      name: getSectionLabel(section),
      score: 0 * 100.0,
      icon: getIcon(section),
      color: getColor(section)
  };
  })



  const { project } = props;
  const classes = useStyles();
  const containerSize = 400;
  // console.debug("hghkkkbj", project)
  const data = Object.entries(project.sectionStats)
  .filter(([key]) => key !== "overall" && key!=="i3DRapid")
  // .sort(([, valueA], [, valueB]) => valueB.avgRating - valueA.avgRating)
  .map(([key, value]) => ({
    name: getSectionLabel(key),
    score: value.avgRating * 100.0,
    completed: value.completed,
    weight: project.sectionWeights[key],
    icon: getIcon(key),
    color: getColor(key),
  }));
  function getIcon(sectionName)
  {
    if(sectionName=="team")
    return <GroupIcon fontSize="large" style={{backgroundColor:"#FFEADA",color:"#FCAE73"}}/>;
    if(sectionName=="market")
    return <PollIcon fontSize="large" style={{backgroundColor:"#FFEADA",color:"#FCAE73"}}/>;
    if(sectionName=="idea")
    return <HighlightIcon fontSize="large" style={{backgroundColor:"#DDF9E4",color:"#209D43"}}/>;
    if(sectionName=="finance")
    return <MonetizationOnIcon fontSize="large" style={{backgroundColor:"#E4F0FF",color:"#70A6E8"}}/>;
    if(sectionName=="competition")
    return <TrendingUpIcon fontSize="large" style={{backgroundColor:"#DDF9E4",color:"#209D43"}}/>;
    if(sectionName=="esg")
    return <LocalFloristIcon fontSize="large" style={{backgroundColor:"#E4F0FF",color:"#70A6E8"}}/>;
  }
  function getColor(sectionName)
  {
    if(sectionName=="team")
    return {
      color:"#FCAE73",
      backgroundColor: "#FFEADA"
    };
    if(sectionName=="market")
    return {
      color:"#FCAE73",
      backgroundColor: "#FFEADA"
    };
    if(sectionName=="idea")
    return {
      color:"#209D43",
      backgroundColor: "#DDF9E4"
    };
    if(sectionName=="finance")
    return {
      color:"#70A6E8",
      backgroundColor: "#E4F0FF"
    };
    if(sectionName=="competition")
    return {
      color:"#209D43",
      backgroundColor: "#DDF9E4"
    };
    if(sectionName=="esg")
    return {
      color:"#70A6E8",
      backgroundColor: "#E4F0FF"
    };
  }  

 


// console.debug("ExpertScoreData", data)
  return (
      <div style={{maxWidth:95+'%'}}>
        <Grid container spacing={2}>
        {data.length == 1 && data.name == undefined || data.length == 0 ? d.map((section, index) => {
          return (
<>
            <Grid item xs={2} key={index}>
                {section.icon}  
            </Grid>
            <Grid item xs={10}>
            <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <ProgressBar colors={section.color} value={section.score}/>
            </Box>
            <Box minWidth={35}>
              <Typography className={classes.score} >{(section.score).toFixed(0)}%</Typography>
            </Box>
            </Box>
            <Typography variant="body2" color="textSecondary">{section.name}</Typography>
            </Grid>
            </>
            );
          }):data.map((section, index) => {
            return (
  <>
              <Grid item xs={2} key={index}>
                  {section.icon}  
              </Grid>
              <Grid item xs={10}>
              <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <ProgressBar colors={section.color} value={section.score}/>
              </Box>
              <Box minWidth={35}>
                <Typography className={classes.score} >{(section.score).toFixed(0)}%</Typography>
              </Box>
              </Box>
              <Typography variant="body2" color="textSecondary">{section.name}</Typography>
              </Grid>
              </>
              );
            })}

        </Grid> 
       </div>
      );
}

export default ExpertScore;

import React from "react";
import {
  makeStyles,
  Theme,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Icon,
} from "@material-ui/core";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { Project } from "../../../../model";
import {
  getSectionLabel,
  getSectorLabel,
  getStageLabel,
} from "../../../create/components/data";
import { firestore } from "firebase";
import { Link } from "react-router-dom";
import { Launch } from "@material-ui/icons";

interface Props {
  project: Project;
  numInvited: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  summary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
    marginLeft: theme.spacing(5),
    textAlign: "left",
  },
  breakdown: {
    marginRight: theme.spacing(3),
  },
  container: {
    marginBottom: theme.spacing(4),
  },
  footer: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function SectionsGraph(props: Props) {
  const { project, numInvited } = props;
  const classes = useStyles();
  const containerSize = 400;
  const data = Object.entries(project.sectionStats)
    .filter(([key]) => key !== "overall")
    .sort(([, valueA], [, valueB]) => valueB.avgRating - valueA.avgRating)
    .map(([key, value]) => ({
      name: getSectionLabel(key),
      score: value.avgRating * 100.0,
      weight: project.sectionWeights[key],
    }));
  const numComments = project.sectionStats.overall.numComments;
  const hasMiddleGraph = data.length >= 3;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid
        key="overall"
        item
        sm={hasMiddleGraph ? 3 : 6}
        className={classes.summary}
      >
        <Typography>
          Company Name:{" "}
          <span style={{ fontWeight: "bold" }}>{project.company.name}</span>
        </Typography>
        {project.company.website && (
          <Typography>
            Company Website:{" "}
            <span style={{ fontWeight: "bold" }}>
              {project.company.website}
            </span>
          </Typography>
        )}
        <Typography>
          Company Stage:{" "}
          <span style={{ fontWeight: "bold" }}>
            {getStageLabel(project.company.stage)}
          </span>
        </Typography>
        <Typography>
          Company Sector:{" "}
          <span style={{ fontWeight: "bold" }}>
            {getSectorLabel(project.company.sector)}
          </span>
        </Typography>
        <Typography>
          Experts Invited:{" "}
          <span style={{ fontWeight: "bold" }}>{numInvited}</span>
        </Typography>
        <Typography
          component={Link}
          to={`/app/breakdown/${project.id}`}
          color="textPrimary"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Number of Comments:{" "}
          <span style={{ fontWeight: "bold" }}>{numComments}</span>
          <Icon fontSize="small">
            <Launch fontSize="small" />
          </Icon>
        </Typography>
        <Typography>
          Project Due Date:{" "}
          <span style={{ fontWeight: "bold" }}>
            {!!project.endDate && project.endDate instanceof firestore.Timestamp
              ? project.endDate.toDate().toDateString()
              : "No date provided"}
          </span>
        </Typography>
        <Typography>
          Company Description:{" "}
          <span style={{ fontWeight: "bold" }}>
            {project.company.description}
          </span>
        </Typography>
        {project.company.investmentLink && (
          <Typography>
            Investment Link:{" "}
            <span style={{ fontWeight: "bold" }}>
              {project.company.investmentLink}
            </span>
          </Typography>
        )}
      </Grid>
      {hasMiddleGraph && (
        <Grid key="graph" item sm={5} className={classes.root}>
          <ResponsiveContainer
            width="100%"
            minWidth={containerSize}
            height={containerSize}
            className={classes.root}
          >
            <RadarChart
              outerRadius={150}
              width={containerSize}
              height={containerSize}
              data={data}
            >
              <PolarGrid gridType="circle" />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis domain={[0, 100]} type="number" />
              <Radar
                name={project.company.name}
                dataKey="score"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </ResponsiveContainer>
        </Grid>
      )}
      <Grid
        key="invited"
        item
        sm={hasMiddleGraph ? 3 : 5}
        className={classes.breakdown}
      >
        <TableContainer>
          <Table stickyHeader className="mb-0">
            <TableHead>
              <TableRow>
                <TableCell>Section</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((section, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{section.name}</Typography>
                    </TableCell>
                    <TableCell>{section.weight.toFixed(2)}</TableCell>
                    <TableCell>{section.score.toFixed(0)}%</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <Typography className={classes.footer}>Overall</Typography>
                </TableCell>
                <TableCell className={classes.footer}>1.00</TableCell>
                <TableCell className={classes.footer}>
                  {(project.sectionStats.overall.avgRating * 100).toFixed(1)}%
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default SectionsGraph;

import { Reducer } from "react";
import {MetaMaskState,metamaskActions,metamaskAction} from '../model/metamask';


const initialState: MetaMaskState = {
    walletOpen: true,
  };

export const metamaskReducer: Reducer<MetaMaskState, metamaskAction> = (
state: MetaMaskState = initialState,
action: metamaskAction
): MetaMaskState => {
switch (action.type) {
    case metamaskActions.WALLET_OPEN:
        return { ...state, walletOpen:action.payload };
    default:
        return state;
}
}
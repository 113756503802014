import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Link, useParams, useHistory, useLocation } from "react-router-dom";

// styles
import useStyles from "./styles";
import FadeIn from "react-fade-in";

// components
import { Grid, Button, Tooltip, Paper, Typography, withStyles, Theme, Box } from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
// import Table from "./components/RequestsOverview/Table";
import Documents from "./components/RequestsOverview/Documents";
import CompanyInformation from "./components/CompanyInformation";
import { RootState } from "../../reducers";
import { ProjectDispatch, Report } from "../../model";
import { fetchReport } from "../../actions/project";
import {  PieOverall, Ranking, SectionRanking, ExpertScore, Details, TechnicalScore, ProgressBar } from "./components/Graphs";
import { ReportMenu } from "../../components/Modals/Menu/Menu";
// import { Info } from "@material-ui/icons";
import * as constants from "../../utils/constants";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import { Scrollbars } from 'react-custom-scrollbars';
// import jsPDF from 'jspdf';
// import * as htmlToImage from 'html-to-image';
import { ProjectRepo } from "../../repositories/project";
import techscore from "../../repositories/techscore";
import { DownloadableReport } from "../../components/DownloadReport"
import { callableFunctions } from "../../repositories/firebase";
import ProjectcCreateSucccessDialog from "../../components/Modals/ProjectCreateSuccessDialog";
// import axios from "axios";
interface State {
  loading: boolean;
  error?: Error | string;
  report?: Report;
  pageSlug: string;
}
const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width: 70 + "%",
  },
}))(Tooltip);

function useLocalState(): State {
  const {
    fetching,
    fetchError,
    focusedReport: report,
    allProjects,
  } = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch<ProjectDispatch>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      const requested = allProjects.find((p) => p.id === id);
      dispatch(fetchReport(id, requested));
    }
  }, [dispatch, allProjects, id]);

  return {
    loading: fetching,
    error: fetchError,
    report,
    pageSlug: id,
  };
}

export default function Dashboard() {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const [projects, setProjects] = React.useState([]);
  const state = useLocalState();
  const [weight, setWeight] = useState();
  const [openCongratsPopup, setOpenCongratsPopup] = useState(false)
  const [pitchDeckPdfUrl, setPitchDeckPdfUrl] = useState('')

  // const [movieDetails, setDetails] = useState([]);
  useEffect(() => {
    ProjectRepo.listProjects().then((response) => {
      setProjects(response);
    });
    techscore.fetchWeights().then((res) => {
      setWeight(res);
    })
    if (id) {
      fetchData(id)
    }
    // getTeamDataCallableFunction('hvjhvjvh')
  }, []);
  const location = useLocation();
  // const { id } = useParams();
  const showPopup = new URLSearchParams(location.search).get("showPopup");

  useEffect(() => {


    if (showPopup === "true") {
      setOpenCongratsPopup(true)
      setTimeout(() => {
        // Redirect to the same page without the query parameter
        // console.log("Redirecting...");
        window.history.replaceState(null, "", `/app/reports/${id}`);
        // setOpenCongratsPopup(false)
      }, 5000);
    }
  }, [showPopup, id]);

  const getPitchDeckPDF = callableFunctions.httpsCallable('getPitchDeckPDF');

  async function fetchData(id) {
    try {
      const response = await getPitchDeckPDF({ projectId: id });
      if (response.data) {
        let data = response.data
        setPitchDeckPdfUrl(data.pitchDeckUrl)

      }
    } catch (error) {
      // Handle any errors that occurred during the axios request
      console.error('Error:', error);
    }
  }


  if (state.pageSlug === "reports") return <Redirect to="/" />;
  if (state.loading || !(weight)) return <Loader text="Fetching your report..." />;
  // if (!(weight)) return <Loader text="Fetching weights..." />;
  if (state.error) return <ErrorDisplay error={state.error} />;
  if (!state.report) return null;
  if (!state.loading) {
    ProjectRepo.projectRank(state.report?.rank, id).then((res) => {
    });
  }

  return (
    <div id="report">
      <FadeIn>
        <PageTitle

          title={
            <>
              Analysis for{" "}
              <span style={{ fontWeight: "bold" }}>
                {state.report.project.company.name}
              </span>
            </>
          }
          extra={
            <>

              {/* {
               ('cid' in state.report?.project) &&  */}
              {/* <Button className={classes.downloadReport} >
                <a href={'https://dweb.link/ipfs/' + state.report?.project.cid} target="_blank" >
                  Download report
                </a>
              </Button> */}
              <DownloadableReport project={state.report?.project} data={projects} rank={state.report?.rank} responses={state.report?.responses} />
              {/* } */}
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                color="secondary"
                component={Link}
                to={`/app/breakdown/${state.pageSlug}`}
              >
                Score Breakdown
              </Button>
              <ReportMenu project={state.report.project} />
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={6}>
                <span className={classes.widgetTitle}>Overall Score</span>
                <br />
                <br />
                <Paper elevation={3} style={{ height: 300, textAlign: "center", paddingTop: 50 }}>

                  <PieOverall project={state.report.project}
                    weight={weight} />
                </Paper>
                <Paper elevation={3} style={{ height: 65, padding: 5, marginTop: 20 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={1} sm={1} md={1} lg={2}>
                      <span>
                        <InfoOutlinedIcon className={classes.infoIcon} />
                      </span>
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={10}>
                      <Typography className={classes.scoreInfo}>
                        The overall score is a composite score that aggregates the heuristic expert Score
                        and the quantitative technical score.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={6}>
                <span className={classes.widgetTitle}>Rankings</span>
                <br />
                <br />
                <Paper elevation={3} style={{ height: 128, padding: "20px" }}>
                  <Ranking rank={state.report.rank}
                    previousRank={state.report.project.previousRank} />
                </Paper>
                <br />
                <span className={classes.widgetTitle}>Weighting Factors  <LightTooltip title={constants.weightingPopup} placement="top">
                  <InfoOutlinedIcon style={{ fontSize: 15, }} color="disabled" />
                </LightTooltip>
                </span>
                <br />
                <Paper elevation={3} style={{ height: 210, padding: "10px" }}>
                  <SectionRanking weight={weight} />
                  {/* </Scrollbars> */}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} id="page4">
            <span className={classes.widgetTitle}>Expert Score
              <span> {(state.report.project.sectionStats.overall.avgRating * 100).toFixed(0)}% </span>
              <LightTooltip title={constants.expertScorePopup} placement="top">
                <InfoOutlinedIcon style={{ fontSize: 15, }} color="disabled" />
              </LightTooltip>

            </span>
            <br />
            <br />
            <Paper elevation={3} style={{ maxHeight: 500, padding: 20, paddingRight: 40, display: "flex", flexDirection: "column" }}>
              <ExpertScore project={state.report.project} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} >
            <span className={classes.widgetTitle}>Technical Score
              <span> {((state.report.project.technical ? state.report.project.technical.overall : 0) * 100).toFixed(0)}% </span>
              <LightTooltip title={constants.technicalScorePopup} placement="top">
                <InfoOutlinedIcon style={{ fontSize: 15, }} color="disabled" />
              </LightTooltip>
            </span>
            <br />
            <br />
            <Paper elevation={3} style={{ maxHeight: 500, padding: 20, paddingRight: 10, display: "flex", flexDirection: "column" }}>
              <TechnicalScore project={state.report.project} statePage={state.pageSlug}
                numInvited={state.report.invitations.length} />
            </Paper>

          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} >
            <span className={classes.widgetTitle}>i3D Rapid Score
              <span> {((state.report.project.sectionStats.i3DRapid ? state.report.project.sectionStats.i3DRapid.avgRating : 0) * 100).toFixed(0)}% </span>
              <LightTooltip title={constants.rapidScorePopup} placement="top">
                <InfoOutlinedIcon style={{ fontSize: 15, }} color="disabled" />
              </LightTooltip>
            </span>
            <br />
            <br />
            <Paper elevation={3} style={{ maxHeight: 500, padding: 20, paddingRight: 10, display: "flex", flexDirection: "column" }}>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                      <ProgressBar colors={{
                        color: "#FCAE73",
                        backgroundColor: "#FFEADA"
                      }} value={((state.report.project.sectionStats.i3DRapid ? state.report.project.sectionStats.i3DRapid.avgRating : 0) * 100).toFixed(0)} />
                    </Box>
                    <Box minWidth={35}>
                      <Typography>{((state.report.project.sectionStats.i3DRapid ? state.report.project.sectionStats.i3DRapid.avgRating : 0) * 100).toFixed(0)}%</Typography>
                    </Box>
                  </Box>
                  <Typography>
                    Team Score
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                      <ProgressBar colors={{
                        color: "#209D43",
                        backgroundColor: "#DDF9E4"
                      }} value={((state.report.project.sectionStats.i3DRapid ? state.report.project.sectionStats.i3DRapid.avgRating : 0) * 100).toFixed(0)} />
                    </Box>
                    <Box minWidth={35}>
                      <Typography>{((state.report.project.sectionStats.i3DRapid ? state.report.project.sectionStats.i3DRapid?.avgRating : 0) * 100).toFixed(0)}%</Typography>
                    </Box>
                  </Box>
                  <Typography>
                    Idea Score
                  </Typography>
                </Grid>

              </Grid>
            </Paper>

          </Grid>

          <Grid item xs={12}>
            <span className={classes.widgetTitle}>Details</span>
            <Grid container >
              <Grid item xs={12} lg={6} md={6} sm={12}>
                <Paper elevation={3} style={{ padding: 10, height: 86, width: "97%", marginTop: 10, display: "flex", alignItems: "center" }}>
                  <Details project={state.report.project} />
                </Paper>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                color="secondary"
                component={Link}
                to={`/app/breakdown/${state.pageSlug}`}
              >
                Score Breakdown
              </Button>
            </Grid> */}
            </Grid>
          </Grid>
          <Grid item sm={6} md={6} lg={6} xs={12} className={classes.halfWidgets}>
            <span className={classes.widgetTitle}>Company Information</span>

            <Widget
              // title="Documents"
              upperTitle
              noBodyPadding
              disableWidgetMenu
            // bodyClass={classes.tableWidget}
            >

              <CompanyInformation
                project={state.report.project}
              />

            </Widget>

          </Grid>
          <Grid item sm={6} md={6} lg={6} xs={12} className={classes.halfWidgets} >
            <span className={classes.widgetTitle}>Data Room</span>
            <Documents
              pitchDeckPdfUrl={pitchDeckPdfUrl}
              pdfLinks={state.report.project.pdfLinks}
              invitations={state.report.invitations ?? []}
            />
            {/* </Scrollbars>  */}
          </Grid>
          {/* <Grid item xs={12} style={{ marginTop: "2rem" }}>
            <span className={classes.widgetTitle}>Sentiment vs. Score</span>
            <Widget
              // title="Sentiment vs. Score"
              upperTitle
              noBodyPadding
              disableWidgetMenu
              bodyClass={classes.mainChartBody}
              trailing={
                <Tooltip title={constants.sentimentGraphDescription}>
                  <Info color="primary" />
                </Tooltip>
              }
            >
              <SentimentBarGraph responses={state.report.responses} />
            </Widget>
          </Grid> */}
        </Grid>
      </FadeIn>
      <ProjectcCreateSucccessDialog open={openCongratsPopup} setOpenCongratsPopup={setOpenCongratsPopup} />
    </div>
  );
}

import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";

export interface Subscription {
     id:string,
    name: string;
    description: string;
    price: string;
    planFeature: string[];
  }

  export interface SubscriptionState {
    allSubscription: Subscription[];
    fetching: boolean;
    fetchError?: Error | string;
  }

  export enum SubscriptionActions {
    FETCH = "FETCH_SUBSCRIPTION",
    FETCHED_ALL = "FETCHED_ALL_SUBSCRIPTION",
    FETCH_ERROR = "FETCH_SUBSCRIPTION_ERROR",
  }

  interface SubscriptionActionActionType<T, P = {}> {
    type: T;
    payload?: P;
  }

  export type SubscriptionAction =
   SubscriptionActionActionType<typeof SubscriptionActions.FETCH>
  |SubscriptionActionActionType<typeof SubscriptionActions.FETCHED_ALL, Subscription[]>
  |SubscriptionActionActionType<typeof SubscriptionActions.FETCH_ERROR>;

  export type SubscriptionThunk = ThunkAction<void, RootState, unknown, SubscriptionAction>;
export type SubscriptionDispatch = ThunkDispatch<RootState, any, SubscriptionAction>;
import React, { useState, useEffect } from "react";
// import { firestore } from "../../../../repositories/firebase";
import { firestore } from "../../../repositories/firebase";
// import {ProjectRepo} from "../../../repositories/project"
// import { Box, Grid, Paper } from "@material-ui/core";
import {
  makeStyles,
  Theme,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Icon,
} from "@material-ui/core";
import { Project } from "../../../model";
import { Launch } from "@material-ui/icons";
import { ProjectRepo } from "../../../repositories/project";
// import i3dlogo from "./../../images/i3Dlogo.png";
import i3dlogo from "../../../images/i3Dlogo.png"

// import { Typography } from "../../../components/Wrappers/Wrappers";
// import { Typography } from "../../../components/Wrappers/Wrappers";

// import useStyles from "../styles";

// interface Props {
//     project: Project;
//     numInvited: number;
//   }

const useStyles = makeStyles((theme: Theme) => ({

  summary: {

    textAlign: "center",
  },

}));


export default function CompanyDetail(props) {
  const classes = useStyles();
  console.debug("NewScore:response", props)
  const [companyDetail, setCompanyDetail] = useState("")
  let id = props.data.projectID
  useEffect(() => {
    /////////// fetching Invitation data///////////////////////
    ProjectRepo.fetchCompanyReport(id).then((response) => {
      if (response) {
        setCompanyDetail(response)
      }
    })
  }, [])
  console.debug("companyDetail", companyDetail)
  return (
    <div className=" align-content-center row vh-100 border-bottom">
      <div className="mb-5">
        {/* <div className="col-md-6 align-self-center ">
        </div> */}
        <div className="text-center h-200 d-flex justify-content-center align-items-end" ><img src={companyDetail.company?.logo} /> </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6 text-white-50  d-flex justify-content-end">
          <span className="w-50 h6"> Company Name:</span> </div>
        <div className="col-md-6 text-white ">{companyDetail.company?.name} </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6 text-white-50  d-flex justify-content-end"><Typography className="w-50">  Description:</Typography> </div>
        <div className="col-md-6 text-white"> {companyDetail.company?.description} </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6 text-white-50 d-flex justify-content-end"><Typography className="w-50">   Stage:</Typography> </div>
        <div className="col-md-6 text-white"> {companyDetail.company?.stage} </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6 text-white-50 d-flex justify-content-end"><Typography className="w-50"> Sector:</Typography> </div>
        <div className="col-md-6 text-white">  {companyDetail.company?.sector} </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6 text-white-50 d-flex justify-content-end"><Typography className="w-50"> WebSite: </Typography></div>
        <div className="col-md-6 text-white"> {companyDetail.company?.website} </div>
      </div>
    </div>

  )


}
import { makeStyles } from "@material-ui/core/styles";
// import { url } from "inspector";

export default makeStyles((theme) => ({

  companyDes: {
    fontSize: "0.8rem",
    padding: "30px 18px !important"
  },
  companyDescription: {
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #BEBEBE",
    boxSizing: "border-box",
    height: "6rem",
    background: theme.palette.background.default,
    padding: "0 20px"
  },
  title: {
    flex: 1,
    flexDirection: 'row'
  },
  back: {
    top: "20px",
    left: "30px",
    fontSize: "40px",
    position: "absolute",
  },
  planList: {
    marginTop: "1.5rem"
  },
  error: {
    fontSize: 14,
    textAlign: "left",
    color: "red",
  },
  // infoIcon: {
  //   fontSize: "30px",
  //   float: "right",
  //   margin: "20px",
  //   color: "white"
  // },
  bonus: {
    marginTop: "0.5rem",
    marginBottom: "1rem",
  },
  sectionTitle: {
    // paddingRight:theme.spacing(2),
    marginLeft: 4,
    marginBottom: 5,
    fontSize: "16px !important",
    fontWeight: "700 !important",
    textAlign: "left",
    width: 200,
    color: theme.palette.blackColor.main,
  },
  pageTitle: {
    textAlign: "left",
    marginBottom: 20,
    fontSize: "16px !important",
    fontWeight: "700 !important",
    color: theme.palette.blackColor.main,
  },
  planName: {
    fontSize: "16px",
    fontWeight: "500px"
  },
  bottonBtn: {
    display: "flex !important",
    flexDirection: "column !important",
    position: "absolute",
    bottom: "5px",
    right: "5px"
  },
  button: {
    alignSelf: "end",
    background: "#137DFB !important"
  },
  pitchDeckPaper: {
    position: 'absolute',
    width: 450,
    height: 445,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 3, 2),
    display: "flex",
    justifyContent: "center",
  },
  infoIcon: {
    // color:"#137DFB !important",
    // margin:"2px"
    position: "relative",
    // top: "55px",
    // left: "30%",
    width: "60px",
    color:"#137DFB !important",
    height:"30px"
  },
  buttonBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: 'center',
    paddingLeft: "16px",
    // paddingRight:"16px",


  },

  flowxContainer: {
    backgroundSize: 'cover', // Makes the background cover the entire container
    backgroundRepeat: 'no-repeat', // Prevents repetition of the image
    backgroundPosition: 'center', // Centers the image within the container
    color: 'white',
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
    height: '45vh',
    overflow: 'auto'

  },
  flowxTitle: {
    fontSize: '38px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color:'lightskyblue',
    textAlign:'start'
  },
  flowxSubtitle: {
    fontSize: '20px',
    fontWeight: '400',
    marginBottom: '18px',
    color:"white"
  },
  flowxDescription: {
    fontSize: '13px',
    fontWeight: '300',
    marginBottom: '20px',
    color:'lightskyblue',
  },
  flowxFeature: {
    marginBottom: '30px',
  },
  featureTitle: {
    fontSize: '13px',
    fontWeight: '500',
    marginBottom: '10px',
    color:'lightskyblue',
  },
  featureDescription: {
    fontSize: '13px',
    fontWeight: '300',
    color:'lightskyblue',
  },
  highlight: {
    fontWeight: '500',
    color: '#a8d5ff',
  },


}));
import React from "react";
import {
  makeStyles,
  Theme,
  Grid,
  Typography,
} from "@material-ui/core";
import { Project } from "../../../../model";

interface Props {
  project: Project;
}

const useStyles = makeStyles((theme: Theme) => ({
    score: {
        fontSize: "20px",
        fontWeight: "bold",
        fontStyle: "normal",
        color: theme.palette.blackColor.main,
      },
    label: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: theme.palette.blackColor.main,
    },
    field: {
        textAlign:"center",
    }
   
}));

function Details(props: Props) {
  const { project } = props;
  const classes = useStyles();
  
  return (
        <Grid container>
            <Grid item xs={3} className={classes.field}>
                <Typography className={classes.score} >{(project.sectionStats.overall.completed)}</Typography>
                <Typography className={classes.label} >i3D Expert</Typography>
            </Grid>
            <Grid item xs={4} className={classes.field}>
                <Typography className={classes.score} >{(project.sectionStats?project.sectionStats.i3DRapid?project.sectionStats.i3DRapid.completed:0:0)}</Typography>
                <Typography className={classes.label} >i3D Rapid</Typography>
            </Grid>
            <Grid item xs={2} className={classes.field}>
                <Typography className={classes.score} >{project.likesCount}</Typography>
                <Typography className={classes.label} >Likes</Typography>
            </Grid>
            <Grid item xs={3} className={classes.field}>
                <Typography className={classes.score} >{project.sectionStats.overall.numComments}</Typography>
                <Typography className={classes.label} >Insights</Typography>
            </Grid>
        </Grid> 
       
      );
}

export default Details;

import React, { Dispatch } from "react";
import { useDispatch } from "react-redux";
import useStyles from "./styles";
import FadeIn from "react-fade-in";
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import { Paper } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddTeamsDialog from "../../../../components/Modals/AddTeamsDialog";
import {Button} from "@material-ui/core";
import { LayoutAction } from "../../../../model";
import DeleteIcon from '@material-ui/icons/Delete';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: "600",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(name, role, experience, education, Ptorft) {
    return { name, role, experience, education, Ptorft };
}

const rows = [
    createData('Michael Doyle', "Founder CEO", "Experience as CEO", "	Masters Level & Above", "Full time"),
    createData('Hemanth Eagala', "Technical", "	Product Manager", "Bachelor Degree", "Part time"),
    createData('Patrick Doyle', "Research", "Experience as CEO", "Masters Level & Above", "Part time"),
];

export default function (props) {
    const classes = useStyles();
    var [addOpen, setAddOpen] = React.useState<boolean>(false);
    const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
    function deleteTeam(index)
    {
        var data=props.user;
        data.splice(index,1);
        props.setUser([...data]);
    }
    return (
        <FadeIn>
            
            <AddTeamsDialog addOpen={addOpen} setAddOpen={setAddOpen} user={props.user} setUser={props.setUser} />
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="left">Role</StyledTableCell>
                            <StyledTableCell align="left">Experience</StyledTableCell>
                            <StyledTableCell align="left">Education</StyledTableCell>
                            <StyledTableCell align="left">FT or PT</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {props.user.map((row,index) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row.Role}</StyledTableCell>
                                <StyledTableCell align="left">{row.Experience}</StyledTableCell>
                                <StyledTableCell align="left">{row.Education}</StyledTableCell>
                                <StyledTableCell align="left">{row.time}</StyledTableCell>
                                <StyledTableCell align="left"><DeleteIcon color="inherit" onClick={()=>deleteTeam(index)}/></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.addButton}>
                <Button className={classes.addMember} variant="contained"
                disabled={props.user.length>=6}
                    onClick={(e) => {
                        setAddOpen(true);
                    }}
                >Add Team Member</Button>
            </div>
        </FadeIn >

    );
}
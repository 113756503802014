import { Select as AntDSelect } from "antd";
import { Field, FieldProps } from "formik";
import * as React from "react";
import { SelectProps } from "antd/lib/select";

export interface FormikFieldProps {
  name: string;
  validate?: (value: any) => undefined | string | Promise<any>;
  fast?: boolean;
}

export function Select<T>({
  name,
  validate,
  fast,
  children,
  onChange,
  onBlur,
  ...restProps
}: FormikFieldProps & SelectProps<T> & { children?: React.ReactNode }) {
  let ref: AntDSelect | null;
  return (
    <Field name={name} validate={validate} fast={fast}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <AntDSelect<any>
          ref={(select) => (ref = select)}
          onChange={(value, option) => {
            setFieldValue(name, value);
            onChange && onChange(value, option);
            ref?.blur();
          }}
          onBlur={(value) => {
            setFieldTouched(name);
            onBlur && onBlur(value);
          }}
          // setting undefined will show the placeholder
          value={value === "" || value === null ? undefined : value}
          {...restProps}
        >
          {children}
        </AntDSelect>
      )}
    </Field>
  );
}

Select.Option = AntDSelect.Option;
Select.OptGroup = AntDSelect.OptGroup;

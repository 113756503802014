import React, { useState, useRef, useEffect } from "react";
import { ethers, Wallet } from "ethers";
import { useHistory } from "react-router-dom";
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
import { Button, Card,CardContent, DialogContent,CardHeader } from "@material-ui/core";
// import { Auth } from '../repositories/auth';
import { useDispatch, useSelector } from "react-redux";
import { UserDispatch } from "../model";
import { signOutUser } from "../actions/user";
import { setWalletOpen } from "../actions/metamask";
import MetaMaskOnboarding from '@metamask/onboarding';
// import { auth } from "firebase";
// import axios from 'axios';
import walletIcon from "../images/metamask.png";
import Web3 from 'web3';
import {ABI} from "./create/components/data";
// import MutationObserver from 'react-mutation-observer';
// import { WatchForRemoval } from 'react-mutation-observer';
import { RootState } from "../../reducers";

declare var window: any
const networks = {
  // polygon mainnet network
  polygon: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: [
      'https://polygon-rpc.com/',
      'https://rpc.ankr.com/polygon',
      'https://rpc-mainnet.matic.network'
    ],
    blockExplorerUrls: ["https://polygonscan.com/"]
  }
  //polygon mumbai testnet network
  // mumbai: {
  //   chainId: `0x${Number(80001).toString(16)}`,
  //   chainName: "Polygon Mumbai Testnet",
  //   nativeCurrency: {
  //     name: "MATIC",
  //     symbol: "MATIC",
  //     decimals: 18
  //   },
  //   rpcUrls: [
  //     'https://rpc-mumbai.maticvigil.com/',
  //     'https://matic-mumbai.chainstacklabs.com',
  //     'https://matic-testnet-archive-rpc.bwarelabs.com'
  // ],
  //   blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
  // }
};
// console.debug(networks.polygon.rpcUrls,"url")
const currentUrl = new URL(window.location.href)
const forwarderOrigin = currentUrl.hostname === 'localhost'
  ? 'http://localhost:9010'
  : undefined

let onboarding
try {
  onboarding = new MetaMaskOnboarding({ forwarderOrigin })
} catch (error) {
  console.error(error)
}
const networkChanged = (chainId) => {
  console.log({ chainId });
};
// const Web3 = require("web3") // for nodejs only

//for polygon mainnet rpc
const web3 = new Web3("https://polygon-rpc.com")

//for polygon testnet mumbai rpc
// const web3 = new Web3("https://rpc-mumbai.maticvigil.com/")

const init = async(selectedAddress) => {
  // console.debug(selectedAddress,"selectedAddress")
  //polygon mainnet contract address
  const addr = "0x946657d87d311bb15512ad8cf23712640f691d50";
  // const addr = "0xb7B0ab7D4235C860d8C10e281684fd584bB09b2E";
  //polygon testnet contract address
  //  const addr = "0x243CbE8938e3e10168981a27DcF971c3F93AEA69";
  // console.debug(ABI,"abi")
  const ContractInstance = new web3.eth.Contract(ABI, addr);
  const num = await ContractInstance.methods
      .balanceOf(
      // "0x82E9b5AAb2bF16D81a3A2ba445cF5Fe9483ebBb9"
      selectedAddress
      ).call();
      return num;
  };
const connect = async ({ networkName, setError }) => {
  try {
    if (!window.ethereum) {
      // onboarding.startOnboarding()
      throw new Error("Crypto wallet not found, Please install it.");
    }
    else {
      onboarding.stopOnboarding()
    }
    // handleNetworkSwitch("polygon");
    // console.debug(networks[networkName])
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...networks[networkName]
        }
      ]
    });
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts"
    });
    const selectedAddress = window.ethereum.selectedAddress || "";
    const num = await init(selectedAddress);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(selectedAddress);
    const balanceEth = ethers.utils.formatEther(balance);
    // window.ethereum.on('accountsChanged', function (accounts) {
    //   // Time to reload your interface with accounts[0]!
    //   if (selectedAddress == '' || selectedAddress == '0')
    //     window.location.reload(true);
    //   // console.debug("reload")
    //   else {
    //     window.location.reload(false);
    //   }
    // });
    return {
      selectedAddress,
      balance: balanceEth,
      num
    };
  } catch (e) {
    const message = e.message;
    console.log(message);
    setError(message);
  }
};

function ErrorMessage({ message }) {
  if (!message) return null;

  return (
    <div className="alert alert-error">
      <div className="flex-1">
        <label>{message}</label>
      </div>
    </div>
  );
}
export default function ConnectWallet(props) {
  const resultBox = useRef();
  const [assetcounter, setAssetCounter] = useState(<></>);
  const [error, setError] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [balance, setBalance] = useState('');
  const dispatchUser = useDispatch<UserDispatch>();
  const dispatch = useDispatch();
  const [install, setInstall] = useState(false);
  const [counterText, setCounterText] = useState(<></>);
  const walletOpen = useSelector((state: RootState) => state.metamask.walletOpen);
  // const handleNetworkSwitch = async (networkName) => {
  //   setError();
  //   await connect({ networkName, setError });
  //   props.setWalletOpen(false);
  // };
  function redirectLink(){
    window.open("https://angel.i3d.ai");
    }
  useEffect(() => {
    if (!window.ethereum) {
      setInstall(true); 
    }
  }, [])

  useEffect(() => {
    // props.setWalletOpen(props.isAuthenticated);
    var connectedAddress = localStorage.getItem('selectedAddress')?localStorage.getItem('selectedAddress'):''
    if (window.ethereum) {
      // if(props.isAuthenticated){
        if(connectedAddress==window.ethereum?.selectedAddress)
        {
          init(window.ethereum?.selectedAddress).then((res) =>{
            if(res<=0){
              setAssetCounter(
                <div className="mt-2">
                  <span> NFT not found. Please Import or Mint at https://angel.i3d.ai </span>
                  <div>
                  <button className="btn btn-danger mt-2" onClick={()=>{redirectLink()}}>
                    Mint
                  </button>
                  </div>
                </div>)
            }
            
            else{
              if(window.ethereum.networkVersion!='137'){
                window.ethereum.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      ...networks["polygon"] //for polygon mainnet
                      // ...networks["mumbai"] //for polygon testnet mumbai                  
                    }
                  ]
                }).then((res)=>{
                  dispatch(setWalletOpen(false));
                  history.push(props.location.state?.from.pathname)
                  // history.push('/app')
                });
              }
              else
              {
                dispatch(setWalletOpen(false));
                history.push(props.location.state?.from.pathname)
              }
            }
          })
          // console.debug('selectedAddress');
        }
      }
    // }
    // else {
    //   const response = axios.get('https://us-central1-invluencer-test.cloudfunctions.net/getWalletConnectStatus/'+window.ethereum.selectedAddress)
    // .then((res)=>{
    //   if(res.data.status)
    //   {
    //     props.setWalletOpen(false);
    //   }
    //   else{
    //     Auth.storeWalletAddress(window.ethereum.selectedAddress);
    //   }
    // })
    // }
  }, [])
  const history = useHistory();
  const handleConnect = async (e, networkName) => {
    e.preventDefault();
    setError(); // clear error display
    const connection =     await connect({ networkName, setError });
    localStorage.setItem('selectedAddress', connection?.selectedAddress);
    if(connection?.num <= 0){
      setAssetCounter(
      <div className="mt-2">
        <span> NFT not found connect with i3dangel to mint or import to your wallet </span>
        <div>
        <button className="btn btn-danger mt-2" onClick={()=>{redirectLink()}}>
          Mint
        </button>
        </div>
      </div>)
    }
    setSelectedAddress(connection?.selectedAddress || "");
    setBalance(connection?.balance || '0');
    // console.debug(connection?.num);
    if (connection && connection?.num>0) {
      // await Auth.storeWalletAddress(connection?.selectedAddress);
      dispatch(setWalletOpen(false));
                // history.push(props.location.state?.from.pathname)
                history.push("/app")
    }
  };
  const startCounter = () => {
    var count = 30;
    var counter = setInterval(onboardinghandler, 1000); //1000 will  run it every 1 second
    function onboardinghandler() {
      count = count - 1;
      if (count == 0) {
        clearInterval(counter);
        window.location.reload(true);
      }
      setCounterText(
        <div className="mt-3">
          <span> Refresh after install or wait for {count} seconds.</span>
        </div>
      );
    }
  }
  // let border = {
  //   border: '2px',
  // }
  return (
    // <Dialog open={props.walletOpen} fullWidth={true} maxWidth={'xs'} style={border} className="">
    //   <DialogTitle className="text-center " >Connect to Wallet</DialogTitle>
    <div className="row align-items-center m-0 vh-100">
    <Card className="w-25  shadow m-auto">
    <CardContent>
      <form className="" onSubmit={(e) => handleConnect(e,"polygon")}>
      {/* <form className="" onSubmit={(e) => handleConnect(e,"mumbai")}>       */}
        {/* <div className="credit-card w-full shadow-lg mx-auto rounded-xl bg-white"> */}
        {/* <div className="border-bottom"> */}
        <CardHeader  className="text-center border-bottom  border-3" titleTypographyProps={{variant:'h4' }} title="Connect to Wallet"/>
    
        {/* <h5 className="text-center fw-bold">Connect to Wallet</h5> */}
        {/* </div> */}
        {/* </CardHeader> */}
        <div className="credit-card w-full shadow-xs mx-auto rounded-xl bg-white ">

          <footer className="p-4 text-center">
            
            <button
              type="submit"
              className="btn btn-primary"
            >
              Connect
            </button>

            {install ?
              <div className="mt-3">
                <button className="btn btn-warning" onClick={() => { onboarding.startOnboarding(); startCounter() }}>
                  Install Metamask
                </button>
                <div >
                <img src={walletIcon} alt="metamask_icon" className="mt-2" style={{height:"46px" ,width:"50px"}}/>
                </div>
              </div>
              : <></>}
            {counterText}
            {assetcounter}
            <ErrorMessage message={error} />
          </footer>
          <div className="px-5 text-center">
            <div className="my-3">
              <p  ><span className="fw-bold">Balance :</span>{balance}</p>
              <p  ><span className="fw-bold">Address :</span>{selectedAddress}</p>
            </div>
          </div>
          <div className="text-center pb-3">
            <button className="btn btn-outline-primary"
              // startIcon={<ExitToAppIcon />}
              onClick={() => dispatchUser(signOutUser())} >
              Sign Out
            </button>
          </div>
        
        
        </div>

        
      </form>
      </CardContent>
      </Card>
      </div>
    // </Dialog>
    
  );
}
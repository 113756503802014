import { UserActions, UserThunk, UserAction } from "../model";
import { Auth } from "../repositories/auth";
import { Paywall } from "../repositories/payment";
import { History, LocationState } from "history";

export const initializeUser = (user: firebase.User | null): UserAction => {
  return {
    type: UserActions.AUTH_SUCCESS,
    payload: user ?? undefined,
  };
};

export const loginUser = (email: string, password: string,from,history:History<LocationState>): UserThunk => {
  return async (dispatch) => {
    dispatch({ type: UserActions.AUTH_LOADING });
    const auth_result = await Auth.signInWithEmail(email, password);
    if (auth_result instanceof Error)
      dispatch({ type: UserActions.AUTH_FAILED, payload: auth_result });
    else{
     dispatch({ type: UserActions.AUTH_SUCCESS, payload: auth_result });
      history.push(from);
      // history.push('/connect');
    }
  };
};

export const loginUserWithGoogle = (): UserThunk => {
  return async (dispatch) => {
    dispatch({ type: UserActions.AUTH_LOADING });
    const auth_result = await Auth.signInWithGoogle();
    if (auth_result instanceof Error)
      dispatch({ type: UserActions.AUTH_FAILED, payload: auth_result });
    else dispatch({ type: UserActions.AUTH_SUCCESS, payload: auth_result });
  };
};

export const registerUser = (
  name: string,
  lastName: string,
  email: string,
  sector: string,
  password: string,
  plan: string | null,
  history: History<LocationState>,
  from,
): UserThunk => {
  return async (dispatch) => {
    dispatch({ type: UserActions.AUTH_LOADING });
    const auth_result = await Auth.registerWithEmail(name, lastName, email, password,sector);
    if (auth_result instanceof Error)
      dispatch({ type: UserActions.AUTH_FAILED, payload: auth_result });
    else {
      if (plan) {
        dispatch({ type: UserActions.START_PAYMENT });
        const uid = auth_result.uid;
        const err = await Paywall.goToCheckout(uid, plan);
        if (err) dispatch({ type: UserActions.ERROR, payload: err });
        // dispatch({ type: UserActions.REDIRECTED });
      }
      dispatch({ type: UserActions.AUTH_SUCCESS, payload: auth_result });
      history.push(from?from:"/");
    }

  };
};

export const signOutUser = (): UserThunk => {
  return async (dispatch) => {
    dispatch({ type: UserActions.AUTH_LOADING });
    const error: void | Error = await Auth.signOut();
    if (!!error) dispatch({ type: UserActions.AUTH_FAILED, payload: error });
    else{ dispatch({ type: UserActions.AUTH_SUCCESS });
    localStorage.clear();
  }
  };
};

export function redirectToPlan(plan: string): UserThunk {
  return async (dispatch, getState) => {
    dispatch({ type: UserActions.START_PAYMENT });
    const uid = getState().firebase.auth.uid;
    const err = await Paywall.goToCheckout(uid, plan);
    if (err) dispatch({ type: UserActions.ERROR, payload: err });
    // dispatch({ type: UserActions.REDIRECTED });
  };
}

export function redirectToCustomerPortal(): UserThunk {
  return async (dispatch) => {
    dispatch({ type: UserActions.START_PAYMENT });
    const err = await Paywall.goToCustomerPortal();
    if (err) dispatch({ type: UserActions.ERROR, payload: err });
  };
}

import React, { useEffect } from "react";
import { PDFObject } from "react-pdfobject";
import useStyles from "./styles";
import { Button, ButtonGroup, Grid, IconButton, TextareaAutosize, Theme, Tooltip, Typography, withStyles } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as constants from "../../utils/constants";
import { Home as CloseIcon } from "@material-ui/icons";
import { Invitation, ProjectDispatch } from "../../model";

import * as typeformEmbed from "@typeform/embed";
import { useDispatch } from "react-redux";
import { incrementProjectViews } from "../../actions/project";
import { grey } from "@material-ui/core/colors";


const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      width:50+"%",
    },
  }))(Tooltip);
  
 
function Form() {
    const classes = useStyles();
    const dark = grey[900];
    return (
        <div className={classes.root}>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        
       
       
        <ol  style={{width:100+"%"}}>
                <li className={classes.questions} style={{marginTop: "10%"}} >
                    
                   <Typography className={classes.questions}> Product market fit has been determined. 
                  
                     <LightTooltip title={constants.expertScoreTooltip} placement="right-start">
                     <sup><InfoOutlinedIcon style={{ fontSize: 15,  }}  color="disabled" /></sup>
                   </LightTooltip>
                   </Typography>
                   <ButtonGroup aria-label="outlined dark button group" className={classes.rating}>
                    <Button>N/A</Button>
                    <Button>1</Button>
                    <Button>2</Button>
                    <Button>3</Button>
                    <Button>4</Button>
                    <Button>5</Button>
                    <Button>6</Button>
                    <Button>7</Button>
                    <Button>8</Button>
                    <Button>9</Button>
                    </ButtonGroup>
                    <Typography className={classes.comment}> Insights 
                  
                    
                   </Typography>
                    <TextareaAutosize className={classes.commentBoxExpert} aria-label="maximum height" rowsMin={4} placeholder="" />
                </li>
                
                <li className={classes.questions} >
                    
                    <Typography className={classes.questions}> Product market fit has been determined. 
                   
                      <LightTooltip title={constants.expertScoreTooltip} placement="right-start">
                      <sup><InfoOutlinedIcon style={{ fontSize: 15,  }}  color="disabled" /></sup>
                    </LightTooltip>
                    </Typography>
                    <ButtonGroup aria-label="outlined dark button group" className={classes.rating}>
                     <Button>N/A</Button>
                     <Button>1</Button>
                     <Button>2</Button>
                     <Button>3</Button>
                     <Button>4</Button>
                     <Button>5</Button>
                     <Button>6</Button>
                     <Button>7</Button>
                     <Button>8</Button>
                     <Button>9</Button>
                     </ButtonGroup>
                     <Typography className={classes.comment}> Insights 
                   
                     
                    </Typography>
                     <TextareaAutosize className={classes.commentBoxExpert} aria-label="maximum height" rowsMin={4} placeholder="" />
                 </li>
                 <li className={classes.questions} >
                    
                    <Typography className={classes.questions}> Product market fit has been determined. 
                   
                      <LightTooltip title={constants.expertScoreTooltip} placement="right-start">
                      <sup><InfoOutlinedIcon style={{ fontSize: 15,  }}  color="disabled" /></sup>
                    </LightTooltip>
                    </Typography>
                    <ButtonGroup aria-label="outlined dark button group" className={classes.rating}>
                     <Button>N/A</Button>
                     <Button>1</Button>
                     <Button>2</Button>
                     <Button>3</Button>
                     <Button>4</Button>
                     <Button>5</Button>
                     <Button>6</Button>
                     <Button>7</Button>
                     <Button>8</Button>
                     <Button>9</Button>
                     </ButtonGroup>
                     <Typography className={classes.comment}> Insights 
                   
                     
                    </Typography>
                     <TextareaAutosize className={classes.commentBoxExpert} aria-label="maximum height" rowsMin={4} placeholder="" />
                 </li>
                 <li className={classes.questions} >
                    
                    <Typography className={classes.questions}> Product market fit has been determined. 
                   
                      <LightTooltip title={constants.expertScoreTooltip} placement="right-start">
                      <sup><InfoOutlinedIcon style={{ fontSize: 15,  }}  color="disabled" /></sup>
                    </LightTooltip>
                    </Typography>
                    <ButtonGroup aria-label="outlined dark button group" className={classes.rating}>
                     <Button>N/A</Button>
                     <Button>1</Button>
                     <Button>2</Button>
                     <Button>3</Button>
                     <Button>4</Button>
                     <Button>5</Button>
                     <Button>6</Button>
                     <Button>7</Button>
                     <Button>8</Button>
                     <Button>9</Button>
                     </ButtonGroup>
                     <Typography className={classes.comment}> Insights 
                   
                     
                    </Typography>
                     <TextareaAutosize className={classes.commentBoxExpert} aria-label="maximum height" rowsMin={4} placeholder="" />
                 </li>
            </ol>
           
        
        
        
        </Grid>
      
        
        <Grid item xs={12} sm={12} md={6} lg={6}>
        
       
        <img src="/image53.png" className={classes.companyLogo}/>
        
        
        </Grid>
        </Grid>
        </div>
    )
}

export default Form;

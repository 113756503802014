import React from "react";
import {
  makeStyles,
  Theme,
  LinearProgress,
  withStyles,
  createStyles,
//   Box,
//   Typography,
} from "@material-ui/core";

// interface Props {
//   section: Project;
// }

const useStyles = makeStyles((theme: Theme) => ({
  
}));

function ProgressBar(props) {
  // console.debug("ProgressBar", props)
const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 9.47,
      borderRadius: 13.617,
    },
    colorPrimary: {
      backgroundColor: props.colors? props.colors.backgroundColor: "",
    },
    bar: {
      borderRadius: 10.7766,
      backgroundColor: props.colors? props.colors.color:"",
    },
  }),
)(LinearProgress);
  return ( 
      <div>
          <BorderLinearProgress variant="determinate"  value={props.value}/>
      </div>
  );
    
}

export default ProgressBar;

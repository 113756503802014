import React from "react";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Field } from 'formik';
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import { Editor } from '@tinymce/tinymce-react';
import { Grid,  } from "@material-ui/core";
import { info } from "../infos";
import CustomiseTooltip from "../../../../components/Tooltip/CustomiseTooltip";
export default function () {
  const classes = useStyles();
  const formik = useFormikContext<FormInfo>();
  const removeHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ");
  }
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h4" className={classes.sectionTitle}>
            {'Elevator Pitch for Rapid'}
            <CustomiseTooltip className={classes.infoIcon} title={info.elevatorPitch} />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{'(max 2000 characters)'}</Typography>
        </Grid>
      </Grid>
      {removeHtmlTags(formik.values.rapidDescription).length > 2000 &&
        <Typography className={classes.error}>
          No. of characters exceeded 2000
        </Typography>
      }
      <Field name='rapidDescription' className={classes.companyDescription + " " + classes.companyDes}>
        {({ field, meta }) => (
          <div>
            <Editor
               id='article_body'
             apiKey='r7ybttnlquoxnwu4golhbicp9pm9wg9b1jupd5i3e0ldhzne'
             init={{
              plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'emoticons'],
              toolbar:'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help | emoticons ',
              menubar: true,
              statusbar: true,
          
            }}
              onEditorChange={(e) => {
                formik.handleChange({ target: { name: 'rapidDescription', value: e } })
              }}
              textareaName='rapidDescription'
              // onChange={field.onChange}
              
              onBlur={field.onBlur}
              value={formik.values.rapidDescription}
            />
          </div>
        )}
      </Field>
    </div>
  );
}
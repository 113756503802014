import React, { useEffect, useState } from "react";
import { Checkbox } from "formik-antd";
import { Checkbox as ChoiceCheckbox } from "antd";
import { Link } from "@material-ui/core";
import { useFormikContext} from "formik";
import { FormInfo } from "../../../../model";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import { Select } from "../../../../components/Wrappers/FormikWrappers";
import { sectionOptions } from "../data";
import useStyles from "./styles";
import FadeIn from "react-fade-in";
import { email2ObjectKey } from "../../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import { fetchSuggestions } from "../../../../utils/localstorage";

const { Option } = Select;

function Share() {
  const classes = useStyles();
  const { values, errors, touched, setFieldValue } = useFormikContext<
    FormInfo
  >();
  const [emailSelectFlag, setEmailSelectFlag]= useState(false)
  const suggestions = fetchSuggestions();
  const formik = useFormikContext<FormInfo>();
  const user = useSelector((state: RootState) => state.firebase.auth);

  function _sendToSelf(add: boolean): void {
    console.debug("fetchSuggestions:values", formik.values.sendProjectToMe) 
    console.debug("fetchSuggestions:add", add)
    // if (formik.values.sendProjectToMe == true && add == true) {
    //   setFieldValue('sendProjectToMe', true)
    //   setFieldValue("emails", [...values.emails, user.email], true);
    //   setFieldValue(
    //     `emailSections.${email2ObjectKey(user.email!)}`,
    //     values.sections,
    //     true
    //   );
    // }
    
    if (formik.values.sendProjectToMe == false && add == true) {
      setFieldValue('sendProjectToMe', true)
      setFieldValue("emails", [...values.emails, user.email], true);
      // setFieldValue(
      //   `emailSections.${email2ObjectKey(user.email!)}`,
      //   values.sections,
      //   true
      // );
    } else {
      setFieldValue('sendProjectToMe', false)
      setFieldValue(
        "emails",
        values.emails.filter((v) => v !== user.email),
        true
      );
      // setFieldValue(`emailSections.${email2ObjectKey(user.email!)}`, [], true);
    }
  }


  function _selectAllSections(add: boolean, email): void {
    if (add) {
      // const _sections = sectionOptions.filter((v) => values.sections.includes(v.value)).map((v) => v.value);
      const _sections = sectionOptions.filter((s) => s.value !='pitchDeck').map((v) => v.value);
      setEmailSelectFlag(true)
      
      setFieldValue(`emailSections.${email2ObjectKey(email)}`, _sections, true);
    } else {
      setFieldValue(`emailSections.${email2ObjectKey(email)}`, [], true);
      setEmailSelectFlag(false)
    }
  }

  function _removeSelectSections(e){
    setEmailSelectFlag(false)
  }

  const isAllSectionsSelected = (email) => {
    const emailKey = email2ObjectKey(email);
    const selectedSections = values.emailSections[emailKey];
    return selectedSections && selectedSections.length === sectionOptions.length - 1;
  };
  return (
    <div className={classes.root}>
      <FadeIn>
        <div className={classes.cardContainer}>
          <Typography variant="h4" className={classes.spacing}>
            Select emails to share the project with
          </Typography>
          {touched.emails && errors.emails && (
            <Typography color="secondary" brightness="dark">
              {errors.emails}
            </Typography>
          )}
          <ChoiceCheckbox checked={formik.values.sendProjectToMe} onChange={(e) => _sendToSelf(e.target.checked)}>
            <Typography variant="caption">Send me the project</Typography>
          </ChoiceCheckbox>
          <Select
            name="emails"
            mode="tags"
            allowClear
            autoClearSearchValue
            maxTagCount={10}
            style={{ width: "100%" }}
            placeholder="Separate emails by , e.g. a@b.com,b@c.com"
            className={classes.select}
            tokenSeparators={[",", ";"]}
          >
            {suggestions.map((suggestion, index) => (
              <Option
                key={index}
                value={suggestion.value}
                title={suggestion.text}
              >
                {suggestion.text}
              </Option>
            ))}
          </Select>
          <div>
          {values.emails.map((email, index) => {
        const emailKey = email2ObjectKey(email);
        const allSectionsSelected = isAllSectionsSelected(email);
        
        return (
          email && (
            <div key={email}>
              <Checkbox
                checked={allSectionsSelected}
                onChange={(e) => _selectAllSections(e.target.checked, email)}
              >
                <Typography variant="body" weight="bold">
                  Select all sections
                </Typography>
              </Checkbox>
              <Typography gutterBottom>{email}</Typography>
                <Select
                  name={`emailSections.${emailKey}`}
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select the sections on which you wish to perform DD"
                  defaultValue={[]}
                >
                  {sectionOptions
                    .filter((s) => s.value !== 'pitchDeck')
                    .map((section, idx) => (
                      <Option key={idx} value={section.value}>
                        {section.label}
                      </Option>
                    ))}
                </Select>
        
            </div>
          )
        );
      })}
            {/* {Array.from(values.emails).map(
              (email: string, index) =>
                email && (
                  <div key={index}>
                    <ChoiceCheckbox
                    checked={values.emailSections.filter((d,i)=> i == index) ? true:false}
                    // checked={emailSelectFlag}
                      onChange={(e) => _selectAllSections(e.target.checked, email, index)}
                    >
                      <Typography variant="body" weight="bold">
                        Select all sections
                      </Typography>
                    </ChoiceCheckbox>
                    <Typography gutterBottom>{email}</Typography>
                    <Select
                      name={`emailSections.${email2ObjectKey(email)}`}
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Please select the sections on which you wish to perform DD"
                      defaultValue={[]}
                      onChange={(e) => _removeSelectSections(e)}
                    >
                      {sectionOptions
                        .filter((s) => s.value !='pitchDeck')
                        .map((section, index) => (
                          <Option key={index} value={section.value}>
                            {section.label}
                          </Option>
                        ))}
                    </Select>
                  </div>
                )
            )} */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 40,
            }}
          >
            {touched.legal && errors.legal && (
              <Typography color="secondary" brightness="dark">
                {errors.legal}
              </Typography>
            )}
            <Checkbox name="legal">
              <Typography variant="caption">
                Requestors/Experts must declare any conflicts of interest in
                carrying project creation or due diligence services. This Conflict
                of Interest is required to confirm that you as an Expert or
                Requestor are not conflicted, for example, you are not already
                invested in the Company, are not employed by the Company, and do
                not have any vested interest in the Company that might influence
                judgement during this due diligence analysis. The Requestor hereby
                declares they have necessary permissions to upload the relevant
                information to the i3D Platform to allow the Expert Network to
                complete the due diligence and that shall not in any way influence
                the outcome of the scoring and aggregation processes.{" "}
                <Link
                  href="https://www.investopedia.com/terms/c/conflict-of-interest.asp"
                  target="_blank"
                >
                  Read more
                </Link>
              </Typography>
            </Checkbox>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}

export default Share;

import React from "react";

import { Typography } from "../../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Select, Input, AutoComplete } from "formik-antd";
import FadeIn from "react-fade-in";
import { useFormikContext } from "formik";
import { BusinessInfo } from "../../../../model";

const { Option } = Select;

export default function () {
    const classes = useStyles();
    const formik = useFormikContext<BusinessInfo>();

    const Social = [
        { id: 1, value: "Company raised fund first year" }, { id: 2, value: "Company raised fund second year" }, { id: 2, value: "Company raised fund third year" }
    ]
    const sizeMarket = [
        { id: 1, value: "Small (less then 100k)" }, { id: 2, value: "Medium(100k-250k)" }, { id: 3, value: "large (250k plus)" }
    ]
    const Industry = [
        { id: 1, value: "Low (funding will last 12 months or more)" }, { id: 2, value: "Medium (funding will last 12 months or more)" }, { id: 3, value: "High (funding will last 12 months or more)" }
    ]

    const marketRisk = [
        { id: 1, value: "Angel or existing investor round" }, { id: 2, value: "Require venture capital" }, { id: 3, value: "Have funding from revenues and wantgrowth funds" }
    ]
    return (
        <FadeIn>
            <div className={classes.cardContainer}>
                <Typography variant="h4" className={classes.sectionHead}>
                    Funding Proposition
                </Typography>
                {/* _______________fundRaise_____________ */}
                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            When have funds  from external shareholders been raised?
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No Social & Networking Presence selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {Social.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>

                {/* _________________________ Size of market_______________ */}
                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Size of intended capital raise
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No Size of Market selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {sizeMarket.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>

                {/* ____________________indusstry cagr_______________ */}

                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Burn Rate
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No   Industry CAGR selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {Industry.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>

                {/* ____________ market risk ___________________ */}


                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Funds come from where?
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="Market Risk Perception"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {marketRisk.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        </FadeIn>

    );
}
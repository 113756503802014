import React from "react";

import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Info } from "@material-ui/icons";
function CustomiseTooltip(props) {
	const LightTooltip = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.87)',
			boxShadow: theme.shadows[1],
			fontSize: 15,
			height: 400,
			overflow: "auto"
		},
	}))(Tooltip);
	// const toolTipText = (text) => (
	// 	<div style={{ whiteSpace: "pre" }}>
	// 		{text}
	// 	</div>
	// );
	return (
		<LightTooltip title={<div style={{ width: "100%", whiteSpace: 'pre-line	', textAlign: 'left' }}>{props.title}</div>} placement="right" interactive>
			<Info fontSize="small" className={props.className} />
			{/* <IconButton className={props.className}>
				ⓘ
			</IconButton> */}
		</LightTooltip>
	)
}
export default CustomiseTooltip;

import React, { useEffect, Dispatch, useState } from "react";

import {
  makeStyles,
  withStyles,
  Theme,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography, Paper,
} from "@material-ui/core";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ProjectRepo } from "../../../repositories/project";
import { Scrollbars } from 'react-custom-scrollbars';
import { Project } from "../../../model";
import {
  getSectionLabel,
} from "../../create/components/data";
interface Props {
  project: Project;
  data: Array<Record<string, any>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  sectionInfo: {
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#000000",
    letterSpacing: 0.44,
    paddingLeft: 15 + '%',
  },
  sectionRank: {
    // fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    // color: "#9D9D9D",
    letterSpacing: 0.44,
    backgroundColor: "inherit !important",

  },
  container: {
    padding: 1,
    maxHeight: 700,
    overflow: 'hidden',
    borderRadius: 10,

  },
  rank: {
    textAlign: "center",
  }
}));

function SectionwiseRanking(props: Props) {

  const { project, data } = props;
  //   const [projects,setProjects] = useState([]);

  //   useEffect(() => {
  //    ProjectRepo.listProjects().then((response)=>{
  //        setProjects(response);
  //    });
  // 	}, []);
  const classes = useStyles();
  //   const data = projects.map((element) => ({
  //     name: element.company?element.company.name:"",
  //     rank: ProjectRepo.getSectionRank(element.sectionStats?(element.sectionStats.team?element.sectionStats.team.avgRating:0):0,project.id,"team",projects,element.startDate),
  //     id: element.id,
  //   }));

  const StyledTableCell = withStyles((theme) => ({
    body: {
      padding: 15,
      textAlign: "center",
    },
    head: {
      backgroundColor: "#F2F7FF",
      paddingRight: 62,
    },
  }))(TableCell);
  return (

    <div>

      <TableContainer className={classes.container}>
        <Paper elevation={3} className={classes.container}>
          <Table size="small" >
            <TableHead>
              <TableRow style={{ display: "table-header-group" }}>
                <StyledTableCell className={classes.sectionRank}>Ranking</StyledTableCell>
                <StyledTableCell className={classes.sectionRank}>Name</StyledTableCell>
                <StyledTableCell className={classes.sectionRank}>Spots Moved</StyledTableCell>
              </TableRow>
            </TableHead>

            <Scrollbars style={{ height: 617 }} autoHide>
              <TableBody style={{ height: 100 + '%' }}>
                {data.sort(function (a, b) { return a.rank - b.rank }).map((section, index) => {
                  if (section.id == project.id) {
                    return (
                      <TableRow key={index} style={{ backgroundColor: "#F2F7FF" }}>
                        <StyledTableCell><span className={classes.rank} >{section.rank}</span></StyledTableCell>
                        <StyledTableCell>
                          <Typography className={classes.sectionInfo}>{section.name}</Typography>
                        </StyledTableCell>
                        <StyledTableCell><span className={classes.sectionInfo}>10</span></StyledTableCell>
                        <StyledTableCell><ArrowDropUpIcon style={{ color: "#00B929" }} /></StyledTableCell>
                      </TableRow>
                    );
                  }
                  else {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell><span className={classes.rank}>{section.rank}</span></StyledTableCell>
                        <StyledTableCell>
                          <Typography className={classes.sectionInfo}>{section.name}</Typography>
                        </StyledTableCell>
                        <StyledTableCell><span className={classes.sectionInfo}>10</span></StyledTableCell>
                        <StyledTableCell><ArrowDropUpIcon style={{ color: "#00B929" }} /></StyledTableCell>
                      </TableRow>
                    );
                  }

                })}
              </TableBody>
            </Scrollbars>

          </Table>
        </Paper>

      </TableContainer>

    </div>


  );
}

export default SectionwiseRanking;

import * as Yup from "yup";

export * from "./forgotPassword";
export * from "./resetForgetPassword";

export interface ForgotInfo {
  email: string;
}

export interface PasswordInfo {
  password: string;
}

export interface AuthInfo {
  email: string;
  terms: boolean;
}

export const forgotValidation = Yup.object().shape<AuthInfo>({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Required"),
  terms: Yup.boolean()
    .oneOf([true], "You need to accept the Terms of Service.")
    .required(),

});

export const loginValidation = Yup.object().shape<ForgotInfo>({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Required"),

});

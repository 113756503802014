import * as React from "react";
import { storage } from "../../../../repositories/firebase";
import FileUploader from "react-firebase-file-uploader";
import { useField } from "formik";
import { Theme } from "@material-ui/core";
import { LoadingOutlined } from "@ant-design/icons";
import { Alert} from "antd";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    imageButton: {
      padding: theme.spacing(1),
      borderRadius: 10,
      width: 100+"%",
      height: 20+"%",
      backgroundColor: "#E5E5E5",
      borderColor: "#D3D3D3",
      borderStyle: "solid",
      border: 1,
      transition: "all 0.25s ease",
      "&:hover": {
        cursor: "pointer",
        borderColor: theme.palette.primary.main,
      },
    },
    image: {
      maxWidth: 100,
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

function ImageUploadField() {
  const classes = useStyles();
  const [field, , helpers] = useField<string>("companyImage");
  const [loading, setloading] = React.useState<boolean>(false);
  const [error, seterror] = React.useState<string>("");

  return (
    <>  
      <label className={field.value ? classes.image : classes.imageButton}>
        {field.value ? (
          <img src={field.value} alt="avatar" style={{ width: "100%" }} />
        ) : (
         <div className={classes.label}>
            {loading && <LoadingOutlined />}
            <div className="ant-upload-text">Choose Image</div>
          </div>
        )}
        <FileUploader
          hidden
          accept="image/*"
          randomizeFilename
          storageRef={storage.ref("logos")}
          onUploadStart={() => setloading(true)}
          onUploadError={() => {
            setloading(false);
            seterror("Image could not be loaded.");
          }}
          onUploadSuccess={(file: string) => {
            setloading(false);
            seterror("");
            storage
              .ref("logos")
              .child(file)
              .getDownloadURL()
              .then((url) => {
                helpers.setValue(url);
              });
          }}
        />
      
      </label>
      <div style={{ marginTop: 10 }} />
      {/* <Typography variant="caption">
        Please upload image with 1:1 Aspect Ratio
      </Typography> */}
      {error && <Alert message={error} />}
    </>
  );
}

export { ImageUploadField };

// serviceWorkerRegistration.js
export function register() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch(function (err) {
        console.error('Service Worker registration failed:', err);
      });
  }
}

  
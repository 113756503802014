import React from "react";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Close as CloseIcon, Info } from "@material-ui/icons";
import { Button, Grid,  Modal, Paper} from "@material-ui/core";
import useStyles from "./style";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
function CreatePitchDeckDialog(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const handleClose = () => {
        props.setOpenPitchDeckPopup(false)
        // setOpen(false);
    };

    const handleDownloadSamplePdf = () => {
        const pdfUrl = './Guide to Creating a Project.pdf'; // Replace with the actual URL or file path of your PDF
        const a = document.createElement('a');
        a.href = pdfUrl;
        a.download = 'Guide to Creating a Project.pdf'; // You can specify the name for the downloaded file
        a.style.display = 'none';
        document.body.appendChild(a);
    
        a.click();
    
        document.body.removeChild(a);
      };

    return (

        <Modal
            open={props.open}
            // onClose={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper className={classes.pitchDeckPaper} style={modalStyle}>
                <Grid container
                    direction="column"
                    // justifyContent="center"
                    // alignItems="center"
                    >
                    <div className='d-flex justify-content-center'>
                        <img src="/rocket.png" className={classes.pitchDeckImg} alt="images" />
                        <h5 className={classes.titlePitch}>
                            FAIL TO PLAN, PLAN TO FAIL
                        </h5>
                        <img src="/rocket.png" className={classes.pitchDeckImg} alt="images" />
                    </div>
                    {/* <h5 >Creating a Great Pitch Deck</h5> */}
                    <Typography variant="subtitle1" className="fw-bold mx-auto mb-3" gutterBottom>Creating a Great Pitch Deck</Typography>
                    <Typography variant="caption" className="fw-bold" gutterBottom>Prepare in Advance:</Typography>
                    {/* <span className={classes.pitchDeckText} >Prepare in Advance:</span> */}
                    <ol className="ps-3">
                        <li className={classes.pitchDeckPoint}>  <Typography variant="caption" className="fw-bold"gutterBottom>Read Future-Proofing Start-ups </Typography></li>
                        <li className={classes.pitchDeckPoint}>Check out other Pitch Decks in the Data Rooms of Projects.</li>
                        <li className={classes.pitchDeckPoint}>Toggle through each of the fields to familiarize yourself with the information and layout required - prepare this info offline! Analysts & investors all need to read the same information, once you have created the deck you can't change it</li>
                    </ol>
                    <Typography variant="caption" className="fw-bold" gutterBottom>Once you Start:</Typography>
                    <ol className="ps-3 mb-2">
                        <li className={classes.pitchDeckPoint}>Read the Info Button for each section</li>
                        <li className={classes.pitchDeckPoint}>Be as Clear & Concise as possible when you fill in the required info as much as you can say in a short space! </li>

                        <li className={classes.pitchDeckPoint}>If you have no info for a section - Say So!</li>
                        <li className={classes.pitchDeckPoint}>Make sure your info-images are Ratio 1:1 </li>

                        <li className={classes.pitchDeckPoint}>Review all sections before submitting!</li>
                    </ol> 
                    <div className="justify-content-between d-flex">
                    <Button
                    className={classes.buttonScore}
                    style={{marginLeft:"6rem"}}
                    variant="contained"
                    onClick={handleDownloadSamplePdf}
                    color="primary" endIcon={<Info fontSize="small" color='black' />}>
                    Sample Pitch & Advice
                  </Button>
                    <CloseIcon onClick={handleClose} />
                    </div>
                </Grid>
            </Paper>
        </Modal>
    )
}

export default CreatePitchDeckDialog;

  import moment from "moment";
    export const getParentCollection = async (intitialValues, projectCollection, id, technicalScoringMarket,technicalScoringFund, setyesNoSwitch) => {
      try {
        const parentDoc = await projectCollection.doc(id).get();
        const draftData = parentDoc.data()
        console.debug("draftData", draftData)
        intitialValues.rapidDescription = draftData.company ? draftData.company.rapidDescription : ""
        intitialValues.companyName = draftData.company ? draftData.company.name : ""
        intitialValues.companyDescription = draftData.company ? draftData.company.description : ""
        intitialValues.companyImage = draftData.company ? draftData.company.logo : ""
        intitialValues.companyStage = draftData.company ? draftData.company.stage : ""
        intitialValues.companyLocation = draftData.company ? draftData.company.location : ""
        intitialValues.companySector = draftData.company ? draftData.company.sector : ""
        intitialValues.contactEmail = draftData.company ? draftData.company.contactEmail : ""
        intitialValues.companyWebsite = draftData.company ? draftData.company.website : ""
        intitialValues.dealSource = draftData.company ? draftData.company.investmentLink : ""
        intitialValues.crypto = draftData.crypto ? draftData.crypto : ""
        intitialValues.scoringTypes = draftData.scoringTypes ? draftData.scoringTypes : ""
        intitialValues.privacy = draftData ? draftData.privacy : 0
        if(draftData.startDate && draftData.endDate){
          const startDateMillis = draftData.startDate.seconds * 1000 + draftData.startDate.nanoseconds / 1000000;
          const endDateMillis = draftData.endDate.seconds * 1000 + draftData.endDate.nanoseconds / 1000000;
          const startDate = new Date(startDateMillis);
          const endDate = new Date(endDateMillis);
          const startDateFormatted = startDate.toISOString();
          const endDateFormatted = endDate.toISOString();
          intitialValues.dateRange = [moment(startDateFormatted), moment(endDateFormatted)]
        }
        if(intitialValues.privacy == 2){
          intitialValues.selectedEmails = draftData ? draftData.selectedEmails: []
          }
        intitialValues.needExpertScore = draftData ? draftData.needExpertScore : "No"
        //  Problem Tab Data
        intitialValues.statement = draftData.scoringAssist ? draftData.scoringAssist.statement : ""
        intitialValues.DTP = draftData.scoringAssist ? draftData.scoringAssist.DTP : ""
        intitialValues.marketDynamics = draftData.scoringAssist ? draftData.scoringAssist.marketDynamics : ""
        intitialValues.painPoints = draftData.scoringAssist ? draftData.scoringAssist.painPoints : ""
        if(draftData.imageLinks.problem1){ 
        intitialValues.imageLinks.problem1 = draftData.imageLinks ? draftData.imageLinks.problem1 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        if(draftData.imageLinks.problem2){ 
        intitialValues.imageLinks.problem2 = draftData.imageLinks ? draftData.imageLinks.problem2 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        //  solution Tab Data
        intitialValues.solutionStatement = draftData.scoringAssist ? draftData.scoringAssist.solutionStatement : ""
        intitialValues.useCases = draftData.scoringAssist ? draftData.scoringAssist.useCases : ""
        intitialValues.USPB = draftData.scoringAssist ? draftData.scoringAssist.USPB : ""
        intitialValues.PKF = draftData.scoringAssist ? draftData.scoringAssist.PKF : ""
        if(draftData.imageLinks.solution1){ 
        intitialValues.imageLinks.solution1 = draftData.imageLinks ? draftData.imageLinks.solution1 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        if(draftData.imageLinks.solution2){ 
        intitialValues.imageLinks.solution2 = draftData.imageLinks ? draftData.imageLinks.solution2 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        //  Business Tab Data
        intitialValues.revenueStreams = draftData.scoringAssist ? draftData.scoringAssist.revenueStreams : ""
        intitialValues.costStructure = draftData.scoringAssist ? draftData.scoringAssist.costStructure : ""
        intitialValues.salesChannelsCustomer = draftData.scoringAssist ? draftData.scoringAssist.salesChannelsCustomer : ""
        intitialValues.CAS = draftData.scoringAssist ? draftData.scoringAssist.CAS : ""
        if(draftData.imageLinks.business1){ 
        intitialValues.imageLinks.business1 = draftData.imageLinks ? draftData.imageLinks.business1 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        if(draftData.imageLinks.business2){ 
        intitialValues.imageLinks.business2 = draftData.imageLinks ? draftData.imageLinks.business2 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        //  Market Tab Data
        intitialValues.marketSizeTam = draftData.scoringAssist ? draftData.scoringAssist.marketSizeTam : ""
        intitialValues.MGP = draftData.scoringAssist ? draftData.scoringAssist.MGP : ""
        intitialValues.marketOpportunity = draftData.scoringAssist ? draftData.scoringAssist.marketOpportunity : ""
        intitialValues.marketTrends = draftData.scoringAssist ? draftData.scoringAssist.marketTrends : ""
        if(draftData.imageLinks.market1){ 
        intitialValues.imageLinks.market1 = draftData.imageLinks ? draftData.imageLinks.market1 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        if(draftData.imageLinks.market2){
        intitialValues.imageLinks.market2 = draftData.imageLinks ? draftData.imageLinks.market2 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        //    Go To Market Tab Data
        intitialValues.targetAudience = draftData.scoringAssist ? draftData.scoringAssist.targetAudience : ""
        intitialValues.userProfiles = draftData.scoringAssist ? draftData.scoringAssist.userProfiles : ""
        intitialValues.marketingStrategy = draftData.scoringAssist ? draftData.scoringAssist.marketingStrategy : ""
        intitialValues.salesStrategy = draftData.scoringAssist ? draftData.scoringAssist.salesStrategy : ""
        if(draftData.imageLinks.goto1){ 
        intitialValues.imageLinks.goto1 = draftData.imageLinks ? draftData.imageLinks.goto1 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        if(draftData.imageLinks.goto2){ 
        intitialValues.imageLinks.goto2 = draftData.imageLinks ? draftData.imageLinks.goto2 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        //   Competition Tab Data
        intitialValues.competitiveLandscape = draftData.scoringAssist ? draftData.scoringAssist.competitiveLandscape : ""
        intitialValues.identifyCompetitors = draftData.scoringAssist ? draftData.scoringAssist.identifyCompetitors : ""
        intitialValues.competitiveAdvantage = draftData.scoringAssist ? draftData.scoringAssist.competitiveAdvantage : ""
        intitialValues.MOAT = draftData.scoringAssist ? draftData.scoringAssist.MOAT : ""
        if(draftData.imageLinks.competition1){
        intitialValues.imageLinks.competition1 = draftData.imageLinks ? draftData.imageLinks.competition1 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        if(draftData.imageLinks.competition2){
        intitialValues.imageLinks.competition2 = draftData.imageLinks ? draftData.imageLinks.competition2 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        //   Milestone Tab Data
        intitialValues.MT = draftData.scoringAssist ? draftData.scoringAssist.MT : ""
        intitialValues.marketValidation = draftData.scoringAssist ? draftData.scoringAssist.marketValidation : ""
        intitialValues.futurePlans = draftData.scoringAssist ? draftData.scoringAssist.futurePlans : ""
        intitialValues.strategicPartnerships = draftData.scoringAssist ? draftData.scoringAssist.strategicPartnerships : ""
        if(draftData.imageLinks.milestone1){
        intitialValues.imageLinks.milestone1 = draftData.imageLinks ? draftData.imageLinks.milestone1 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        if(draftData.imageLinks.milestone2){
          intitialValues.imageLinks.milestone2 = draftData.imageLinks ? draftData.imageLinks.milestone2 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        //   Funds Tab Data
        intitialValues.fundingAmount = draftData.scoringAssist ? draftData.scoringAssist.fundingAmount : ""
        intitialValues.useofFunds = draftData.scoringAssist ? draftData.scoringAssist.useofFunds : ""
        intitialValues.valuation = draftData.scoringAssist ? draftData.scoringAssist.valuation : ""
        intitialValues.financialProjections = draftData.scoringAssist ? draftData.scoringAssist.financialProjections : ""
        if(draftData.imageLinks.fund1){
        intitialValues.imageLinks.fund1 = draftData.imageLinks ? draftData.imageLinks.fund1 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        if(draftData.imageLinks.fund2){
        intitialValues.imageLinks.fund2 = draftData.imageLinks ? draftData.imageLinks.fund2 : ""
        }else{
          intitialValues.imageLinks =intitialValues.imageLinks
        }
        //   Tokenomics Tab Data
        intitialValues.tokenUtility = draftData.scoringAssist ? draftData.scoringAssist.tokenUtility : ""
        intitialValues.tokenDistribution = draftData.scoringAssist ? draftData.scoringAssist.tokenDistribution : ""
        intitialValues.tokenSale = draftData.scoringAssist ? draftData.scoringAssist.tokenSale : ""
        intitialValues.incentives = draftData.scoringAssist ? draftData.scoringAssist.incentives : ""
        if(draftData.imageLinks.token1){ 
        intitialValues.imageLinks.token1 = draftData.imageLinks ? draftData.imageLinks.token1 : ""
        }else{
          intitialValues.imageLinks= intitialValues.imageLinks
        }
        if(draftData.imageLinks.token2){ 
        intitialValues.imageLinks.token2 = draftData.imageLinks ? draftData.imageLinks.token2 : ""
        }else{
          intitialValues.imageLinks=intitialValues.imageLinks
        }
        if (draftData.TechnicalAnalysis.marketRisk != undefined) {
          intitialValues.marketRisk = getTechnicalAnanylsisScore(technicalScoringMarket, "market", "marketRisk", draftData.TechnicalAnalysis.marketRisk)
          intitialValues.SalesChannels = getTechnicalAnanylsisScore(technicalScoringMarket, "market","SalesChannels", draftData.TechnicalAnalysis.SalesChannels)
          intitialValues.marketSize = getTechnicalAnanylsisScore(technicalScoringMarket, "market","marketSize", draftData.TechnicalAnalysis.marketSize)
          intitialValues.industryCagr = getTechnicalAnanylsisScore(technicalScoringMarket, "market", "industryCagr", draftData.TechnicalAnalysis.industryCagr)
          intitialValues.CompetitiveLandscape = getTechnicalAnanylsisScore(technicalScoringMarket, "market", "CompetitiveLandscape", draftData.TechnicalAnalysis.CompetitiveLandscape)
          intitialValues.MarketingPartners = getTechnicalAnanylsisScore(technicalScoringMarket, "market", "MarketingPartners", draftData.TechnicalAnalysis.MarketingPartners)
          intitialValues.SocialNetwork = getTechnicalAnanylsisScore(technicalScoringMarket, "market","SocialNetwork", draftData.TechnicalAnalysis.SocialNetwork)
          intitialValues.MAU = getTechnicalAnanylsisScore(technicalScoringMarket,"market", "MAU", draftData.TechnicalAnalysis.MAU)
          intitialValues.TRL_Levels = getTechnicalAnanylsisScore(technicalScoringMarket,"market", "TRL_Levels", draftData.TechnicalAnalysis.TRL_Levels)
          intitialValues.burnRate = getTechnicalAnanylsisScore(technicalScoringFund, "finance", "burnRate", draftData.TechnicalAnalysis.burnRate)
          intitialValues.RevenueStage = getTechnicalAnanylsisScore(technicalScoringFund, "finance", "RevenueStage", draftData.TechnicalAnalysis.RevenueStage)
          intitialValues.fundFromWhere = getTechnicalAnanylsisScore(technicalScoringFund, "finance","fundFromWhere", draftData.TechnicalAnalysis.fundFromWhere)
          intitialValues.ExternalFundYear = getTechnicalAnanylsisScore(technicalScoringFund, "finance","ExternalFundYear", draftData.TechnicalAnalysis.ExternalFundYear)
          intitialValues.CapitalSize = getTechnicalAnanylsisScore(technicalScoringFund,"finance", "CapitalSize", draftData.TechnicalAnalysis.CapitalSize)
        }
        intitialValues.singlePdf =  draftData.singlePdf
        if(draftData.singlePdf == true){ 
        intitialValues.singlePdfLink= draftData.pdfLinks ? draftData.pdfLinks.team :""
        }else{
          if(draftData.pdfLinks.team){ 
            intitialValues.pdfLinks.team = draftData.pdfLinks ? draftData.pdfLinks.team:""
            }else{
              intitialValues.pdfLinks =  intitialValues.pdfLinks 
            }
            if(draftData.pdfLinks.idea){ 
              intitialValues.pdfLinks.idea =  draftData.pdfLinks ? draftData.pdfLinks.idea :""
            }else{
              intitialValues.pdfLinks =  intitialValues.pdfLinks 
            }
            if(draftData.pdfLinks.competition){ 
              intitialValues.pdfLinks.competition =  draftData.pdfLinks ? draftData.pdfLinks.competition :""
            }else{
              intitialValues.pdfLinks =  intitialValues.pdfLinks 
            }
            if(draftData.pdfLinks.esg){ 
              intitialValues.pdfLinks.esg =  draftData.pdfLinks ? draftData.pdfLinks.esg :""
            }else{
              intitialValues.pdfLinks =  intitialValues.pdfLinks 
            }
            if(draftData.pdfLinks.market){ 
              intitialValues.pdfLinks.market =  draftData.pdfLinks ? draftData.pdfLinks.market :""
            }else{
              intitialValues.pdfLinks =  intitialValues.pdfLinks 
            }
            if(draftData.pdfLinks.finance){ 
              intitialValues.pdfLinks.finance =  draftData.pdfLinks ? draftData.pdfLinks.finance :""
            }else{
              intitialValues.pdfLinks =  intitialValues.pdfLinks 
            }
        }
        if(draftData.sendProjectToMe == true){
          intitialValues.sendProjectToMe = draftData.sendProjectToMe
          intitialValues.emailSections = draftData.emailSections
          intitialValues.emails = draftData.emails

        }

        // console.debug("Parent document data:", parentDoc.data());
      } catch (error) {
        console.error("Error fetching parent document:", error);
      }
    };

    function getTechnicalAnanylsisScore(technicalScoringMarket, type, section, score) {
        let result
        let technicalResult = technicalScoringMarket.filter(v => {
          if (v.value == section) {
            v.options.filter(s => {
              if (s.score == score) {
                if(type == "market"){ 
                result = (score+"|"+s.comment).toString();
                }else{
                  result = s.score;
                }
              }
            })
          }
        })
        return result
      }
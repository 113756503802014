import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import 'firebase/messaging';


let app: firebase.app.App;

//Production environment
const firebaseConfig = {
  apiKey: "AIzaSyCXNkmdTVreM-7cDFks-A6qC6XM4vf0b58",
  authDomain: "invluencer-dev.firebaseapp.com",
  databaseURL: "https://invluencer-dev.firebaseio.com",
  projectId: "invluencer-dev",
  storageBucket: "invluencer-dev.appspot.com",
  messagingSenderId: "290973399886",
  appId: "1:290973399886:web:c6e985b64ad7ca91bdfc1f",
  measurementId: "G-B7PEXEF0MR",
  apiUrl:"https://us-central1-invluencer-dev",
  stripePublishKey:"pk_live_RQ9vYxeWp67F4e5qUhL2SaxW",
  vapkeyId:"BNMr-nUt4lUVs6dWOROqpxrd08oYKWcRBOZEw5vUi0JE9lrMtGrHjQd17NO6tjbxsk2i01onMe9_YZnhyD1x9a4"
};
//test Instance
var firebaseConfigTest = {
  apiKey: "AIzaSyBm3906K_w9RsbX9q78zM12b8VIAxRsFbQ",
  authDomain: "vk-invluencer-dev.firebaseapp.com",
  projectId: "vk-invluencer-dev",
  storageBucket: "vk-invluencer-dev.appspot.com",
  messagingSenderId: "50466493020",
  appId: "1:50466493020:web:244dd333a1476f622fcc99",
  measurementId: "G-FHGV35L83Y"
}
//local environment
var firebaseConfigInvtest = {
  apiKey: "AIzaSyBTIpSksGFEcosFTA_qP0_gWte4xkxvcJI",
  authDomain: "invluencer-test.firebaseapp.com",
  projectId: "invluencer-test",
  storageBucket: "invluencer-test.appspot.com",
  messagingSenderId: "253127520490",
  appId: "1:253127520490:web:7d89577f57a5fb2d70e793",
  measurementId: "G-4JJ6HQ2BPM",
  apiUrl:"https://us-central1-invluencer-test",
  stripePublishKey:"pk_test_Md6tQuscaC9rKSIB0Pqlizj1",
  vapkeyId: "BBuVslltgo6fHqUMcz8bl6iXcPOLmw_dHqDMGVwJuhhfQ-oEKD6UQfFQF2Bnjky0ZVv5bJcg7ULPVp_vc8HNolQ"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyDXD-L4sMPfhv7LwqffzZILBEy7B6Rgnqc",
//   authDomain: "localhost:9099",
//   databaseURL: "http://localhost:8080",
//   projectId: "hemanth-test2",
//   storageBucket: "hemanth-test2.appspot.com",
//   messagingSenderId: "53119637515",
//   appId: "1:53119637515:web:b59aa4726e97b79ececf0e"
// };


if (!firebase.apps.length) {

  if(process.env.REACT_APP_ENV=='production'){
    console.debug("if :production mein gaya",)
    if (firebaseConfig) {
      app = firebase.initializeApp(firebaseConfig)
    }
  }else{
    console.debug("if :test mein gaya",process.env.REACT_APP_ENV)
    if (firebaseConfigInvtest) {
      app = firebase.initializeApp(firebaseConfigInvtest);
    }
  }


  
  //   if(firebaseConfigTest){
  //     app = firebase.initializeApp(firebaseConfigTest);

  // }
  // if(firebaseConfigLocal){
  //   app = firebase.initializeApp(firebaseConfigLocal);
  //   firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
  //   // firebase.auth().useEmulator('http://localhost:9099/');
  // }
}

const auth = firebase.auth(app!);
const firestore = firebase.firestore(app!);

const callableFunctions = firebase.functions(app!);
const storage = firebase.storage(app!);
// const messaging = firebase.messaging(app!);
const vapkeyId="BBuVslltgo6fHqUMcz8bl6iXcPOLmw_dHqDMGVwJuhhfQ-oEKD6UQfFQF2Bnjky0ZVv5bJcg7ULPVp_vc8HNolQ";
// const messaging = firebase.messaging().usePublicVapidKey(vapkeyId);
const messaging = firebase.messaging();
export { auth, firestore, firebase, callableFunctions, storage ,messaging, app};

import React ,{ useEffect, Dispatch, useState }from "react";

import {
  makeStyles,
  withStyles,
  Theme,
  Grid,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Typography,Paper,
} from "@material-ui/core";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ProjectRepo } from "../../../repositories/project";
import { Scrollbars } from 'react-custom-scrollbars';
import { Project } from "../../../model";
import {
    getSectionLabel,
  } from "../../create/components/data";
import { CompassCalibrationOutlined } from "@material-ui/icons";
interface Props {
  project: Project;
  data: Array<Record<string,any>>;
}

const useStyles = makeStyles((theme: Theme) => ({
    companyLogo:{
        width: 100+'%',
        height: 100+'%',
        
    }
}));


function MostSimilarDownload(props: Props) {
  
  const { project, data } = props;
  const classes = useStyles();
  const projectRank= data.find(obj => {
    return obj.id === project.id;
  });
  const similarRank1= data.find(obj => {
    if(projectRank.rank==1)
    {
    return obj.rank === projectRank.rank+1;
    }
    return obj.rank === projectRank.rank-1;
  });
  const similarRank2= data.find(obj => {
    if(projectRank.rank==1)
    {
    return obj.rank === projectRank.rank+2;
    }
    if(projectRank.rank==data.length)
    {
    return obj.rank === projectRank.rank-2;
    }
    return obj.rank === projectRank.rank+1;
  });
 
  
  return (
    <Grid container spacing={4}>
      <p style={{fontSize:14,}}>
        Based on the score {project.company.name} received for this section, 
        companies {similarRank1.name} and {similarRank2.name} are the most similar. 
      </p>
        <Grid item  xs={6}>
            <Paper elevation={3} style={{height:120,padding:10,borderRadius:10,}}>
                <img 
               
                src={similarRank1.logo}
                alt="company logo"
                className={classes.companyLogo}
                />
            </Paper>
        </Grid>
        <Grid item  xs={6}>
            <Paper elevation={3} style={{height:120,padding:10,borderRadius:10,}}>
                <img 
                
                src={similarRank2.logo}
                alt="company logo"
                className={classes.companyLogo}
                />
            </Paper>
        </Grid>
    </Grid>
      );
}

export default MostSimilarDownload;

import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import { Checkbox } from "antd";
// styles
import useStyles from "./styles";
// logo
import logo from "./logo.svg";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { getSectionLabel } from "../../pages/create/components/data";
import Loader from "../../components/Loader/Loader";
import invitation from "../../repositories/invitation"
import { Invitation } from "../../model";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import firebase from "firebase";
// import logo from "./logos.png";
function OverView() {
    const classes = useStyles();
    let history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [start, setStart] = useState(false);
    const [page, setPage] = useState(true);
    const [final, setFinal] = useState(false);
    const [invitationData, setInvitationData] = useState<Invitation>();
    const [end, setEnd] = useState(false);
    const user = useSelector((state: RootState) => state.firebase.auth);
    const [isTourOpen, setTourOpen] = React.useState(true);
    const { id } = useParams<{ id: string }>();
    const myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    useEffect(() => {
        /////////// fetching Invitation data///////////////////////
        invitation.fetchInvitation(id).then((response) => {
            if (response) {
                // invitation.fetchProjectStatus(response.projectID).then((res)=>{
                //     setEnd(res);
                // })
                if(response.dueDate.seconds > myTimestamp.seconds)
                {
                    setEnd(true);
                }
                if (response.disclaimerAccepted) {
                    setTourOpen(!response.disclaimerAccepted);
                    setStart(response.disclaimerAccepted);
                }
                var total = response.sections.length;
                var done = 0;
                (response.sections).forEach(sec => {
                    if (response.sectionsStatus[sec] == "done")
                        done++;
                });
                if (done == total) {
                    setFinal(true);
                }
            }
            else {
                setPage(false);
            }
            setInvitationData(response);
        })
    }, []);
    // _________________________REACTOUR FUNCTION______________________
    // const accentColor = "#5cb7b7";
    // const closeTour = () => {
    //     setTourOpen(false);
    //   };
    // const tourConfig = [
    //     {
    //         selector: '[data-tut="reactour__logo"]',
    //         content: `You have to click on that button, so that you can access information `
    //     },
    // ];


    const handleStart = () => {
        invitation.acceptTerms(id).then((response) => {
            setStart(true);
        })
            .catch((error) => {
                console.debug(error, "acceptTerms in invitation")
            });
    };

    // ____________________________START FORM ON NEWSCORE COMPONENT___________________

    const handleStartScore = (e) => {
        history.push("/newScore/" + id + "/" + e.target.value)
    };

    if (!page) return <ErrorDisplay error={"can not find with the given id"} />;
    if (!invitationData) return <Loader text="Fetching your form..." />;
    if(!end) return <ErrorDisplay error={"Project is locked from scoring since its end date is reached"}/>;
    // if(user.email!==invitationData.email) return <ErrorDisplay error={"sorry you don't have permission to this page"} />
    return (
        <Grid container className={classes.container}>
            {/* <Tour
                onRequestClose={closeTour}
                disableInteraction={false}
                steps={tourConfig}
                isOpen={isTourOpen}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={accentColor}
            /> */}
            <Grid xs={5}>
                <div className={classes.logotypeContainers}>
                    <div className={classes.logotypeContainer}>
                        <img src={logo} alt="logo" className={classes.logotypeImage} />
                        <Typography className={classes.logotypeText}>i3D Platform</Typography>
                    </div>

                    <div className={classes.detailsContainer}>
                        {
                            !final ?
                                <>
                                    <Typography >
                                        Welcome
                                    </Typography>
                                    <Typography>
                                        {user.email}
                                    </Typography>
                                </>
                                :
                                <span>
                                    Thank you {invitationData.email} for completing the analysis
                                </span>

                        }

                        {
                            !start ?
                                <>
                                    <Typography >
                                        By clicking the button below, you certify that you have all legal requirement to access
                                        sensitive information concerning {invitationData.company_name} and its legal subsidiaries.
                                    </Typography>
                                    <Button
                                        // data-tut="reactour__logo"
                                        onClick={() => handleStart()}
                                    >
                                        I accept
                                    </Button>
                                </>
                                : ""
                        }
                        <Button
                            style={{ marginLeft: 15 }}
                            onClick={() => history.push("/app/dashboard")}
                        >
                            Dashboard
                        </Button>
                    </div>

                </div>
            </Grid>
            <Grid xs={7}>
                {start ? "" : <div className={classes.overlay}></div>}
                <div className={classes.formContainer}>
                    <Grid xs={12} className={classes.formContainerData}>
                        <Typography >
                            Due diligence on {invitationData.company_name}
                        </Typography>
                        <Typography >
                            Complete Overview
                        </Typography>
                        {
                            !final && <Typography >
                                You have been requested to complete due diligence on these sections
                            </Typography>
                        }

                    </Grid>
                    <Grid xs={12} style={{ display: "flex", marginTop: "3rem", justifyContent: "center", }}>
                        <Grid xs={5}>
                            <div className={classes.sectionToDO}>
                                <Typography >
                                    Section To Do
                                </Typography>
                                {invitationData.sections.map((sec) => {
                                    return (
                                        invitationData.sectionsStatus[sec] !== "done" ? (
                                            <Checkbox onClick={(e) => handleStartScore(e)} value={sec}>
                                                <Typography variant="body" weight="bold" >
                                                    {getSectionLabel(sec)}
                                                </Typography>
                                            </Checkbox>
                                        ) : ""
                                    )
                                })}

                            </div>
                        </Grid>
                        <Grid xs={5}>
                            <div className={classes.sectionToDO}>
                                <Typography >
                                    Section completed
                                </Typography>
                                {invitationData.sections.map((sec) => {
                                    if(invitationData.sectionsStatus[sec] === "done")
                                    return (
                                        <Checkbox checked={true}  value={sec}>
                                            <Typography variant="body" weight="bold" >
                                                {invitationData.sectionsStatus[sec] === "done" ? getSectionLabel(sec) : ""}
                                            </Typography>
                                        </Checkbox>
                                    )
                                    else
                                    return (<></>)
                                })}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}

export default OverView;

import React, { useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { Grid, Typography, Tabs, Tab, Button,InputAdornment,IconButton, } from "@material-ui/core";
import { Typography as Typo } from "../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import logo from "./logo.svg";
import { LoginForm, RegisterForm } from "../../components/Login";
import { Field, Formik, Form, FormikProps } from "formik";
import { PasswordInfo, forgotValidation } from "./index";
import { TextField } from "material-ui-formik-components";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { RootState } from "../../reducers";
import { UserDispatch } from "../../model";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {Auth} from "../../repositories/auth";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function ResetForgetPassword() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<UserDispatch>();
    const [open, setOpen] = useState(false);
    const [emailValue, setEmailValue] = useState(false);
    const [passwordShown, setpasswordShown] = React.useState<boolean>(false);

    const code = new URLSearchParams(location.search).get("oobCode");
    const handleDialogStatus = () => {
        setOpen(!open);
    };

    const handleSubmit = (newPassword) => {
        Auth.resetForgetPassword(newPassword,code).then((res)=>{
            alert(res);
            history.push("/login");
        })
        
    };





    return (
        <Grid container className={classes.container}>
            <div className={classes.logotypeContainer}>
                <img src={logo} alt="logo" className={classes.logotypeImage} />
                <Typography className={classes.logotypeText}>i3D Platform</Typography>
                <Typo variant="h3" htmlColor="white">
                    Powered by Invluencer Ltd.
        </Typo>
                <Typo
                    color="primary"
                    brightness="contrastText"
                    className={classes.copyright}
                >
                    © 2020 Invluencer Ltd. All rights reserved.
        </Typo>
            </div>
            <div className={classes.formContainer}>
                <Typography variant="h1" className={classes.greeting}>
                    New Password
                </Typography>
                <div className={classes.form}>

                    <Formik<PasswordInfo>
                        initialValues={{
                            password: "",
                        }}
                        validateOnMount
                        validateOnChange
                        validateOnBlur
                        validationSchema={forgotValidation}
                        // onSubmit={(values) => dispatch(forgetPass(values.email))}
                        onSubmit={(values) => handleSubmit(values.email)}
                    >
                        {(formik) => {
                            return (
                                <Form>

                                    <Field
                                        required
                                        name="password"
                                        label="new Password"
                                        component={TextField}
                                        margin="normal"
                                        placeholder="New Password"
                                        type={passwordShown ? "text" : "password"}
                                        fullWidth
                                        InputProps={{
                                            classes: {
                                                underline: classes.textFieldUnderline,
                                                input: classes.textField,
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setpasswordShown(!passwordShown);
                                                    }}
                                                    edge="end"
                                                  >
                                                    {passwordShown ? <Visibility /> : <VisibilityOff />}
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                        }}
                                        
                                    />
                                    <Button
                                        color="primary"
                                        size="large"
                                        className={classes.submitButton}
                                        onClick={(e) => {
                                            console.debug("hi");
                                            handleSubmit(formik.values.password);
                                          }}
                                    >
                                        Submit
                                    </Button>
                                    <p className={classes.loginLink}>
                                        Go to the <Link to="/">login page</Link>
                                    </p>
                                </Form>
                            );
                        }}
                    </Formik>

                </div>
            </div>
            <Dialog onClose={handleDialogStatus} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Mail has been sent to your registered email</DialogTitle>
            </Dialog>
        </Grid>
    );
}

export default ResetForgetPassword;

import React from "react";
import {
    makeStyles,
    Theme,
    Grid,
    Box,
    Typography,
    Button
} from "@material-ui/core";
import { Project } from "../../../../model";
import {
    sectionTechnicalOptions,
} from "../../../create/components/data";
import ProgressBar from './progressBar';
import { Redirect, Link, useParams, useHistory } from "react-router-dom";

interface Props {
    project: Project;
}

const useStyles = makeStyles((theme: Theme) => ({
    score: {
        fontSize: "20px",
        fontWeight: "bold",
        fontStyle: "normal",
        color: "#000000",
    },
    buttonScore:{
        fontSize: "0.8rem",
        backgroundColor:'#137DFB !important',
        "&:hover": {
            // boxShadow: theme.customShadows.widget,
            color:"white !important"
          },
        // width: "100%",
        // marginTop: "1rem"
    }

}));

function TechnicalScore(props: Props) {
    const { project } = props;
    const classes = useStyles();
    const containerSize = 400;
    function getColor(sectionName) {
        if (sectionName == "teamScore")
            return {
                color: "#FCAE73",
                backgroundColor: "#FFEADA"
            };
        if (sectionName == "marketScore")
            return {
                color: "#209D43",
                backgroundColor: "#DDF9E4"
            };
        if (sectionName == "fundScore")
            return {
                color: "#70A6E8",
                backgroundColor: "#E4F0FF"
            };
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <Grid container spacing={2} >
                    {sectionTechnicalOptions.map((section, index) => {
                        return (
                                <Grid item xs={4} key={index}>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1} style={{width:"70%"}}>
                                            <ProgressBar colors={getColor(section.value)} value={((project.technical ? project.technical[section.value] : 0) * 100).toFixed(0)} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography className={classes.score} >{((project.technical ? project.technical[section.value] : 0) * 100).toFixed(0)}%</Typography>
                                        </Box>
                                    </Box>
                                    <Typography variant="body2" color="textSecondary">{section.label}</Typography>
                                </Grid>
                              
                        );
                    })}
                <Grid item xs={12}>
                    <Button
                        className={classes.buttonScore }
                        variant="contained"
                        color="primary"
                        // style={{background:"#137DFB !important"}}
                        component={Link}
                        to={`/app/breakdown/${props.statePage}`+'?sort=technical'}
                    >
                        Score Breakdown
                    </Button>
                </Grid>

            </Grid>
        </div>

    );
}

export default TechnicalScore;

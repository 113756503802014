import React, {useState} from "react";
import useStyles from "./styles";
import { Button, Typography } from "@material-ui/core";
import badgeIcon from '../../images/badge.png'
import SubscriptionCard from "../../pages/create/components/plan";
import { Info } from "@material-ui/icons";
import LoginLeftInfoDialog from "../Modals/LoginLeftInfoDialog";


export function LeftSideBarLogin(props) {
    const classes = useStyles();
    const [openPlan, setOpenPlan] = useState(false)
    const [openLoginInfoPopup, setOpenLoginInfoPopup] = useState(false)

    const createFreeAccount = () =>{
        props.setActiveTabId(1)
    }

    const openPriceDialog=() =>{
      setOpenPlan(true)
    }

    return( 
   <div className={classes.container}>
    <div className={classes.cardContainer}>
            <Typography variant="h6" className={classes.failTitle} >
            FAIL TO PLAN, PLAN TO FAIL​ {<Info fontSize="small" className={classes.info} onClick={() =>{setOpenLoginInfoPopup(true)} } />}
            </Typography>
            <Typography variant="caption" className={classes.subgreeting}>
            Future-Proof your Start-up in the i3D Protocol
            </Typography>
            <ul className={classes.listItem}>
                <li><img src={badgeIcon} className={classes.badge} />i3D Rapid Score by thousands of analysts</li>
                <li><img src={badgeIcon} className={classes.badge} />i3D Arena Score by Deep Dive Experts</li>

                <li><img src={badgeIcon} className={classes.badge} />AI & Algorithm Scoring based on 60+ Success Factors</li>

                <li><img src={badgeIcon} className={classes.badge} />Ranking shared to thousands of investors​</li>

                <li><img src={badgeIcon} className={classes.badge} />Data Room for Start-up Documentation</li>

                <li><img src={badgeIcon} className={classes.badge} />Pitch Deck & Structured Success Protocols</li>
                <li><img src={badgeIcon} className={classes.badge} />Master Manual: Future-Proofing Start-ups</li>
            </ul>
            <div className={classes.LeftCardDiv}>
            <Button className={classes.LeftPricingButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  onClick={openPriceDialog}
                >
                  Pricing
                </Button>
            <Button
                variant="contained" 
                color="primary"
                size="large"
                type="button"
                className={classes.LeftCardButton}
               onClick={createFreeAccount}
              >
                Free Account
              </Button>
              </div>
       {/* <Typography></Typography> */}

    </div>
    <LoginLeftInfoDialog  open={openLoginInfoPopup} setOpenLoginInfoPopup={setOpenLoginInfoPopup}/>
    <SubscriptionCard open={openPlan} setOpenPlan={setOpenPlan} hide={true}/>
    </div>
    )

}
import React from 'react';
const PdfCardComponents = ({ data }) => {
  const card = {
    backgroundColor: 'white',
    height: "300px",
    width: "300px",
    padding: "20px",
    color: "black",
    margin: "auto"
  }
  const image = {
    height: "290px",
    width: "285px",
  }
  const imageStyle = {
    height: "291px",
    width: "286px",
    padding: "0px",
    margin:"0px"
  }
  // console.debug("wddvtfwyhwj: problem statemenbt", data )
  return (
    <div >
      <div className='row p-4'>
        <h4 className='text-white ms-5 mt-1'> {data ? data.heading : ""}</h4>
        <div className='col-md-12 d-flex  justify-content-center '>
          <div className='col-md-6 '>
            <div className='ms-5'>
              <h6 className='text-white mb-3'> {data ? data.cardHeading1 : ""}</h6>
            </div>
            <div className='card mx-auto' style={card}>
            <span dangerouslySetInnerHTML={{
                    __html: data ? data.cardData1.replace(/<br\s*\/?>/gi, ' ').replace(/&(nbsp|amp|quot|lt|gt);/g, " "):""
                }}></span>
                {/* {data ? data.cardData1 : ""} */}
            </div>
          </div>
          <div className='col-md-6 '>
            <div className='ms-5'>
            {/* <h6 className='text-white  '>{data ? data.cardHeading2 : ""}</h6> */}
            <h6 className="text-white mb-3 ">{data ? data.cardHeading2 : ""}</h6>
              {/* {data? data.cardHeading2==="Use of Funds & Milestones" && <span className='visibility-hidden p-0 m-0'>.</span>:""} */}
            </div>
            <div className='card mx-auto' style={card}>
            <span dangerouslySetInnerHTML={{
                    __html: data ? data.cardData2.replace(/<br\s*\/?>/gi, ' ').replace(/&(nbsp|amp|quot|lt|gt);/g, " "):""
                }}></span>
              {/* {data ? data.cardData2 : ""} */}
            </div>
          </div>
        </div>
        <div className='col-md-12 d-flex  justify-content-center '>
          <div className='col-md-6  '>
            <div className=' ms-5'>

              <h6 className='text-white my-3'>   {data ? data.cardHeading3 : ""}</h6>
            </div>
            <div className='card mx-auto' style={card}>
            <span dangerouslySetInnerHTML={{
                    __html: data ? data.cardData3.replace(/<br\s*\/?>/gi, ' ').replace(/&(nbsp|amp|quot|lt|gt);/g, " "):""
                }}></span>
              {/* {data ? data.cardData3 : ""} */}
            </div>
          </div>
          <div className='col-md-6 '>
            <div className='ms-5'>
              <h6 className='text-white my-3'> {data ? data.cardHeading4 : ""}</h6>
            </div>
            <div className='card mx-auto' style={card}>
            <span dangerouslySetInnerHTML={{
                    __html: data ? data.cardData4.replace(/<br\s*\/?>/gi, ' ').replace(/&(nbsp|amp|quot|lt|gt);/g, " "):""
                }}></span>
              {/* {data ? data.cardData4 : ""} */}
            </div>
          </div>
        </div>
        <div className='col-md-12 d-flex  justify-content-center '>
          {console.debug("pdf component: images", data)}
        {data.images != undefined &&data.images.length > 0 ?<div className='col-md-6 ' style={{marginTop:"2.1rem"}}>
            <div className='card mx-auto' style={image}>
              {/* <img src={ props.data ? props.data.imageLink1:"" } alt="img" style={image}/> */}
              <img src={data.images[0]} alt="img" style={imageStyle} />

            </div>

          </div>:""}
          {data.images.length > 0  ? <div className='col-md-6  ' style={{marginTop:"2.1rem"}}>
            <div className='card mx-auto' style={image}>
              {/* <img src={props.data ? props.data.imageLink2:"" } alt="img" style={image}/> */}
              <img src={data.images[1]} alt="img" style={imageStyle}/>

            </div>
          </div>:""}
        </div>
      </div>
    </div>



  );
};

export default PdfCardComponents;

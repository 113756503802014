import React ,{ useEffect, Dispatch, useState }from "react";
import { firestore } from "../../../../repositories/firebase";

import {
  makeStyles,
  withStyles,
  Theme,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableHead,
} from "@material-ui/core";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ProjectRepo } from "../../../../repositories/project";
import { Scrollbars } from 'react-custom-scrollbars';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Project } from "../../../../model";
import {
    getSectionLabel,
  } from "../../../create/components/data";
interface Props {
  weight
}
const projectCollection = firestore.collection("project");

const useStyles = makeStyles((theme: Theme) => ({
    sectionInfo:{
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#000000",
    letterSpacing: 0.44,
  },
  sectionRank: {
    // fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    // color: "#9D9D9D",
    letterSpacing: 0.44,
  },
  container: {
    maxHeight:210,
    overflow:"hidden",
  }
}));

function SectionRanking(props: Props) {
  
  const { weight } = props;
  const classes = useStyles();
  // const data = Object.entries(project.sectionStats)
  // .filter(([key]) => key !== "overall")
  // .sort(([, valueA], [, valueB]) => valueB.avgRating - valueA.avgRating)
  // .map(([key, value]) => ({
  //   name: getSectionLabel(key),
  //   section: key,
  //   score: value.avgRating * 100.0,
  //   completed: value.completed,
  //   previousRank: value.previousRank,
  //   weight: project.sectionWeights[key],
  //   rank: ProjectRepo.getSectionRank(value.avgRating,project.id,key,projects,project.startDate),
  // }));
  // if(!fetching)
  // {
  //     data.forEach((sec)=>{
  //         ProjectRepo.projectSectionRank(sec.rank,project.id,sec.section).then((res)=>{
  //         })
  //   })
  // }
  const StyledTableCell = withStyles((theme) => ({
    body: {
      padding: 5,
    },
  }))(TableCell);
  return (
      <div>
          <TableContainer className={classes.container}>
          <Scrollbars autoHeight autoHide>

          <Table size="small" >
            <TableBody>
              {/* {data.map((section, index) => {
                return ( */}
                
                  <TableRow>
                    <StyledTableCell>
                      <Typography className={classes.sectionInfo}>{'Expert'}</Typography>
                    </StyledTableCell>
                    <StyledTableCell><span className={classes.sectionRank}>{weight.expert*100}%</span></StyledTableCell>
                    
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <Typography className={classes.sectionInfo}>{'Rapid'}</Typography>
                    </StyledTableCell>
                    <StyledTableCell><span className={classes.sectionRank}>{weight.rapid*100}%</span></StyledTableCell>
                    
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <Typography className={classes.sectionInfo}>{'Technical'}</Typography>
                    </StyledTableCell>
                    <StyledTableCell><span className={classes.sectionRank}>{weight.technical*100}%</span></StyledTableCell>
                    
                  </TableRow>
                {/* );
              })} */}
            </TableBody>
          </Table>
          </Scrollbars>
        </TableContainer>
      </div>
       
      );
}

export default SectionRanking;

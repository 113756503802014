import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({

  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "#E5E5E5",
    overflow: "hidden",

  },
  questions: {
    justifyContent: "center",
    marginTop: 4 + "%",
    marginLeft: 5 + "%",
    fontWeight: "bolder",
    fontSize: 14

  },
  comment: {
    padding: 4,
    marginLeft: 4 + "%",
    fontWeight: "bolder",
    fontSize: 14
  },
  commentBox: {
    borderRadius: 5,
    padding: 4,
    marginLeft: 4 + "%",
    width: 80 + "%",
    backgroundColor: "#F9F9F9",
    '& .MuiInputBase-root': {
      marginTop: 4 + "%"
    },
    '& .MuiNativeSelect-select.MuiNativeSelect-select': {
      fontSize: "14px"
    }

  },
  commentBoxExpert: {
    borderRadius: 5,
    padding: 4,
    marginLeft: 4 + "%",
    width: 71 + "%",
    backgroundColor: "#F9F9F9",
    border: "1px solid #BEBEBE",
    marginTop: "0.5rem",
    '& .MuiInputBase-root': {
      marginTop: 4 + "%"
    },
    '& .MuiNativeSelect-select.MuiNativeSelect-select': {
      fontSize: "14px"
    }
  },
  companyLogo: {
    width: "100%",
    height: "100%",
  },
  rating: {
    padding: 20,
    marginLeft: 1 + "%",
    '& button': {
      border: "1px solid #000000 !important",
      fontSize: "14px",
      color: theme.palette.blackColor.main,
      fontWeight: "600"
    }
  },
}));


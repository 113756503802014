import React from "react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  makeStyles,
  Theme,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { IResponse } from "../../../model";
import { groupBy } from "../../../utils";
import { Flag } from "@material-ui/icons";
import Widget from "../../../components/Widget/Widget";
import FadeIn from "react-fade-in";
import { RED_FLAG_THRESHOLD } from "../../../utils/constants";

const singleScoreTooltip =
  "Score, given by the analyst, associated with the comment on the left";
const overallScoreTooltip = "Average score for this question";

interface Props {
  sectionName: string;
  numReviews: number;
  responses: IResponse[];
}

export default function QuestionAccordion(props: Props) {
  const { responses, sectionName, numReviews } = props;
  const classes = useStyles(props);
  const breakdown = groupBy<IResponse>(responses, "question");

  return (
    <Widget disableWidgetMenu title={sectionName}>
      <FadeIn className={classes.root}>
        {Object.entries(breakdown).map(
          ([question, relatedResponses], index) => {
            const responsesWithComments = relatedResponses.filter(
              (v) => !!v.comment
            );
            const numComments = responsesWithComments.length;
            const questionScore =
              relatedResponses.reduce((p, n) => p + n.score / 9.0, 0) /
              relatedResponses.length;
            return (
              <MuiExpansionPanel defaultExpanded={index === 0} key={index}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" className={classes.heading}>
                    {index + 1}. {question}
                  </Typography>
                  <Typography
                    style={{
                      color:
                        questionScore < RED_FLAG_THRESHOLD ? "red" : "inherit",
                    }}
                    className={classes.scoreHeader}
                  >
                    <Tooltip title={overallScoreTooltip} arrow>
                      <span className={classes.scoreHeader}>
                        {(questionScore * 100.0).toFixed(1)}%{" "}
                      </span>
                    </Tooltip>
                    | {numReviews} Reviews | {numComments} Comments{" "}
                    {questionScore < RED_FLAG_THRESHOLD && <Flag />}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanel}>
                  {responsesWithComments.map((response, index) => {
                    return <CommentWidget key={index} {...response} />;
                  })}
                  {!responsesWithComments.length && (
                    <div
                      className={classes.scoreHeader}
                      style={{ width: "100%" }}
                    >
                      <Typography variant="body1">
                        No comments to show for this question yet!
                      </Typography>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </MuiExpansionPanel>
            );
          }
        )}
      </FadeIn>
    </Widget>
  );
}

const MuiExpansionPanel = withStyles((theme: any) => ({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {
    margin: "auto",
  },
}))(ExpansionPanel);

function CommentWidget(response: IResponse) {
  const classes = useStyles();
  const questionScore = response.score / 9.0;

  return (
    <div className={classes.commentContainer}>
      <Typography
        variant="body1"
        style={{
          color: questionScore < RED_FLAG_THRESHOLD ? "red" : "inherit",
        }}
      >
        {response.comment} •{"\t"}
        <Tooltip title={singleScoreTooltip} arrow>
          <span style={{ fontWeight: 500 }}>
            {(questionScore * 100.0).toFixed(1)}%
          </span>
        </Tooltip>
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  commentContainer: {
    width: "100%",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: "1px solid #D3D3D380",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  scoreHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
  },
  expansionPanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
}));

import React, { useEffect } from "react";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Select, Input, AutoComplete } from "formik-antd";
import { Field } from 'formik';
import FadeIn from "react-fade-in";
import { countries, sectors, stages } from "../data";
import { ImageUploadField } from "../Fields/ImageUpload";
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import { Paper, Grid } from "@material-ui/core";
import PeopleIcon from '@material-ui/icons/People';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { ToggleButtonGroup } from 'formik-material-ui-lab';
import PlatformInfo from "../PlatformSelect/PlatformInfo";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProjects } from "../../../../actions/project";
import { callableFunctions, firestore, storage } from '../../../../repositories/firebase';

const { Option } = Select;
export default function (props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formik = useFormikContext<FormInfo>();
  const allProjects = useSelector((state => state?.project?.allProjects))


  async function searchCompany(event, allProjects) {
    try {
      const response = await fetch(`https://us-central1-invluencer-test.cloudfunctions.net/fetchProjectsDuplicate?company=${event}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      // formik.setErrors("wecwwddwdwdw");
      formik.setErrors({companyName: data.data.projects.res});
      // Process the fetched data here
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // const searchCompany = async (event) => {
  //   const value = event.target.value;




  //   formik.handleChange(event); // Update formik values
  //   try {
  //     const response = await fetch(`https://us-central1-invluencer-test.cloudfunctions.net/searchCompany`);
  //     const data = await response.json();
  //     console.debug("copanyDatadearch", data.length)
  //     // if (data.length > 0) {

  //     //   formik.setErrors({ companyName: 'Company name already exists' });
  //     // }
  //     if (data.length === 0) {
  //       formik.setErrors({ companyName: '' });
  //     } else {
  //       formik.setErrors({ companyName: 'Company name already exists' });
  //     }
  //   } catch (error) {
  //     console.error('Error checking company name:', error);
  //     // setError('Error checking company name');
  //   }
  // };
  useEffect(() => {
    dispatch(fetchAllProjects());
    // fetchData()
  }, [dispatch]);
  useEffect(function () {
    // searchCompany(formik.values)
    // console.debug("formikCreate Project ",formik)
    let data = {
      "companyName": formik.values ? formik.values.companyName : "",
      "companyImage": formik.values ? formik.values.companyImage : "",
      "companyDescription": formik.values ? formik.values.companyDescription : "",
      "companyLocation": formik.values ? formik.values.companyLocation : "",
      "companySector": formik.values ? formik.values.companySector : "",
      "companyStage": formik.values ? formik.values.companyStage : "",
      "companyWebsite": formik.values ? formik.values.companyWebsite : "",
    }
    props.setCompanyData(data)
  }, [formik.values])
  function every() {
    return (
      <div style={{ marginTop: 40, }}>
        <Paper elevation={3} className={classes.privacySelect}>
          <Grid container>
            <Grid item xs={3} className={classes.selection}>
              <PeopleIcon style={{ height: 40, width: 40 }} />
            </Grid>
            <Grid item xs={9}>
              <span style={{ fontSize: 15, fontWeight: "500", color: "#000000", fontFamily: "Montserrat" }}>
                Everyone
              </span>
              <p style={{ fontSize: 12, fontFamily: "Montserrat", fontWeight: "normal", width: "85%", color: "000000" }}>
                when selected every user in the i3D network can view your project and its results on their Dashboard
              </p>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
  function adminOnly() {
    return (
      <div style={{ marginTop: 30, }}>
        <Paper elevation={3} className={classes.privacySelect}>
          <Grid container>
            <Grid item xs={3} className={classes.selection}>
              <PeopleIcon style={{ height: 40, width: 40 }} />
            </Grid>
            <Grid item xs={9}>
              <span style={{ fontSize: 15, fontWeight: "500", color: "#000000", fontFamily: "Montserrat" }}>
                Admins Only
              </span>
              <p style={{ fontSize: 12, fontFamily: "Montserrat", fontWeight: "normal", width: "85%", color: "000000" }}>
                When selected, only the i3D team (and yourself) can view the project and its results.
              </p>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
  function selected() {
    return (
      <div style={{ marginTop: 30, }}>
        <Paper elevation={3} className={classes.privacySelect}>
          <Grid container>
            <Grid item xs={3} className={classes.selection}>
              <PeopleIcon style={{ height: 40, width: 40 }} />
            </Grid>
            <Grid item xs={9}>
              <span style={{ fontSize: 15, fontWeight: "500", color: "#000000", fontFamily: "Montserrat", }}>
                Selected People
              </span>
              <p style={{ fontSize: 12, fontFamily: "Montserrat", fontWeight: "normal", width: "85%", color: "000000" }}>
                When selected, only those accounts that you grant access to will be able to view the project and its results
              </p>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
  return (
    <FadeIn>

      {/* ________________________ PLATFORM ___________________ */}


      {/* _______________________COMPANY INFO_________________________ */}

      <div className={classes.companyInfoContainer}>
        <div className={classes.cardContainer}>
          <div className={classes.inputFields} style={{ width: "100%", }}>
            <Typography variant="h4" className={classes.sectionTitle}>
              Is this a crypto project?
            </Typography>
            <Field type="checkbox" name="crypto" disabled />
            <label className={classes.label}>
              yes
            </label>
          </div>
          <div className={classes.inputFields} style={{ width: "100%", alignItems: "baseline" }}>
            <Typography variant="h4" className={classes.sectionTitle}>
              Select Platform
            </Typography>
            <PlatformInfo />
          </div>
          <div className={classes.dropZone} >
            <Typography variant="h4" className={classes.sectionTitle}>
              Add Logo
            </Typography>
            <ImageUploadField />

          </div>
          <div>
            <div className={classes.inputFields} style={{ width: "100%", }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Company Name
              </Typography>
              <AutoComplete
                style={{ width: "100%", borderRadius: 10 }}
                name="companyName"
                onChange={(event) => {
                  formik.handleChange(event);
                  searchCompany(event);
                }}
                placeholder="e.g. Invluencer Ltd."
                className={classes.CompanyDetails}
              />
            </div>
            <div>
              {formik.errors.companyName && formik.touched.companyName &&(
                <Typography variant="caption" color="secondary">
                  {formik.errors.companyName}
                </Typography>
              )}

            </div>
            <div className={classes.inputFields} style={{ width: "100%", }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Company Description
              </Typography>
              <Field
                className={classes.companyDescription + " " + classes.companyDes}
                name="companyDescription"
                style={{ width: "100%", borderRadius: 10 }}
                placeholder="e.g. An awesome company that provides fintech solutions."
                component="textarea">
              </Field>

            </div>
            <div>
              {formik.errors.companyDescription &&
                formik.touched.companyDescription && (
                  <Typography variant="caption" color="secondary">
                    {formik.errors.companyDescription}
                  </Typography>
                )}
            </div>
          </div>
          <div>
            <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Company Stage
              </Typography>
              <Select
                name="companyStage"
                defaultValue="No stage selected"
                style={{ width: "100%" }}
                className={classes.CompanyDetails}
              >
                {stages.map((stage) => (
                  <Option key={stage.value} value={stage.value} title={stage.label}>
                    {stage.label}
                  </Option>
                ))}
              </Select>

            </div>
            <div>
              {formik.errors.companyStage && formik.touched.companyStage && (
                <Typography variant="caption" color="secondary">
                  {formik.errors.companyStage}
                </Typography>
              )}
            </div>
            <div className={classes.inputFields} style={{ width: "100%", }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Company Sector
              </Typography>
              <Select
                showSearch
                name="companySector"
                placeholder="Select a sector"
                optionFilterProp="children"
                style={{ width: "100%", borderRadius: 10 }}
                className={classes.CompanyDetails}
                filterOption={(input, option) => {
                  if (option)
                    return (
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  return true;
                }}
                onDropdownVisibleChange={(open) => {
                  if (!open && document.activeElement instanceof HTMLElement)
                    document.activeElement.blur();
                }}
              >
                {sectors.map((sector) => (
                  <Option value={sector.value} key={sector.value}>
                    {sector.label}
                  </Option>
                ))}
              </Select>

            </div>
            <div className={classes.inputFields} style={{ width: "100%", }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Company Location
              </Typography>
              <Select
                showSearch
                name="companyLocation"
                placeholder="Select a Location"
                optionFilterProp="children"
                style={{ width: "100%", borderRadius: 10 }}
                className={classes.CompanyDetails}
                filterOption={(input, option) => {
                  if (option)
                    return (
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  return true;
                }}
                onDropdownVisibleChange={(open) => {
                  if (!open && document.activeElement instanceof HTMLElement)
                    document.activeElement.blur();
                }}
              >
                {countries.map((country) => (
                  <Option value={country.name} key={country.code}>
                    {country.name}
                  </Option>
                ))}
              </Select>

            </div>
            <div>
              {formik.errors.companyLocation && formik.touched.companyLocation && (
                <Typography variant="caption" color="secondary">
                  {formik.errors.companyLocation}
                </Typography>
              )}
            </div>
          </div>
          <div
            style={{

            }}
          >
            <div className={classes.inputFields} style={{ width: "100%", }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Company Website
              </Typography>
              <AutoComplete
                style={{ width: "100%", }}
                name="companyWebsite"
                placeholder="e.g. https://invluencer.com/"
                className={classes.CompanyDetails}

              />

            </div>
            <div>
              {formik.errors.companyWebsite && formik.touched.companyWebsite && (
                <Typography variant="caption" color="secondary">
                  {formik.errors.companyWebsite}
                </Typography>
              )}
            </div>
            <div className={classes.inputFields} style={{ width: "100%", }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Deal Source
              </Typography>
              <Input
                name="dealSource"
                style={{ width: "100%", borderRadius: 10, }}
                placeholder="e.g. https://invluencer.com/"
                className={classes.companySourceEmail}
              />

            </div>
            <div>
              {formik.errors.dealSource && formik.touched.dealSource && (
                <Typography variant="caption" color="secondary">
                  {formik.errors.dealSource}
                </Typography>
              )}
            </div>
            <div className={classes.inputFields} style={{ width: "100%", }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Contact Email
              </Typography>
              <Input
                name="contactEmail"
                style={{ width: "100%", borderRadius: 10, }}
                placeholder="e.g. contact email"
                className={classes.companySourceEmail}
              />

            </div>
          </div>
        </div>
        <div className={classes.cardContainer}>
          <div className={classes.privacyDiv}>
            <Typography className={classes.privacySection}>
              Privacy Settings
            </Typography>
            <Typography className={classes.privacySectionDescription}>
              These Settings will indicate who can view the project and its results
            </Typography>
            <Field component={ToggleButtonGroup} orientation="vertical" exclusive name="privacy" type="checkbox">
              <ToggleButton value={0}>
                {every()}
              </ToggleButton>
              <ToggleButton value={1}>
                {adminOnly()}
              </ToggleButton>
              <ToggleButton value={2}>
                {selected()}
              </ToggleButton>
            </Field>
          </div>
          {/* ___________________________INVITE/ADD A PERSON TSX___________________________ */}
          {formik.values.privacy == 2 ? (<div style={{ marginTop: 30, }}>
            <div className={classes.inputFields} style={{ width: "100%", }}>
              <Typography variant="h4" className={classes.sectionTitle}>
                Invite/Add a person
              </Typography>
              <Select
                name="selectedEmails"
                mode="tags"
                size="large"
                style={{ width: "100%" }}
                placeholder="enter emails"
                tokenSeparators={[","]}
                maxTagCount={15}
              >
              </Select>
            </div>
          </div>) : ""}
        </div>
      </div>
    </FadeIn>

  );
}
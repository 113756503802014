import React from "react";
import { Row, Col } from "antd";
import { Slider, InputNumber } from "formik-antd";
interface Props {
  section: string;
  weight:number
}

export function PercentSlider({ section, weight }: Props) {
  return (
    <Row>
      {/* <Col span={12}>
        <Slider
          name={`sectionWeights.${section}`}
          min={0}
          step={0.1}
          max={100}
          defaultValue={0}
        />
      </Col> */}
      <Col span={4}>
        <InputNumber
          name={`sectionWeights.${section}`}
          min={0}
          step={0.1}
          max={100}
          // style={{ margin: "0 16px" }}
          defaultValue={weight}
          disabled={true}
        />
      </Col>
    </Row>
  );
}

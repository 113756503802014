import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent:"center",
      alignItems:"center",
      borderRadius: "10px",
  
    },
    expertText:{
      marginTop: "2rem",
      marginBottom: "2rem",
      fontWeight: "600"
  },
  CompanyDetails: {
    width: "100%",
    '& .ant-select-selector': {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        height: "40px !important",
        border: "1px solid #BEBEBE !important",
        background: "#fff !important",
        borderRadius: "10px !important",
        // fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "17px",
        color: theme.palette.blackColor.main,
        padding: "0 20px !important"
    },
    '& >div>span': {
        display: "flex",
        alignItems: "center",
        // justifyContent: "center"
    }
},
  scoringTypeLabel:{
    marginRight:10,
  }
  }));
// prettier-ignore
import {  IconButton, Grid, Paper, } from '@material-ui/core';
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { IResponse, ProjectDispatch, Report } from "../../model";
import { Redirect, useHistory, useParams, useLocation } from "react-router-dom";
import { fetchReport } from "../../actions/project";
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import FadeIn from "react-fade-in";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Select, Typography, HtmlTooltip } from "../../components/Wrappers/Wrappers";
import { getSectionOptions, SectionOption } from "../create/components/data";
import { groupBy } from "../../utils";
import { ArrowBackIos } from "@material-ui/icons";
import {
  HIGH_SCORE_THRESHOLD,
  RED_FLAG_THRESHOLD,
} from "../../utils/constants";
import { Info } from "@material-ui/icons";
import { ExpertScore } from "../reports/components/Graphs";
import useStyles from './styles'
import { Scrollbars } from 'react-custom-scrollbars';
import { SectionwiseRanking, MostSimilar } from "./components"
import { ProjectRepo } from "../../repositories/project";
import invitation from "../../repositories/invitation";
const projectNotFound = "Could not find the right breakdown for you...";

enum FilteringType {
  none = "none",
  redFlagsOnly = "redFlagsOnly",
  highScores = "highScores",
  general = "general",
}

interface State {
  reportId?: string;
  section?: SectionOption;
  possibleSections: SectionOption[];
  setSection: React.Dispatch<React.SetStateAction<SectionOption | undefined>>;
  loading: boolean;
  error: string | Error | undefined;
  report: Report | undefined;
  numReviews: number;
  sectionScore: number;
  filtering: FilteringType;
  setFiltering: React.Dispatch<React.SetStateAction<FilteringType>>;
}

function _checkFiltering(
  response: IResponse,
  filtering: FilteringType
): boolean {
  const score = response.score / 9;

  switch (filtering) {
    case FilteringType.redFlagsOnly:
      return score < RED_FLAG_THRESHOLD;
    case FilteringType.highScores:
      return score > HIGH_SCORE_THRESHOLD;
    case FilteringType.general:
      let bool = score > RED_FLAG_THRESHOLD && score < HIGH_SCORE_THRESHOLD;
      return bool;
    default:
      return true;
  }
}

function useBreakdownState(): State {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<ProjectDispatch>();
  const { fetching, fetchError, focusedReport: report } = useSelector(
    (state: RootState) => state.project
  );
  const [sectionState, setSectionState] = React.useState<
    SectionOption | undefined
  >();
  const [sections, setSections] = React.useState<SectionOption[]>([]);
  const [filtering, setFiltering] = React.useState<FilteringType>(
    FilteringType.none
  );
  const location = useLocation();
  const sorting = new URLSearchParams(location.search).get("sort");
  React.useEffect(() => {
    if (report) {
      const grouped = groupBy(report.responses, "section");
      const sections = Array.isArray(report.project.sections)
        ? report.project.sections
        : Object.keys(report.project.sections);
      const options = getSectionOptions(sections).sort((a, b) => {
        const bLength = grouped[b.value]?.length ?? 0;
        const aLength = grouped[a.value]?.length ?? 0;
        return bLength - aLength;
      });

      setSectionState(options[0]);
      // console.debug("getComments:getComments:options:options", options)
      options.push({
        label: "Technical",
        value: "Automated",
        description: "",
      });
      if (sorting == "technical") {
        setSectionState(options[options.length - 1]);
      }
      setSections(options);
    }
  }, [report]);

  React.useEffect(() => {
    if (id) {
      dispatch(fetchReport(id));
    }
  }, [dispatch, id]);

  const numReviews =
    report && sectionState && sectionState.value != "Automated"
      ? report.project.sectionStats[sectionState.value].completed
      : 0;
  const sectionScore =
    report && sectionState && sectionState.value != "Automated"
      ? report.project.sectionStats[sectionState.value].avgRating
      : 0;

  return {
    reportId: id,
    section: sectionState,
    setSection: setSectionState,
    possibleSections: sections,
    loading: fetching || !sectionState,
    error: fetchError ?? (!id ? projectNotFound : undefined),
    report,
    numReviews,
    sectionScore,
    filtering: filtering,
    setFiltering: setFiltering,
  };
}

function Breakdown() {
  const classes = useStyles();
  const history = useHistory();

  // local
  const { id } = useParams<{ id: string }>();
  const [projects, setProjects] = React.useState([]);
  const [technicalResponses, setTechnicalResponses] = React.useState([]);
  const [generalComments, setGeneralComments] = React.useState([]);
  React.useEffect(() => {
    ProjectRepo.listProjects().then((response) => {
      setProjects(response);
    });
  }, []);
  
  const state = useBreakdownState();
  React.useEffect(() => {
    invitation.getGeneralComments(id,state.section?state.section.value:'').then((res)=>{
      setGeneralComments(res);
    })
  }, [state.section]);
  const data = projects.map((element) => {
    if (state.section?.value == "team") {
      return ({
        name: element.company ? element.company.name : "",
        rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.team ? element.sectionStats.team.avgRating : 0) : 0, element.id, "team", projects, element.startDate),
        id: element.id,
        logo: element.company ? element.company.logo : "",
      });
    }
    if (state.section?.value == "idea") {
      return ({
        name: element.company ? element.company.name : "",
        rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.idea ? element.sectionStats.idea.avgRating : 0) : 0, element.id, "idea", projects, element.startDate),
        id: element.id,
        logo: element.company ? element.company.logo : "",
      });
    }
    if (state.section?.value == "market") {
      return ({
        name: element.company ? element.company.name : "",
        rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.market ? element.sectionStats.market.avgRating : 0) : 0, element.id, "market", projects, element.startDate),
        id: element.id,
        logo: element.company ? element.company.logo : "",
      });
    }
    if (state.section?.value == "finance") {
      return ({
        name: element.company ? element.company.name : "",
        rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.finance ? element.sectionStats.finance.avgRating : 0) : 0, element.id, "finance", projects, element.startDate),
        id: element.id,
        logo: element.company ? element.company.logo : "",
      });
    }
    if (state.section?.value == "competition") {
      return ({
        name: element.company ? element.company.name : "",
        rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.competition ? element.sectionStats.competition.avgRating : 0) : 0, element.id, "competition", projects, element.startDate),
        id: element.id,
        logo: element.company ? element.company.logo : "",
      });
    }
    if (state.section?.value == "esg") {
      return ({
        name: element.company ? element.company.name : "",
        rank: ProjectRepo.getSectionRank(element.sectionStats ? (element.sectionStats.esg ? element.sectionStats.esg.avgRating : 0) : 0, element.id, "esg", projects, element.startDate),
        id: element.id,
        logo: element.company ? element.company.logo : "",
      });
    }
  });
  if (!state.reportId || state.reportId === "breakdown")
    return <Redirect to="/" />;

  // console.debug("getComments:getComments:state:Report", state)
  if (state.loading || !state.section)
    return <Loader text="Fetching breakdown..." />;
  if (state.error || !state.report)
    return <ErrorDisplay error={state.error ?? projectNotFound} />;

  const responses = state.report.responses.filter(
    (c) =>
      c.section === state.section?.value &&
      _checkFiltering(c, state.filtering) &&
      !!c.comment
  );

  const automatedResponses = state.report.responses.filter(
    (c) =>
      c.type === state.section?.value
  );


  const getComments = () => {
    if(automatedResponses.length>0)
    {
      ProjectRepo.getComments(automatedResponses).then((response) => {
      setTechnicalResponses(response);
    });
    }
  }

  // console.debug("getComments:state.section", state.section)
  if (state.section.value == "Automated") {
    getComments();
    return (
      <FadeIn>
        <PageTitle

          title={
            <>
              <IconButton className={classes.backArrow} onClick={() => history.goBack()}>
                <ArrowBackIos />
              </IconButton>
              <Select<string>

                value={state.section.value}
                possibleValues={state.possibleSections}
                onChange={(e) => {
                  state.setSection(
                    state.possibleSections.find((v) => v.value === e)
                  )
                  history.push("/app/breakdown/" + id)
                }
                }
              />
              Breakdown for
              <span style={{ fontWeight: "bold" }}>
                {state.report.project.company.name}
              </span>
            </>
          }
        />
        {!automatedResponses.length ? (
        <div className={classes.empty}>
          <Typography variant="h6">
            No analysis for this section to show yet!
          </Typography>
        </div>
      ) :(
        <>
        <span className={classes.widgetTitle}>
          Automated Comments
        </span>
        <Paper elevation={3} style={{ minWidth: 100 + '%', textAlign: "justify", padding: 15, borderRadius: 10 }}>
        
          {technicalResponses.map((v, index) => (
            <Typography
            key={index}
              variant="h6"
              className={classes.body}
              style={{
                color: "inherit",
                marginBottom: 4.0,
                fontStyle: "normal",
              }}

            >
              {"-" + (v.text)}

            </Typography>

          ))}
        </Paper>
        </>
      )}
      </FadeIn>
            )
  }

  return (
    <FadeIn>
      <PageTitle

        title={
          <>
            <IconButton className={classes.backArrow} onClick={() => history.goBack()}>
              <ArrowBackIos />
            </IconButton>
            <Select<string>

              value={state.section.value}
              possibleValues={state.possibleSections}
              onChange={(e) => {
                state.setSection(
                  state.possibleSections.find((v) => v.value === e)
                )
                history.push("/app/breakdown/" + id)
              }
              }
            />
            Breakdown for
            <span style={{ fontWeight: "bold" }}>
              {state.report.project.company.name}
            </span>
          </>
        }
      //   extra={
      //     <>
      //       {/* { { <Select<FilteringType>
      //         label="Filtering"
      //         value={state.filtering}
      //         onChange={state.setFiltering}
      //         possibleValues={[
      //           { label: "None", value: FilteringType.none },
      //           { label: "Red Flags Only", value: FilteringType.redFlagsOnly },
      //           { label: "Positives", value: FilteringType.positive },
      //         ]}
      //       /> } } */}
      //       <Select<string>

      //         value={state.section.value}
      //         possibleValues={state.possibleSections}
      //         onChange={(e) =>
      //           state.setSection(
      //             state.possibleSections.find((v) => v.value === e)
      //           )
      //         }
      //       />
      //     </>
      //   }
      />
      {!responses.length ? (
        <div className={classes.empty}>
          <Typography variant="h6">
            No analysis for this section to show yet!
          </Typography>
        </div>
      ) : (
        <div className={classes.root}>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={4}>
                <Grid item xs={12} >
                  <span className={classes.widgetTitle}>
                    {state.section.label} Section Info
                  </span>

                  <Paper elevation={3} style={{ maxHeight: 400, minWidth: 101 + '%', textAlign: "start", padding: 10, borderRadius: 10 }}>
                    <Scrollbars autoHeight autoHide>
                      <Typography className={classes.body} style={{ display: "flex", flexDirection: "column" }}>
                        {state.section.description}
                      </Typography>
                    </Scrollbars>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <span className={classes.widgetTitle}>
                    Most-Similar  {state.section.label} Score
                  </span>
                  <MostSimilar project={state.report.project}
                    data={data} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <span className={classes.widgetTitle}>
                Sections{" "}
                {/* {(state.sectionScore * 100).toFixed(1)}%{" "}
            <span style={{ color: "red" }}>
              (
              {
                responses.filter(
                  (v) => !!v.comment && v.score / 9 < RED_FLAG_THRESHOLD
                ).length
              }
              <Flag />)
            </span> */}
              </span>

              <Paper elevation={3} style={{ maxHeight: 500, padding: 20, paddingRight: 40, borderRadius: 10 }}>

                <ExpertScore project={state.report.project} />

              </Paper>

            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <span className={classes.widgetTitle}>
                Insights
                <HtmlTooltip
                  title={<ul className={classes.insightTitle}>
                    <li>Insights given by analysts are their personal opinions based on their experience and expertise.</li>
                    <li>-Positive Insights are when an analyst has scored above 67%</li>
                    <li>-Negative Insights are for scores below 45% requiring a mandatory comment.</li>
                    <li>-General Insights are related to scores between 47-66%.</li>
                    <li>A reader should use this information in the overall context of the project. It can also be used to identify opportunities for value add that may be given to a project. The insights given are those of the analyst and not those of the i3D Platform which is designed to enable consensus and scoring based on these personal insights of the analysts.
                    </li>
                  </ul>}
                >
                  <Info fontSize="small" className={classes.infoColor}/>
                </HtmlTooltip>
              </span>

              <Paper elevation={3} style={{ maxHeight: 700, minWidth: 90 + '%', textAlign: "start", paddingLeft: 25, paddingTop: 30, borderRadius: 10, }}>
                <Scrollbars style={{ height: 650 }} autoHide>
                  <span className={classes.positive}>Positives:</span>

                  <ul className={classes.list}>
                    {responses.map((v,index) => (
                      <Typography
                      key={index+'4'}
                        variant="h6"
                        className={classes.body}
                        style={{
                          color: "inherit",
                          marginBottom: 4.0,
                          fontStyle: "normal",
                        }}

                      >
                        {_checkFiltering(v, FilteringType.highScores) && "-" + v.comment}

                      </Typography>
                    ))}
                  </ul>

                  <span className={classes.negative}>Negatives:</span>

                  <ul className={classes.list}>
                    {responses.map((v,index) => (
                      <Typography
                      key={index+'3'}
                        variant="h6"
                        className={classes.body}
                        style={{
                          color: "inherit",
                          marginBottom: 4.0,
                          fontStyle: "normal",
                        }}

                      >
                        {_checkFiltering(v, FilteringType.redFlagsOnly) && "-" + v.comment}
                      </Typography>
                    ))}
                  </ul>

                  <span className={classes.general}>General:</span>


                  <ul className={classes.list}>
                    {responses.map((v,index) => (
                      <Typography
                      key={index+'2'}
                        variant="h6"
                        className={classes.body}
                        style={{
                          color: "inherit",
                          marginBottom: 4.0,
                          fontStyle: "normal",
                        }}

                      >
                        {_checkFiltering(v, FilteringType.general) && "-" + v.comment}
                      </Typography>
                    ))}
                    {generalComments.map((v,index) => (
                      <Typography
                      key={index+'1'}
                        variant="h6"
                        className={classes.body}
                        style={{
                          color: "inherit",
                          marginBottom: 4.0,
                          fontStyle: "normal",
                        }}

                      >
                         {"-" + v.comment}
                      </Typography>
                    ))}
                  </ul>
                </Scrollbars>
              </Paper>

            </Grid>


            <Grid item xs={12} sm={12} md={5} lg={5}>
              <span className={classes.widgetTitle}>
                {state.section.label} Rankings
              </span>
              <SectionwiseRanking project={state.report.project}
                data={data} />
            </Grid>
          </Grid>
        </div>
      )}
    </FadeIn>
  );
}



export default Breakdown;

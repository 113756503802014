import * as React from "react";
import { storage } from "../../../../repositories/firebase";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import { useField } from "formik";
import { Theme } from "@material-ui/core";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import { Alert } from "antd";
import { makeStyles, createStyles } from "@material-ui/styles";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import {
  Grid,
} from "@material-ui/core";
interface Props {
  fieldName: string;
//   uploadButtonState: boolean,
//   UploadButtonF
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: 8,
      borderRadius: 4,
      backgroundColor: "#137DFB !important",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    widthDefine: {
      width: "97% !important"
    },
    uploaded: {
      padding: 8,
      borderRadius: 4,
      width: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "2px solid black",
      "&:hover": {
        cursor: "pointer",
      },
    },
    singleFile: {
      display: "flex",
      alignItems: "center",
      width: "100%",

    },
    singleFiles: {
      display: "flex",
      alignItems: "center",
      minWidth: "100%"
    },
    marginL: {
      marginLeft: "4rem"
    },
    image: {
      height:380,
      width:380,
      marginTop: 20,
    }
  })
);

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="text">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function ImagesUploadField(props: Props) {
  const classes = useStyles();
  const [field, , helpers] = useField<string>(props.fieldName);
  const [loading, setloading] = React.useState<boolean>(false);
  const [progress, setprogress] = React.useState<number>(0.0);
  const [error, seterror] = React.useState<string>("");
  const [url, setUrl] = React.useState<string>("");

  if (loading) return <LinearProgressWithLabel value={progress} />;
  // {console.debug("FieldValue", field)}

  return (
    <>
      <CustomUploadButton
        accept="image/*"
        name="avatar"
        randomizeFilename
        storageRef={storage.ref("images")}
        onUploadStart={() => setloading(true)}
        onUploadError={() => {
          setloading(false);
          seterror("File could not be loaded.");
        }}
        onProgress={(p) => setprogress(p)}
        onUploadSuccess={(file: string) => {
          setloading(false);
          seterror("");
        //   props.UploadButtonF(true);
          storage
            .ref("images")
            .child(file)
            .getDownloadURL()
            .then((url) => {
              setUrl(url);
              helpers.setValue(url);
            });
        }}
      >
        <Grid container >
          <div className={classes.singleFiles} style={{width:200}}>
            <Grid item xs={field.value ? 5 : 12} >
              <div className={classes.button + " " + classes.widthDefine} >
                <Typography htmlColor="white" >
                  Upload {field.value && "new"} Image 1:1
                </Typography>
              </div>
            </Grid>
          </div>
        </Grid>
            {field.value && (
             
                  <img src={field.value} className={classes.image}></img>
            )}
      </CustomUploadButton>
      {error && <Alert message={error} />}
    </>
  );
}

export { ImagesUploadField };

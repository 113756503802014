import { makeStyles } from "@material-ui/core/styles";

const logoSize = 100
;

export default makeStyles((theme) => ({
  paper: {
    position: "relative",
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 0px 8px 1px #ccc",
    // width: 330,
    "&:hover": {
      cursor: "pointer",
    },
    
    margin: theme.spacing(2),
    width: 315,
    height: theme.spacing(40),
    right: 15,
  },
  wrapper: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
   
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: 1,

  },
  companyLogo: {
    width: 51.56,
    height: 50,
    borderRadius: "50%",
  
  },
  companyInfo: {
    height: logoSize,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: theme.spacing(2),
  },

  companyName: {
    // fontFamily: "Montserrat !important",
    fontSize: "16px !important",
    fontWeight: "700 !important",
    // color:"#000000 !important"
    color: theme.palette.blackColor.main,

  },
  captionText: {
    // fontFamily: "Montserrat !important",
    fontWeight: "bold",
    textAlign: "left",
  },
  description: {
    // fontFamily: "Montserrat !important",
    fontSize: "1.5rem",
    textAlign: "left",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3),
    
    // flex: "1 1 auto",
  },
  description1: {
    // fontFamily: "Montserrat !important",
    textAlign: "left",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: 'rgb(74, 74, 74)'
    
  },
  interactions: {
     display: "inline-block",
     position:"absolute",
     right:"5%",
     "&>svg:nth-child(1)":{
       color: theme.palette.redIcon.main
     }
  },
  gridContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  grid: {
    margin: theme.spacing(3),
    marginBottom: "0px",
    position: "absolute",
    bottom: "0"
  },
}));

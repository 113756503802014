import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import useStyles from "./style";
import { Select, Input, AutoComplete } from "formik-antd";
import FadeIn from "react-fade-in";
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import { Field } from 'formik';

const { Option } = Select;

export default function () {
    const classes = useStyles();
    const formik = useFormikContext<FormInfo>();
    const [state, setState] = React.useState(false);
    const [state2, setState2] = React.useState(false);
    const [state1, setState1] = React.useState(false);
    // React.useEffect(() => {
    //     if((formik.values.scoringTypes).includes("i3D Expert"))
    //     {
    //         setState1(true);
    //     }
    //     else
    //     {
    //         setState1(false);
    //     }
    //     if((formik.values.scoringTypes).includes("i3D Rapid"))
    //     {
            
    //         setState2(true);
    //     }
    //     else
    //     {
    //         setState2(false);
    //     }
    //     if(((formik.values.scoringTypes).includes("i3D Expert") && (formik.values.scoringTypes).includes("i3D Rapid")))
    //     {
    //         setState(true);
    //     }
    //     else
    //     {
    //         setState(false);
    //     }
    //   }, [formik.values.scoringTypes]);

    const handleChange = (event) => {
        setState(event.target.checked);
        setState1(event.target.checked);
        setState2(event.target.checked);
        if(event.target.checked)
        formik.values.scoringTypes.push("all");
        else
        {
            var index = formik.values.scoringTypes.indexOf("all");
            if (index > -1) {
                formik.values.scoringTypes.splice(index, 1);
              }
        }
    };
    

    

    // const { Technical, i3DExpert, i3DRapid, All } = state;
    // const error = [Technical, i3DExpert, i3DRapid, All].filter((v) => v).length !== 2;
    return (
        <>
            {/* <FormControl component="fieldset"> */}
                {/* <FormLabel className={classes.expertText} >Select Platform</FormLabel> */}
                <FormGroup className={classes.CompanyDetails} row aria-label="position" name="position" defaultValue="top">
                    <div className={classes.scoringTypeLabel}>
                    <label>
                    <Field className={classes.scoringTypeLabel} type="checkbox" name="scoringTypes" value="Technical" checked={true} disabled />
                        Technical
                    </label>
                    </div>
                    <div className={classes.scoringTypeLabel}>
                    <label>
                    {/* <Field className={classes.scoringTypeLabel} type="checkbox" name="scoringTypes" value="i3D Expert" checked={state1}/> */}
                    <Field className={classes.scoringTypeLabel} type="checkbox" name="scoringTypes" value="i3D Expert" disabled checked={true}/>
                        i3D Expert
                    </label>
                    </div>
                    <div className={classes.scoringTypeLabel}>
                    <label>
                    {/* <Field className={classes.scoringTypeLabel} type="checkbox" name="scoringTypes" value="i3D Rapid" disabled checked={state2} /> */}
                    <Field className={classes.scoringTypeLabel} type="checkbox" name="scoringTypes" value="i3D Rapid" disabled checked={true} />
                        i3D Rapid
                    </label>
                    </div>
                    <div className={classes.scoringTypeLabel}>
                    <label>
                    <Field className={classes.scoringTypeLabel} type="checkbox" name="scoringTypes" value="all" disabled checked={true}/>

                    {/* <Field className={classes.scoringTypeLabel} type="checkbox" name="scoringTypes" value="all" onChange={handleChange} checked={state}/> */}
                        All
                    </label>
                    </div>
                
                    {/* <FormControlLabel
                        value="Technical"
                        control={<Checkbox color="primary" checked={Technical} onChange={handleChange} name="Technical" />}
                        label="Technical"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="i3D Expert"
                        control={<Checkbox color="primary" checked={i3DExpert} onChange={handleChange} name="i3DExpert" />}
                        label="i3D Expert"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="i3D Rapid"
                        control={<Checkbox color="primary" checked={i3DRapid} onChange={handleChange} name="i3DRapid" />}
                        label="i3D Rapid"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="All"
                        control={<Checkbox color="primary" checked={All} onChange={handleChange} name="All" />}
                        label="All"
                        labelPlacement="end"
                    /> */}

                </FormGroup>
            {/* </FormControl> */}

        </>

    );
}
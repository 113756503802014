import React, { useEffect, useState } from 'react';
import { callableFunctions, firestore, storage } from '../../../../repositories/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../../reducers";
import { fetchSubscription } from '../../../../actions/subscription';
import { SubscriptionDispatch } from '../../../../model';
import { Typography, Button, Box, Card, CardContent, Grid } from '@mui/material';
import { Close } from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import useStyles from "./style";
import { useHistory } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import { IconButton } from '@material-ui/core';
import { useParams } from "react-router-dom";
import { truncate } from 'lodash';
function PaymentSuccessFull() {
  // const {location} = useParams();
  const { planId } = useParams();

  // const planId = location.state && location.state.planId;
  // console.debug("planId",planId)
  // console.debug("planId:location",location)
  // console.debug("planId:location:state",location.state)



  // const id = searchParams.get("planDetail");
  const classes = useStyles();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,

  }));
  const [loading, setLoading] = useState(false);
  let history = useHistory()
  const profile = useSelector((state: RootState) => state.firebase.profile);

  const user = useSelector((state: RootState) => state.firebase.auth);
  const subscriptionPlans = useSelector((state: RootState) => state.subscription.allSubscription);
  const [planObject, setPlanObject] = useState()
  const [transactionData, setTransactionData] = useState()
  const [freeTransactionData, setFreeTransactionData] = useState()
  const [pageShown, setPageShown] = useState(false);
  const [disabledDOwnloadButton, setDisabledDOwnloadButton] = useState(false);
  const [downloadErrorMessage, setDownloadErrorMessage] = useState('');
  const downloadUserBonusCollection = firestore.collection("downloadUserBonusDoc")
  const dispatch = useDispatch<SubscriptionDispatch>();
  const userId = user.uid;
  const userEmail = user.email;

  const downloadPDF = async () => {
    setDisabledDOwnloadButton(true)
    try {
      // Replace 'path/to/your/pdf-file.pdf' with the actual path of your PDF file in Firebase Storage
      const pdfFileRef = storage.ref().child('Future-Proofing-Start-ups-ebook.pdf');

      // Get the download URL of the PDF file
      const url = await pdfFileRef.getDownloadURL();

      // Trigger the download
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = 'future-proofing-start-up.pdf'; // Set the desired file name
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
        downloadUserBonusCollection.doc(userId).set({
          read: 'true'
          // timestamp: admi.firestore.FieldValue.serverTimestamp(),
        });

      };
      xhr.open('GET', url);
      xhr.send();
    } catch (error) {
      console.error('Error getting download URL:', error);
    }
  };
  React.useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch]);

  React.useEffect(() => {
    // if (!planId) {
    //   redirectToHome()
    // }
    const downloadSnapshot = downloadUserBonusCollection.doc(userId).get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        setDisabledDOwnloadButton(true)
        // const data = docSnapshot.data();
      } else {
      }
    }).catch((error) => {
      console.error("Error fetching document:", error);
    });
  }, []);
  const getFormateDate = (duration) => {
    const planExpireDate = new Date();
    switch (duration) {
      case '30 Days':
        planExpireDate.setDate(planExpireDate.getDate() + 30);
        break;
      case '3 Months':
        planExpireDate.setMonth(planExpireDate.getMonth() + 3);
        break;
      case '12 Months':
        planExpireDate.setMonth(planExpireDate.getMonth() + 12);
        break;
      default:
        throw new Error('Invalid plan duration');
    }
    return planExpireDate;
  };

  const updateCurrentPlanWithUser = async (id, plan) => {
    try {
      // if (id == "1Faq1IPGLtvhpDnXmkwa") {
      const planData = {
        stripPlanId: id,
        accessDuration: plan.accessDuration,
        duration: plan.accessDuration,
        name: plan.name,
        // planStatus: 'activated',
        planExpireDate: getFormateDate(plan.accessDuration).toISOString(),
        shareProject: plan.share,
        sharedProject: 0,
        uploadProject: plan.uploadProject,
        uploadedProject: 0,
      };
      const updateUserPlan = callableFunctions.httpsCallable('updateUserPlan');
      await updateUserPlan(planData);
      //   history.push("/app/dashboard")
      console.log('Custom object added successfully.');
    } catch (error) {
      console.error('Error adding custom object:', error);
    }
  };

  const redirectToHome = () => {
    history.push("/app/dashboard")
  }
  const getPaymentDetails = callableFunctions.httpsCallable('getPaymentDetails');
  async function fetchData(sessionId) {
    try {
      const response = await getPaymentDetails({ sessionId: sessionId });
      if (response.data) {
        let data = response.data
        setLoading(false)
        setTransactionData(data.subscription[0])
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    // setLoading(true)
    const purchasedPlan = subscriptionPlans.find(o => o.stripePaymentId == profile.purchasedPlanId);
    if (profile.sessionId != undefined) {
      const sessionId = profile.sessionId
      fetchData(sessionId)
    }
    setPlanObject(purchasedPlan)
    if (subscriptionPlans && purchasedPlan)
      updateCurrentPlanWithUser(profile.purchasedPlanId, purchasedPlan)
  }, [profile.purchasedPlanId])



  useEffect(() => {
    const purchasedPlan = subscriptionPlans.find(o => o.stripePaymentId === "Null");
    setFreeTransactionData(purchasedPlan)
    if (subscriptionPlans && purchasedPlan)
      updateCurrentPlanWithUser(purchasedPlan.id, purchasedPlan)
  }, [planId])


  if (loading == true) return <Loader text="wait payment is proceeding" />;
  return (
    <>
      {/* <KeyboardBackspace className={classes?.back} onClick={redirectToHome}/> */}
      <IconButton
        edge="start"
        className={classes?.back}
        color="inherit"
        aria-label="menu"
        onClick={redirectToHome} >
        <Close />
      </IconButton>
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh', background: "aliceblue" }}>
        {freeTransactionData ?
          <Card className={classes?.freeCard} >
            <CardContent className={classes?.cardContent}>
              <img src={"/rocket.png"} className={classes.image} />
              <Typography variant="h5" align="center" gutterBottom>
                Congratulations!
              </Typography>
              <Typography variant="h5" align="center" gutterBottom>
                Your free plan is now active.
              </Typography>
              <Box className={classes.box}>
                <Grid container spacing={2}>
                  <Grid item xs={5} className='ps-5'>
                    <Typography className={classes.typography}>Name</Typography>
                    <Typography className={classes.typography}> Email</Typography>
                    <Typography className={classes.typography}> Amount paid</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography className={classes.typography}>{freeTransactionData ? freeTransactionData.name : "--"}</Typography>
                    <Typography sx={{overflow: "hidden", textOverflow: "ellipsis", }} className={classes.typography}>{freeTransactionData ? userEmail : "--"}</Typography>
                    <Typography className={classes.typography}>Free</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant='h6' align="center">Get Future-Proofing Start-ups</Typography>
              <Button variant="contained" disabled={disabledDOwnloadButton == false ? false : true} sx={{ display: "flex", margin: "auto", marginTop: "1.5rem", background: "#137DFB" }} onClick={downloadPDF}>Download</Button>

            </CardContent>
          </Card> :
          <Card className={classes?.card} >
            <CardContent className={classes?.cardContent}>
              <img src={"/rocket.png"} className={classes.image} />
              <Typography variant="h5" align="center" gutterBottom>
                Transaction Successful
              </Typography>
              <Box className={classes.box}>
                <Grid container spacing={2}>
                  <Grid item xs={6} className='ps-5'>
                    <Typography className={classes.typography}> Payment type</Typography>
                    <Typography className={classes.typography}>Name</Typography>
                    <Typography className={classes.typography}> Email</Typography>
                    {/* {transactionData &&transactionData.email.length>20&& <Typography className={classes.typography} sx={{visibility:"hidden"}}> .</Typography>} */}
                    <Typography className={classes.typography}> Amount paid</Typography>
                    <Typography className={classes.typography}> Transaction Id</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.typography}>{transactionData ? "Online" : "--"}</Typography>
                    {/* date._seconds * 1000 + date._nanoseconds / 1000000 */}
                    <Typography className={classes.typography}>{transactionData ? transactionData.name : "--"}</Typography>
                    <Typography className={classes.typography}>{transactionData ? transactionData.email : "--"}</Typography>
                    <Typography className={classes.typography}>{transactionData ? "$" + (transactionData.amountTotal / 100).toFixed(2) : "--"}</Typography>
                    <Typography className={classes.typography}>{transactionData ? transactionData.invoice : "--"}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant='h6' align="center">Get Future-Proofing Start-ups</Typography>

              <Button variant="contained" disabled={disabledDOwnloadButton == false ? false : true} sx={{ display: "flex", margin: "auto", marginTop: "1.5rem", background: "#137DFB" }} onClick={downloadPDF}>Download</Button>
              {/* {disabledDOwnloadButton == true ? <Typography className={classes.error}>{downloadErrorMessage}</Typography>: ""} */}
            </CardContent>
          </Card>
        }



      </Grid>
    </>
  );
};

export default PaymentSuccessFull;








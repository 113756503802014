import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
    color:"black",
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
    color:"black",
  },
  googleButton: {
    marginTop: theme.spacing(3),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  i3dLogo: {
    height: "130px",
    width: "130px",
  },
  
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > button:disabled,button[disabled]":{
      color:"white",
      backgroundColor:"#137DFB !important",
      }
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
    color:"#fff",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    // "&:before": {
      '&:before': {
        borderBottom: 'none !important', // Remove the underline before input
      },
      '&:after': {
        borderBottom: 'none !important', // Remove the underline after input
      },
      // '&:hover': {
      //   '&:before': {
      //     borderBottom: 'none', // Remove the underline on hover
      //   },'&:after': {
      //     borderBottom: 'none', // Remove the underline on hover
      //   },
      // },

  },
  textField: {
    marginLeft:'11px',
    // color:"black",
    "& > MuiFormLabel-root":{
     color:"#black !important"
    },
    "& > input:webkit-autofill":{
      color: "#black !important"
    }
  },
  
  formikPassword:{
    border:"1.5px solid #B2BEB5 !important",
    padding:"5px",
    borderRadius:"10px",
    "& > label":{
      color:"#B2BEB5",
      paddingLeft:"20px"
     },
      "& > div > input:-webkit-autofill":{
        marginLeft:'11px',
        transition: "background-color 5000s ease-in-out 0s",
        '-webkit-text-fill-color': "#black !important",
     },

},
  selectField:{
    color:"#B2BEB5",
    border:"1px solid !important",
    padding:"12px",
    borderRadius:"10px",
    "& > div":{
      backgroundColor:"#F2F7FF !important",
      color:"#B2BEB5 !important",
    },
    "& > div > span":{
      // backgroundColor:"#F2F7FF !important",
      color:"#B2BEB5 !important",
    },
    "&::-webkit-input-placeholder": { 
      // color: "black" 
      color:"#B2BEB5 !important",
    },
  },
  selectorTextField:{
    backgroundColor:"#F2F7FF !important",
    "& > div":{
      border: "none !important",
    },
    "&::-webkit-input-placeholder": { 
      color: "##B2BEB5 !important" 
    },
  },
  formButtons: {
    width: "100%",
    marginTop: "25px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    
    "& > button:disabled,button[disabled]":{
    color:"white",
    backgroundColor:"#137DFB !important",
    },
    "& > button:hover,button[hover]":{
      color:"white",
      backgroundColor:"#137DFB !important",
    },
    "& > button:focus,button[focus]":{
      color:"white",
      backgroundColor:"#137DFB !important",
    },
    "& > button:active,button[active]":{
      color:"white",
      backgroundColor:"#137DFB !important",
    },
  },
  loginButton:{
    backgroundColor:"#137DFB !important",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
    color:"black",
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  terms:{
    color:"#B2BEB5",
    fontSize:"15px",
  },

  cardContainer:{
    height: "auto",
    width:'60%',
    margin: "16px",
    // position: "relative",
    boxShadow: "0px 0px 8px 1px #ccc",
    textAlign: "center",
    borderRadius: "8px",
    padding: "2rem",   
    paddingTop: "1rem",
    textAlign:'card',
    backgroundColor:theme.palette.background.default,
    "& > span":{
      display: 'block',
      textAlign: 'center',
      fontSize:'16px'
  }
},
container:{
  // height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  // alignItems: "center",
  // position: "relative",
  top: 0,
  left: 0,
  // backgroundColor:'#F2F7FF',
  overflow: "hidden",
  
},
badge:{
  height:'30px',
  width:"30px",
  marginRight:'15px'
},
listItem:{
  margin:'20px',
  listStyleType: 'none',
  textAlign:'left',
  paddingLeft:'0px',
  "& > li":{
    marginBottom:'20px',
    fontSize:'16px'
}
},
LeftCardDiv:{
  width: '100%',
  display: 'flex',
  marginTop: '15px',
  // marginBottom:'5px',
  alignItems: 'center',
  justifyContent: 'center'
},
LeftCardButton:{
  color: 'white',
  backgroundColor: '#137DFB !important',
  marginLeft:'2rem'
},
LeftPricingButton:{
  color: 'white',
  backgroundColor: '#137DFB !important',
  // marginLeft:'2rem'
},
failTitle:{
  fontWeight:700,
  textAlign: "center",
  marginTop: theme.spacing(4),
  color:"black",
},
footerContainer:{
  backgroundColor:"#00005b",
  padding:'15px',
  position:"fixed !important",
  bottom:"0px"
},
info:{
  color:'#137DFB !important',
}

}));

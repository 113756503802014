import React, { useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "./styles";
import { Field, Formik, Form } from "formik";
import { ForgotInfo, forgotValidation } from "./index";
import { TextField } from "material-ui-formik-components";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Auth } from "../../repositories/auth";
import logo from "./logos.png";
import logo3 from '../../images/i3D.png'

function ForgotPassword() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const handleDialogStatus = () => {
        setOpen(!open);
    };

    const handleSubmit = (email) => {
        Auth.resetPasswordEmail(email).then((res) => {
            alert(res);
            history.push("/login");

        })
    };




    
    return (
        <Grid container className={classes['container']}>
            <div className={classes['logotypeContainer']}>
                <img src={logo} alt="logo" className={classes['logotypeImage']} />
                {/* <Typography className={classes.logotypeText}>i3D Platform</Typography>
                <Typo variant="h3" htmlColor="white">
                    Powered by Invluencer Ltd.
                </Typo>
                <Typo
                    color="primary"
                    brightness="contrastText"
                    className={classes.copyright}
                >
                    © 2020 Invluencer Ltd. All rights reserved.
                </Typo> */}
            </div>
            <div className={classes['formContainer']}>
            <div className="text-center">
            <img src={logo3} alt="i3dlogo" className={classes['i3dLogo']} />
            </div>
                <Typography variant="h1" className={classes['greeting']}>
                    Forgot Password
                </Typography>
                <Typography variant="h3" className={classes['forgotpara']}>
                    Nothing to worry about. We have you covered
                </Typography>
                <Typography variant="h3" className={classes['forgotparas']}>
                    All you need to do is input your registered email address and leave the rest to us.
                </Typography>
                <div className={classes['form']}>

                    <Formik<ForgotInfo>
                        initialValues={{
                            email: "",
                        }}
                        validateOnMount
                        validateOnChange
                        validateOnBlur
                        validationSchema={forgotValidation}
                        // onSubmit={(values) => dispatch(forgetPass(values.email))}
                        onSubmit={(values) => handleSubmit(values.email)}
                    >
                        {(formik) => {
                            return (
                                <Form>

                                    <Field className={classes['formikPassword']}
                                        required
                                        name="email"
                                        label="Email"
                                        component={TextField}
                                        margin="normal"
                                        placeholder="Email Address"
                                        type="email"
                                        fullWidth
                                        InputProps={{
                                            classes: {
                                                underline: classes['textFieldUnderline'],
                                                input: classes['textField'],
                                            },
                                        }}
                                    />
                                    <Button className={classes['submitButton']}
                                        color="primary"
                                        size="large"
                                        onClick={(e) => {
                                            console.debug("hi");
                                            handleSubmit(formik.values.email);
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    <p className={classes['loginLink']}>
                                        Go to the <Link to="/"> login page</Link>
                                    </p>
                                </Form>
                            );
                        }}
                    </Formik>

                </div>
            </div>
            <Dialog onClose={handleDialogStatus} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Mail has been sent to your registered email</DialogTitle>
            </Dialog>
        </Grid>
    );
}

export default ForgotPassword;

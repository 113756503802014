import React, { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { Select, Input, AutoComplete } from "formik-antd";
import FadeIn from "react-fade-in";
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import { Paper, Grid } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    Typography, Button
} from "@material-ui/core";
import { LayoutAction, ModalType } from "../../../../model";
import { showModal } from "../../../../actions/layout";

const { Option } = Select;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: "600",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(name, role, experience, education, Ptorft) {
    return { name, role, experience, education, Ptorft };
}

const rows = [
    createData('Michael Doyle', "Founder CEO", "Experience as CEO", "	Masters Level & Above", "Full time"),
    createData('Heamanth Eagala', "Technical", "	Product Manager", "Bachelor Degree", "Part time"),
    createData('Patrick Doyle', "Research", "Experience as CEO", "Masters Level & Above", "Part time"),
];

export default function () {
    const classes = useStyles();
    const formik = useFormikContext<FormInfo>();
    var [isMoreMenuOpen, setMoreMenuOpen] = React.useState<boolean>(false);
    const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();

    return (
        <FadeIn>
            <div className={classes.addButton}>
                <Button color="primary"
                    onClick={(e) => {
                        setMoreMenuOpen(false);
                        dispatchLayout(showModal(ModalType.TEAMADD));
                    }}
                >Add Teams</Button>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="right">Role</StyledTableCell>
                            <StyledTableCell align="right">Experience</StyledTableCell>
                            <StyledTableCell align="right">Education</StyledTableCell>
                            <StyledTableCell align="right">FT or PT</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.role}</StyledTableCell>
                                <StyledTableCell align="right">{row.experience}</StyledTableCell>
                                <StyledTableCell align="right">{row.education}</StyledTableCell>
                                <StyledTableCell align="right">{row.Ptorft}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </FadeIn>

    );
}
import { SubscriptionState, SubscriptionAction,SubscriptionActions } from "../model/subscription";
import { Reducer } from "react";

const initialState: SubscriptionState = {
    allSubscription: [],
    fetching: true,
    fetchError:undefined,
};

export const subscriptionReducer: Reducer<SubscriptionState, SubscriptionAction> = (
  state: SubscriptionState = initialState,
  action: SubscriptionAction
): SubscriptionState => {
  switch (action.type) {
    case SubscriptionActions.FETCH:
      return { ...state, fetching: true, fetchError: undefined };
    case SubscriptionActions.FETCHED_ALL:
      return {...state, fetching: false,fetchError: undefined,
        allSubscription: action.payload ?? [],
      };
    case SubscriptionActions.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: "error" };

    default:
      return state;
  }
};

import React, { useState } from "react";
import { Route, Switch, withRouter, Redirect,useHistory } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

// pages
import { Dashboard,MyUploads, Reports, Notifications, Breakdown, ProfileDetails, FutureProfileDetail } from "../../pages";
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { NotificationsDispatch } from "../../model";

//actions
import { fetchNotifications } from "../../actions/notifications";
import Test from "../../pages/Test";
import Drafts from "../../pages/drafts/Drafts";

function Layout(props: any) {
  var classes = useStyles();
  const walletOpen = useSelector((state: RootState) => state.metamask.walletOpen);
  const dispatch = useDispatch<NotificationsDispatch>();
  const history = useHistory();
  const [redirect,setRedirect] = useState(false);
  React.useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const layoutState = useSelector((state: RootState) => state.layout);
  // React.useEffect(() => {
  //   // console.debug(walletOpen && props.location.pathname!='/app/dashboard' && props.location.search)
  //   setRedirect((walletOpen && props.location.pathname!='/app/dashboard') || props.location.search)
  // }, [props.location]);
  // if(redirect)
  // {
  //   return <Redirect
  //     to={{
  //       pathname: "/connect",
  //       state: {
  //         from: props.location,
  //       },
  //     }}
  //   />
  // }
  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar/>
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          // style={{backgroundColor:"#F2F7FF"}}
        >
          <div className={classes.fakeToolbar}/>
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/test" component={Test} />
            <Route path="/app/drafts" component={Drafts} />

            {/* <Route path="/app/myUploads" component={MyUploads} /> */}
           
            <Route path="/app/reports/:id" component={Reports} />
   
            <Route path="/app/notifications" component={Notifications} />
            <Route path="/app/breakdown/:id" component={Breakdown} />
            <Route path="/app/profile" component={ProfileDetails} />
            <Route path="/app/profileDetail" component={FutureProfileDetail} />
            <Route render={() => <Redirect to="/app/dashboard" />} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);

import React from "react";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Select } from "formik-antd";
import FadeIn from "react-fade-in";
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import FundingProposition from "../Funding/FundingProposition";
const { Option } = Select;

export default function ({ technicalScoringFund, technicalScoringMarket }) {
    const classes = useStyles();
    const formik = useFormikContext<FormInfo>();
    return (
        <div>
            <FadeIn>
                <div className={classes.cardContainer}>
                    {technicalScoringMarket.map((question, index) => {
                        return (
                            <div key={index}>
                                <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                                    <Typography variant="h4" className={classes.sectionTitle}>
                                        {question.label}
                                    </Typography>
                                    <Select
                                        name={question.value}
                                        defaultValue={question.default}
                                        style={{ width: "100%" }}
                                        className={classes.CompanyDetails}
                                    >
                                        {question.options.map((stage) => (
                                            <Option key={stage.id} value={stage.score + "|" + stage.comment} title={stage.value}>
                                                {stage.value}
                                            </Option>
                                        ))}
                                    </Select>

                                </div>
                                <div>
                                    {formik.errors[question.value] && formik.touched[question.value] && (
                                        <Typography variant="caption" color="secondary">
                                            {formik.errors[question.value]}
                                        </Typography>
                                    )}
                                </div>
                            </ div>
                        )
                    })}

                </div>
                {/* ________________________ Funding proposition ____________________ */}

                <FundingProposition technicalScoringFund={technicalScoringFund} />
            </FadeIn>
        </div>

    );
}
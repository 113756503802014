import React from "react";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Field } from 'formik';
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import { Editor } from '@tinymce/tinymce-react';
import { Grid } from "@material-ui/core";

export default function (props) {
    const classes = useStyles();
    const formik = useFormikContext<FormInfo>();

    const removeHtmlTags = (html: string) => {
        return html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ");
      }
  

    return (
        <Grid item xs={6}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h4" className={classes.sectionTitle}>
                        {props.label}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{'(max 330 characters)'}</Typography>
                </Grid>
            </Grid>
             
            {removeHtmlTags(formik.values[props.name]).length > 330 &&
                <Typography className={classes.error}>
                    No. of characters exceeded 330
                </Typography>
            }
            <Field name={props.name} className={classes.companyDescription + " " + classes.companyDes}>
                {({ field, meta }) => (
                    <div>
                        <Editor
                      
                      apiKey='r7ybttnlquoxnwu4golhbicp9pm9wg9b1jupd5i3e0ldhzne'
                      init={{
                        plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'emoticons'],
                        toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help | emoticons '+"link| autolink",
                        toolbar_location: "top-right",
                        menubar: false,
                        force_br_newlines : true,
                        statusbar: true,
                    
                      }}
                            id={props.name}
                            // initialValue=''
                            onEditorChange={(e) => {
                                formik.handleChange({ target: { name: props.name, value: e } })
                            }}
                            textareaName={props.name}
                            // onChange={field.onChange}
                            onBlur={field.onBlur}
                            value={formik.values[props.name]}
                        />
                    </div>
                )}

            </Field>
        </Grid>
    );
}
import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { FormInfo } from "../../model";
import { Typography } from "../../components/Wrappers/Wrappers";
// import { Typography } from "@material-ui/core";
import { Button } from "antd";
import { Select, Input, AutoComplete } from "formik-antd";
import { useFormikContext } from "formik";
import { AddTeams } from "../../model";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { time, Experience, education, Roles, age } from "../../pages/create/components/data";
import DOMPurify from "dompurify";
import useStyles from "./style";
import TEditor from "../../pages/create/components/Problem/TEditor";
import { Editor } from "@tinymce/tinymce-react";
const { Option } = Select;
interface DeleteProps {
  addOpen: boolean;
  setAddOpen;
  user: AddTeams[];
  setUser;
  // onClose: () => void;
  // project: Project;
}
// const schema = Yup.object().shape<DeleteConfirmation>({
//   confirmation: Yup.string()
//     .matches(new RegExp("DELETE"), "Text does not match caption.")
//     .required("Please confirm the deletion."),
// });
const schema = Yup.object().shape<AddTeams>({
  // CompanyName: Yup.string()
  //   .max(30, "Company name must be less than 30 characters")
  //   .required("The company needs a name!"),
  Role: Yup.array<string>()
    .min(1, "At least 1 section must be selected.")
    .required("This field is required"),
  Experience: Yup.string().required("This field is required!"),
  Education: Yup.string().required("This field is required!"),
  name: Yup.string().required("This field is required!"),
  time: Yup.string().required("This field is required!"),
  memberDescription: Yup.string().optional(),
  linkedin: Yup.string().url().optional(),
  // age: Yup.number().required("This field is required if role is ceo"),

});
const removeHTMLTags = (str) => {
  return str.replace(/<[^>]*>/g, '');
};

function AddTeamsDialog(props: DeleteProps) {
  const { addOpen, setAddOpen, user, setUser } = props;
  // const [open,setOpen] = React.useState(false);
  // const [user,setUser] = React.useState<AddTeams[]>([]);
  // const formik = useFormikContext<AddTeams>();
  const formik = useFormikContext<AddTeams>();
  const [editorContent, setEditorContent] = useState("");
  const classes = useStyles();
  // const Social = [
  //   { id: 1, value: "Company raised fund first year" }, { id: 2, value: "Company raised fund second year" }, { id: 2, value: "Company raised fund third year" }
  // ]
  //   const dispatch = useDispatch<ProjectDispatch>();
  //   const { deleting, deleted, deleteError } = useSelector(
  //     (state: RootState) => state.project
  //   );
  const handleClose = () => {
    // onClose();
    setAddOpen(!addOpen);
  };
  const [editorKey, setEditorKey] = useState(1);
  const handleSubmit = (values, onsubmitProps) => {
    console.debug(values);
    setUser([...user, values]);
    setAddOpen(!addOpen);
    onsubmitProps.resetForm();
  };
  console.debug("Formik:", formik)
  const sanitizeHTML = (html: string) => {
    return DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
  };
  return (
    <Formik<AddTeams>
      onSubmit={(values, onsubmitProps) => {
        values.memberDescription = removeHTMLTags(values.memberDescription);
        handleSubmit(values, onsubmitProps)}}
      initialValues={{
        time: "",
        Role: [],
        Experience: "",
        Education: "",
        name: "",
        linkedin: "",
        memberDescription: "",
      }}
      validationSchema={schema}
      validateOnChange
      validateOnMount
    >
      {({ values, isValid, errors, touched, submitForm, setFieldValue }) => (
        <Modal
          title="Add Teams"
          centered
          className={classes.modalTeam}
          zIndex={2}
          visible={addOpen}
          onCancel={handleClose}
          footer={[
            <Button key="back"
              onClick={handleClose}
            >
              Cancel
            </Button>,
            <Button
              disabled={!isValid}
              key="submit"
              type="primary"
              style={{background:"#137DFB"}}
              //   loading={deleting}
              onClick={submitForm}
            >
              Submit
            </Button>
          ]}
        >

          <div className={classes.inputFields} style={{}}>
            <Typography variant="h7" className={classes.sectionTitle}>
              Name
            </Typography>
            <AutoComplete
              style={{ borderRadius: 10 }}
              name="name"
              placeholder="name of the user"
              className={classes.CompanyDetails}
            />
            {errors.name && touched.name && (
              <Typography color="error" className={classes.errorSize}>{errors.CompanyName}</Typography>
            )}
          </div>

          <div className={classes.inputFields} style={{}}>
            <Typography variant="h7" className={classes.sectionTitle}>
              Linkedin
            </Typography>
            <AutoComplete
              style={{ borderRadius: 10 }}
              name="linkedin"
              placeholder="linkedin profile url of the user"
              className={classes.CompanyDetails}
            />
            {errors.linkedin && touched.linkedin && (
              <Typography color="error" className={classes.errorSize}>{errors.linkedin}</Typography>
            )}
          </div>


          <div className={classes.inputFields} style={{ borderRadius: 10, }}>
            <Typography variant="h7" className={classes.sectionTitle}>
              Role
            </Typography>
            <Select
              name="Role"
              mode="tags"
              tokenSeparators={[","]}
              defaultValue="No Role selected"
              className={classes.CompanyDetails}
            >
              {Roles.map((stage) => (
                <Option key={stage.id} value={stage.value} title={stage.value}>
                  {stage.value}
                </Option>
              ))}
            </Select>
            {errors.Role && touched.Role && (
              <Typography color="error" className={classes.errorSize} >{errors.Role}</Typography>
            )}
          </div>

          {values.Role.includes("CEO") ? <div className={classes.inputFields} style={{}}>
            <Typography variant="h7" className={classes.sectionTitle}>
              age
            </Typography>
            <Select
              name="age"
              defaultValue="No age selected"
              className={classes.CompanyDetails}
            >
              {age.map((stage) => (
                <Option key={stage.id} value={stage.score} title={stage.value}>
                  {stage.value}
                </Option>
              ))}
            </Select>
            {errors.age && touched.age && (
              <Typography color="error" className={classes.errorSize}>{errors.age}</Typography>
            )}
          </div> : <></>}


          <div className={classes.inputFields} style={{ borderRadius: 10, }}>
            <Typography variant="h7" className={classes.sectionTitle}>
              Experience
            </Typography>
            <Select
              name="Experience"
              defaultValue="No Experience selected"
              className={classes.CompanyDetails}
            >
              {Experience.map((stage) => (
                <Option key={stage.id} value={stage.value} title={stage.value}>
                  {stage.value}
                </Option>
              ))}
            </Select>
            {errors.Experience && touched.Experience && (
              <Typography color="error" className={classes.errorSize}>{errors.Experience}</Typography>
            )}
          </div>

          <div className={classes.inputFields} style={{ borderRadius: 10, }}>
            <Typography variant="h7" className={classes.sectionTitle}>
              Education
            </Typography>
            <Select
              name="Education"
              defaultValue="No Education selected"
              className={classes.CompanyDetails}
            >
              {education.map((stage) => (
                <Option key={stage.id} value={stage.value} title={stage.value}>
                  {stage.value}
                </Option>
              ))}
            </Select>
            {errors.Education && touched.Education && (
              <Typography color="error" className={classes.errorSize}>{errors.Education}</Typography>
            )}
          </div>

          <div className={classes.inputFields} style={{ borderRadius: 10, }}>
            <Typography variant="h7" className={classes.sectionTitle}>
              PT or FT
            </Typography>
            <Select
              name="time"
              defaultValue="No PT or FT selected"
              className={classes.CompanyDetails}
            >
              {time.map((stage) => (
                <Option key={stage.id} value={stage.value} title={stage.value}>
                  {stage.value}
                </Option>
              ))}
            </Select>
            {errors.Education && touched.Education && (
              <Typography color="error" className={classes.errorSize}>{errors.Education}</Typography>
            )}
          </div>

          {/* <div className="mt-3" style={{}}> */}
            {/* <div className="d-flex">
              <Typography variant="h7" className={classes.sectionTitle}>
                Additional Info
              </Typography>
              <Grid item xs={6}>
                <Typography>{'(max 330 characters)'}</Typography>
              </Grid>
            </div> */}
            {/* {formik.values["memberDescription"].length > 330 &&
                <Typography className={classes.error}>
                    No. of characters exceeded 330
                </Typography>
            } */}
            {/* <Field name="memberDescription" className={classes.sectionTitle}>
              {({ field, meta }) => (
                <div>
             <Editor
                    apiKey='cqcs2kis87q9a5elaqbtrdbbdt08j41o80kie5n5cwrgaftk'
                    init={{
                      // Simplify the init object, remove unnecessary plugins
                      plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'emoticons'],
                      toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link',
                      toolbar_location: "top-right",
                      menubar: false,
                      statusbar: true,
                    }}
                   
                    value={values.memberDescription}
                    onEditorChange={(content) => {
                      setEditorContent(content);
                      setFieldValue('memberDescription', content);
                    }}
                    // onFocus={() => setEditorFocus(true)} // Set editorFocus to true when the editor receives focus
                    // onBlur={() => setEditorFocus(false)}
                    onFocus={() => setEditorKey((prevKey) => prevKey + 1)}
                  />
                  {errors.memberDescription && touched.memberDescription && (
                    <Typography color="error" className={classes.errorSize}>{errors.memberDescription}</Typography>
                  )}
                </div>
              )}
            </Field> */}



            {/* <AutoComplete
              style={{ borderRadius: 10 }}
              name="memberDescription"
              placeholder="Info of the member about what he does "
              className={classes.CompanyDetails}
            /> */}

          {/* </div> */}
        </Modal>
      )}
    </Formik>
  );
}

export default AddTeamsDialog;

import React from "react";
import {
  makeStyles,
  Theme,
  CircularProgress,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import { Project } from "../../../../model";
import {
  getSectionLabel,
} from "../../../create/components/data";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
interface Props {
  rank: number;
  previousRank: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  rankHeading: {
    // fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "0.44px",
    color: "#000000",
  },
  rank: {
    // fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "30px",
    lineHeight: "44px",
    letterSpacing: "0.44px",
    color: "#000000",
  },
}));

function Ranking(props: Props) {
  const { rank, previousRank } = props;
  const classes = useStyles();
  const containerSize = 400;

  return (
      <div>
          <span className={classes.rankHeading}>
            Overall Rank
          </span>
          <br/>
          <Grid container>
            <Grid item xs={6}>
              <span className={classes.rank}>
              {rank}{rank==1?"st":rank==2?"nd":rank==3?"rd":"th"}
              </span>
            </Grid>
            <Grid item xs={6} style={{marginTop:10,paddingLeft:15}}>
              <span style={{fontSize:20,fontWeight:"400"}}>
                {Math.abs(rank-previousRank)}
              </span>
              {rank-previousRank<=0
               ?<ArrowDropUpIcon style={{color:"#00B929",marginBottom:-5}}/>
               :<ArrowDropDownIcon style={{color:"#ff0000",marginBottom:-5}}/>}
              
            </Grid>
          </Grid>
          <span className={classes.rankHeading}>
              compared to last week
          </span>
      </div>
       
      );
}

export default Ranking;

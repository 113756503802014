import {
  LayoutActions,
  ModalType,
  Project,
  LayoutAction,
  Invitation,
} from "./../model";

export const toggleSidebar = () => ({
  type: LayoutActions.TOGGLE_SIDEBAR,
});

export const showModal = (type: ModalType, project: Project) => ({
  type: LayoutActions.TOGGLE_MODAL,
  payload: { type, project },
});

export const showPastDueModal = (): LayoutAction => ({
  type: LayoutActions.TOGGLE_MODAL,
  payload: { type: ModalType.PAST_DUE },
});

export const showUpgradeModal = (): LayoutAction => ({
  type: LayoutActions.TOGGLE_MODAL,
  payload: { type: ModalType.UPGRADE },
});

export const shareUpgradeModal = (): LayoutAction => ({
  type: LayoutActions.TOGGLE_MODAL,
  payload: { type: ModalType.SHAREUPGRADE },
});

export const showFeedbackModal = (): LayoutAction => ({
  type: LayoutActions.TOGGLE_MODAL,
  payload: { type: ModalType.FEEDBACK },
});

export const showReminderModal = (invitation: Invitation): LayoutAction => ({
  type: LayoutActions.TOGGLE_MODAL,
  payload: { type: ModalType.REMINDER, invitation },
});

export const closeModal = () => ({
  type: LayoutActions.TOGGLE_MODAL,
});

import React from "react";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Field } from 'formik';
import { useFormikContext } from "formik";
import { FormInfo } from "../../../../model";
import { Editor } from '@tinymce/tinymce-react';
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import TEditor from "./TEditor";
import { ImagesUploadField } from "../Fields/ImagesUpload";
import { info, fields } from "../infos";
import CustomiseTooltip from "../../../../components/Tooltip/CustomiseTooltip";

export default function () {
    const classes = useStyles();
    const formik = useFormikContext<FormInfo>();

    return (
        <div>
            <Typography variant='h4' className={classes.pageTitle}>
                Tokenomics
                <CustomiseTooltip className={classes.infoIcon} title={info.tokenomics} />

                {/* <Tooltip title={info.tokenomics} placement="right">
                <IconButton>
                    ⓘ
                </IconButton>
                </Tooltip> */}
            </Typography>
            <Grid container spacing={4}>
                {fields.tokenomics.map((field) => (<TEditor name={field.value} label={field.label} />))}
                <Grid item xs={6}>
                    <ImagesUploadField fieldName={`imageLinks.${'token1'}`} />
                </Grid>
                <Grid item xs={6}>
                    <ImagesUploadField fieldName={`imageLinks.${'token2'}`} />
                </Grid>

            </Grid>
        </div>
    );
}
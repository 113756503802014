import React, { Dispatch, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import {
    AppBar, Toolbar, Typography, IconButton, Button as MButton, Stepper, Step, StepLabel,
} from "@material-ui/core";
import { useCounter } from "ahooks";
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import { FileUpload, Share } from "./components";
import { Button } from "../../components/Wrappers/Wrappers";
import FadeIn from "react-fade-in";
import { ProjectDispatch, FormInfo, LayoutAction } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { createProject } from "../../actions/project";
import { RootState } from "../../reducers";
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import { sectors } from "./components/data";
import { Moment } from "moment";
import ExpertScore from "./components/ExpertScore/ExpertScore";
import CompanyInformation from "./components/TechnicalScore/CompanyInformation";
import TeamCompanyScore from "./components/TechnicalScore/TeamCompanyScore";
import BusinesScape from "./components/TechnicalScore/BusinesScape";

const validationSchema = Yup.object().shape<FormInfo>({
    companyImage: Yup.mixed(),
    companyName: Yup.string()
        .max(30, "Company name must be less than 30 characters")
        .required("The company needs a name!"),
    companyDescription: Yup.string()
        .min(10, "Description must be at least 10 characters")
        .max(95, "Description must be less than 67/250 characters")
        .required("The company needs a description!"),
    companySector: Yup.string().oneOf(
        sectors.map((s) => s.value),
        "This company needs a sector of operations!"
    ),
    companyStage: Yup.string().required("This company needs a stage to be at!"),
    companyWebsite: Yup.string()
        .url("This website is not valid.")
        .required("A company must also have a website."),
    dealSource: Yup.string().url().optional(),
    contactEmail: Yup.string().optional(),
    privacy: Yup.number(),
    sections: Yup.array<string>()
        .min(1, "At least 1 section must be selected.")
        .required("This field is required"),
    emails: Yup.array<string>()
        .min(1, "At least one email must be provided.")
        .max(15, "Maximum 15 emails allowed.")
        .required("This field is required."),
    selectedEmails: Yup.array<string>()
        // .min(1, "At least one email must be provided.")
        .max(15, "Maximum 15 emails allowed.")
        .optional(),
    dateRange: Yup.array<Moment>()
        .min(2, "There must be a start AND end date to this project.")
        .max(2, "There must be a start AND end date to this project.")
        .required("This field is required."),
    sectionWeights: Yup.mixed().required("This field is required."),
    emailSections: Yup.mixed().required("This field is required."),
    legal: Yup.boolean().oneOf([true], "You must accept these terms.").required(),
    pdfLinks: Yup.mixed(),
    singlePdf: Yup.boolean().required("This field is required"),
    singlePdfLink: Yup.string().optional(),
});

const intitialValues: FormInfo = {
    legal: false,
    sections: [],
    emails: [],
    selectedEmails: [],
    companyName: "",
    companyDescription: "",
    companyImage: "",
    companySector: "",
    companyStage: "",
    companyWebsite: "",
    privacy: 0,
    dealSource: "",
    contactEmail: "",
    sectionWeights: {},
    emailSections: {},
    dateRange: [],
    pdfLinks: {},
    singlePdf: false,
};

function CreateTechnicalScore() {
    const classes = useStyles();
    const history = useHistory();
    const [step, { inc, dec }] = useCounter(0);
    const profile = useSelector((state: RootState) => state.firebase.profile);
    const { creating, createError } = useSelector(
        (state: RootState) => state.project
    );
    const dispatch = useDispatch<ProjectDispatch>();

    function getContent() {
        switch (step) {
            case 0:
                return <CompanyInformation />;
            case 1:
                return <TeamCompanyScore />;
            case 2:
                return <BusinesScape />;
          
            case 3:
                return <ExpertScore />;
            case 4:
                return <FileUpload />;
            case 5:
                return <Share />;
            default:
                return null;
        }
    }

    function getButtons(formik: FormikProps<FormInfo>) {
        if (step === 0) {
            const valid =
                !formik.errors.companyImage &&
                !formik.errors.companyDescription &&
                !formik.errors.companyName &&
                !formik.errors.companySector &&
                !formik.errors.companyStage &&
                !formik.errors.companyWebsite &&
                !formik.errors.dealSource;
            return (
                <>
                    <Button type="button" onClick={() => inc()}>
                        Next
                    </Button>
                </>
            );
        } else if (step === 1 || step === 2|| step ===3) {
            return (
                <>
                    <Button type="button" onClick={() => inc()}>
                        Next
                    </Button>
                    <Button type="button" onClick={() => dec()}>
                        Back
                    </Button>
                </>
            );
        } else if (step === 4) {
            console.debug(Object.keys(formik.values.pdfLinks).length)
            console.debug(Object.keys(formik.values.pdfLinks).length < 1)
            const valid =
                !!formik.values.sections.length &&
                formik.values.dateRange.length === 2 &&
                ((formik.values.singlePdf && !!formik.values.singlePdfLink) ||
                    Object.keys(formik.values.pdfLinks).length >= 1) &&
                Object.values(formik.values.sectionWeights).reduce(
                    (p, n) => p + n,
                    0
                ) === 100;
            return (
                <>
                    <Button disabled={!valid} type="button" onClick={() => inc()}>
                        Next
                    </Button>
                    <Button type="button" onClick={() => dec()}>
                        Back
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <Button
                        disabled={!formik.isValid}
                        type="button"
                        onClick={() => formik.submitForm()}
                    >
                        Submit
                    </Button>
                    <Button
                        type="button"
                        onClick={() => {
                            dec();
                        }}
                    >
                        Back
                    </Button>
                </>
            );
        }
    }

    if (creating) return <Loader text="Creating project..." />;
    if (createError) return <ErrorDisplay error={createError} />;

    return (
        <div className={classes.root}>
            <Formik<FormInfo>
                initialValues={intitialValues}
                validateOnMount={true}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    dispatch(createProject(values, history));
                }}
            >
                {(formik) => (
                    <Form className={classes.root}>
                        <FadeIn>
                            <AppBar elevation={0} position="static" color="transparent">
                                <Toolbar className={classes.toolbar}>
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={() => history.goBack()}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography className={classes.title}>
                                        {formik.values.companyName || "Untitled Project"}
                                    </Typography>
                                    <MButton color="inherit" onClick={() => history.goBack()}>
                                        Cancel
                                    </MButton>
                                </Toolbar>
                            </AppBar>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <div className={classes.stepperContainer}>
                                    <Stepper
                                        style={{ backgroundColor: "transparent" }}
                                        activeStep={step}
                                    >
                                        {/* <Step>
                      <StepLabel>Select Platform</StepLabel>
                    </Step> */}
                                        <Step>
                                            <StepLabel>Company Info</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel>Teams</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel>Business Lanscape</StepLabel>
                                        </Step>
                                        {/* <Step>
                      <StepLabel>Funding Proposition</StepLabel>
                    </Step> */}
                                        <Step>
                                            <StepLabel>Expert Score</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel>Sections</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel>Share</StepLabel>
                                        </Step>
                                    </Stepper>
                                    {getContent()}
                                    <div style={{ marginBottom: 40 }} />
                                    {getButtons(formik)}
                                </div>
                            </div>
                        </FadeIn>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default CreateTechnicalScore;

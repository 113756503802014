
import React from 'react';
import { Typography } from '@material-ui/core';
import 'jspdf-autotable';
  const CompanyDetailPDF = ({ data }) => {
  const containerStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    height:"100vh"
  }
  const container = {
   paddingTop:"20rem"
  }
  return (
    <div style={containerStyle}> 
    <div style={container} >
      <div className="mb-5 text-center">
          <div className="mx-auto" style={{height:"200px" ,width:"200px"}}>
            <img src={data.image} className="mx-auto" style={{height:"100%" ,width:"100%"}} alt=""/>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 text-white-50 d-flex justify-content-end">
            <span className="w-50 h6">Company Name:</span>
          </div>
          <div className="col-md-6 text-white" style={{paddingRight:"5rem"}}>{data.companyName}</div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 text-white-50 d-flex justify-content-end">
            <Typography className="w-50">Description:</Typography>
          </div>
          <div className="col-md-6 text-white" style={{paddingRight:"5rem"}}>{data.companyDescription}</div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 text-white-50 d-flex justify-content-end">
            <Typography className="w-50">Stage:</Typography>
          </div>
          <div className="col-md-6 text-white" style={{paddingRight:"5rem"}}>{data.companyStage}</div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 text-white-50 d-flex justify-content-end">
            <Typography className="w-50">Sector:</Typography>
          </div>
          <div className="col-md-6 text-white" style={{paddingRight:"5rem"}}>{data.companySector}</div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 text-white-50 d-flex justify-content-end">
            <Typography className="w-50">WebSite:</Typography>
          </div>
          <div className="col-md-6 text-white" style={{paddingRight:"5rem"}}>{data.companyWebsite}</div>
        </div>
      {/* ... your existing code for the first page ... */}
    </div>
    </div>
  );
};

export default CompanyDetailPDF;

import React ,{ useEffect, Dispatch, useState }from "react";

import {
  makeStyles,
  withStyles,
  Theme,
  Grid,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Typography,Paper,
} from "@material-ui/core";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ProjectRepo } from "../../../repositories/project";
import { Scrollbars } from 'react-custom-scrollbars';
import { Project,IResponse, ProjectDispatch, Report } from "../../../model";
import {
    HIGH_SCORE_THRESHOLD,
    RED_FLAG_THRESHOLD,
  } from "../../../utils/constants";
  import useStyles from '../styles';
import { fetchReport } from "../../../actions/project";
import { getSectionOptions, SectionOption } from "../../create/components/data";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../reducers";
import { groupBy } from "../../../utils";
  enum FilteringType {
    none = "none",
    redFlagsOnly = "redFlagsOnly",
    highScores = "highScores",
    general = "general",
  }

  interface Props {
    project: Project;
    data: Array<Record<string,any>>;
  }
  
  interface State {
    reportId?: string;
    section?: SectionOption;
    possibleSections: SectionOption[];
    setSection: React.Dispatch<React.SetStateAction<SectionOption | undefined>>;
    loading: boolean;
    report: Report | undefined;
    numReviews: number;
    sectionScore: number;
    filtering: FilteringType;
    setFiltering: React.Dispatch<React.SetStateAction<FilteringType>>;
  }

  function _checkFiltering(
    response: IResponse,
    filtering: FilteringType
  ): boolean {
    const score = response.score / 9;
   
    switch (filtering) {
      case FilteringType.redFlagsOnly:
        return score < RED_FLAG_THRESHOLD;
      case FilteringType.highScores:
        return score > HIGH_SCORE_THRESHOLD;
        case FilteringType.general:
          let bool = score > RED_FLAG_THRESHOLD && score < HIGH_SCORE_THRESHOLD;
        return bool ;
      default:
        return true;
    }
  }
  function useBreakdownState(): State {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<ProjectDispatch>();
    const { fetching, fetchError, focusedReport: report } = useSelector(
      (state: RootState) => state.project
    );
    const [sectionState, setSectionState] = React.useState<
      SectionOption | undefined
    >();
    const [sections, setSections] = React.useState<SectionOption[]>([]);
    const [filtering, setFiltering] = React.useState<FilteringType>(
      FilteringType.none
    );
  
    React.useEffect(() => {
      if (report) {
        const grouped = groupBy(report.responses, "section");
        const sections = Array.isArray(report.project.sections)
          ? report.project.sections
          : Object.keys(report.project.sections);
        const options = getSectionOptions(sections).sort((a, b) => {
          const bLength = grouped[b.value]?.length ?? 0;
          const aLength = grouped[a.value]?.length ?? 0;
          return bLength - aLength;
        });
  
        setSectionState(options[0]);
        setSections(options);
      }
    }, [report]);
  
    React.useEffect(() => {
      if (id) {
        dispatch(fetchReport(id));
      }
    }, [dispatch, id]);
  
    const numReviews =
      report && sectionState
        ? report.project.sectionStats[sectionState.value].completed
        : 0;
    const sectionScore =
      report && sectionState
        ? report.project.sectionStats[sectionState.value].avgRating
        : 0;
  
    return {
      reportId: id,
      section: sectionState,
      setSection: setSectionState,
      possibleSections: sections,
      loading: fetching || !sectionState,
      report,
      numReviews,
      sectionScore,
      filtering: filtering,
      setFiltering: setFiltering,
    };
  }
  function Insights(props: Props) {
    const { project, data } = props;
    const classes = useStyles();
    const state = useBreakdownState();
    const responses = state.report.responses.filter(
        (c) =>
          c.section === state.section?.value &&
          _checkFiltering(c, state.filtering) &&
          !!c.comment
      );

      return(
    <Paper elevation={3} style={{maxHeight:700, minWidth:90+'%',textAlign:"start",paddingLeft:44,paddingTop:30,paddingRight:35,borderRadius:10,}}>
    <Scrollbars style={{ height: 650 }} autoHide>
    <span className={classes.positive}>Positives:</span>
   
    <ul className={classes.list}>
      {responses.map((v) => (
        <Typography
          variant="h6"
          className={classes.body}
          style={{
            color: "inherit",
            marginBottom: 4.0,
          }}
          
        >
        {_checkFiltering(v, FilteringType.highScores) && "-"+ v.comment}
          
        </Typography>
      ))}
    </ul>
    
      <span className={classes.negative}>Negatives:</span>
     
    <ul className={classes.list}>
      {responses.map((v) => (
        <Typography
          variant="h6"
          className={classes.body}
          style={{
            color: "inherit",
            marginBottom: 4.0,
          }}
          
        >
         {_checkFiltering(v, FilteringType.redFlagsOnly) && "-"+ v.comment}
         {console.debug("Length of +",v.comment.length)}
        </Typography>
      ))}
    </ul>
         
    <span className={classes.general}>General:</span>

   
    <ul className={classes.list}>
      {responses.map((v) => (
        <Typography
          variant="h6"
          className={classes.body}
          style={{
            color: "inherit",
            marginBottom: 4.0,
          }}
          
        >
         {_checkFiltering(v, FilteringType.general) && "-"+ v.comment}
         {console.debug("Length of +",v.comment.length)}
        </Typography>
      ))}
    </ul>
    </Scrollbars>
    </Paper>
      );
  }
export default Insights;

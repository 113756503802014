import React from "react";

import { Typography } from "../../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Select, Input, AutoComplete } from "formik-antd";
import { Field } from 'formik';
import FadeIn from "react-fade-in";
import { useFormikContext } from "formik";
import { BusinessInfo } from "../../../../model";
import { Paper, Grid } from "@material-ui/core";
import Funding from "./Funding";

const { Option } = Select;

export default function () {
    const classes = useStyles();
    const formik = useFormikContext<BusinessInfo>();

    const stages = [
        { id: 1, value: "Fractured with many small players" }, { id: 2, value: "Dominated by serveral players" }, { id: 3, value: "Dominated by single large players" }
    ]
    const Marketing = [
        { id: 1, value: "Key partner are in place" }, { id: 2, value: "No key partner identified" }
    ]
    const Sales = [
        { id: 1, value: "Sales traction already" }, { id: 2, value: "Trial with customers" }, { id: 3, value: "Key beta testers identified and contacted" }, { id: 4, value: "No sales channel identified" }
    ]

    const Social = [
        { id: 1, value: "Key partner are in place" }, { id: 2, value: "No key partner identified" }
    ]
    const sizeMarket = [
        { id: 1, value: "Sales traction already" }, { id: 2, value: "Trial with customers" }, { id: 3, value: "Key beta testers identified and contacted" }, { id: 4, value: "No sales channel identified" }
    ]
    const Industry = [
        { id: 1, value: "Sales traction already" }, { id: 2, value: "Trial with customers" }, { id: 3, value: "Key beta testers identified and contacted" }, { id: 4, value: "No sales channel identified" }
    ]

    const marketRisk = [
        { id: 1, value: "Sales traction already" }, { id: 2, value: "Trial with customers" }, { id: 3, value: "Key beta testers identified and contacted" }, { id: 4, value: "No sales channel identified" }
    ]
    return (
        <FadeIn>
            <div className={classes.cardContainer}>
                <Typography  variant="h4" className={classes.sectionHead}>
                    Business Landscape
                </Typography>
                {/* <Grid container>
                <Grid item xs={6}> */}
                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Competitive Landscape
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No Competitive Landscape selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {stages.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.CompetitiveLandscape && formik.touched.CompetitiveLandscape && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.CompetitiveLandscape}
                            </Typography>
                        )}
                    </div>
                </div>
                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Sales Channels
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No Sales Channels selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {Sales.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.SalesChannels && formik.touched.SalesChannels && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.SalesChannels}
                            </Typography>
                        )}
                    </div>
                </div>

                {/* _______________marketing ______________ */}

                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Marketing Partners
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No Marketing Partners selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {Marketing.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>

                {/* _______________Social & Networking Presence_____________ */}
                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Social & Networking Presence
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No Social & Networking Presence selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {Social.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>

                {/* _________________________ Size of market_______________ */}
                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Size of Market
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No Size of Market selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {sizeMarket.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>

                {/* ____________________indusstry cagr_______________ */}

                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Industry CAGR
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="No   Industry CAGR selected"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {Industry.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>

                {/* ____________ market risk ___________________ */}


                <div>
                    <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
                        <Typography variant="h4" className={classes.sectionTitle}>
                            Market Risk Perception
                        </Typography>
                        <Select
                            name="companyStage"
                            defaultValue="Market Risk Perception"
                            style={{ width: "100%" }}
                            className={classes.CompanyDetails}
                        >
                            {marketRisk.map((stage) => (
                                <Option key={stage.id} value={stage.value} title={stage.value}>
                                    {stage.value}
                                </Option>
                            ))}
                        </Select>

                    </div>
                    <div>
                        {formik.errors.MarketingPartners && formik.touched.MarketingPartners && (
                            <Typography variant="caption" color="secondary">
                                {formik.errors.MarketingPartners}
                            </Typography>
                        )}
                    </div>
                </div>
            </div>

            {/* </Grid>
                <Grid item xs={6}> */}
            {/* ________________________ Funding proposition ____________________ */}

            <Funding />
            {/* </Grid> */}

            {/* </Grid> */}


        </FadeIn>

    );
}
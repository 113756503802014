import axios from "axios";
import {app} from "../repositories/firebase";
export async function readNotification(userId=null, notificationId=null){
const instanceurl =app.options.apiUrl
    // console.debug("readNotification", notificationId)
    console.debug("readNotification", instanceurl)

    // let prarmTempObj={};

    // }

    const response=await axios.post(
        // instanceurl+".cloudfunctions.net/",
        // "https://us-central1-invluencer-test.cloudfunctions.net/readNotification?userId="+ `${userId}`+"&notificationId="+ `${notificationId}`,
        instanceurl+".cloudfunctions.net/readNotification?userId="+ `${userId}`+"&notificationId="+ `${notificationId}`,

        {
            // params:prarmTempObj,
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}
import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Button, CardActions, Box, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import useStyles from "./Problem/styles";
import { SubscriptionDispatch } from '../../../model';
import { fetchSubscription } from '../../../actions/subscription';
import { callableFunctions } from '../../../repositories/firebase';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Loader from '../../../components/Loader/Loader';
import { IconButton, Modal } from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import FutureProofingStartupsDialog from '../../../components/Modals/FutureProofingStartupsDialog';
import ApplyCouponDialog from '../../../components/Modals/ApplyCouponDialog';
import { app } from "../../../repositories/firebase";
import flowX from "../../../images/flowX.png"
import flowFeature from "../../../images/FlowX-features-01.png"
import { url } from "inspector";



function getModalStyle() {
  const width = 25;
  const height = 85;
  return {
    width: `${width}%`,
    height: `${height}%`,
    // overflow:'auto',
    marginTop:'2rem',
    marginBottom:'2rem',

    // transform: `translate(-${width}%, -${height}%)`,
  };
}
function FlowX(props: any) {
  const [loading, setLoading] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);


  let history = useHistory()
  const classes = useStyles()

  const redirectToHome = () => {
    props.setOpenFlowX(false)
  }


  return (
    <div>
      <Modal style={{display:"flex", justifyContent:"center", alignItems:"center", height:'100vh'}}
        open={props.openFLowX}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/* <Paper className={classes.pitchDeckPaper} style={modalStyle}> */}
        {/* <Paper className={classes.pitchDeckPaper}> */}
        <Paper style={modalStyle}>
          {loading == false ?
            <div>
              <IconButton
                edge="start"
                style={{
                  paddingLeft: '30px',
                  position: 'absolute',
                  fontSize: '40px'}}
                color="inherit"
                aria-label="menu"
                onClick={redirectToHome}
              >
                <Close />
              </IconButton>
              <Grid container justifyContent="center" alignItems="stretch" sx={{ background: "aliceblue"}} >
           
               
                <img src={flowX} style={{height:'25rem',width:'100%',}}/>
              </Grid>
              <Grid container justifyContent="center" alignItems="stretch"  >
                <div className={classes.flowxContainer} style={{backgroundImage: `url(${flowFeature})`,}} >
      <h1 className={classes.flowxTitle}>FlowX</h1>
      <h2 className={classes.flowxSubtitle}>i3D Exchange</h2>
      <p className={classes.flowxDescription}>
        A dynamic, decentralized exchange where your moves shape the future of startups!
      </p>

      <div className={classes.flowxFeature}>
        <h3 className={classes.featureTitle}>Mint & Burn in Real-Time</h3>
        <p className={classes.featureDescription}>
          Easily swap between projects as tokens are <span className={classes.highlight}>burned</span> in old LPs and <span className={classes.highlight}>minted</span> in new ones, reflecting real-time support and market sentiment.
        </p>
      </div>

      <div className={classes.flowxFeature}>
        <h3 className={classes.featureTitle}>Liquidity-Driven Rankings</h3>
        <p className={classes.featureDescription}>
          Projects rise in rank based on the <span className={classes.highlight}>volume</span> of i3D Tokens. The more liquidity, the more attractive they become to investors.
        </p>
      </div>

      <div className={classes.flowxFeature}>
        <h3 className={classes.featureTitle}>Gamified Liquidity</h3>
        <p className={classes.featureDescription}>
          Strategically allocate your i3D Tokens and <span className={classes.highlight}>swap between startups</span> to back the best and shape their success.
        </p>
      </div>

      <div className={classes.flowxFeature}>
        <h3 className={classes.featureTitle}>VC Buyouts & Milestone Rewards</h3>
        <p className={classes.featureDescription}>
          When a project reaches its goal or a VC closes the LP, you’ll get your <span className={classes.highlight}>pro-rata share</span> of future project tokens automatically.
        </p>
      </div>

      <div className={classes.flowxFeature}>
        <h3 className={classes.featureTitle}>Earn Fees & Rewards</h3>
        <p className={classes.featureDescription}>
          Earn <span className={classes.highlight}>fees</span> for liquidity participation.
        </p>
      </div>
    </div>

                </Grid>
            </div> : <Loader text="redirecting to merchant..." />}
        </Paper></Modal>
    </div>

  );
}
export default FlowX;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  
  companyDes:{
    fontSize: "0.8rem",
    padding: "30px 18px !important"
  },
  companyDescription: {
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #BEBEBE",
    boxSizing: "border-box",
    height: "6rem",
    background: theme.palette.background.default,
    padding: "0 20px"
  },
  title:{
    flex:1,
    flexDirection: 'row'
  },
  error: {
    fontSize: 14,
    textAlign:"left",
    color:"red",
  },
  sectionTitle: {
    // paddingRight:theme.spacing(2),
    marginLeft: 4,
    marginBottom: 5,
    fontSize:"16px !important",
    fontWeight:"700 !important" ,
    textAlign:"left",
    width:300,
    color: theme.palette.blackColor.main,
 
  },
  infoIcon:{
    color:"#137DFB !important",
    margin:"2px"
  }

}));
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  pageTitleContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: (props) => (props.noMargin ? 0 : theme.spacing(2)),
  },
  typo: {
    color: theme.palette.text.dark,
    fontFamily: "'Manrope', sans-serif",
    fontSize: "calc(2rem) !important",
    marginTop:"1rem",
    display: "flex",
    alignItems: "end",
    whiteSpace: "pre-wrap",
    "& > span":{
      paddingLeft: 5
  
      }
  },
}));

import React, { useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Button, ButtonGroup, Grid, IconButton, TextareaAutosize, Theme, Tooltip, Typography, withStyles, Tabs, Tab } from "@material-ui/core";
import { Typography as Typo } from "../../components/Wrappers/Wrappers";
import { Checkbox, Divider } from "antd";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// styles
import useStyles from "./styles";
function NewScoreMessage(props) {
    const classes = useStyles();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const plan = searchParams.get("plan");
    const data = props.data;
    return (
        <Grid item xs={12}>
            {/* <Typography className={classes.welcomPara}>
                Welcome! You are performing analysis on the {props.section} Section for {data.company_name}
            </Typography> */}
            <Typography className={classes.welcomPara}>
               You are performing analysis on the {props.section} section for {data.company_name}
            </Typography>

            <Typography className={classes.sectionpara}>
                Please evaluate the following statement on a scale from '1'(poor) to '9' (exceptional).
                Choose 'O' if the question is not applicable. Consider all factors and refer to the documentation
                provided. You can easily scroll between questions.
            </Typography>

            <div className={classes.startButton} >
                <Button onClick={() => props.handleStart()}>Start</Button>
            </div>

        </Grid>
    );
}

export default NewScoreMessage;

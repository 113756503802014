import React from "react";
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers/Wrappers";

interface Props {
  title: React.ReactNode;
  noMargin?: boolean;
  extra?: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function PageTitle(props: Props) {
  var classes = useStyles(props);

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
     

      <div>{props.extra}</div>
    </div>
  );
}

import { makeStyles } from "@material-ui/styles";
// import { color } from "html2canvas/dist/types/css/types/color";

export default makeStyles((theme) => ({
  pages: {
    padding: 30,
    // paddingTop: 10 + "%"
  },
  companyInfo: {
    // fontFamily: "Montserrat !important",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    color: theme.palette.blackColor.main,
    marginLeft: "-15px",
    // [theme.breakpoints.up("sm")]: {
    //   fontSize: "1.2em",
    // },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: "1.2em",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: "1em",
    // },
  },
  paymentSuccessPaper: {
    position: 'absolute',
    // width: 400,
    width: 450,
    height: 430,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 4, 3),
    display: "flex",
    justifyContent: "center",
  },
  paymentSuccessListItem: {
    padding: 0
  },
  paymentSuccessImg:{
    height:"30px",
    marginBottom:"1.2rem"
  },
  paymentSuccessList: {
    padding: 0
  },
  paymentSuccessTitle: {
    fontWeight: " 600 !important",
    marginBottom: "14px"
  },
  paymentSuccessText: {
    fontSize: "12px !important",
    paddingBottom: "12px !important"
  },
  paymentSuccessIcon: {
    paddingBottom: "10px"
  },
  paperContainer: {
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '& div': {
      height: "35px",
      marginTop: "40px",
      width: "75%",
      marginLeft: "2%",
      borderRadius: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#2b2b2b",
    },
    '& div p': {
      textAlign: "center",
      color: theme.palette.background.default
    }
  },
  alignCenter: {
    textAlign: "center",
    size: "A4",
    padding: 9 + '%',
    backgroundColor: theme.palette.background.default,
    clear: "both",
    '& alignCenter[size="A4"]': {
      width: "21cm",
      height: "29.7cm",
    }
    // paddingTop: "0px",
    // marginBottom: "9%",
    // paddingBottom: "0px",
  },
  alignLeft: {
    textAlign: "left",
    padding: 9 + '%',
    size: "A4",
    // paddingTop: "0px",
    // marginBottom: "9%",
    // paddingBottom: "0px",
    // marginTop: "10%",
    // display: "block",
  },
  MuiTableCellSizeSmall: {
    padding: "0px",
  },
  bolderText: {
    // fontFamily: "Montserrat !important",
    fontWeight: "bolder",
  },
  pageHeading: {
    // fontFamily: "Montserrat !important",
    fontWeight: "bolder",
    fontSize: 34,
    // [theme.breakpoints.up("sm")]: {
    //   fontSize: 27,
    // },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: 27,
    // },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: 32,
    // },
  },
  sideHeading: {
    // fontFamily: "Montserrat !important",
    fontWeight: "bold",
    // fontSize: 22,
    color: "#666666"
  },
  textNormal: {
    fontSize: 18,
    // fontFamily: "Montserrat !important",
    marginTop: "1rem"
    // [theme.breakpoints.up("sm")]: {
    //   fontSize: 18,
    // },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: 19,
    // },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: 16,
    // },
  },
  grey: {
    color: "#666666",
    fontWeight: "600"
  },
  red: {
    color: "#ff0000",
  },
  blue: {
    color: "#4798F1",
  },
  table: {
    minWidth: 100 + "%",
    border: "none",
    backgroundColor: theme.palette.background.default,

  },
  paper: {

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  companyLogo: {
    // width: "100%",
    height: "100%",
  },
  imageContain: {
    width: "100%",
    height: "60vh",
  },
  atGlancePaper: {
    padding: 10, height: 60, margin: "auto",
    marginTop: 40,
    width: 85 + '%',
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2b2b2b",
    color: theme.palette.background.default
  },
  inputFields: {
    display: "inline-block",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "1rem",
    width: "50%"
  },
  sectionTitle: {
    paddingRight: theme.spacing(2),
    fontSize: "15px !important",
    fontWeight: "500 !important",
    textAlign: "left",
    width: "100%",
    color: theme.palette.blackColor.main,

  },
  pitchDeckPaper: {
    position: 'absolute',
    width: 450,
    height: 445,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
    display: "flex",
    justifyContent: "center",
  },
  futureproof:{
    position: 'absolute',
    width: 650,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
    display: "flex",
    justifyContent: "center",
  },
  fontSizeTypo:{
    marginTop:"5px !important",
  fontSize:"15px !important", 
  fontWeight:'600 !important'
  },
  loginInfoPopup: {
    position: 'absolute',
    width: 735,
    height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
    display: "flex",
    justifyContent: "center",
  },
  infoPoints:{
    listStyleType:'none'
  },
  pitchDeckListItem: {
    padding: 0
  },
  pitchDeckImg:{
    height:"30px",
    // marginBottom:"1.2rem",
    margin:"0.5rem"
  },
  pitchDeckList: {
    padding: 0
  },
  pitchDeckTitle: {
    fontWeight: " 600 !important",
    margin:"auto"
    // marginBottom: "14px"
  },
  pitchDeckText: {
    fontSize: "12px !important",
    paddingBottom: "12px !important",
    color:"black"
  },
  paymentSuccessIcon: {
    paddingBottom: "10px"
  },
  titlePitch:{
    fontWeight: "bolder !important",
     margin:'0.5rem'
  },
  pitchDeckPoint:{
    fontWeight: '600',
    fontSize:'12px'
  },
  infoLoginPoint:{
    // fontWeight: '600',
    fontSize:'12px'
  },
  CompanyDetails: {
    width: "85%",
    '& .ant-select-selector': {
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      // height: "40px !important",
      border: "1px solid #BEBEBE !important",
      background: "#fff !important",
      borderRadius: "10px !important",
      // fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "17px",
      color: theme.palette.blackColor.main,
      padding: "0 20px !important"
    },
    '& >div>span': {
      display: "flex",
      alignItems: "center",
      // justifyContent: "center"
    }
  },
  errorSize: {
    fontSize: "0.6rem"
  },
  buttonScore:{
    backgroundColor:'#137DFB!important',
  },
  buttonBox:{
    display:"flex",
    width:"100%",
    justifyContent:"space-between",
    alignItems:'center',
    paddingLeft:"16px",
    // paddingRight:"16px",


  },
  button:{
    alignSelf:"end",
    background:"#137DFB !important",
    color:"white"
  },
  payableAmount:{
    marginLeft:'1.5rem'
  }
}));


import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    
    root: {
        display: "flex",
        flexDirection: "cloumn",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
     },
    companyInfoContainer: {
        marginTop: "2rem",
    },
    sectionTitle: {
        paddingRight: theme.spacing(2),
        fontSize: "15px !important",
        fontWeight: "500 !important",
        textAlign: "right",
        width: 300,
        color: theme.palette.blackColor.main,

    },
    fieldTitle: {
        marginRight: 5 + "%",
        fontSize: "17px !important",
        fontWeight: "500 !important",
        textAlign: "left",
    },
    privacySection: {
        fontSize: "25px !important",
        fontWeight: "500 !important",
        // textAlign:"left",
        color: theme.palette.blackColor.main,
    },
    privacyDiv: {
        alignItems: "center",
        marginTop: "2rem",
        textAlign: "left",
        marginLeft: "14rem",
    },
    selection: {
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    privacySectionDescription: {
        fontSize: "14px",
        marginTop: "0.6rem",
        // marginLeft: 190,
        // textAlign:"center",
        color: "#414141",
    },
    textfield: {
        marginRight: 5 + "%",
        fontSize: "17px !important",
        fontWeight: "500 !important",
        textAlign: "left",
    },
    dropZone: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        marginTop: theme.spacing(4),
    },
    inputFields: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        marginTop: theme.spacing(4),
    },
    privacySelect: {
        // height: 106,
        width: 454,
        textAlign: "left",
        background: "#F8FAFD",
        border: "1px solid #BEBEBE",
        boxSizing: "border-box",
        borderRadius: "10px",
        // padding: "0.6rem",
        boxShadow: "none"

    },
    image: {
        display: "flex",
        alignItems: "center",
    },
    label: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    imageButton: {
        padding: theme.spacing(4),
        borderRadius: 4,
        width: 100,
        height: 100,
        // backgroundColor: "#FAFAFA",
        backgroundColor: "#E3E3E3",
        // borderColor: "#D3D3D3",
        borderColor: "#E3E3E3",
        borderStyle: "dashed",
        border: 1,
        transition: "all 0.25s ease",
        "&:hover": {
            cursor: "pointer",
            borderColor: theme.palette.primary.main,
        },
    },
    CompanyDetails: {
        width: "100%",
        '& .ant-select-selector': {
            display: "flex",
            alignItems: "center",
            textAlign: "left",
            height: "40px !important",
            border: "1px solid #BEBEBE !important",
            background: "#fff !important",
            borderRadius: "10px !important",
            // fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "13px",
            lineHeight: "17px",
            color: theme.palette.blackColor.main,
            padding: "0 20px !important"
        },
        '& >div>span': {
            display: "flex",
            alignItems: "center",
            // justifyContent: "center"
        }
    },
    comapnyDes: {
        fontSize: "0.8rem",
        padding: "30px 18px !important"
    },
    companySourceEmail: {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        height: "40px !important",
        border: "1px solid #BEBEBE !important",
        background: "#fff !important",
        borderRadius: "10px !important",
        padding: "0 20px !important"
    },
    companyDescription: {
        width: "100%",
        borderRadius: "10px",
        border: "1px solid #BEBEBE",
        boxSizing: "border-box",
        height: "6rem",
        background: theme.palette.background.default,
        padding: "0 20px"
    },
    addButton: {
        width: "100px",
        /* height: 38px; */
        // background: "#137DFB",
      backgroundColor: theme.palette.primary.main,

        border: "1px solid #137DFB",
        boxSizing: "border-box",
        borderRadius: "10px",
        // fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        color: "#F8FAFD",
        marginLeft: "1rem",
    },

    expertText: {
        marginTop: "2rem",
        marginBottom: "2rem",
        fontWeight: "600"
    },
    addButton:{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: "1rem",
        "& > button":{
            // width: "70px",
            height: "38px",
            padding: "0 16px",
            minWidth: "48px",
            minHeight: "auto",
            borderRadius: "5px",
            color: "white",
            // backgroundColor: "#137DFB",
      backgroundColor: theme.palette.primary.main,

        }
    },
    cardContainer:{
        height: "auto",
        margin: "16px",
        position: "relative",
        boxShadow: "0px 0px 8px 1px #ccc",
        textAlign: "center",
        borderRadius: "8px",
        padding: "2rem",
        paddingLeft: "0px",
        paddingTop: "1rem",
        backgroundColor:theme.palette.background.default,
    },
    sectionHead:{
        textAlign: "center",
        width: "100%",
        fontSize: "1.2rem",
    }

}));
import React from "react";
import {
    makeStyles,
    withStyles,
  } from "@material-ui/core";
import { Project } from "../../../model";
import { Grid } from "@material-ui/core";
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
  } from "@material-ui/core";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import EmailIcon from '@material-ui/icons/Email';
import PublicIcon from '@material-ui/icons/Public';
import LocalMallIcon from '@material-ui/icons/LocalMall';
const useStyles = makeStyles((theme)=>({
    companyLogo: {
        width: 51.56,
        height: 50,
        borderRadius: "50%",
        marginRight: "10px",
      },
      companyName: {
        fontSize: "16px",
        fontWeight: "bold",
      },
      companyWrapper: {
        paddingLeft: "10px",
       
      },
      companyInfoWrapper: {
        padding: "10px",
        overflow:'hidden',
        
      },
      tableWidget: {
        //overflowX: "auto",
      },
      companyInfo: {
        // fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        color: "#000000",
        marginLeft: "-15px",
        
      },
      MuiTableCellSizeSmall: {
         padding: "0px",
      },
  }));
interface IProps {
project: Project;
}

export default function CompanyComponent({ project }: IProps) {
    const classes = useStyles();
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: "#F2F7FF",
          },
          height:"30px",
        },
      }))(TableRow);
    return (
        <div>
      <div className={classes.companyWrapper}>
          <img 
            src={project.company.logo}
            alt="company logo"
            className={classes.companyLogo}
          />
        <span className={classes.companyName}>
        {project.company.name}
        </span>
      </div>
      <div className={classes.companyInfoWrapper}>
      <Grid container spacing={4}>
      <Grid item sm={7}>
      <TableContainer>
      <Table size="small">
        <TableBody style={{}}>
            <StyledTableRow>
            <TableCell>
            <AccountBoxIcon style={{color:"#858C94"}}/>
            </TableCell>
            <TableCell>
                <span className={classes.companyInfo}>
                    {project.company.name}
                </span>
            </TableCell>
            </StyledTableRow>
            <StyledTableRow>
            <TableCell>
                <WatchLaterIcon style={{color:"#858C94"}}/>
            </TableCell>
            <TableCell>
                <span className={classes.companyInfo}>
                    {project.company.stage + " stage"}
                </span>
            </TableCell>
            </StyledTableRow>
            <StyledTableRow>
            <TableCell>
                <LocalMallIcon style={{color:"#858C94"}}/>
            </TableCell>
            <TableCell>
                <span className={classes.companyInfo}>
                    {project.company.sector}
                </span>
            </TableCell>
            </StyledTableRow>
            <StyledTableRow>
            <TableCell>
                <PublicIcon style={{color:"#858C94"}}/>
            </TableCell>
            <TableCell>
                <a href={project.company.website} target="_blank" className={classes.companyInfo}>
                    {project.company.website}
                </a>
            </TableCell>
            </StyledTableRow>
            <StyledTableRow>
            <TableCell>
                <EmailIcon style={{color:"#858C94"}} />
            </TableCell>
            <TableCell>
                <a href={project.company.investmentLink} target="_blank"  className={classes.companyInfo}>
                    {project.company.investmentLink}
                </a>
            </TableCell>
            </StyledTableRow>
        
        </TableBody>
        </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={5}>
          <span style={{fontFamily: "Montserrat",fontWeight:"bold",fontSize:"16px"}}>Description</span>
          <br/>
          <div style={{maxHeight: 200,overflowY: 'auto',overflowX:'hidden'}}>
            <span style={{fontFamily: "Montserrat",fontStyle: "normal",fontWeight: "normal",fontSize: "14px",
                  color:" #9D9D9D",}}>
            {project.company.description}
            </span>
          </div>
      </Grid>
      </Grid>
      </div>
      </div>
    );
  }
  
import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  makeStyles,
  withStyles,
  IconButton,
  Paper,
  Link,
  Tooltip
} from "@material-ui/core";
import { Invitation } from "../../../../model";
import {
  getSectionLabel,
  sectionOptions,
} from "../../../create/components/data";
import _ from "lodash";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import Widget from "../../../../components/Widget/Widget";
import { Scrollbars } from 'react-custom-scrollbars';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles({
  container: {
    minHeight: "50vh",
    overflow: "hidden",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    marginTop: "0.8rem",
    "& th": {
      fontWeight: 600,
    }
  },
  cell: {
    // fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#000000",
  },
  moreButton: {
    color: "#000000",
  },
  tableWidget: {
    //   overflowX: "auto",

  },
});
const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width:70+"%",
  },
}))(Tooltip);


interface Props {
  pdfLinks: Record<string, string>;
  invitations: Invitation[];
  pitchDeckPdfUrl:Record<string, string>;
}

function _collectPDFLinks(invitations: Invitation[]): Record<string, string> {
  let temp: Record<string, string> = {};
  for (let invite of invitations) {
    temp = {
      ...temp,
      ...invite.pdfLink,
    };
    // console.debug("_collectPDFLinks:temp", temp)
    if (Object.keys(temp).length === sectionOptions.length) break;
  }
  return temp;
}

//TODO: make a function that collects all the sections and all the pdf links
export default function TableComponent({pitchDeckPdfUrl, invitations, pdfLinks }: Props) {
  const classes = useStyles();
  const keys = ["section", "document"].map((v) => v.toUpperCase());
  const [sectionPdfs, setsectionPdfs] = useState<Record<string, string>>({});
  // console.debug
  useEffect(() => {
    const links =
      !pdfLinks || _.isEmpty(pdfLinks)
        ? _collectPDFLinks(invitations)
        : pdfLinks;
        // console.debug("TableComponent:links", links.market)
    setsectionPdfs(links);
  }, [invitations, pdfLinks]);
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: "#F2F7FF",
      },
      height: "60px",
    },
  }))(TableRow);
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#F2F7FF",
    },
  }))(TableCell);
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader size="small" >
        <TableHead>
          <StyledTableRow style={{ width: 100 + '%', display: "inline-table" }}>
            {/* {keys.map((key) => (
              <TableCell key={key}>{key}</TableCell>
            ))} */}
            <StyledTableCell>Name</StyledTableCell>
            {/* <StyledTableCell>Date Added</StyledTableCell>
            <StyledTableCell>File Size</StyledTableCell> */}
            {/* <StyledTableCell></StyledTableCell> */}
          </StyledTableRow>
        </TableHead>
        {/* {console.debug("TableComponent:sectionPdfs", sectionPdfs)} */}
        <Scrollbars style={{ height: "43vh" }} autoHide>
          <TableBody component={Paper} elevation={3} style={{ backgroundColor: "white", width: 100 + '%', display: "inline-table" }}>
            {Object.entries(sectionPdfs).map(([key, value], index) => {
             
              return (
                <StyledTableRow key={index}>
                  {/* <TableCell>{getSectionLabel(key)}</TableCell> */}
                  <TableCell className={classes.cell} style={{ width: "145px" }} >
                    <Link  title={value}  href={key == 'pitchDeck' ? pitchDeckPdfUrl : value} target="_blank" style={{ color: "#000000" }} >
                      {getSectionLabel(key)}
                      {/* <LightTooltip title={value} placement="top">
                      </LightTooltip> */}
                    </Link>
                  </TableCell>
                  {/* <TableCell className={classes.cell}>
                    <Link href={value} target="_blank" style={{ color: "#000000" }} > Just Now</Link>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    25MB
                  </TableCell> */}
                  {/* <TableCell className={classes.cell}>
                <IconButton
                  color="primary"
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  // onClick={() => setMoreMenuOpen(true)}
                  // buttonRef={setMoreButtonRef}
                >
                  <MoreIcon />
                </IconButton>
                </TableCell> */}
                </StyledTableRow>
              );
            })}

          </TableBody>
        </Scrollbars>
      </Table>
    </TableContainer>
  );
}

import { Box, Button, FormControl, IconButton, InputAdornment,  Modal, OutlinedInput, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import React, { useState } from 'react'
import useStyles from "./style";
import { useHistory } from 'react-router-dom';
import { callableFunctions } from '../../repositories/firebase';

function ApplyCouponDialog(props) {
    const classes = useStyles();
    const [discountedPrice, setDiscountPrice] = useState('')
    const [disableBuyNow, setDisabledBuyNow] = useState(true)
    const [payableAmount, setPayableAmount] = useState()
    let history = useHistory()
    const [unvalidCoupounMessage, setUnvalidCoupounMessage] = useState('')
    // console.debug("checkCoupn", props)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRadius: "8px",
        boxShadow: 24,
        // p: 4,s
    };

    const getFormateDate = (duration) => {
        const planExpireDate = new Date();
    
        switch (duration) {
          case '30 Days':
            planExpireDate.setDate(planExpireDate.getDate() + 30);
            break;
          case '3 Months':
            planExpireDate.setMonth(planExpireDate.getMonth() + 3);
            break;
          case '12 Months':
            planExpireDate.setMonth(planExpireDate.getMonth() + 12);
            break;
          default:
            throw new Error('Invalid plan duration');
        }
    
        return planExpireDate;
      };
    function onCheckCoupon() {
        // console.log("checkCoupn")
        const validateCoupon = callableFunctions.httpsCallable('validateCoupon');

        // Call the function with the coupon code
        validateCoupon({ code: props.coupoun })
            .then((result) => {
                const data = result.data;
                // console.debug('Validation result:', data);
                const formattedPrice = props.planObject.price.replace(/\$/g, "");
                if(data.discountPercentage == 100){
                 
                }
                if(data.status == 200){
                    const discountPercentage = data.coupon.percent_off
                    const subtotal =formattedPrice
                    const discountedAmount = subtotal * (discountPercentage / 100);
      
                    // Calculate the payable amount after discount
                    const payableAmount = subtotal - discountedAmount;
                    setUnvalidCoupounMessage(data.message)
                    
                    setDisabledBuyNow(false)
                    setDiscountPrice(`$${payableAmount.toFixed(2)}`)
                    // console.debug('Discount Percentage:', discountPercentage);
                    // console.debug('Subtotal:', subtotal);
                    // console.debug('Discounted Amount:', discountedAmount);
                    // console.debug('Payable Amount After Discount:', payableAmount);
                    setPayableAmount(payableAmount)

                }else{
                    setUnvalidCoupounMessage(data.message)
                }
            })
            .catch((error) => {
                console.error('Error validating coupon:', error);
            });
    }

    const updateCurrentPlanWithUser = async (id, plan) => {
        try {
        //   console.debug("plan with plan", plan)
    
          // if (id == "1Faq1IPGLtvhpDnXmkwa") {
          const planData = {
            stripPlanId: id,
            accessDuration: plan.accessDuration,
            duration: plan.accessDuration,
            name: plan.name,
            planStatus: 'activated',
            planExpireDate: getFormateDate(plan.accessDuration).toISOString(),
            shareProject: plan.share,
            sharedProject: 0,
            uploadProject: plan.uploadProject,
            uploadedProject: 0,
          };
        //   console.log('Custom object', planData);
          const updateUserPlan = callableFunctions.httpsCallable('updateUserPlan');
        const r =  await updateUserPlan(planData);
        // console.debug('Custom object added successfully.', r);
          props.setOpenApplyCoupon(false)
          props.setOpenPlan(false)
            history.push("/app/dashboard")
        //   console.debug('Custom object added successfully.');
        } catch (error) {
          console.error('Error adding custom object:', error);
        }
      };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
            // slots={{ backdrop: Backdrop }}
            // slotProps={{
            //     backdrop: {
            //         timeout: 500,
            //     },
            // }}
            >
                <Box sx={style}>
                    <Box className='d-flex justify-content-between border-bottom p-4'>
                        <Typography variant='h6'>APPLY COUPON</Typography>
                        <IconButton
                            edge="start"
                            className="p-0"
                            color="inherit"
                            aria-label="menu"
                            onClick={props.handleClose}
                        >
                            <Close />
                        </IconButton>
                    </Box>
                    <Box className='p-4'>
                        <FormControl variant="outlined" className='w-100'>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                placeholder="Enter coupon code "
                                onChange={(e) => props.setCoupoun(e.target.value)}
                                endAdornment={
                                    <InputAdornment color='primary' position="end" className='cursorPointer' onClick={onCheckCoupon}>

                                        Check
                                    </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                        <span className={disableBuyNow == true? 'text-danger': 'text-success'}>{unvalidCoupounMessage}</span>
                    </Box>
                    <Box
                        className="d-flex justify-content-between p-4 border-top align-items-center"
                    >
                       {/* {disableBuyNow == false ? */}
                       <div style={{display:"flex"}}> <Typography style={{ color: "#137DFB", fontWeight: 600, cursor: "pointer" }}>Payable Amount:</Typography>

                        <Typography variant='h6' className={classes.payableAmount}>{discountedPrice ? discountedPrice: props.planObject != undefined ? props.planObject.price : ""}</Typography> </div>
                        {/* :""}  */}

                        <Button variant="contained" disabled={disableBuyNow == true ? true : false} className={classes.button}
                            onClick={() => {payableAmount > 0 ? props.buynow(props.planObject != undefined ? props.planObject.stripePaymentId : "") : updateCurrentPlanWithUser(props.planObject.stripePaymentId, props.planObject)}}
                        >
                            {'Buy Now'}
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    )
}

export default ApplyCouponDialog

import React, { useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Grid, Typography, Tabs, Tab,Button } from "@material-ui/core";
import { Typography as Typo } from "../../components/Wrappers/Wrappers";
import classnames from "classnames";
import { loginUserWithGoogle } from "../../actions/user";
import { UserDispatch } from "../../model";
import { useSelector, useDispatch } from "react-redux";

// styles
import useStyles from "./styles";

// logo
// import logo from "./logo.svg";
import logo from "./logos.png";
import google from "./../../images/google.svg";
import i3dlogo from "./../../images/i3D.png";

import { LoginForm, RegisterForm } from "../../components/Login";
import { LeftSideBarLogin } from "../../components/Login/LeftSideLogin";

function Login(props) {
  const dispatch = useDispatch<UserDispatch>();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get("plan");
  // local
  const [activeTabId, setActiveTabId] = useState(!!plan ? 1 : 0);
  return (
    <div className="" style={{height:"94vh", backgroundColor:"#F2F7FF"}}>
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <LeftSideBarLogin setActiveTabId={setActiveTabId}/>
        {/* <img src={logo} alt="logo" className={classes.logotypeImage} /> */}
      </div>
      <div className={classes.formContainer}>
      <div className="text-center">
            <img src={i3dlogo} alt="i3dlogo" className={classes.i3dLogo} />
            </div>
            <div className="align-items-center d-flex justify-content-center pb-5">
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            className="text-white"
            indicatorColor="primary"
            // textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="Create a free account" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && <LoginForm from={props.location.state?.from.pathname}/>}
          {activeTabId === 1 && <RegisterForm from={props.location.state?.from.pathname} plan={plan} />}
          <Button
              size="large"
              type="button"
              className={classnames(
                classes.googleButton,
                classes.googleButtonCreating
              )}
              onClick={() => {
                dispatch(loginUserWithGoogle());
              }}
            >
              <img src={google} alt="google" className={classes.googleIcon} />
              &nbsp;Sign in with Google
            </Button>
        </div>
        </div>
      </div>
    </Grid>
          <div className={classes.footerContainer}>
        <Typography variant="caption" className={classes.footer} >i3D Protocol by  <a href='https://invluencer.com/' target='_blank' > Invluencer Ltd </a></Typography>
   </div>
   </div>
  );
}

export default withRouter(Login);

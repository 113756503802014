import React from "react";
import { ProjectCard } from "../../components/ProjectCard";
import FadeIn from "react-fade-in";
import useStyles from './styles';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { Filter } from "../../utils/enums";
import { Paper } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import { Link } from "react-router-dom";

export default function ProjectGrid(props) {

    const classes = useStyles();
    const search = useSelector((state: RootState) => state.search);
    const user = useSelector((state: RootState) => state.firebase.auth);
    const profile = useSelector((state: RootState) => state.firebase.profile);
    if (profile?.currentPlan) {
        return (
            <FadeIn className={classes.gridContainer}>
                {props.sortedProjects
                    .filter((v) =>
                        v.company.name
                            .toLowerCase()
                            .includes(
                                search.searchString.length >= 3
                                    ? search.searchString.toLowerCase()
                                    : ""
                            )
                    )
                    // .slice(0, 3)
                    .map((v, index) => {
                        if (props.filter == Filter.uploads) {
                            if (v.requester == user.uid) {
                                return (
                                    <ProjectCard project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else if (props.filter == Filter.likes) {
                            if (v.likes) {
                                return (
                                    <ProjectCard project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else if (props.filter == Filter.shares) {
                            if (v.shared ? v.shared : false) {
                                return (
                                    <ProjectCard project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else {
                            return (
                                <ProjectCard project={v} key={index} filter={props.scoreSort} />
                            )
                        }
                    })}
                {/* {props.sortedProjects
                    .filter((v) =>
                        v.company.name
                            .toLowerCase()
                            .includes(
                                search.searchString.length >= 3
                                    ? search.searchString.toLowerCase()
                                    : ""
                            )
                    )
                    .slice(3, props.sortedProjects.length)
                    .map((v, index) => {
                        return (
                            <>
                                <ProjectCard project={v} key={index} filter={props.scoreSort} top={true} />

                            </>
                         
                        )

                    })} */}
            </FadeIn>)
    }
    else {
        return (
         <>
            <FadeIn className={classes.gridContainer}>
                {props.sortedProjects
                    .filter((v) =>
                        v.company.name
                            .toLowerCase()
                            .includes(
                                search.searchString.length >= 3
                                    ? search.searchString.toLowerCase()
                                    : ""
                            )
                    )
                    .map((v, index) => {
                        if (props.filter == Filter.uploads) {
                            if (v.requester == user.uid) {
                                return (
                                    <ProjectCard project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else if (props.filter == Filter.likes) {
                            if (v.likes) {
                                return (
                                    <ProjectCard project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else if (props.filter == Filter.shares) {
                            if (v.shared ? v.shared : false) {
                                return (
                                    <ProjectCard project={v} key={index} filter={props.scoreSort} />
                                )
                            }
                        }
                        else {
                            return (
                                <ProjectCard project={v} key={index} filter={props.scoreSort} />
                            )
                        }
                    })}
            
            </FadeIn>
            </>
           
        );
    }

}
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },

  positive: {
    color: "green",
    fontSize: 16,
    fontWeight: "bold",

  },
  negative: {
    color: "red",
    fontSize: 16,
    fontWeight: "bold",

  },
  general: {
    color: "grey",
    fontSize: 16,
    fontWeight: "bold",

  },
  body: {
    display: "block",
    fontStyle: "italic",
    fontSize: 14,
    marginTop: theme.spacing(1),

  },
  empty: {
    width: 100 + "%",
    height: theme.spacing(10),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  widgetTitle: {
    // fontFamily: "Montserrat ",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 2,
    color: theme.palette.blackColor.main,
    // marginBottom: "20px",
  },

  backArrow: {
    paddingTop: "0px",
    paddingBottom: "0px",
    marginLeft: "1rem",
    '& >span:nth-child(1)': {
       width: "35px",
       height: "35px",
       borderRadius: "100%",
       border: "1px solid",
       paddingLeft: "0.6rem"
    },
    '& +div':{
      height: "40px",
      boxShadow: "0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a",
      marginTop: "0.5rem",
      textTransform: "none",
      backgroundColor: "#137DFB !important",
      // backgroundColor: theme.palette.primary.main,

      fontWeight: "500",
      lineHeight: "1.75",
      borderRadius: "4px",
      width: "145px",
      color: theme.palette.background.default,
      marginRight: "1rem",
    },
    '& +div>svg':{
      color:theme.palette.background.default,
    }
  },
  insightTitle:{
    listStyle: "none",
    padding: 5,
    "& li":{
      marginTop: "0.6rem"
    }
  },
  infoColor:{
    color:'#137DFB !important'
  }

}));


import { useDocumentData } from "react-firebase-hooks/firestore";
import { callableFunctions, firestore } from "../repositories/firebase";
import { handleError } from "../utils/decorators";
import { Invitation, Project, Reminder } from "../model";
import firebase from 'firebase';
// import { technicalScoringMarket,technicalScoringFund } from "../pages/create/components/data";

const invitationsCollection = firestore.collection("invitations");
const submissionCollection = firestore.collection("submissions");
const projectCollection = firestore.collection("project");
const questionCollection = firestore.collection("questions");
const technicalScoreCollection = firestore.collection("technicalScore");
// const technicalFundCollection = firestore.collection("technicalScoringFund");

export default class {
  static sendReminderFunc = callableFunctions.httpsCallable("sendReminder");

  @handleError("Failed to accept terms...")
  static async acceptTerms(id: string): Promise<void | Error> {
    const data = { disclaimerAccepted: true };
    await invitationsCollection.doc(id).update(data);
  }

  
  @handleError("Failed to add technicalMarket...")
  static async getTechnicalQuestions(){
  
    const data = await technicalScoreCollection.where('active','==',true).get();
    // console.debug("technicalScoreCollection", data.docs)
    return data.docs.map((d)=>{ return {...d.data(),did:d.id }});
    // await invitationsCollection.doc(id).update(data);
  }



  static useDocumentData(
    id: string
  ): [Invitation | undefined, boolean, Error | undefined] {
    return useDocumentData<Invitation>(invitationsCollection.doc(id), {
      idField: "id",
    });
  }

  @handleError("Failed to send reminder.")
  static async sendReminder(
    uid: string,
    reminder: Reminder,
    inviteID: string
  ): Promise<void | Error> {
    const data = { ...reminder, uid, inviteID };
    const err = await this.sendReminderFunc(data);
    if (err.data) return new Error(err.data);
  }

  @handleError("Failed to fetch invitation data.")
  static async fetchInvitation(
    inviteID: string,
  ): Promise<Invitation> {
    const invitation = await invitationsCollection.doc(inviteID).get();
    const data=  invitation.data() as Invitation;
    return data;
  }

  @handleError("Failed to fetch invitation data.")
  static async fetchProjectStatus(
    id: string,
  ): Promise<boolean> {
    const project = await projectCollection.doc(id).get();
    const data=  project.data() as Project ;
    const myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    if( myTimestamp.seconds < data.endDate?.seconds)
    {
      return true;
    }
    return false;
  }

  @handleError("Failed to fetch invitation data.")
  static async updateSectionStatus(
    inviteID: string,
    section: string
  ): Promise<void | Error> {
    const invitation = await invitationsCollection.doc(inviteID).get();
    const data=  invitation.data() as Invitation;
    data.sectionsStatus[section] = "done";
    await invitationsCollection.doc(inviteID).update(data);


  }

  @handleError("Failed to fetch general comment data.")
  static async getGeneralComments(
    projectId: string,
    section: string
  ) {
    const submission = await submissionCollection.where('form_response.hidden.projectid','==',projectId).where('form_response.hidden.section','==',section).get();
    const data = submission.docs.map((d)=>{
      return {comment:d.data().generalComment?d.data().generalComment:''};
    })
    return data.filter((d)=> d.comment!=='');

  }

  @handleError("Failed to questions.")
  static async fetchSectionQuestions(
    section: string,
    projectId: string,
  ) {
    const project = await (await projectCollection.doc(projectId).get()).data();
    console.debug("fetchSectionQuestions:project",project)
    const stage = project.company.stage;
    var questions;
    if(project.crypto)
    {
     questions = await questionCollection.where("section","==", section).where("active","==", true).where("crypto","==", true).where("stage","in", [stage, "all"]).get();
    }
    else
    {
     questions = await questionCollection.where("section","==", section).where("active","==", true).where("stage","in", [stage, "all"]).get();
    }
    const data=  questions.docs.map((d)=>{
      return {...d.data(),id:d.id}
    });
    // data.filter((value)=>{
    //   return value.platform=="expert" || value.platform=="both"
    // })
    return data.filter((value)=>{
      return value.platform=="expert" || value.platform=="both"
    });
  }


}

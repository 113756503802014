import React, { useState } from "react";
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import classnames from "classnames";

// styles
import useStyles from "./styles";

interface Props {
  title?: string;
  noBodyPadding?: any;
  bodyClass?: string;
  disableWidgetMenu?: boolean;
  header?: React.ReactElement;
  trailing?: React.ReactElement;
  [x: string]: any;
}

export default function Widget({
  children,
  title,
  noBodyPadding,
  bodyClass = "",
  disableWidgetMenu,
  header,
  trailing,
}: React.PropsWithChildren<Props>) {
  var classes = useStyles();

  // local
  var [moreButtonRef, setMoreButtonRef] = useState();
  var [isMoreMenuOpen, setMoreMenuOpen] = useState<boolean>(false);

  return (
    <div className={classes.widgetWrapper}>
      <Paper style={{marginTop:10}}
        variant="outlined"
        className={classes.paper}
        classes={{ root: classes.widgetRoot }}
      >
        <div className={classes.widgetHeader}>
          {header ? (
            header
          ) : (
            <React.Fragment>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {title}
              </Typography>
              {!disableWidgetMenu && (
                <IconButton
                  color="primary"
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  buttonRef={setMoreButtonRef}
                >
                  <MoreIcon />
                </IconButton>
              )}
              {trailing}
            </React.Fragment>
          )}
        </div>
        <div
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          {children}
        </div>
      </Paper>
      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem>
          <Typography>Share</Typography>
        </MenuItem>
        {/* <MenuItem>
          <Typography>Print</Typography>
        </MenuItem> */}
      </Menu>
    </div>
  );
}

import React from "react";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Button, Grid,  List, ListItem, Modal, Paper, } from "@material-ui/core";
import useStyles from "./style";
import {  Filter1, Filter2} from "@material-ui/icons";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
function ProjectcCreateSucccessDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.setOpenCongratsPopup(false)
    // setOpen(false);
  };

  return (

    <Modal
      open={props.open}
      // onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={classes.paymentSuccessPaper} style={modalStyle}>
        <Grid container
          direction="column"
          justifyContent="center"
          alignItems="center">
         <img src="/rocket.png" className={classes.paymentSuccessImg} />
          <Typography gutterBottom className={classes.paymentSuccessTitle}>
            Upload Successful!
          </Typography>
          <Typography className={classes.paymentSuccessText} gutterBottom>It's out there  and shared to the i3D Protocol Network for scoring by thousands of our analysts!</Typography>
          <Typography className={classes.paymentSuccessTitle} gutterBottom>What's next?</Typography>
          <List className={classes.paymentSuccessList}>
            <ListItem className={classes.paymentSuccessListItem}>
              <Filter1 className="pe-2 mb-4 fs-14"/>
              <Typography className={classes.paymentSuccessText} >Share with your own network! Invite them to download and score your Start-up in the i3D Rapid</Typography>
            </ListItem>
            <ListItem className={classes.paymentSuccessListItem}>
              <Filter2 className="pe-2 mb-4 fs-14"/>
              <Typography className={classes.paymentSuccessText} >Share with your Trusted Network for a Deep Dive using the  Share Button</Typography>
            </ListItem>
          </List>
          <Typography gutterBottom className={classes.paymentSuccessTitle}>
            Good Luck!
          </Typography>
          <Button variant="contained" style={{background:"#137DFB", color:"white"}} onClick={handleClose}>Ok</Button>
        </Grid>
      </Paper>
    </Modal>
  )
}

export default ProjectcCreateSucccessDialog;

import React, { useEffect, Dispatch, useState } from "react";
import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";

import {
	Fab,
	Input,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow, Tooltip
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Add as AddIcon, ChevronRight } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import SearchBar from "material-ui-search-bar";
import { searchString } from "../../actions/search";
import useStyles from "./styles";
import { ProjectCard } from "../../components/ProjectCard";
import { ProjectList } from "../../components/ProjectList";
import { RootState } from "../../reducers";
import { fetchAllDraftProjects } from "../../actions/project";
import ReactDOM from 'react-dom'
import {
	ProjectDispatch,
	LayoutAction,
	Project,
	SearchAction,
} from "../../model";
import { Typography } from "../../components/Wrappers/Wrappers";
import ListViewToggle from "../../components/ListToggle/ListToggle";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import { firestore, callableFunctions, storage } from "../../repositories/firebase";
import FadeIn from "react-fade-in";
import _ from "lodash";
import { overallWeightedScore } from "../create/components/data";
import { ViewType, SortingType, Filter, ScoreType } from "../../utils/enums";
import {
	getDefaultViewType,
	setDefaultViewType,
} from "../../utils/localstorage";
// import { ethers } from "ethers";
// import Web3 from 'web3';
import techscore from "../../repositories/techscore";
import ProjectGrid from "./ProjectGrid";
import html2pdf from 'html2pdf.js';
import ReactDOMServer from 'react-dom/server';
import { showPastDueModal, showUpgradeModal } from "../../actions/layout";
import DemoComponent from "../create/components/companyDetailPDF";
import { Grid } from "@mui/material";
import ProblemDefinition from "../create/components/ProblemDefinition";
import SubscriptionCard from "../create/components/plan";
// import { firestore } from "../../repositories/firestore";

declare var window: any


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);

const containerStyle = {
	backgroundColor: '#002060',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '500vh',
}

const getTotalNumberProjectOfUser = callableFunctions.httpsCallable('getTotalNumberProjectOfUser');

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#F2F7FF",
	},
}))(TableCell);
const LightTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);
function getDefaultSorting(search: string): Filter {
	const sorting = new URLSearchParams(search).get("sort");
	switch (sorting) {
		case "myLikes":
			return Filter.likes;
		case "myShare":
			return Filter.shares;
		case "myUploads":
			return Filter.uploads;
		default:
			return Filter.default;
	}
}


function _byRanking(a: Project, b: Project): number {
	return (a.sectionStats.overall ? a.sectionStats.overall.avgRating : 0) - (b.sectionStats.overall ? b.sectionStats.overall.avgRating : 0);
}

function _byTechnicalRanking(a: Project, b: Project): number {
	return (a.technical ? a.technical.overall : 0) - (b.technical ? b.technical.overall : 0);
}


function _byRapidRanking(a: Project, b: Project): number {
	return (a.sectionStats.i3DRapid ? a.sectionStats.i3DRapid.avgRating : 0) - (b.sectionStats.i3DRapid ? b.sectionStats.i3DRapid.avgRating : 0);
}

const pageStyle = {
	backgroundColor: '#002060',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minHeight: '200vh',
	flexDirection: 'column',
};

const imageStyle = {
	width: '100%',
	height: 'auto',
};
function Drafts() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [scoreType, setScoreType] = useState("Overall");
	const keys = [
		{ id: 1, label: "" },
		{ id: 2, label: "" },
		{ id: 3, label: "Name" },
		{ id: 4, label: "Industry" },
		{ id: 5, label: "Stage" },
		{ id: 6, label: "Description" },
		{ id: 7, label: "Experts" },
		{ id: 8, label: "Comments" },
		{ id: 9, label: scoreType + " Score" },
		{ id: 10, label: "" },
	];
	const project = useSelector((state: RootState) => state.project);
	const [userCreatedProject, setUserCreatedProject] = useState([])
	// const projectCollection = firestore.collection("project");
	const profile = useSelector((state: RootState) => state.firebase.profile);
	const [allProjects, setAllProject] = useState([])
	const search = useSelector((state: RootState) => state.search);
	const walletOpen = useSelector((state: RootState) => state.metamask.walletOpen);
	const userData = useSelector((state: RootState) => state.firebase.auth);
	const user = useSelector((state: RootState) => state.firebase.auth);
	const userCollection = firestore.collection("users");
	const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
	const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
	const dispatch = useDispatch<ProjectDispatch>();
	const [sortingType, setsortingType] = useState(SortingType.overallRanking);
	const [filter, setFilter] = useState(Filter.default);
	const [scoreSort, setScoreSort] = useState(ScoreType.default);
	const [weight, setWeight] = useState();
	const [pdfUrl, setPdfUrl] = useState('');
	const [openPlan, setOpenPlan] = useState(false)

	function _byOverallRanking(a: Project, b: Project): number {
		return overallWeightedScore(a, weight) - overallWeightedScore(b, weight);
	}
	
	const [sortedProjects, setsortedProjects] = useState<Project[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [viewType, setViewType] = useState<ViewType>(getDefaultViewType());
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		dispatch(fetchAllDraftProjects());
		fetchData()
	}, [dispatch]);
   
	async function fetchData() {
		try {
			const response = await getTotalNumberProjectOfUser();
			if (response.data) {
				let data = response.data
				setUserCreatedProject(data.userProjectData.map(p => p.projectId))
			}
		} catch (error) {
			console.error('Error:', error);
		}
	}

	function projectPerPlan(allProjects, projectId) {
		const resultArray = allProjects.filter((element) => !projectId.includes(element.id));
		return resultArray;
	}

	useEffect(() => {
		if (profile.isEmpty == false && profile.isLoaded == true) {
			if (profile.currentPlan) {
				const p = project.draftProjects
				console.debug("activeProject", p)
				setAllProject(p);
			} else {
				if (project.allProjects) {
					const p = projectPerPlan(project.allProjects, userCreatedProject);
					setAllProject(p)
				}
			}
		}
	}, [profile, project.draftProjects, userCreatedProject]);

	useEffect(() => {
		dispatchSearch(searchString(searchQuery));
	}, [dispatchSearch]);
	useEffect(() => {
		setFilter(getDefaultSorting(location.search));
	}, [location]);
	useEffect(() => {
		if (!allProjects.length) return;
		const type = sortingType.split("|")[0];
		const descending = sortingType.includes("desc");
		var sorted: Project[];
		if (scoreSort == ScoreType.technical) {
			sorted = allProjects.sort(_byTechnicalRanking)
			setScoreType("Technical");
		}
		if (scoreSort == ScoreType.expert) {
			sorted = allProjects.sort(_byRanking)
			setScoreType("Expert");
		}
		if (scoreSort == ScoreType.rapid) {
			sorted = allProjects.sort(_byRapidRanking)
			setScoreType("i3D Rapid");
		}
		if (scoreSort == ScoreType.default) {
			sorted = allProjects.sort(_byOverallRanking);
		}
		switch (sortingType) {
			// case SortingType.ranking:
			// 	sorted = project.allProjects.sort(_byRanking);
			// 	setScoreType("Expert");
			// 	break;
			// case SortingType.technicalRanking:
			// 	sorted = project.allProjects.sort(_byTechnicalRanking);
			// 	setScoreType("Technical");
			// 	break;
			case SortingType.overallRanking:
				// sorted = project.allProjects.sort(_byOverallRanking);
				// setScoreType("Overall");
				break;
			case SortingType.overallRankingReverse:
				// sorted = project.allProjects.sort(_byOverallRanking);
				// setScoreType("Overall");
				break;
			// case SortingType.rankingReversed:
			// 	sorted = project.allProjects.sort(_byRanking);
			// 	break;
			default:
				sorted = _.sortBy(allProjects, [type]);
				break;
		}
		
	

			setsortedProjects(descending ? [...sorted.reverse()] : [...sorted]);
		


	}, [allProjects.length > 0, sortingType, search.searchString, scoreSort]);
	useEffect(() => {
		techscore.fetchWeights().then((res) => {
			setWeight(res);
		})
	}, []);
	useEffect(() => {
		const timeoutId = setTimeout(() => {
		  setLoading(false); // Set loading to false after the timeout
		}, 5000); // Adjust the timeout duration as needed (in milliseconds)
	
		// Cleanup the timeout if the component unmounts or if the fetching is completed
		return () => clearTimeout(timeoutId);
	  }, []);
	if (project.fetching && loading){
		return <Loader text="Fetching your Draft projects..." />;

	}
// console.debug("project.fetching",project.fetching)
// 	if (project.fetching ){
// 		return <Loader text="Fetching your Draft projects..." />;

// 	}
// 	else{

// 	}
	
	function openPlanPage(e: React.MouseEvent): void{
		e.preventDefault();
		setOpenPlan(true)
	}

	const userName = user.displayName?.split(" ")[0] ?? "there!";

	return (
		<>
			<>

				<PageTitle
					noMargin
					title={"Drafts"	}
					extra={

						<div
							style={{
								display: "flex",
							}}
						>
							<div className={classes.rankedContent}>
								<Typography className={classes.typo} variant="h3" size="sm">
									Ranked by:
								</Typography>
								<LightTooltip title="Overall" placement="top">
									<div className={classes.circleRank} onClick={(e) => {
										setsortingType(
											SortingType.overallRanking as SortingType
										)
										setScoreSort(ScoreType.default);
										setScoreType("Overall");
									}
									}><img src="/Buttons-02.png" className={scoreType == "Overall" ? "" : classes.disabledImage} /></div>
								</LightTooltip>
								<LightTooltip title="Expert" placement="top">
									<div className={classes.circleRank} onClick={(e) => {
										// if (walletOpen) {
										// 	history.push('/connect')
										// }
										setsortingType(
											SortingType.overallRanking as SortingType
										)
										setScoreSort(ScoreType.expert);
									}

									}><img src="/Buttons-04.png" className={scoreType == "Expert" ? "" : classes.disabledImage} /></div>
								</LightTooltip>
								<LightTooltip title="Technical" placement="top">
									<div className={classes.circleRank} onClick={(e) => {
										// if (walletOpen) {
										// 	history.push('/connect')
										// }
										setsortingType(
											SortingType.overallRanking as SortingType
										)
										setScoreSort(ScoreType.technical);
									}
									}><img src="/Buttons-03.png" className={scoreType == "Technical" ? "" : classes.disabledImage} /></div>
								</LightTooltip>
								<LightTooltip title="i3D Rapid" placement="top">
									<div className={classes.circleRank} onClick={(e) => {
										// if (walletOpen) {
										// 	history.push('/connect')
										// }
										setsortingType(
											SortingType.overallRanking as SortingType
										)
										setScoreSort(ScoreType.rapid);
									}
									}><img src="/Buttons-01.png" className={scoreType == "i3D Rapid" ? "" : classes.disabledImage} /></div>
								</LightTooltip>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-end",
								}}
							>
								<FormControl variant="filled">
									{/* <InputLabel id="sort">Sort By</InputLabel> */}
									<Select
										id="sort"
										label="Sorting"
										value={sortingType}
										style={{
											height: 40,
											backgroundColor: "#F4F6F9",
										}}
										onChange={(e) => {
											// if (walletOpen) {
											// 	history.push('/connect')
											// }
											setsortingType(
												e.target.value as SortingType
											)
										}
										}
										input={
											<Input
												classes={{
													input: classes.selectInput,
												}}
											/>
										}
									>
										<MenuItem value={SortingType.default}>
											Sort/(A-z)
										</MenuItem>
										<MenuItem
											value={SortingType.defaultReversed}
										>
											Alphabetical (z-A)
										</MenuItem>
										<MenuItem value={SortingType.date}>
											Newest - Oldest
										</MenuItem>
										<MenuItem value={SortingType.dateReversed}>
											Oldest - Newest
										</MenuItem>
										<MenuItem value={SortingType.overallRanking}>
											Score High - Low
										</MenuItem>
										<MenuItem
											value={SortingType.overallRankingReverse}
										>
											Score Low - High
										</MenuItem>
										<MenuItem value={SortingType.sector}>
											Sector
										</MenuItem>
									</Select>
								</FormControl>
								<ListViewToggle
									checked={viewType === ViewType.list}
									onChange={(_, checked) => {
										// if (walletOpen) {
										// 	history.push('/connect')
										// }
										const viewType = checked
											? ViewType.list
											: ViewType.grid;
										setViewType(viewType);
										setDefaultViewType(viewType);
									}}
								/>
							</div>
						</div>
					}
				/>
				{/* */}


				{<div className={classes.container}>
					{viewType === ViewType.grid ? (
						<>
						{loading ?
						<Loader text="Fetching your Draft projects..." />:
						<ProjectGrid scoreSort={scoreSort} sortedProjects={sortedProjects} filter={filter} walletOpen={walletOpen} />
						}
						</>
					) : (
						<TableContainer>
							<Table stickyHeader className="mb-0">
								<TableHead>
									<TableRow>
										{keys.map((key, index) => (
											<StyledTableCell key={index} className={classes.labelHead} >{key.label}</StyledTableCell>
										))}
									</TableRow>
								</TableHead>
																<TableBody>
									{sortedProjects.length > 0? sortedProjects.filter((v) =>
										v.company.name
											.toLowerCase()
											.includes(search.searchString.toLowerCase())
									).map((project, index) => {
										if (filter == Filter.uploads) {
											if (project.requester == user.uid) {
												return <ProjectList filter={scoreSort} key={index} project={project} pageType={'draft'} />
											}
										}
										else if (filter == Filter.likes) {
											if (project.likes) {
												return <ProjectList filter={scoreSort} key={index} project={project} pageType={'draft'} />
											}
										}
										else if (filter == Filter.shares) {
											if (project.shared ? project.shared : "") {
												return <ProjectList filter={scoreSort} key={index} project={project} pageType={'draft'} />
											}
										}
										else {
											return <ProjectList filter={scoreSort} key={index} project={project}  pageType={'draft'} />
										}

									}):<div>No Data Found</div>}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</div>}
			</><SubscriptionCard open={openPlan} setOpenPlan={setOpenPlan}/>
		</>
	);

	function goToCreate(e: React.MouseEvent): void {
		e.preventDefault();
		if (profile.currentPlan) {
			let plan = profile.currentPlan
			if (plan.uploadProject != plan.uploadedProject && plan.uploadProject >= plan.uploadedProject) {
				return history.push("/create");
			} else {
				return dispatchLayout(showUpgradeModal());
			}
		} else {
			console.debug("else mein gaya")
			openPlanPage(e)
			// setOpenPlan(true)
		}

	}

}



export default Drafts;
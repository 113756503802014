import React, { useState,useEffect } from "react";
import {  useLocation } from "react-router-dom";
import { Button,Paper,Grid,  Typography } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { ToggleButtonGroup } from 'formik-material-ui-lab';
import { Field } from 'formik';
import { useFormikContext } from "formik";
import { ScoreForm } from "../../model/responses";
// styles
import useStyles from "./styles";
function NewScoreForm(props) {
    const classes = useStyles();
    const location = useLocation();
    const [subt,setSubt] = useState(true);
    const searchParams = new URLSearchParams(location.search);
    const formik = useFormikContext<ScoreForm>();

    useEffect(() => {
        var data = formik.values;
        var som = true;
        props.questions.forEach((val)=>{
            if(data[val.id]<=4 && (!(val.id+"_comment" in data) || (data[val.id+"_comment"]=="")))
            {
                som = false;
            }

        })
        setSubt(som);
    }, [formik.values]);
    
    console.debug("Question", props)
    function getQuestions(data, index) {
        return (
            <li className={classes.questions} key={index}>
                <Paper style={{padding: 10,marginRight:10}}>
                <Typography className={classes.questions}> {data.question}
                    <sup><InfoOutlinedIcon style={{ fontSize: 15, }} color="disabled" /></sup>
                </Typography>
                <Typography className={classes.comment}>
                    {data.context}
                    {/* A lack of passion and vision can put lomg-term success in jeopardy */}
                </Typography>
                </Paper>
                <Field aria-label="outlined dark button group" component={ToggleButtonGroup} exclusive className={classes.rating} name={data.id} type="checkbox">
                    <ToggleButton value={0}>0</ToggleButton>
                    <ToggleButton value={1}>1</ToggleButton>
                    <ToggleButton value={2}>2</ToggleButton>
                    <ToggleButton value={3}>3</ToggleButton>
                    <ToggleButton value={4}>4</ToggleButton>
                    <ToggleButton value={5}>5</ToggleButton> 
                    <ToggleButton value={6}>6</ToggleButton>
                    <ToggleButton value={7}>7</ToggleButton>
                    <ToggleButton value={8}>8</ToggleButton>
                    <ToggleButton value={9}>9</ToggleButton>
                    <ToggleButton value={10}>n/a</ToggleButton>
                </Field>
                {formik.values[data.id]<=4?<Typography color={"error"}>If you scored 4 or less please add some comment</Typography>:""}
                {/* <Typography className={classes.comment}> N/A </Typography> */}
                {/* <TextareaAutosize className={classes.commentBoxExpert} aria-label="maximum height" rowsMin={4} placeholder="" /> */}
                {/* <Input
                    className={classes.commentBoxExpert}
                    name={data.id+"_comment"}
                    style={{ width: "65%", borderRadius: 10 }}
                    disabled={!data.comment}
                    placeholder= "Add your comment here"
                /> */}
                <Field
                    className={classes.commentBoxExpert}
                    name={data.id+"_comment"}
                    style={{ width: "65%", borderRadius: 10, padding: "30px 10px" }}
                    disabled={!data.comment}
                    placeholder= "Add your comment here"
                    component="textarea">
                </Field>
            </li>
        );
    }


    return (
        <Grid item xs={12}>
            <ol className={classes.scoreform}>
                {props.questions.map((data, index) => getQuestions(data, index))}
            </ol>

            {/* <div>
                <div className={classes.buttonMargin}>
                    
                </div> */}
                {subt?<div className={classes.startButton} >
                <Button
                    onClick={() => props.handleSubmitScore()}
                >
                    Submit
                </Button>
            </div>:""}
            
            {/* </div> */}


        </Grid>
    );
}

export default NewScoreForm;
